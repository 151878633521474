import React from "react";
import {
    CustomField,
    DateField,
    getArrayDataContent,
    SelectedChoiceField,
    StatusIconField,
    TextField,
    useCrudProps
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, StatisticsTable} from "@stratos/reporting";

export interface GatewayLicensesCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            gatewayType?: string;
        };
    };
}


export const GatewayLicensesCard: React.FC<GatewayLicensesCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const {id} = details || {};
    const [translate] = useTranslation();
    const licenseStateChoices = getArrayDataContent(useCrudProps(apiResources.licensesStates)[0]?.data);
    const licenseTypeChoices = getArrayDataContent(useCrudProps(apiResources.licensesTypes)[0]?.data);
    const unsupported = additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";

    return (
        <BaseCard
            id="GatewayLicensesCard"
            title={translate("tesseract.appliances.dashboard.summary.licenses.title")}
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        >
            <StatisticsTable
                resource={id ? apiResources.licensesGateways : undefined}
                params={{id}}
                formatData={(data) => data?.licenseStates || []}
                minCellWidth={64}
            >
                <StatusIconField
                    label="tesseract.appliances.dashboard.summary.licenses.status"
                    source="state"
                />
                <TextField
                    label="tesseract.appliances.dashboard.summary.licenses.serial"
                    source="instanceId"
                />
                <SelectedChoiceField
                    label="tesseract.appliances.dashboard.summary.licenses.state"
                    source="state"
                    key="textState"
                    choices={licenseStateChoices}
                />
                <SelectedChoiceField
                    source="type"
                    label="tesseract.appliances.dashboard.summary.licenses.type"
                    choices={licenseTypeChoices}
                />
                <CustomField
                    source="expiryDate"
                    label="tesseract.appliances.dashboard.summary.licenses.expirationDate"
                    render={(_expiryDate, data) => !(data.type === "payg" && data.state === "ok")
                        ? <DateField data={data} source="expiryDate"/>
                        : <TextField source="data"
                                     data={{data: translate("tesseract.appliances.dashboard.summary.licenses.noData")}}/>
                    }
                />
            </StatisticsTable>
        </BaseCard>
    );
};