import {
    ActionDialogContent,
    ButtonDialog,
    CrudTypes,
    getDataContent,
    useCrudFetch,
    useCrudSubscription,
    useDeepCompareEffect
} from "@cuda-react/core";
import {FirewallIcon, VpnIcon} from "@cuda-react/icons";
import React, {useState} from "react";
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {DropDownArrow} from "@barracuda-internal/bds-core/dist/Icons/Controls";
import {Refresh} from "@barracuda-internal/bds-core/dist/Icons/Core";
import apiResources from "../../../../apiResources";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

interface RestartSiteDialogProps {
    id?: number | string,
    serials?: any
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    restartButtonStyle: {
        marginLeft: theme.spacing(1)
    }
}));

const RestartSiteDialog = (props: RestartSiteDialogProps) => {
    const {id, serials} = props;
    const [restartEnabled, setRestartEnabled] = useState(false);
    const classes = useStyles(props);
    const [restartAvailableSerial1, loadingAvailable1, refreshSerial1] = useCrudSubscription(
        CrudTypes.GET,
        serials && serials.length && apiResources.restartAvailable,
        serials && {siteId: id, id: serials[0]},
        {pollInterval: restartEnabled ? 300000 : 30000}
    );
    const [restartAvailableSerial2, loadingAvailable2, refreshSerial2] = useCrudSubscription(
        CrudTypes.GET,
        serials && serials.length > 1 && apiResources.restartAvailable,
        serials && {siteId: id, id: serials[1]},
        {pollInterval: restartEnabled ? 300000 : 30000}
    );
    const restartAvailable = [getDataContent(restartAvailableSerial1.data), getDataContent(restartAvailableSerial2.data)];

    useDeepCompareEffect(() => {
        setRestartEnabled(Boolean(restartAvailable[0] || restartAvailable[1]));
    }, [restartAvailable]);

    const [, boxLoading, requestRestartBox] = useCrudFetch(CrudTypes.CREATE, apiResources.restartBox, {id});
    const [, vpnLoading, requestRestartVpn] = useCrudFetch(CrudTypes.CREATE, apiResources.restartVpn, {id});
    const [, firmwareLoading, requestRestartFirmware] = useCrudFetch(CrudTypes.CREATE, apiResources.restartFirmware, {id});
    const restartAll = (requestFunction: any) => () => {
        serials.forEach((serial: string, index: number) => {
            if (restartAvailable[index]) {
                requestFunction({serial}).then(index === 0 ? refreshSerial1 : refreshSerial2);
            }
        });
        return true;
    };

    const renderMessage = (i18nKey: string) => (
        <Typography>
            <Trans i18nKey={i18nKey} count={serials && serials.length}>
                <br/>
            </Trans>
        </Typography>
    );

    const restartAvailableDataLoading = loadingAvailable1 || loadingAvailable2;
    const loading = boxLoading || vpnLoading || firmwareLoading;
    const canRestart = restartEnabled && !restartAvailableDataLoading && !loading;

    return (
        <ButtonDialog
            useMenu
            buttonIcon={<DropDownArrow/>}
            buttonText="tesseract.appliances.restart.buttonLabel"
            disabled={!canRestart}
            buttonClasses={{button: classes.restartButtonStyle}}
        >
            <ActionDialogContent
                // @ts-ignore: Read by ButtonDialog, so missing from proptypes of ActionDialogContent
                icon={<Refresh/>}
                label="tesseract.appliances.restart.box.menuChoice"
                message={renderMessage("tesseract.appliances.restart.box.confirmMessage")}
                onConfirm={restartAll(requestRestartBox)}
                title="tesseract.appliances.restart.box.dialogTitle"
            />
            <ActionDialogContent
                // @ts-ignore: Read by ButtonDialog, so missing from proptypes of ActionDialogContent
                icon={<VpnIcon/>}
                label="tesseract.appliances.restart.vpn.menuChoice"
                message={renderMessage("tesseract.appliances.restart.vpn.confirmMessage")}
                onConfirm={restartAll(requestRestartVpn)}
                title="tesseract.appliances.restart.vpn.dialogTitle"
            />
            <ActionDialogContent
                // @ts-ignore: Read by ButtonDialog, so missing from proptypes of ActionDialogContent
                icon={<FirewallIcon/>}
                label="tesseract.appliances.restart.firmware.menuChoice"
                message={renderMessage("tesseract.appliances.restart.firmware.confirmMessage")}
                onConfirm={restartAll(requestRestartFirmware)}
                title="tesseract.appliances.restart.firmware.dialogTitle"
            />
        </ButtonDialog>
    );
};


export default RestartSiteDialog;