import React from "react";
import {LocationsCardContent} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {useTheme} from "@mui/material";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

export interface SitesTunnelLocationsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: number | string,
            name?: string,
            gateway?: string | number,
        },
    }
}

export const SitesTunnelLocationsCard: React.FC<SitesTunnelLocationsCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const theme = useTheme();
    // Determine if this is a stand-alone site (no gateway provided)
    const isStandaloneSite = !details?.gateway;

    return (
        <BaseCard
            id="SitesTunnelLocationsCard"
            title={isStandaloneSite ? "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.title" : "tesseract.appliances.dashboard.tunnelLocations.title"}
            size={size}
            preview={preview}
        >
            {details?.name && isStandaloneSite ? (
                <LocationsCardContent
                    params={{id: details?.id}}
                    resource={apiResources.standaloneSiteLocation}
                    series={[{
                        label: "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.label",
                        color: theme.palette.general.graphite,
                    }]}
                />
            ) : null}
            {details?.name && !isStandaloneSite ? (
                <LocationsCardContent
                    params={{id: details?.id}}
                    resource={apiResources.siteTunnelLocations}
                    series={[
                        {
                            label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                            color: theme.palette.general.purple,
                        },
                        {
                            label: "tesseract.appliances.dashboard.tunnelLocations.sites.label",
                            color: theme.palette.general.blue,
                            source: "sites"
                        }
                    ]}
                />
            ) : null}
        </BaseCard>
    );
};