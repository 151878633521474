import React from 'react';
import {BaseBarCard} from "../../baseCards";
import {colorFunctionSuccess, numberFormatter} from "../../../utils";
import {AppsIcon} from "../../icons";
import {Trans, useTranslation} from "react-i18next";
import {useReportingData} from "../../../contexts";
import {ApplicationDataPoint, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {Typography} from "@barracuda-internal/bds-core";
import {useTheme} from "@mui/material";

export interface TopAllowedApplicationsSessionsCardProps extends DashboardCardProps {
}

export const TopAllowedApplicationsSessionsCard = (props: TopAllowedApplicationsSessionsCardProps) => {
    const {preview, size = []} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopAllowedApplicationsSessionsCard", preview);
    const {applicationDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...applicationDataPoints.map((category: ApplicationDataPoint) => category.numberOfSessions));
    const entries: BarGridEntry[] = sortBy(applicationDataPoints, "numberOfSessions").reverse().map((category: ApplicationDataPoint) => ({
        title: category.label,
        value: size === CardSizes.small ?
            numberFormatter(category.numberOfSessions) :
            translate("stratosReporting.allowedApplicationsSessions.sessions", {
                count: category.numberOfSessions,
                formattedCount: numberFormatter(category.numberOfSessions)
            }),
        barProgress: (100.0 / maxValue) * category.numberOfSessions,
        color: colorFunctionSuccess((100.0 / maxValue) * category.numberOfSessions, theme),
        onClick: (category.label && addFilter) ? () => addFilter({key: category.id, name: category.label}, "applicationDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.allowedApplicationsSessions.title">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            rowIcon={<AppsIcon size={20}/>}
            loading={loading && !data.applicationDataPoints}
            noData={!loading && !data.applicationDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};