import React from "react";
import {
    ConnectedForm,
    DialogBody,
    getArrayDataContent,
    HiddenInput,
    SearchableSelectInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validateMaxLengthMemo,
    validateRegexMemo,
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {formatRequestCriteria, formatResourceCriteria} from "../../../../../components/sourceAndDestination/formUtils";
import generateDestinationCriteriaSection
    from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {settingsIcons} from "../../../../../components/iconMapping";
import {useGeneratedSourceCriteriaSection} from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import {get} from "lodash";


const useStyles = makeStyles({
    dialogContent: {},
    multiInputButtons: {
        width: 74,
        // @ts-ignore
        flexGrow: "0!important",
        flexShrink: 0,
        height: 50,
        marginLeft: 0,
        marginRight: 8
    },
    multiInputInputGroup: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            maxWidth: "calc(100% - 122px)"
        }
    },
    multiInputDivider: {
        width: "calc(100% - 64px)",
        maxWidth: "calc(100% - 64px) !important",
        marginBottom: -4
    },
    multiInputNetworkSelectChips: {
        marginLeft: "calc(-100% - 8px)",
        maxWidth: "calc(200% + 186px)",
        width: "calc(200% + 186px)"
    }
});

/**
 * For now we are using this workaround to get the karma tests working.
 */
interface CreateEditApplicationControlProps {
    create?: boolean,
    defaultScope?: string,
    id?: string | number,
    onClose?: () => void,
    type: string,
    onSuccess?: () => void
}

const hideInput = (key: string, type: string) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

const CreateEditApplicationControl: React.FC<CreateEditApplicationControlProps> = (props) => {
    const {create, defaultScope, id, type, onSuccess, ...dialogProps} = props;
    const classes = useStyles(props);
    const isGateway: boolean | any = type === "gateway";
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.applicationControlActions)[0]?.data);
    const icmpChoices = getArrayDataContent(useCrudProps(apiResources.applicationControlIcmp)[0]?.data);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const registerAction = useMixpanel(isGateway ? "Gateway ACL" : "Site ACL", false);
    const selectAllValue = isGateway ? "allGateways" : "allSites";

    return (
        <DialogBody
            {...dialogProps}
            form
            title={`tesseract.security.applicationControl.settings.${create ? "create" : "edit"}.dialogTitle`}
            classes={{dialogContent: classes.dialogContent}}
        >
            <ConnectedForm
                resource={apiResources.applicationControl}
                // @ts-ignore
                form="applicationControl"
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response: any) => {
                    onSuccess?.();
                    // @ts-ignore
                    dialogProps.onClose();
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type,
                        destinationType: response.destination?.type,
                        scopeAll: response.scope === selectAllValue
                    });
                }}
                onCancel={dialogProps.onClose}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {
                    type,
                    scope: defaultScope || selectAllValue,
                    source: {allSites: true},
                    destination: {allSites: true},
                    loggingEnabled: true
                } : undefined}
                allowDirtyNavigation
            >
                <SearchableSelectInput
                    source="scope"
                    label="tesseract.security.applicationControl.settings.scope"
                    description="tesseract.security.applicationControl.settings.descriptions.scope"
                    resource={isGateway ? apiResources.gatewayNames : apiResources.siteNames}
                    optionValue="uuid"
                    options={!isGateway ? {params: {filter: {modelSeries: "T"}}} : undefined}
                    selectAllValue={selectAllValue}
                    selectAllText={`tesseract.security.applicationControl.settings.${isGateway ? "allGateways" : "allSites"}`}
                    selectAll
                    clearOnFocus
                    isRequired
                />
                <TextInput
                    source="name"
                    label="tesseract.security.applicationControl.settings.name"
                    description="tesseract.security.applicationControl.settings.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.applicationControl.settings.description"
                    description="tesseract.security.applicationControl.settings.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.applicationControl.settings.action"
                    description="tesseract.security.applicationControl.settings.descriptions.action"
                    iconMap={settingsIcons}
                    choices={actionChoices}
                    isRequired
                />
                <HiddenInput
                    source="icmp"
                    hiddenValue="block"
                    hide={hideInput("action", "block")}
                />
                <SelectInput
                    source="icmp"
                    label="tesseract.security.applicationControl.settings.icmp"
                    description="tesseract.security.applicationControl.settings.descriptions.icmp"
                    iconMap={settingsIcons}
                    choices={icmpChoices}
                    disable={hideInput("action", "allow")}
                    isRequired
                />
                {useGeneratedSourceCriteriaSection([isGateway && "application", "network", "onPremGateway", "site", "userOrGroup", "secureEdgeConnector"])}
                {generateDestinationCriteriaSection(["network", "application", "onPremGatewayNetworks", "site", "secureEdgeConnector"])}
            </ConnectedForm>
        </DialogBody>
    );
};


export default CreateEditApplicationControl;