import React from 'react';
import {IncidentBaseCard} from "../../baseCards";
import {CardSizes, DashboardCardProps, IpsDataPoint} from "../../../typesAndConstants";
import {useTranslation} from "react-i18next";
import {useReportingData} from "../../../contexts";
import {BarGridEntry} from "../../statistics/BarGrid";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {sortBy} from 'lodash';
import {IpsIcon} from "../../icons";
import {useTheme} from "@mui/material";

export interface IpsIncidentsCardProps extends DashboardCardProps {
}

/**
 * Shows the total number of malware detected and the top of the list
 *
 * @param props
 * @constructor
 */
export const IpsIncidentsCard = (props: IpsIncidentsCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("IpsIncidentsCard", preview);
    const {ipsDataPoints = []} = data || {};
    const theme = useTheme();

    const maxEntry = Math.max(...ipsDataPoints.map((ipsDataPoint: IpsDataPoint) => ipsDataPoint.numberOfSessions));
    const total = numberFormatter(ipsDataPoints.reduce((ipsTotal: number, ipsDataPoint: IpsDataPoint) => ipsTotal + ipsDataPoint.numberOfSessions, 0));
    const entries: BarGridEntry[] = sortBy(ipsDataPoints, "numberOfSessions").reverse().map((ipsDataPoint: IpsDataPoint) => ({
        title: ipsDataPoint.label,
        value: size === CardSizes.small ?
            numberFormatter(ipsDataPoint.numberOfSessions) :
            translate("stratosReporting.ipsIncidentCard.sessions", {
                count: ipsDataPoint.numberOfSessions,
                sessions: numberFormatter(ipsDataPoint.numberOfSessions)
            }),
        barProgress: (100.0 / maxEntry) * ipsDataPoint.numberOfSessions,
        color: colorFunctionError((100.0 / maxEntry) * ipsDataPoint.numberOfSessions, theme),
        icon: <IpsIcon size={20}/>,
        onClick: addFilter ? () => addFilter({key: ipsDataPoint.id, name: ipsDataPoint.label}, "ipsDataPoints") : undefined,
        tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <IncidentBaseCard
            icon={<IpsIcon/>}
            title="stratosReporting.ipsIncidentCard.title"
            entries={entries}
            loading={loading && !data.ipsDataPoints}
            noData={!loading && !data.ipsDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            total={total}
            activeFilter
            {...props}
        />
    );
};