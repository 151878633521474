import React from "react";
import {
    ChipArrayField,
    getArrayDataContent,
    LinkField,
    ListCardContent,
    SelectedChoiceField,
    TextField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {StatusIcon} from "@cuda-react/icons";
import apiResources from "./../../../../apiResources";
import {makeStyles} from "@mui/styles";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import {siteGatewayStatusIcons} from "../../../../components/iconMapping";

const useStyles = makeStyles((theme) => ({
    serialIconSpan: {
        lineHeight: 3,
        marginLeft: -7,
        "& svg": {
            position: "relative",
            top: 7,
            marginRight: theme.spacing(1)
        }
    }
}));

export interface GatewaySummaryCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            gatewayType?: string;
            gateway?: number;
        };
    };
}

export const GatewaySummaryCard: React.FC<GatewaySummaryCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const {gatewayType} = details || {};
    const classes = useStyles();
    const [translate] = useTranslation();
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const isOnPremGateway = gatewayType === "on-prem";
    const isManagedGateway = gatewayType === "managed";

    return (
        <BaseCard
            id="GatewaySummaryCard"
            title={translate("tesseract.appliances.dashboard.summary.status.tabTitle")}
            size={size}
            preview={preview}
        >
            <ListCardContent data={details}>
                {isOnPremGateway ? (
                    <TextField label="tesseract.appliances.dashboard.summary.siteStatus.model" source="model"/>
                ) : null}
                <LinkField
                    source="hubName"
                    label="tesseract.appliances.dashboard.summary.status.hub"
                    link={getAzureHubLink("hubId", isDemo)}
                    filter="text"
                    sortable
                />
                <LinkField
                    source="cloudVirtualWan.name"
                    label="tesseract.appliances.dashboard.summary.status.vwan"
                    link={getAzureResourceLink("cloudVirtualWan.resourceId", isDemo)}
                    sortable
                />
                <SelectedChoiceField
                    source="region"
                    label="tesseract.appliances.dashboard.summary.status.region"
                    choices={getArrayDataContent(regionChoices)}
                    sortable
                />
                <ChipArrayField
                    label={isOnPremGateway ? "tesseract.appliances.dashboard.summary.gateway.serial" : isManagedGateway ? "tesseract.appliances.dashboard.summary.gateway.publicIP" : "tesseract.appliances.dashboard.summary.gateway.publicIPs"}
                    source="connectionStatus.connectionStates"
                    render={(val) => (
                        <span className={classes.serialIconSpan}>
                            <StatusIcon
                                iconMap={siteGatewayStatusIcons}
                                status={val.state}
                            />
                            {val.id || translate("tesseract.appliances.dashboard.summary.gateway.instanceNotFound")}
                        </span>
                    )}
                />
            </ListCardContent>
        </BaseCard>
    );
};