import React from "react";
import AccountTitle from "../AccountTitle/AccountTitle";
import BreadcrumbsTitle, {BreadcrumbsTitleProps} from "../BreadcrumbsTitle/BreadcrumbsTitle";
import Authenticated from "../../authentication/Authenticated/Authenticated";
import {makeOverrideableStyles} from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {useGlobalParam} from "../../../hooks";
import {createStyles} from "@mui/styles";
import {Skeleton, Theme} from "@mui/material";

export const styles = (theme: Theme) => createStyles<string, BasicPageProps>({
    root: {
        width: "100%",
        margin: 0,
        height: (props) => props.title ? undefined : 0
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        height: 44
    },
    actions: {
        margin: (props) => (props.title || props.loading) ? theme.spacing(0, 1.5, 0, 0) : theme.spacing(0),
        height: (props) => (props.title || props.loading) ? "100%" : 0,
        display: "inline-flex",
        alignItems: "center",
        float: "right",
    },
    action: {
        marginLeft: theme.spacing(1)
    },
    content: {
        margin: (props) => (props.title || props.loading) ? `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}` : 0
    },
    titleBar: (props) => (props.title || props.loading) ? {
        margin: `0 ${theme.spacing(1.5)}`,
        padding: 0,
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } : {
        margin: `0 ${theme.spacing(1.5)}`,
        padding: 0,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
});

const useStyles = makeOverrideableStyles("BasicPage", styles);

export interface BasicPageProps {
    /**
     * if true, renders content in an [Authenticated](/?path=/docs/core-components-authentication-authenticated--authenticated) component, which enforces a successful auth check before it renders.
     */
    authenticated?: boolean,
    /**
     * authParams prop passed to the [Authenticated](/?path=/docs/core-components-authentication-authenticated--authenticated) component (if authenticated is provided).
     */
    authParams?: any,
    /**
     * a single or array of children to render as actions. Usually these are buttons.
     */
    actions?: React.ReactNode | Array<React.ReactNode>,
    /** the page content. */
    children: React.ReactNode,
    /**
     * show a loading skeleton instead of a title (use while the poge title is being loaded asynchronously).
     */
    loading?: boolean

    /**
     * the title to render. Can be a simple string, or a custom node.
     *
     * Pages created without titles are assumed to be a nested page. So the margins are removed, and the actions are floated
     * higher to place themselves within the parent page's existing title bar.
     *
     * To avoid unintentional flickering between these 2 styles, please ensure that any "calculated" titles are still initialised
     * and provided as a non-empty string.
     */
    title?: BreadcrumbsTitleProps["title"],
    /**
     * the sub title to render after the main title. Used to designate child pages
     */
    subtitle?: BreadcrumbsTitleProps["subtitle"],
    /**
     * if true shows a separate page title inside the page container
     */
    showSeparateTitle?: boolean
}

/**
 * Renders a basic page, with a title and content section.
 *
 * Optionally you can flag the page as "authenticated". This places the content within an
 * [Authenticated](/?path=/docs/core-components-authentication-authenticated--authenticated) component, which enforces a
 * successful auth check before it renders.
 *
 * Pages created without titles are assumed to be a nested page. So the margins are removed, and the actions (if provided)
 * are floated higher to place themselves within the parent page's existing title bar.
 *
 * To avoid unintentional flickering between these 2 styles, please ensure that any async or calculated titles are still initialised
 * and provided as a non-empty string (such as " ").
 *
 * The account title can be set, by setting the "pageTitle.account" global param. This can be either a string, or a list of strings, as supported
 * by the title prop of AccountTitle.
 */
export const BasicPage = (props: BasicPageProps) => {
    const {actions, children, title, subtitle, authenticated, authParams, loading} = props;
    const classes = useStyles(props);
    const arrayActions = actions && (Array.isArray(actions) ? actions : [actions]);
    const accountTitle = useGlobalParam("pageTitle.account")[0];

    return (
        <ErrorBoundary>
            <div className={classes.root} id="cuda-react-page">
                {loading || title || actions ? (
                    <div className={classes.titleBar}>
                        <div className={classes.titleContainer}>
                            {(loading || title) && accountTitle && (
                                <AccountTitle
                                    title={accountTitle}
                                />
                            )}{loading ? (
                            <Skeleton sx={{width: 200, height: 20, transform: "none"}}/>
                            ) : (title && (
                                <BreadcrumbsTitle
                                    title={title}
                                    subtitle={subtitle}
                                />
                            ))}
                        </div>
                        {arrayActions && (
                            <span className={classes.actions}>
                                {arrayActions.map((action, index) => (
                                    <span key={index} className={classes.action}>{action}</span>
                                ))}
                            </span>
                        ) || null}
                    </div>
                ) : null}
                {authenticated ? (
                    <Authenticated authParams={authParams}>
                        <div className={classes.content}>
                            {children}
                        </div>
                    </Authenticated>
                ) : (
                    <div className={classes.content}>
                        {children}
                    </div>
                )}
            </div>
        </ErrorBoundary>
    );
};

export default BasicPage;