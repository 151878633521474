import React from "react";
import {useMixpanel, usePreview} from "@cuda-react/core";
import {CardSizes, DashboardPage} from "@stratos/reporting";
import {useTranslation} from "react-i18next";
import useCustomDashboard from "../../../hooks/useCustomDashboard";
import {useIoTDashboardCards} from "./cards";

interface IoTDashboardProps {
    details: {
        id: string;
        name?: string;
        serials?: string[];
        uuid?: string;
        modelSeries?: "S" | "T";
        model?: string;
        applicationId?: string | number;
    };
    type: "gateways" | "sites";
}

export const ioTDashboardCards = [
    {componentId: "SiteSummaryCard", size: CardSizes.small},
    {componentId: "SiteGatewayCard", size: CardSizes.small},
    {componentId: "IoTTunnelLocationsCard", size: CardSizes.large},
    {componentId: "FirmwareDetailsCard", size: CardSizes.small},
    {componentId: "ConfigurationUpdatesCard", size: CardSizes.small},
    {componentId: "DeploymentStatusCard", size: CardSizes.medium},
    {componentId: "DeploymentLocationsCard", size: CardSizes.large},
    {componentId: "DeploymentEventsCard", size: CardSizes.large}
];

const IoTDashboard = (props: IoTDashboardProps) => {
    const {type, details} = props;
    const [translate] = useTranslation();
    const expert = usePreview("expert");
    const templateDashboards = [{
        name: translate("tesseract.dashboard.status"),
        cards: ioTDashboardCards,
        template: translate("tesseract.dashboard.status")
    }, {
        name: translate("tesseract.dashboard.emptyTemplate"),
        cards: []
    }];
    const defaultDash = templateDashboards.slice(0, 1);
    const availableCards = useIoTDashboardCards(expert);
    const {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards
    } = useCustomDashboard("iotDashboard");

    useMixpanel(
        "IoT Dashboard",
        !!details.model,
        {model: details.model || "", type: details.modelSeries || ""}
    );

    return (
        <DashboardPage
            id="IoTDashboard"
            availableCards={availableCards}
            defaultDashboards={defaultDash}
            templates={templateDashboards}
            remoteStoredDashboards={remoteStoredCustomDashboards}
            loadingRemoteStoredDashboards={loadingDashboards}
            onDashboardsChange={handleDashboardFetch}
            maxDashboardsAmount={15}
            additionalProps={{details, type}}
        />
    );
};
export default IoTDashboard;