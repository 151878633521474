import {
    CrudTypes,
    getArrayDataContent,
    getDataContent,
    NoResourcePage,
    Tab,
    TabbedPage,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam
} from "@cuda-react/core";
import React from "react";
import EditTVTContent from "../../../components/appliances/edit/EditTVTContent";
import ApplianceAuditLog from "../appliances/logs/ApplianceAuditLog";
import LiveLog from "../appliances/logs/LiveLog";
import HistoryLog from "../appliances/logs/HistoryLog";
import {get} from "lodash";
import SiteDashboard from "../appliances/SiteDashboard";
import apiResources from "../../../apiResources";
import EditSCContent from "../../../components/appliances/edit/EditSCContent";
import IoTDashboard from "../appliances/IoTDashboard";
import EnableSupportDialog from "../appliances/support/EnableSupportDialog";
import RestartSiteDialog from "../appliances/restart/RestartSiteDialog";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    actions: {
        margin: 0
    }
});

interface SiteDetailsProps {
    match: {
        path?: string,
        params: {
            id: string
        }
    }
}

const SiteDetails: React.FC<SiteDetailsProps> = ({match}) => {
    const id = match.params.id;
    const classes = useStyles();
    const [siteDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.siteDashboardStatus, {id});
    const configStatus = getArrayDataContent(useCrudProps(id ? apiResources.configurationStatus : undefined, {id})[0]?.data);
    const activeAppliance = configStatus.find((appliance: any) => appliance.activeHA) || configStatus[0];
    const activeSerial = activeAppliance?.instanceId;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    const siteDetails = getDataContent(siteDetailsData, {});
    const siteVirtualWanId = siteDetails?.virtualWan?.id;
    const siteOnline = get(siteDetails, "connectionStatus.aggregateConnectionState") === "online";
    const siteNotFound = !!siteDetailsData?.error || siteVirtualWanId && virtualWanId !== siteVirtualWanId;
    const siteModel = get(siteDetails, "modelSeries");
    const isSc = siteModel === "S" || match?.path?.startsWith("/infrastructure/iot");

    const actionButtons = isSc ? [] : [
        <EnableSupportDialog key={0} uuid={siteDetails.uuid} name={siteDetails.name} applianceType="site"/>,
        <RestartSiteDialog key={1} id={id} serials={siteDetails.serials}/>
    ];

    return (
        !siteNotFound ?
            <TabbedPage
                defaultTab={0}
                title="tesseract.network.pageTitle"
                subtitle={[
                    {
                        text: `tesseract.network.${isSc ? "iot.menuTitle" : "sites"}`,
                        path: `/infrastructure/${isSc ? "iot" : "sites"}`
                    },
                    siteDetails.name || " "
                ]}
                actions={actionButtons}
                //@ts-ignore
                classes={{actions: classes.actions}}
            >
                <Tab
                    value="#dashboard"
                    label="tesseract.sites.dashboard.tabTitle"
                >
                    {isSc ? (
                        <IoTDashboard
                            details={{id, activeSerial, ...siteDetails, loading}}
                            type="sites"
                        />
                    ) : (
                        <SiteDashboard
                            details={{id, activeSerial, ...siteDetails, loading}}
                            type="sites"
                        />
                    )}
                </Tab>
                <Tab
                    value="#settings"
                    label="tesseract.sites.settings.tabTitle"
                >
                    {siteModel ? (
                        isSc ? (
                            <EditSCContent id={id} details={siteDetails}/>
                        ) : (
                            <EditTVTContent id={id} type="site" details={siteDetails}/>
                        )
                    ) : null}
                </Tab>
                {siteModel && !isSc ? (
                    <Tab
                        value="#connectionHistory"
                        label="tesseract.sites.connectionHistory.tabTitle"
                        disabled={!siteOnline}
                    >
                        <HistoryLog
                            id={id}
                            messagesType="sites"
                            resource={apiResources.siteConnectionHistoryLog}
                            serial={activeSerial}
                            tableName="siteHistory"
                            details={siteDetails}
                        />
                    </Tab>
                ) : null}
                {siteModel && !isSc ? (
                    <Tab
                        value="#live"
                        label="tesseract.sites.liveLog.tabTitle"
                        disabled={!siteOnline}
                    >
                        <LiveLog
                            id={id}
                            messagesType="sites"
                            resource={apiResources.siteLiveLog}
                            serial={activeSerial}
                            tableName="siteLive"
                            details={siteDetails}
                        />
                    </Tab>
                ) : null}
                <Tab
                    value="#auditLog"
                    label="tesseract.sites.auditLog.tabTitle"
                >
                    <ApplianceAuditLog
                        id={id}
                        messagesType="sites"
                        resource={apiResources.siteAuditLog}
                        details={siteDetails}
                    />
                </Tab>
            </TabbedPage>
            : <NoResourcePage content="tesseract.sites.siteNotFound"/>
    );
};


export default SiteDetails;