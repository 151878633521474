import React from 'react';
import {ActionButtonsField, ChipArrayField, generateQueryString, StatusIconField, TextField} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {
    CardSizes,
    DashboardCardProps,
    ResourceStatisticsCard,
    useFetchStatisticsCardDetailsAndTotal
} from "@stratos/reporting";
import {getStatusColorFromTheme} from "../../../../utils/themeUtils";
import {Theme, useTheme} from "@mui/material";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useHistory} from "react-router";
import {StatisticsDetails} from "@stratos/reporting/lib/hooks/useFetchStatisticsCardDetailsAndTotal";

const previewData = (theme: Theme) => [
    [
        {
            "title": "tesseract.dashboard.cards.gateway.online",
            "value": 25,
            "color": getStatusColorFromTheme("online", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.pending",
            "value": 1,
            "color": getStatusColorFromTheme("pending", theme)
        },
        {
            "title": "tesseract.dashboard.cards.vpn.status.connecting",
            "value": 3,
            "color": getStatusColorFromTheme("connecting", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.degraded",
            "value": 0,
            "color": getStatusColorFromTheme("degraded", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.offline",
            "value": 1,
            "color": getStatusColorFromTheme("offline", theme)
        }
    ],
    27
] as [StatisticsDetails[], number];
const filterDegraded = (details: any) => !(details.link || details.title).includes("degraded");

export interface SiteStatusCardProps extends DashboardCardProps {
    additionalProps?: {
        details?: {
            id: number | string;
        };
    }
}

export const SiteStatusCard = (props: SiteStatusCardProps) => {
    const {additionalProps, preview, size} = props;
    const id = additionalProps?.details?.id || "";
    const theme = useTheme();
    const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
        {
            url: preview || !id ? undefined : apiResources.siteStatus,
            params: {filter: {upstream: id, modelSeries: 'T'}}
        },
        {
            url: apiResources.siteStates
        },
        {
            linkCreator: (status: any) => `/#/infrastructure/sites${generateQueryString({
                upstream: [+id],
                connectionStatus: [status.key]
            })}`,
            getKeyColor: (key: any) => (getStatusColorFromTheme(key, theme))
        }
    );
    const history = useHistory();

    const [details, total] = preview ? previewData(theme) : apiResourceData;

    return (
        <ResourceStatisticsCard
            {...props}
            overViewData={size === CardSizes.small && details ? details.filter(filterDegraded) : details}
            overViewValue={total}
            overviewTitle="tesseract.dashboard.cards.status.site.tabTitle"
            tableTitle="tesseract.network.sites"
        >
            <StatusIconField
                source="aggregateState"
                width={80}
            />
            <TextField
                source="name"
                label="tesseract.sites.table.name"
                sortable
            />
            <ChipArrayField
                source="serials"
                label="tesseract.sites.table.serial"
                columnProps={{
                    // @ts-ignore
                    id: "serials",
                    field: "serial"
                }}
            />
            <TextField
                source="gatewayName"
                label="tesseract.sites.table.gateway"
                columnProps={{
                    field: "gateway"
                }}
                sortable
            />
            <ActionButtonsField
                source="id"
                width={128}
            >
                <Forward
                    id="site-dashboard-icon"
                    onClick={(event: any, data: any) => history.push("/infrastructure/sites/" + data.id)}
                />
            </ActionButtonsField>
        </ResourceStatisticsCard>
    );
};