export default {
    translation: {
        tesseract: {
            productName: "Barracuda SecureEdge",
            dashboard: {
                menuTitle: "TABLEAU DE BORD",
                pageTitle: "Tableau de bord",
                emptyTemplate: "Vide",
                cards: {
                    gateway: {
                        cardTitle: "Statut d’Edge Service",
                        online: "En ligne",
                        pending: "En cours",
                        degraded: "Dégradé",
                        offline: "Hors ligne"
                    },
                    iotStatusCard: {
                        healthy: "En ligne",
                        pending: "En cours",
                        connecting: "Connexion en cours",
                        degraded: "Dégradé",
                        offline: "Hors ligne",
                        name: "Nom",
                        gateway: "Passerelle",
                        aggregateState: "Statut",
                        type: "Modèle"
                    },
                    status: {
                        site: {
                            tabTitle: "Statut de site"
                        },
                        iot: {
                            tabTitle: "Statut IoT"
                        },
                        applianceConfiguration: {
                            tabTitle: "Statut de configuration d'appareil",
                            completed: "Terminé",
                            connecting: "Connexion en cours",
                            failed: "Échoué",
                            pending: "En cours"
                        }
                    },
                    vpn: {
                        locations: {
                            tabTitle: "Emplacement",
                            gateways: {
                                label: "Edge Services",
                                linkedSites: "Sites/IoT connectés",
                                fields: {
                                    name: "Nom",
                                    sites: "Sites connectés",
                                    iot: "IoT connecté",
                                    vwan: "Espace de travail",
                                    hub: "Hub",
                                    region: "Région",
                                    size: "Taille"
                                }
                            },
                            sites: {
                                label: "Sites",
                                linkedHubs: "Edge Service connecté",
                                fields: {
                                    name: "Nom",
                                    location: "Emplacement",
                                    gateway: "Edge Service",
                                    providers: "Nombre de fournisseurs"
                                }
                            },
                            iot: {
                                label: "IoT",
                                linkedHubs: "Edge Service connecté",
                                fields: {
                                    name: "Nom",
                                    location: "Emplacement",
                                    gateway: "Edge Service",
                                    providers: "Nombre de fournisseurs"
                                }
                            },
                            standaloneSites: {
                                label: "Sites autonomes"
                            }
                        },
                        status: {
                            tabTitle: "Statut des tunnels",
                            ok: "OK",
                            warning: "Avertissement",
                            error: "Erreur",
                            online: "En ligne",
                            pending: "En cours",
                            connecting: "Connexion en cours",
                            degraded: "Dégradé",
                            offline: "Hors ligne"
                        },
                        offline: {
                            tabTitle: "Tunnels hors ligne",
                            gatewayName: "Nom d’Edge Service",
                            gatewayStatus: "Statut d’Edge Service",
                            siteName: "Nom de site / IoT",
                            siteStatus: "Statut de site / IoT",
                            siteLocation: "Emplacement de site / IoT",
                            noData: "Aucun tunnel hors ligne trouvé."
                        }
                    },
                    recentEvents: {
                        adminTabTitle: "Événements administratifs récents",
                        systemTabTitle: "Événements système récents",
                        latest: "Derniers {{length}}"
                    },
                    categories: {
                        status: "Statut"
                    }
                },
                status: "Statut",
                security: "Sécurité",
                webFilter: "Filtre Web",
                filters: {
                    timeframe: "Échéancier",
                    appliance: "Service Edge/Site",
                    noAppliances: "Aucun",
                    timeframes: {
                        lastDay: "Dernières 24 heures",
                        lastMonth: "Dernier mois",
                        lastWeek: "Dernière semaine",
                        lastQuarter: "3 derniers mois"
          },
          sourceIp: "IP source",
          riskLevel: "Niveau de risque",
          application: "Application",
          destinationDomain: "Domaine de destination",
          targetIp: "IP cible",
          user: "Utilisateur",
          urlCategory: "Catégorie d'URL",
          malwareFile: "Fichier maliciel",
          ipsEvent: "Événement IPS",
          addFilter: "Ajouter un filtre",
          geoSource: "Géo-Source",
          geoDestination: "Géo-Destination",
          resetFilter: "Réinitialiser les filtres",
          riskLevelChoice_0: "Bas",
          riskLevelChoice_1: "Bas - Moyen",
          riskLevelChoice_2: "Moyen",
          riskLevelChoice_3: "Moyen - Haut",
          riskLevelChoice_4: "Haut",
          sdwanCategory: "Catégorie d'application",
          activeFilters: "Tous les filtres actifs des widgets marqués avec <0/> sont appliqués.",
          disabledFilters: "Les filtres actuels ne sont pas appliqués à l'un quelconque des widgets affichés.",
          addFilterMessage: "Cliquez sur 'Ajouter un filtre' ci-dessus ou sur une ligne de widget pour créer un filtre."
                },
                ztnaDashboard: "Accès Zero Trust",
                ztnaUserDashboard: "Tableau de bord d'utilisateur"
            },
            network: {
                pageTitle: "Infrastructure",
                vmActivation: "Activation de VM",
        vmActivationMessage: "Saisissez vos informations de facturation pour l'activation de VM automatique dans tous les espaces de travail. C'est nécessaire uniquement la première fois que vous utilisez une image de machine virtuelle.",
                gateways: "Edge Services",
                sites: "Sites",
                iot: {
                    menuTitle: "IoT",
                    noData: {
                        iconHeader: "Commencez par ajouter un dispositif IoT !",
                        labelButton: "Ajouter un dispositif IoT",
                        textHeader: "Un dispositif IoT : c'est quoi ?",
                        text: "Un dispositif IoT désigne un emplacement de l'Internet des objets qui est connecté (et protégé) par un appareil Barracuda Secure Connector. Les appareils Secure Connector peuvent être déployés comme un appareil physique ou virtuel – selon la méthode idéale pour vos besoins."
                    }
                },
                settings: "Paramètres",
                firewalls: "CloudGen Firewalls",
                sdwanConnector: {
                    menuTitle: "CONNECTEUR",
                    pageTitle: "Connecteurs",
                    table: {
                        status: "Statut",
                        name: "Nom",
                        gateway: "Edge Service",
                        internalResources: "Ressources internes",
                        provisioningDate: "Date d'approvisionnement",
                        servers: "Serveurs",
                        ellipsisMenu: {
                            downloadLinux: "Télécharger l'agent Linux",
                            downloadWindows: "Télécharger l'agent Windows",
                            token: "Générer un jeton d'enregistrement"
                        },
                        hostnames: "Noms d'hôte"
                    },
                    delete: {
                        body: "Êtes-vous certain de vouloir supprimer ce connecteur ?",
                        title: "Supprimer connecteur {{name}}"
                    },
                    dialog: {
                        add: "Ajouter",
                        menuItemDisabled: "Le connecteur nécessite un service Edge",
                        basic: {
                            name: "Nom",
                            sectionTitle: "Informations basiques",
                            tabTitle: "Basique"
                        },
                        create: {
                            dialogTitle: "Ajouter connecteur"
                        },
                        edit: {
                            dialogTitle: "Modifier connecteur"
                        },
                        poolNetwork: {
                            sectionTitle: "IP acheminée",
                            descriptionMessage: "Avant de pouvoir ajouter votre premier connecteur, vous devez définir une plage de réseau client. Le réseau client sert à assigner des IP à des ressources derrière les connecteurs.",
                            network: "Réseau client"
                        },
                        general: {
                            sectionTitle: "Général",
                            name: "Nom",
                            description: "Description",
                            appliance: "Edge Service"
                        },
                        servers: {
                            sectionTitle: "Serveurs",
                            serviceName: "Serveurs",
                            itemName: "Serveur",
                            hostname: "Nom d'hôte",
                            internalIp: "IP interne",
                            category: "Catégorie"
                        },
                        summary: {
                            sectionTitle: "Synthèse",
                            name: "Synthèse pour connecteur {{connector}}",
                            connectorName: "Nom de connecteur",
                            serviceName: "Nom de service",
                            hostname: "Nom d'hôte"
                        },
                        completed: {
                            sectionTitle: "Création de connecteur « {{connector}} » réussie",
                            sectionTitleNextSteps: "Étapes suivantes",
                            sectionMessage: "Procédez comme suit pour commencer à l'utiliser",
                            sectionSteps: "Téléchargez et installez le connecteur",
                            sectionSteps2: "Fournissez au connecteur le jeton OTP :",
                            sectionSteps3: "Vérifiez que la connexion est établie",
                            downloadButtonLinux: "Téléchargement pour Linux",
                            downloadButtonWindows: "Téléchargement pour Windows"
                        },
                        description: {
                            poolNetwork: {
                                network: "La plage de réseau client dans une notation CIDR"
                            },
                            general: {
                                sectionTitle: "Général",
                                name: "Un nom unique pour votre connecteur.",
                                description: "Une brève description du connecteur.",
                                appliance: "Sélectionnez les appareils Edge Service à configurer."
                            },
                            servers: {
                                sectionTitle: "Serveurs",
                                serviceName: "Un nom unique pour votre serveur.",
                                hostname: "Le nom d'hôte lié au serveur.",
                                internalIp: "L'IP interne liée au serveur.",
                                category: "Catégorie"
                            }
                        },
                        token: {
                            dialogTitle: "Générer un jeton d'enregistrement",
                            message: "Générer un nouveau jeton pour \"{{sec}}\"",
                            subLabel: "Jeton de connecteur"
                        }
                    },
                    noData: {
                        iconHeader: "Commencez par ajouter un connecteur !",
                        labelButton: "Ajouter un connecteur",
                        textHeader: "Un connecteur : c'est quoi ?",
                        text: "La création de services spécifiques disponibles pour votre personnel peut s'avérer un défi.<0 /> With a connector, you can easily connect Windows services and servers right to your network and make them available to your workforce without any need for a dedicated site device."
                    }
                },
                customCategories: "Catégories personnalisées"
            },
            endpoint: {
                pageTitle: "Zero Trust Access",
                devices: "Dispositifs",
                settings: "Paramètres",
                zeroTrustAccess: "Zero Trust Access",
                zeroTrustSettingsTitle: "Paramètres",
                pointToSite: "Point à site",
                users: "Utilisateurs inscrits",
                appCatalog: {
                    pageTitle: "Catalogue d'applications",
                    textHeader: "Un catalogue d'applications : c'est quoi ?",
                    text: "Avec le catalogue d'applications, vous définissez les applications qui s'affichent dans l'app SecureEdge Access Agent en raccourcis.<0 />Cette fonctionnalité de raccourci est valide pour les applications externes mais aussi internes.<br/><br/>Cette section sera bientôt disponible.",
                    startText: "Avec le catalogue d'applications, vous définissez les applications qui s'affichent dans l'app SecureEdge Access Agent en raccourcis.<0 />Cette fonctionnalité de raccourci est valide pour les applications externes mais aussi internes.",
                    startTextHeader: "Commencez par ajouter une application !",
                    buttonText: "Ajouter application",
                    iconText: "Icône",
                    name: "Nom",
                    application: "Application",
                    launchURL: "URL de lancement",
                    visibility: "Visibilité",
                    create: {
                        title: "Ajouter application",
                        displayName: "Afficher nom",
                        displayIcon: "Afficher icône",
                        associatedApplication: "Application associée",
                        users: "Utilisateurs",
                        groups: "Groupes",
                        descriptions: {
                            icon: "Téléchargez l'icône d'application.",
                            iconInfo: "Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge.",
                            name: "Un nom unique pour l'application.",
                            application: "Choisissez l'application associée",
                            launchUrl: "Fournissez l'URL de lancement",
                            visibility: "Choisissez la visibilité du lien",
                            users: "Sélectionnez les utilisateurs qui devraient avoir accès aux apps sélectionnées. Si aucun utilisateur ou groupe n'est sélectionné, les apps sélectionnées sont accessibles à tous.",
                            groups: "Sélectionnez les groupes qui devraient avoir accès aux apps sélectionnées. Si aucun utilisateur ou groupe n'est sélectionné, les apps sélectionnées sont accessibles à tous."
                        }
                    },
                    edit: {
                        title: "Modifier application"
                    },
                    delete: {
                        body: "Êtes-vous certain de vouloir supprimer cette application ?",
                        title: "Supprimer {{name}}"
                    },
                    validate: {
                        userGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
                        tenantIdRequired: "Utilisateurs et groupes indisponibles. Vous devez d'abord ajouter un Répertoire d'utilisateurs dans la page Identité > Paramètres."
                    }
                },
                enrollEmailDialog: {
                    addEmail: "Ajouter un utilisateur ou e-mail",
                    body: "Corps",
                    customMessage: "Message personnalisé",
                    defaultContent: "Salut ! \n\nYou have been added by your organisation to Barracuda SecureEdge User Connectivity. \n\nTo gain access, follow these instructions: \n\n1. Go to {{link}}\n2. Download and install the Barracuda VPN client.\n3. Click \"Enroll now\" to complete the process.\n\nYou are now able to connect to Barracuda SecureEdge from your Windows or Mac OS clients.",
                    defaultSubject: "Vous venez d'être ajouté à Barracuda SecureEdge",
                    zeroTrustSubject: "Votre invitation d'inscription pour votre agent Barracuda SecureEdge Access Agent",
                    emailTitle: "Inscrire des utilisateurs",
                    resendEmailTitle: "Renvoyer des invitations d'inscription",
                    noHtml: "les caractères spéciaux suivants sont interdits : <>",
                    recipients: "À",
                    users: "Utilisateurs",
                    send: "Envoyer",
                    subject: "Objet",
                    enrollMessage: "Sélectionnez les utilisateurs ou groupes à inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre cette progression dans la page Accès > Utilisateurs.<0>Il vous reste {{count}} siège.</0>",
                    sendMessage: "Le message suivant sera envoyé aux utilisateurs et groupes sélectionnés. Êtes-vous certain de vouloir envoyer votre invitation ?",
                    successHeader_plural: "Votre invitation d'inscription est en cours d'envoi à {{count}} utilisateurs !",
                    successHeader: "Votre invitation d'inscription est en cours d'envoi à {{count}} utilisateur !",
                    successMessage_plural: "Vous pouvez vérifier le statut d'inscription de chaque utilisateur dans le tableau Utilisateurs.",
                    successMessage: "Vous pouvez vérifier le statut d'inscription de l'utilisateur dans le tableau Utilisateurs.",
                    emailTemplate: {
                        hello: "Salut {{username}} !",
                        message: "Vous êtes invité à inscrire votre Barracuda SecureEdge Access Agent. Cet agent sécurise votre accès aux ressources d'entreprise et protège vos dispositifs des cybermenaces.",
                        clickStart: "Veuillez cliquer \"Start\" to start the self-enrollment process.",
                        start: "Démarrer",
                        copyLink: "Vous pouvez aussi copier le lien suivant directement dans votre SecureEdge Access Agent pour vous inscrire.",
                        exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
                    },
                    addUsers: "Ajouter des utilisateurs",
                    addGroups: "Ajouter des groupes",
                    userNotificationSectionTitle: "Notification d'utilisateur (Option)",
                    enrollmentEmail: {
                        label: "E-mail d'inscription",
                        enabled: "Oui - inclut le lien de téléchargement d'agent",
                        disabled: "Non - installation silencieuse"
                    },
                    groups: "Groupes",
                    enroll: "Inscription",
                    enrollMessageOld: "Sélectionnez les utilisateurs ou groupes à inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre l'avancement dans la page Accès > Utilisateurs.",
                    enrollMessage_plural: "Sélectionnez les utilisateurs ou groupes à inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre cette progression dans la page Accès > Utilisateurs.<0>Il vous reste {{count}} sièges.</0>",
                    resolvedUsers: "Les utilisateurs/groupes sélectionnés représentent plus d'utilisateurs que les sièges restants. Veuillez utiliser le menu à la suite pour retirer les utilisateurs que vous ne voulez pas inscrire maintenant ou revenir en arrière pour sélectionner des utilisateurs/groupes différents.",
                    successHeaderOld: "Votre invitation d'inscription est en cours d'envoi à '{{users}}' !",
                    successHeaderOld_plural: "Votre invitation d'inscription est en cours d'envoi à {{count}} utilisateurs !",
                    noEmailSuccessHeader: "Inscription de {{count}} utilisateur !",
                    noEmailSuccessHeader_plural: "Inscription de {{count}} utilisateurs !",
                    validate: {
                        userGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
                        userRequired: "au moins un utilisateur est obligatoire",
                        seatLimit: "Inscrire {{currentCount}} utilisateurs ({{maxCount}} sièges disponibles)"
                    },
                    reenrollMessage: "Renvoyez l'e-mail d'invitation d'inscription à l'utilisateur sélectionné.",
                    noSeats: "il ne reste plus de sièges.",
                    seats: "Il vous reste {{count}} siège.",
                    seats_plural: "Il vous reste {{count}} sièges.",
                    unSuccessHeaderAllUsersAlreadyEnrolled: "L'utilisateur sélectionné est déjà inscrit.",
                    unSuccessHeaderAllUsersAlreadyEnrolled_plural: "Tous les utilisateurs sélectionnés sont déjà inscrits.",
                    alreadyEnrolledUsersUnsuccessful_emailplural: "Aucun e-mail ne sera envoyé.",
                    alreadyEnrolledUsers: "{{totalCount}} des utilisateurs sélectionnés est déjà inscrit.",
                    alreadyEnrolledUsers_workspace: "{{totalCount}} des utilisateurs sélectionnés est déjà inscrit ({{unenrollableCount}} sur un compte ou dans un espace de travail différent).",
                    alreadyEnrolledUsers_email: "{{totalCount}} des utilisateurs sélectionnés est déjà inscrit. Dès lors, aucun e-mail ne sera envoyé à cet utilisateur.",
                    alreadyEnrolledUsers_emailworkspace: "{{totalCount}} des utilisateurs sélectionnés est déjà inscrit ({{unenrollableCount}} sur un compte ou dans un espace de travail différent). Dès lors, aucun e-mail ne sera envoyé.",
                    alreadyEnrolledUsers_plural: "{{totalCount}} des utilisateurs sélectionnés sont déjà inscrits.",
                    alreadyEnrolledUsers_workspaceplural: "{{totalCount}} des utilisateurs sélectionnés sont déjà inscrits ({{unenrollableCount}} sur un compte ou dans un espace de travail différent).",
                    alreadyEnrolledUsers_emailplural: "{{totalCount}} des utilisateurs sélectionnés sont déjà inscrits. Dès lors, aucun e-mail ne sera envoyé à ces utilisateurs.",
                    alreadyEnrolledUsers_emailworkspaceplural: "{{totalCount}} des utilisateurs sélectionnés sont déjà inscrits ({{unenrollableCount}} sur un compte ou dans un espace de travail différent). Dès lors, aucun e-mail ne sera envoyé."
                },
                remoteAccessPolicies: {
                    delete: {
                        title: "Supprimer stratégie Accès Zero Trust {{name}}",
                        body: "Êtes-vous certain de vouloir supprimer cette stratégie Accès Zero Trust ?"
                    },
                    order: "Ordre",
                    name: "Nom",
                    description: "Description",
                    groupsGrantedAccess: "Accès octroyé aux groupes",
                    resources: "Ressources",
                    devicePosture: "Posture de sécurité",
                    usersGrantedAccess: "Accès octroyé aux utilisateurs",
                    zeroTrustAccess: "Zero Trust Access",
                    policy: "Stratégie",
                    create: "Créer stratégie Accès Zero Trust",
                    edit: "Modifier stratégie Accès Zero Trust",
                    success: "Succès",
                    successMessage: "La nouvelle stratégie Accès Zero Trust a été correctement créée.",
                    createButton: "Ajouter une stratégie",
                    deviceAttributes: "Posture de sécurité",
                    resourcesSection: {
                        title: "Ressources",
                        internalResource: "Ressources internes (apps personnalisées)",
                        publicResource: "Point final public (SaaS)",
                        resourceTypeLabel: "Type de ressource",
                        resourceTypeDescription: "Sélectionnez \"Interne\" pour les ressources uniquement accessibles en interne via un point d'entrée et \"Public\" pour les ressources accessibles publiquement.",
                        resourcesLabel: "Ressources"
                    },
                    applications: "Applications",
                    accessCriteriaSection: "Critères d'accès",
                    users: "Utilisateurs",
                    groups: "Groupes",
                    securityInspection: "Inspection de sécurité",
                    screenLock: "Verrou d'écran activé",
                    firewall: "Pare-feu activé",
                    jailbroken: "Bloquer les dispositifs débridés",
                    encryption: "Faire respecter le cryptage de disque",
                    appUpdates: "Mises à jour de Barracuda SecureEdge Access Agent",
                    minimumVersion: "Version minimum",
                    platform: "Plateforme",
                    osUpdates: "Mises à jour d'OS",
                    antivirus: "Antivirus activé",
                    descriptions: {
                        name: "Un nom unique pour la stratégie.",
                        description: "Une brève description de la stratégie.",
                        resourceType: "Configurez soit les applications personnalisées soit les applications de tiers externes.",
                        applications: "Les applications concernées par cette stratégie.",
                        users: "Sélectionnez les utilisateurs qui devraient avoir accès aux apps sélectionnées. Si aucun utilisateur ou groupe n'est sélectionné, les apps sélectionnées sont accessibles à tous.",
                        groups: "Sélectionnez les groupes qui devraient avoir accès aux apps sélectionnées. Si aucun utilisateur ou groupe n'est sélectionné, les apps sélectionnées sont accessibles à tous.",
                        securityPosture: "Choisissez si les attributs des dispositifs configurés doivent être appliqués ou si les manquements doivent seulement être consignés.",
                        securityInspection: "Si activé, le trafic vers les applications choisies est acheminé via le tunnel pour assurer une inspection de sécurité approfondie et les stratégies de point d'entrée seront appliquées. Si désactivé, le trafic est acheminé normalement via Internet.",
                        screenLock: "Les utilisateurs doivent régler un verrou d'écran sur leurs dispositifs. Plateformes compatibles : Android, iOS.",
                        firewall: "Les utilisateurs doivent activer et configurer un pare-feu sur leurs dispositifs. Plateformes compatibles : mac OS, Windows.",
                        jailbroken: "Évitez que les dispositifs compromis n'accèdent aux ressources. Plateformes compatibles : Android, iOS.",
                        encryption: "Les utilisateurs doivent définir un cryptage de disque. Plateformes compatibles : Android, iOS, mac OS, Windows.",
                        appUpdates: "Les utilisateurs doivent mettre à jour Barracuda SecureEdge Access Agent sur la version la plus récente. Plateformes compatibles : Android, iOS, Linux, mac OS, Windows.",
                        osUpdates: "Les utilisateurs doivent mettre à jour le système d'exploitation vers la version minimum. Plateformes compatibles : Android, iOS, mac OS, Windows.",
                        antivirus: "Les utilisateurs doivent avoir configuré un service antivirus sur leur dispositif. Plateformes : Windows."
                    },
                    validatePlatform: "OS déjà sélectionné",
                    validateDeviceCriteria: "au moins une fonctionnalité de sécurité doit être activée",
                    lastMileOptimization: "Optimisation du dernier kilomètre ",
                    noData: {
                        iconHeader: "Commencez par ajouter une stratégie d'Accès Zero Trust !",
                        labelButton: "Ajouter une stratégie d'Accès Zero Trust",
                        textHeader: "Une stratégie d'Accès Zero Trust : c'est quoi ?",
                        text: "Dans cette interface, vous définissez les exigences envers les dispositifs d'utilisateur pour pouvoir accéder au réseau.<0 /> For example, you can require devices to have the latest OS updates applied and onboard security enabled, or you can even block jailbroken devices."
                    }
                },
                pointsOfEntry: {
                    title: "Points d'entrée",
                    table: {
                        name: "Nom",
                        type: "Type",
                        size: "Taille",
                        location: "Emplacement",
                        publicIp: "IP publiques",
                        noData: "Aucun point d'entrée n'a été configuré. Au moins un point d'entrée doit être configuré pour utiliser Zero Trust Access."
                    },
                    createButton: "Ajouter des points d'entrée",
                    create: {
                        title: "Ajouter des points d'entrée",
                        configurationType: "Type",
                        site: "Site",
                        gateway: "Edge Service",
                        firewall: "Pare-feu",
                        ipAddress: "IP publique",
                        sites: "Sites",
                        gateways: "Edge Services",
                        firewalls: "Pare-feux",
                        descriptions: {
                            sites: "Sites à ajouter comme points d'entrée.",
                            gateways: "Edge Services à ajouter comme points d'entrée.",
                            firewalls: "Pare-feux à ajouter comme points d'entrée."
                        },
                        validateSelectOne: "Au moins un pare-feu, site ou Edge Service doit être sélectionné."
                    },
                    edit: {
                        title: "Modifier un point d'entrée",
                        name: "Nom",
                        ipAddress: "IP publiques",
                        descriptions: {
                            ipAddress: "Les adresses IP publiques via lesquelles ce pare-feu est accessible."
                        }
                    },
                    delete: {
                        title: "Supprimer le point d'entrée {{name}}",
                        body: "Êtes-vous certain de vouloir supprimer ce point d'entrée ?"
                    },
                    noData: {
                        iconHeader: "Commencez par ajouter un point d'entrée !",
                        labelButton: "Ajouter un point d'entrée",
                        textHeader: "Un point d'entrée : c'est quoi ?",
                        text: "Un point d'entrée (PoE - Point of Entry) agit comme un point de connexion pour vos SecureEdge Access Agents.<0 /> Les agents choisissent automatiquement le PoE disponible le plus proche pour optimiser la qualité de service."
                    }
                },
                zeroTrustSettings: {
                    agentWebFiltering: "Filtrage Web d'agent",
                    dnsSuffix: "Suffixe DNS",
                    downloadCertificateHelpText: "Téléchargez et déployez ce certificat racine pour tous les clients afin d'assurer des opérations parallèles avec les configurations client à site CloudGen Firewall et SecureEdge Access Agent.",
                    downloadCertificateLabel: "Télécharger un certificat",
                    networkSectionTitle: "Configuration réseau Access Agent",
                    clientNetworkDescription: "Le réseau client sert à assigner des IP aux clients qui se connectent via SecureEdge Access Agent. Il est divisé en pools qui sont ensuite distribués sur chaque point d'entrée.",
                    manualConfigurationEnabled: "Configuration manuelle",
                    clientNetworkRange: "Réseau client",
                    clientNetworkSize: "Ce réseau client offre au moins {{connections}} connexions client par point d'entrée.",
                    clientNetworkPoolSize: "Masque de bits de pool",
                    validatePoolSize: "doit être inférieur au réseau client",
                    enforce: "Appliquer",
                    disable: "Désactiver",
                    globalZtnaSettings: "Réglages d'accès"
                },
                ztnaUsers: {
                    dashboardTabTitle: "Tableau de bord",
                    settingsTabTitle: "Paramètres",
                    userNotFound: "Impossible de trouver l'utilisateur"
                }
            },
            securityAndAccess: {
                pageTitle: "Stratégie de sécurité",
                apps: "Applications et ressources",
                network: "ACL de réseau",
                gateway: "ACL de Edge Service",
                site: "ACL de site",
                sdwan: "SD-WAN",
                categories: "Catégories d'application",
                explicit: "Catégories de priorité",
                security: "Sécurité",
                malware: "Protection contre les menaces avancées",
                ssl: "Inspection SSL",
                ips: "IPS",
                ingressNatRules: "NAT d'entrée",
                urlFilter: "Stratégies",
                webFilter: "Filtre Web",
                webFilterSettings: "Paramètres",
                webMonitorPolicies: "Surveillance Web",
                settings: "Paramètres",
                customResponse: "Messages de réponse"
            },
            appliances: {
                dashboard: {
                    deployment: {
                        status: {
                            title: "Statut de déploiement",
                            status: "Statut",
                            action: "Action",
                            message: "Message",
                            registered: "Enregistré",
                            lastSeen: "Vu en dernier",
                            completed: "Terminé",
                            localIp: "IP locale",
                            remoteIp: "IP publique"
                        },
                        events: {
                            title: "Événements de déploiements",
                            state: "État",
                            status: "Statut",
                            created: "Date",
                            siteDeploying: "Déploiement en cours"
                        },
                        location: {
                            title: "Emplacement de déploiement"
                        }
                    },
                    details: {
                        servicesCardTitle: "Services",
                        interfaces: {
                            title: "Interfaces",
                            status: "Statut",
                            name: "Nom",
                            speed: "Vitesse",
                            duplex: "Duplex",
                            errors: "Erreurs",
                            state: {
                                ok: "OK",
                                warning: "Avertissement",
                                error: "Erreur",
                                unknown: "Inconnu",
                                loading: " "
                            }
                        },
                        boxResources: {
                            title: "Ressources",
                            cpuLoad: "CPU",
                            cpuLoadError: "Une erreur a été détectée dans la CPU. Veuillez redémarrer le dispositif. Si cette erreur persiste, veuillez contacter le support Barracuda.",
                            memory: "Mémoire",
                            diskInformation: "Disque dur",
                            diskInformationError: "Une erreur a été détectée dans l'une des partitions de disque. Veuillez redémarrer le dispositif. Si cette erreur persiste, veuillez contacter Barracuda Support."
                        },
                        vpnTunnels: {
                            title: "Tunnels VPN",
                            instanceId: "Numéro de série d'instance",
                            status: "Statut",
                            name: "Nom",
                            peer: "Pair",
                            local: "Local",
                            type: "Type",
                            state: {
                                UP: "Actif",
                                DOWN: "Arrêté",
                                INACTIVE: "Inactif",
                                FAIL: "Arrêté - Échec",
                                STANDBY: "Arrêté - Veille",
                                "DOWN (PASSIVE)": "Arrêté - Passif",
                                "DOWN (FAIL)": "Arrêté - Échoué",
                                "DOWN (STANDBY)": "Arrêté - Veille",
                                "DOWN (DISABLED)": "Désactivé",
                                PENDING: "En cours"
                            }
                        }
                    },
                    firmware: {
                        details: {
                            tabTitle: "Micrologiciel",
                            version: "Version",
                            hotfixes: "Correctifs installés",
                            window: "Mise à jour suivante",
                            nextUpdate: "Entre {{from}} et {{to}}"
                        },
                        hotfixes: {
                            tabTitle: "Correctifs",
                            status: "Statut",
                            name: "Nom"
                        },
                        updates: {
                            tabTitle: "Mises à jour de configuration",
                            lastUpdate: "Dernière mise à jour",
                            lastSeen: "Vu en dernier",
                            remoteIp: "IP distante"
                        },
                        state: {
                            error: "Erreur",
                            ok: "OK",
                            unknown: "Inconnu",
                            warning: "En cours",
                            loading: " "
                        }
                    },
                    siteStatus: {
                        tabTitle: "Sites connectés"
                    },
                    iotStatus: {
                        tabTitle: "IoT connecté"
                    },
                    summary: {
                        siteStatus: {
                            title: "Statut",
                            model: "Modèle",
                            serials: "Numéro(s) de série",
                            serial: "Numéro de série",
                            release: "Version"
                        },
                        licenses: {
                            title: "Licences",
                            type: "Type",
                            state: "État",
                            status: "Statut",
                            expirationDate: "Date d'expiration",
                            serial: "Numéro de série",
                            noData: "S/O"
                        },
                        gateway: {
                            tabTitle: "Edge Service",
                            noGateway: "Aucun Edge Service n'est configuré pour ce site",
                            name: "Nom",
                            region: "Région",
                            hubName: "Hub",
                            vwan: "Cloud VWAN",
                            size: "Taille",
                            publicIP: "IP publique",
                            publicIPs: "IP publique(s)",
                            serial: "Numéro(s) de série",
                            instanceNotFound: "Impossible de trouver l'instance"
                        },
                        status: {
                            tabTitle: "Statut",
                            hub: "Hub",
                            region: "Région",
                            vwan: "Cloud VWAN",
                            connectedSites: "Sites connectés"
                        }
                    },
                    tunnelLocations: {
                        title: "Emplacement",
                        gateways: {
                            label: "Edge Services",
                            fields: {
                                name: "Nom",
                                sites: "Sites connectés",
                                iot: "IoT connecté",
                                vwan: "Espace de travail",
                                hub: "Hub",
                                region: "Région",
                                size: "Taille"
                            }
                        },
                        iot: {
                            label: "IoT"
                        },
                        sites: {
                            label: "Sites",
                            fields: {
                                name: "Nom",
                                location: "Emplacement",
                                gateway: "Edge Service",
                                providers: "Nombre de fournisseurs"
                            }
                        },
                        standaloneSites: {
                            title: "Emplacement",
                            label: "Sites autonomes"
                        }
                    }
                },
                dialog: {
                    createType: {
                        sectionTitle: "Type d’Edge Service",
                        new: "Nouvel Edge Service de zéro",
                        promote: "Promouvoir un site existant en tant qu’Edge Service"
                    },
                    siteSelection: {
                        sectionTitle: "Sélection de site",
                        mainMessage: "Sélectionnez le site que vous souhaitez promouvoir vers un service Private Edge.",
                        secondaryMessage: "Note : Toutes les stratégies associées (ex. ACL, filtres d'URL, etc...) seront supprimées en cas de promotion.",
                        haWarning: "Ce site n'est pas HA. Il est recommandé d'utiliser HA pour les services Private Edge.",
                        site: "Site"
                    },
                    basic: {
                        gateway: "Edge Service",
                        upstream_gateway: "Edge Service amont",
                        noGateway: "(Aucun)",
                        name: "Nom",
                        sectionTitle: "Informations basiques",
                        tabTitle: "Basique",
                        rootPassword: "Mot de passe racine",
                        rootPasswordConfirmation: "Confirmer le mot de passe racine",
                        descriptions: {
                            gateway_site: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce site se connecte.",
                            gateway_gateway: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce Edge Service se connecte.",
                            name_site: "Un nom unique pour votre site.",
                            name_gateway: "Un nom unique pour votre Edge Service.",
                            rootPassword_site: "Le mot de passe utilisateur racine pour le site.",
                            rootPassword_gateway: "Le mot de passe utilisateur racine pour l’Edge Service.",
                            upstream_gateway: "L’Edge Service amont de Barracuda SecureEdge auquel vous voulez que cet Edge Service se connecte."
                        }
                    },
                    serials: {
                        gatewayMessage: "Sélectionnez les appareils Edge Service à configurer. Nous recommandons la sélection de deux appareils pour créer un cluster HA (High Availability - Haute disponibilité).",
                        TVTSiteMessage: "Sélectionnez les appareils de site à configurer. Vous pouvez sélectionner un seul appareil ou deux appareils pour créer un cluster HA.",
                        SCSiteMessage: "Sélectionnez l'appareil Secure Connector à configurer.",
                        haMessage: "<1>Note :</1> Les deux appareils d'un cluster HA doivent avoir le même modèle et la même version de micrologiciel",
                        sectionTitle_site: "Appareils de site",
                        sectionTitle_gateway: "Appareils Edge Service",
                        addAppliance: "Ajoutez un appareil avec son numéro de série et son code de liaison /jeton de licence",
                        linkingCode: "Code/Jeton",
                        table: {
                            serial: "Numéro de série",
                            model: "Modèle",
                            location: "Emplacement",
                            orderId: "ID d'ordre",
                            orderDate: "Date d'ordre"
                        }
                    },
                    wans: {
                        sectionTitle: "WAN",
                        message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une désignation WAN et LAN.",
                        message2_site: "Combien de connexions WAN voulez-vous configurer pour ce site ?",
                        message2_gateway: "Combien de connexions WAN voulez-vous configurer pour cet Edge Service ?",
                        size: "Nombre de WAN",
                        setup: "Configurer un lien WAN {{step}}",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        type: "Type",
                        providerPinning: "Spécification de fournisseur",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        pin: "PIN de SIM pour {{serial}}",
                        invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
                        SCmessage: "Votre dispositif a un seul port WAN à configurer.",
                        SCmessage_failover: "Votre dispositif peut avoir jusqu'à 2 connexions WAN (une primaire et une de basculement).",
                        SCconfigureFailover: "Voulez-vous configurer un basculement pour ce site ?",
                        failover: "Basculement WAN",
                        setupSC: "Configurer un lien WAN primaire",
                        setupSC_0: "Configurer un lien WAN primaire",
                        setupSC_1: "Configurer un lien WAN de basculement",
                        descriptions: {
                            name: "Un nom unique pour ce lien WAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            type: "Le type de réseau à utiliser pour ce lien.",
                            providerPinning: "Le groupe de fournisseurs sur lequel épingler ce lien. Les groupes de fournisseurs sont utilisés par les stratégies SD-WAN pour définir le comportement et le débit du trafic.",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau pour ce lien.",
                            gateway: "L'adresse IP de la passerelle associée à ce lien.",
                            pin: "Le PIN de la carte SIM installée dans ce dispositif."
                        },
                        bridgeType: {
                            name: "Pont",
                            address: "Adresse IP",
                            gateway: "Passerelle",
                            lanPorts: "Ports LAN",
                            netmask: "Masque réseau",
                            wanPort: "Port WAN",
                            descriptions: {
                                address: "L'adresse IP à assigner au port.",
                                gateway: "L'adresse IP de la passerelle associée à ce lien.",
                                lanPorts: "Le port LAN à utiliser",
                                netmask: "Le suffixe de masque réseau CIDR de réseau",
                                wanPort: "Le port WAN à utiliser"
                            }
                        },
                        pppoeType: {
                            name: "Nom d'utilisateur",
                            password: "Mot de passe",
                            description: {
                                name: "Un nom unique pour ce PPPoE.",
                                password: "Mot de passe unique"
                            }
                        },
                        validation: {
                            mode: "ne peut pas être un pont si un Edge Service est sélectionné"
                        }
                    },
                    lans: {
                        sectionTitle: "LAN",
                        message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une désignation WAN et LAN. Vous avez alloué {{wans}} au WAN.",
                        message2_site: "Combien de connexions LAN voulez-vous configurer pour ce site ?",
                        message2_gateway: "Combien de connexions LAN voulez-vous configurer pour cet Edge Service ?",
                        size: "Nombre de LAN",
                        setup: "Configurer un lien WAN {{step}}",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        dhcpEnable: "Serveur DHCP",
                        dhcpDescription: "Description",
                        dhcpFirstIp: "Première adresse IP",
                        dhcpLastIp: "Dernière adresse IP",
                        dhcpDomain: "Domaine DHCP",
                        descriptions: {
                            dhcpEnable: "Active le serveur DHCP pour ce lien LAN.",
                            name: "Un nom unique pour ce lien LAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau à utiliser pour le réseau créé.",
                            dhcpDescription: "Description personnalisée pour ce réseau DHCP",
                            dhcpFirstIp: "Première adresse IP allouable dans ce réseau DHCP.",
                            dhcpLastIp: "Dernière adresse IP allouable dans ce réseau DHCP.",
                            dhcpDomain: "Nom de domaine pour ce service DHCP.",
                            wifi: {
                                ssid: "Le SSID du réseau Wi-Fi.",
                                securityMode: "Le mode de sécurité Wi-Fi à utiliser.",
                                passphrase: "La phrase secrète pour le réseau."
                            },
                            type: "Le type d'interface. Elle peut être configurée par défaut comme une interface LAN ou un Pont."
                        },
                        type: "Type",
                        bridgeType: {
                            default: "Port simple",
                            name: "Pont",
                            address: "Adresse IP",
                            lanPorts: "Ports",
                            netmask: "Masque réseau",
                            description: {
                                address: "L'adresse IP à assigner au port.",
                                lanPorts: "Les ports LAN à utiliser",
                                netmask: "Le suffixe de masque réseau CIDR de réseau"
                            }
                        },
                        dhcpRelayType: "DHCP",
                        validation: {
                            dhcpRelayType: "ne peut pas être activé si un SecureEdge est défini"
                        }
                    },
                    advanced: {
                        sectionTitle: "Paramètres avancés",
                        updateWindowTZ: "Fuseau horaire de fenêtre de mise à jour",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire",
                        descriptions: {
                            updateWindowTZ_site: "Le fuseau horaire pour ce site, utilisé pour déterminer sa fenêtre de mise à jour.",
                            updateWindowTZ_gateway: "Le fuseau horaire pour l’Edge Service, utilisé pour déterminer sa fenêtre de mise à jour.",
                            primaryDns: "Définissez manuellement l'adresse du DNS primaire.",
                            secondaryDns: "Définissez manuellement l'adresse du DNS secondaire."
                        }
                    },
                    submit: {
                        message_site: "Veuillez examiner les détails de votre site et cliquer sur Enregistrer",
                        message_gateway: "Veuillez examiner les détails de votre Edge Service et cliquer sur Enregistrer",
                        name: "Nom",
                        model: "Modèle",
                        description: "Description",
                        serial: "Numéro de série",
                        gateway: "Edge Service",
                        noGateway: "(Aucun)",
                        upstream_gateway: "Edge Service amont",
                        timezone: "Fuseau horaire",
                        status: "Statut de connexion",
                        peeringAddress: "Adresse d'appairage",
                        wan: "WAN",
                        lan: "LAN",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire",
                        haWarning: "Ce site n'est pas HA. Il est recommandé d'utiliser HA pour les services Private Edge."
                    },
                    result: {
                        success_site: "Le site a bien été créé",
                        success_gateway: "L’Edge Service a bien été créé",
                        connectPort: "Assurez-vous de brancher le port <0>{{port}}</0> de l'appareil suivant dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                        connectPort_plural: "Assurez-vous de brancher le port <0>{{port}}</0> des appareils suivants dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de vos appareils. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur les appareils.",
                        HAMessage: "Connectez le port <0>P1</0> des deux appareils directement avec un câble de réseau. La synchronisation HA démarre automatiquement.",
                        campus: "Pour en savoir plus, voir <0>Barracuda Campus</0>",
                        serial: "Numéro de série : {{serial}}",
                        nonstandardPort: "Avertissement : Cette configuration utilise un port non-standard : <0>{{ports}}</0>. Assurez-vous qu'il est activé sur votre appareil virtuel.",
                        nonstandardPort_plural: "Avertissement : Cette configuration utilise des ports non-standard : <0>{{ports}}</0>. Assurez-vous qu'ils sont activés sur votre appareil virtuel."
                    },
                    promoteResult: {
                        primaryMessage: "Le site est correctement promu vers l’Edge Service.",
                        secondaryMessage: "Vous pouvez maintenant commencer à y joindre des sites nouveaux ou existants."
                    },
                    steps: {
                        result: "Terminé",
                        submit: "Synthèse"
                    },
                    types: {
                        dynamic: "Dynamique (DHCP)",
                        configure_on_branch: "Configuré sur branche"
                    },
                    validation: {
                        duplicateNetwork: "un réseau correspondant à ce port/LAN virtuel a déjà été configuré",
                        duplicateWwan: "un seul WWAN peut être configuré",
                        duplicateExpressRoute: "un seul itinéraire express peut être configuré",
                        duplicateName: "nom déjà utilisé pour une autre connexion",
                        secondaryDns: "ne doit pas être défini en l'absence de DNS primaire",
                        availableAsn: "cette valeur ASN est indisponible",
                        publicIp: "l'adresse IP doit appartenir à la plage publique",
                        noLans: "doit avoir au moins un LAN",
                        exceededPPoE: "quatre PPPoE WAN au maximum peuvent être configurés",
                        oneInlineBridge: "un seul pont WAN peut être configuré"
                    }
                },
                settings: {
                    tabTitle: "Paramètres",
                    basic: {
                        tabTitle: "Informations basiques",
                        name: "Nom",
                        model: "Modèle",
                        gateway: "Edge Service",
                        serials: "Numéro(s) de série",
                        descriptions: {
                            gateway_site: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce site se connecte.",
                            gateway_gateway: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce Edge Service se connecte.",
                            name_site: "Un nom unique pour votre site.",
                            name_gateway: "Un nom unique pour votre Edge Service.",
                            model_site: "Le modèle de dispositif pour ce site.",
                            model_gateway: "Le modèle de dispositif pour cet Edge Service.",
                            serials_site: "Le ou les numéros de séries de dispositif pour ce site.",
                            serials_gateway: "Le ou les numéros de série de dispositif pour cet Edge Service."
                        },
                        serialEdit: {
                            table: {
                                status: "Statut",
                                serial: "Numéro de série",
                                add: "Ajouter second"
                            },
                            addTitle: "Ajouter un numéro de série",
                            editTitle: "Échanger un numéro de série",
                            deleteTitle: "Retirer un numéro de série",
                            edit_site: "Sélectionnez à la suite le numéro de série de l'appareil de remplacement que vous voulez joindre à ce site. Les appareils peuvent être remplacés uniquement par un autre du même modèle.",
                            edit_gateway: "Sélectionnez le numéro de série de l'appareil de remplacement que vous voulez joindre à cet Edge Service à la suite. Les appareils peuvent être remplacés uniquement par un autre du même modèle.",
                            edit: "Sélectionnez à la suite le numéro de série de l'appareil de remplacement que vous voulez joindre à ce site. Les appareils peuvent être remplacés uniquement par un autre du même modèle.",
                            add_site: "Le fait de joindre un second appareil à votre site active la fonctionnalité HA. Veuillez sélectionner à la suite le numéro de série de votre appareil.",
                            add_gateway: "Le fait de joindre un second appareil à votre Edge Service active la fonctionnalité HA. Veuillez sélectionner à la suite le numéro de série de votre appareil.",
                            add: "Le fait de joindre un second appareil à votre site active la fonctionnalité HA. Veuillez sélectionner à la suite le numéro de série de votre appareil.",
                            delete1_site: "Êtes-vous certain de vouloir retirer le numéro de série {{serial}} de ce site ?",
                            delete1_gateway: "Êtes-vous certain de vouloir retirer le numéro de série {{serial}} de cet Edge Service ?",
                            delete1: "Êtes-vous certain de vouloir retirer le numéro de série {{serial}} de ce site ?",
                            delete2: "Vous allez retirer la fonctionnalité HA et désactiver l'appareil associé.",
                            save: "Les modifications seront prises en compte après l'enregistrement.",
                            saveDelete: "Après l'enregistrement vous pouvez déconnecter l'appareil.",
                            saveAdd: "Après l'enregistrement, vous pouvez connecter le nouvel appareil en commençant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                            saveSCAdd: "Après l'enregistrement, vous pouvez connecter le nouvel appareil en connectant le port WAN à une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
                            saveEdit: "Après l'enregistrement, vous pouvez déconnecter l'ancien appareil. Connectez alors le nouvel appareil en commençant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                            saveSCEdit: "Après l'enregistrement, vous pouvez déconnecter l'ancien appareil. Connectez ensuite le nouvel appareil en connectant le port WAN à une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
                            saveHA: "En outre, connectez le port <0>P1</0> du nouvel appareil et de l'ancien directement avec un câble de réseau. La synchronisation HA démarre automatiquement.",
                            saveEditSecondary: "L'ancien appareil a été configuré pour HA. Avant de pouvoir l'utiliser à nouveau, vous devez créer une nouvelle image.",
                            saveDeleteSecondary: "Cet appareil a été configuré pour HA. Avant de pouvoir l'utiliser à nouveau, vous devez créer une nouvelle image.",
                            saveDeletePrimary_site: "L'autre appareil joint à ce site a été configuré pour HA. Vous devez créer une nouvelle image après l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous à un temps d'arrêt de votre site jusqu'à ce que ce processus soit terminé.",
                            saveDeletePrimary_gateway: "L'autre appareil joint à cet Edge Service a été configuré pour HA. Vous devez créer une nouvelle image après l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous à un temps d'arrêt de votre Edge Service jusqu'à ce que ce processus soit terminé.",
                            saveDeletePrimary: "L'autre appareil joint à ce site a été configuré pour HA. Vous devez créer une nouvelle image après l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous à un temps d'arrêt de votre site jusqu'à ce que ce processus soit terminé."
                        }
                    },
                    wan: {
                        tabTitle: "WAN",
                        addTitle: "Ajouter une nouvelle interface WAN",
                        addButtonText: "Ajouter une interface WAN",
                        addSCButtonText: "Ajouter une solution de repli WAN",
                        editTitle: "Modifier une interface WAN",
                        deleteTitle: "Supprimer une interface WAN",
                        deleteMessage: "Êtes-vous certain de vouloir supprimer cette interface WAN ?<0/>Toutes les connexions et stratégies associées avec ce WAN (comme les tunnels IPsec) sont automatiquement retirées.<0/>La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        type: "Type",
                        providerPinning: "Spécification de fournisseur",
                        fallback: "Solution de repli",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        pin: "PIN de SIM pour {{serial}}",
                        scPin: "PIN de SIM",
                        invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
                        portWarning: "Les rangées grisées ci-dessus indiquent que le port sélectionné n'a pas été activé sur l'appareil.",
                        failover: "Basculement HA",
                        network: "Réseau",
                        doublePort: "Port",
                        doubleVirtualLanId: "ID de LAN virtuel",
                        doubleAddress: "Adresse IP",
                        doubleNetmask: "Masque réseau",
                        virtualTransferNetwork: "Réseau de transfert virtuel",
                        asn: "ASN itinéraire express",
                        expressRoute: "Itinéraire express",
                        primary: "Primaire",
                        secondary: "Secondaire",
                        validatePublicIp: "doit être une IP publique pour la connectivité utilisateur",
                        validateOnePort: "vous pouvez uniquement configurer un port WAN dynamique/statique et un port WWAN",
                        descriptions: {
                            name: "Un nom unique pour ce lien WAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            type: "Le type de réseau à utiliser pour ce lien.",
                            providerPinning: "Le groupe de fournisseurs sur lequel épingler ce lien. Les groupes de fournisseurs sont utilisés par les stratégies SD-WAN pour définir le comportement et le débit du trafic.",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau pour ce lien.",
                            gateway: "L'adresse IP de la passerelle associée à ce lien.",
                            pin: "Le PIN de la carte SIM installée dans ce dispositif.",
                            failover: "En cas d'activation, le port est surveillé et un événement de basculement HA est exécuté en cas de perte de la connexion. Disponible uniquement si HA est activée.",
                            doublePort: "Les ports de dispositif à utiliser pour les liens primaires et secondaires.",
                            doubleVirtualLanId: "ID LAN virtuel en option pour les liens primaire et secondaire (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            doubleAddress: "L'adresse IP à assigner aux liens primaire et secondaire. Il doit s'agir de la première IP utilisable des plages configurées dans Azure pour chaque connexion (ex. si 172.16.0.4/30 est configurée dans Azure, alors renseignez l'adresse IP 172.16.0.5).",
                            doubleNetmask: "Le suffixe de masque réseau CIDR du réseau pour les liens primaires et secondaires.",
                            asn: "Un ASN BGP autogénéré pour ce lien. Il n'y aura pas de conflit avec d'autres ASN gérés par SecureEdge.",
                            virtualTransferNetwork: "Réseau utilisé pour réserver les IP des interfaces de transfert virtuel.",
                            healthCheck: {
                                enabled: "En cas d'activation, l'interface vérifie périodiquement sa santé.",
                                mode: "Le mode de Contrôle de santé que vous voulez utiliser.",
                                targets: "Adresses IP à cibler avec le contrôle de santé ICMP."
                            },
                            userNamePassword: "Doit comporter moins de 256 caractères",
                            mtu: "Doit être un nombre inférieur ou égal à 1492.",
                            additionalAddresses: "Adresses IP additionnelles à assigner au port."
                        },
                        bridge: "Pont",
                        advanced: "Avancé",
                        basic: "Basique",
                        userName: "Nom d'utilisateur",
                        password: "Mot de passe",
                        mtu: "Taille MTU",
                        healthCheck: {
                            enabled: "Activer Contrôle de santé",
                            mode: "Contrôle de santé",
                            targets: "Cible de contrôle de santé"
                        },
                        warning: "Le changement de type de WAN entraîne la suppression des règles NAT d'entrée ou des tunnels IPSec associés.",
                        additionalAddresses: "Adresses IP additionnelles",
                        addressWarning: "Avertissement : si l'une quelconque des adresses IP WAN WAN que vous avez supprimées était utilisée dans un tunnel IPsec ou pour une règle NAT d'entrée, l'enregistrement de ces modifications va entraîner la suppression des règles associées."
                    },
                    lan: {
                        tabTitle: "LAN",
                        sectionTitle: "Interfaces LAN",
                        addTitle: "Ajouter une nouvelle interface LAN",
                        addButtonText: "Ajouter une interface LAN",
                        editTitle: "Modifier une interface LAN",
                        deleteTitle: "Supprimer une interface LAN",
                        deleteMessage1: "Êtes-vous certain de vouloir supprimer cette interface LAN ? L'effet sera le suivant :",
                        deleteMessage2: "Toutes les réservations et la configuration DHCP correspondantes seront retirées.",
                        deleteMessage3: "Toutes les règles de sécurité utilisant cette interface LAN seront mises à jour ou retirées.",
                        deleteMessage4: "La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        dhcpEnable: "Serveur DHCP",
                        dhcpFirst: "Première adresse IP",
                        dhcpLast: "Dernière adresse IP",
                        dhcpDomain: "Domaine DHCP",
                        dhcpRange: "Plage DHCP",
                        portWarning: "Les rangées grisées ci-dessus indiquent que le port sélectionné n'a pas été activé sur l'appareil.",
                        failover: "Basculement HA",
                        descriptions: {
                            name: "Un nom unique pour ce lien LAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau à utiliser pour le réseau créé.",
                            dhcpEnable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                            dhcpFirst: "La première IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                            dhcpLast: "La dernière IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                            dhcpDomain: "Le nom de domaine à assigner au serveur DHCP.",
                            failover: "En cas d'activation, le port est surveillé et un événement de basculement HA est exécuté en cas de perte de la connexion. Disponible uniquement si HA est activée."
                        },
                        wifi: {
                            validateSsid: "doit uniquement contenir des caractères alphanumériques ou '-'"
                        }
                    },
                    route: {
                        tabTitle: "Itinéraire",
                        sectionTitle: "Itinéraires statiques",
                        addTitle: "Ajouter un nouvel itinéraire statique",
                        addButtonText: "Ajouter un itinéraire statique",
                        editTitle: "Modifier un itinéraire statique",
                        deleteTitle: "Supprimer un itinéraire statique",
                        deleteMessage: "Êtes-vous certain de vouloir supprimer cet itinéraire statique ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        address: "Réseau cible",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        descriptions: {
                            name: "Un nom unique pour cet itinéraire.",
                            address: "L'adresse réseau cible pour cet itinéraire.",
                            netmask: "Le suffixe de masque réseau CIDR du réseau cible.",
                            gateway: "La passerelle à utiliser pour cet itinéraire."
                        }
                    },
                    dhcp: {
                        tabTitle: "DHCP",
                        networksSectionTitle: "Réseaux DHCP",
                        leasesSectionTitle: "Baux DHCP",
                        reservationsSectionTitle: "Réservations DHCP",
                        dhcpEnable: "Activer un service DHCP",
                        addNetworkTitle: "Ajouter un nouveau réseau DHCP",
                        addNetworkButtonText: "Ajouter un réseau",
                        editNetworkTitle: "Modifier un réseau DHCP",
                        deleteNetworkTitle: "Supprimer un réseau DHCP",
                        deleteNetworkMessage: "Êtes-vous certain de vouloir supprimer ce réseau DHCP ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        networks: {
                            networkSectionTitle: "Réseau",
                            basicSectionTitle: "Basique",
                            basicTabTitle: "Basique",
                            advancedSectionTitle: "Avancé",
                            advancedTabTitle: "Avancé",
                            enable: "Statut DHCP",
                            port: "Nom LAN",
                            description: "Description",
                            firstIp: "Première adresse IP",
                            lastIp: "Dernière adresse IP",
                            domain: "Nom de domaine",
                            dnsServers: "Serveurs DNS",
                            bootFileName: "Nom du fichier de démarrage",
                            defaultLeaseTime: "Durée du bail par défaut",
                            maxLeaseTime: "Durée du bail maximum",
                            winsServers: "Serveurs WINS",
                            tftpHostname: "Nom d'hôte TFTP",
                            tftpIpAddress: "Adresse IP TFTP",
                            vendorOptions: "Options de fournisseur",
                            validateMaxLeaseTime: "ne doit pas être inférieure à la durée du bail par défaut",
                            dhcpRelayType: "DHCP",
                            disabled: "Désactivé",
                            dhcpRelay: "Relais DHCP",
                            dhcpService: "Service HCP"
                        },
                        blockUnknownMac: "Autoriser uniquement les adresses MAC connues",
                        addReservationTitle: "Ajouter une nouvelle réservation DHCP",
                        addReservationButtonText: "Ajouter une réservation",
                        editReservationTitle: "Modifier une réservation DHCP",
                        deleteReservationTitle: "Supprimer une réservation DHCP",
                        deleteReservationMessage: "Êtes-vous certain de vouloir supprimer cette réservation DHCP ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        leases: {
                            reservationSectionTitle: "Baux",
                            port: "Nom LAN",
                            ip: "Adresse IP",
                            mac: "Adresse MAC",
                            reserve: "IP réservée",
                            noLeases: "Aucun bail actuellement actif. L'affichage des nouveaux baux peut prendre environ 2 minutes."
                        },
                        reservations: {
                            reservationSectionTitle: "Réservation",
                            name: "Nom",
                            description: "Description",
                            port: "Nom LAN",
                            ip: "Adresse IP",
                            mac: "Adresse MAC",
                            clientId: "ID client",
                            hostname: "Nom d'hôte",
                            validateMac: "doit être une adresse MAC valide",
                            validateHostname: "doit uniquement contenir des caractères alphanumériques, des « - » ou «  »",
                            duplicate: "doit être unique dans le LAN",
                            duplicateName: "doit être unique"
                        },
                        descriptions: {
                            dhcpEnable_site: "Activez le prestataire de services DHCP pour ce site.",
                            dhcpEnable_gateway: "Activez le prestataire de services DHCP pour cet Edge Service.",
                            blockUnknownMac: "En cas d'activation, seules les adresses MAC listées dans le tableau suivant peuvent utiliser le service DHCP.",
                            networks: {
                                enable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                                description: "Indiquez une description optionnelle pour cette configuration de serveur DHCP.",
                                port: "L'interface LAN sur laquelle ce réseau DHCP doit être configuré.",
                                firstIp: "La première IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                                lastIp: "La dernière IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                                domain: "En option, définissez un nom de domaine à assigner au serveur DHCP.",
                                dnsServers: "En option, définissez une liste de serveurs DNS à utiliser.",
                                bootFileName: "En option, définissez le nom d'un fichier de démarrage initial qui est chargé par un client. Il devrait être identifiable pour un protocole de transfert de fichier quelconque que le client pourrait utiliser afin de charger le fichier.",
                                defaultLeaseTime: "La longueur par défaut en secondes à assigner à un bail.",
                                maxLeaseTime: "La longueur maximum en secondes à assigner à un bail.",
                                winsServers: "En option, ajoutez une ou plusieurs adresses IP de serveurs WINS.",
                                tftpHostname: "En option, définissez un nom d'hôte d'un serveur TFTP.",
                                tftpIpAddress: "En option, définissez l'adresse IP d'un serveur TFTP.",
                                vendorOptions: "En option, définissez une ou plusieurs options de fournisseur séparées par « ; »."
                            },
                            reservations: {
                                name: "Un nom servant à identifier cette réservation.",
                                description: "Description optionnelle pour cette réservation.",
                                port: "L'interface LAN sur laquelle effectuer cette réservation.",
                                ip: "L'IP à réserver pour le client.",
                                mac: "L'adresse MAC pour identifier le client.",
                                clientId: "L'identificateur client qui identifie le client hôte en cas de demande d'adresse IP.",
                                hostname: "Nom d'hôte à assigner à cette IP."
                            },
                            dhcpRelayType: "Choisissez le type DHCP."
                        },
                        validation: {
                            dhcpEnable: "ne peut pas être activé quand un pont WAN est configuré"
                        }
                    },
                    advanced: {
                        tabTitle: "Paramètres avancés",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire",
                        rootPassword: "Mot de passe racine",
                        rootPasswordConfirmation: "Confirmer le mot de passe racine",
                        updateWindowTZ: "Fuseau horaire de fenêtre de mise à jour",
                        disableOMS: "Désactiver Log Analytics",
                        disableSecurityPolicy: "Désactiver Sécurité",
                        disableIpsPolicy: "Désactiver IPS",
                        omsNote: "Vous ignorez ainsi les paramètres de Log Analytics par défaut.",
                        descriptions: {
                            updateWindowTZ_site: "Le fuseau horaire pour ce site, utilisé pour déterminer sa fenêtre de mise à jour.",
                            updateWindowTZ_gateway: "Le fuseau horaire pour l’Edge Service, utilisé pour déterminer sa fenêtre de mise à jour.",
                            primaryDns: "Définissez manuellement l'adresse du DNS primaire.",
                            secondaryDns: "Définissez manuellement l'adresse du DNS secondaire.",
                            rootPassword_site: "Le mot de passe utilisateur racine pour le site.",
                            rootPassword_gateway: "Le mot de passe utilisateur racine pour l’Edge Service.",
                            disableOMS_site: "Désactivez les flux de journaux vers Azure Log Analytics pour ce site.",
                            disableOMS_gateway: "Désactivez les flux de journaux vers Azure Log Analytics pour cet Edge Service.",
                            disableSecurityPolicy_site: "Désactivez toutes les stratégies de sécurité pour ce site.",
                            disableSecurityPolicy_gateway: "Désactivez toutes les stratégies de sécurité pour cet Edge Service.",
                            disableIpsPolicy_site: "Désactivez la stratégie IPS pour ce site.",
              disableIpsPolicy_gateway: "Désactivez la stratégie IPS pour cet Edge Service.",
              secureOnPremNetwork: "Activez le trafic de retour et la sécurisation de tout le trafic Internet des réseaux LAN de site aval.",
              proxyConnect: "Activer les connexions via proxy Par défaut, des connecteurs logiciels sont créés sur l'IP principale pour chaque réseau LAN avec les ports 3128 et 8080. Cependant, vous pouvez spécifier vos propres connecteurs logiciels à la suite.",
              proxyConnectSockets: "Spécifiez les connecteurs logiciels à utiliser pour les connexions via proxy."
            },
            secureOnPremNetwork: "Sécuriser le Trafic Internet des <br/> LAN",
            proxyConnect: "Activer la connexion via proxy",
            proxyConnectSockets: "Connecteurs logiciels de connexion via proxy",
            validation: {
              proxyConnect: "doit être un connecteur logiciel valide"
                        }
                    },
                    confirmPortNotActivated: "Certains des ports sélectionnés ne sont pas actuellement activés pour ce dispositif.",
                    confirmPortNotActivated_site: "Certains des ports sélectionnés ne sont pas actuellement activés pour ce site.",
                    confirmPortNotActivated_gateway: "Certains des ports sélectionnés ne sont pas actuellement activés pour cet Edge Service.",
                    confirmPointOfEntryBroken: "Ce dispositif est configuré comme un point d'entrée et nécessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configurée va retirer la fonctionnalité de point d'entrée.",
                    confirmPointOfEntryBroken_site: "Ce site est configuré comme un point d'entrée et nécessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configurée va retirer la fonctionnalité de point d'entrée.",
                    confirmPointOfEntryBroken_gateway: "Cet Edge Service est configuré comme un point d'entrée et nécessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configurée va retirer la fonctionnalité de point d'entrée.",
                    confirm: "Êtes-vous certain de vouloir procéder ?"
                },
                support: {
                    buttonText: "Accès au support",
                    dialogTitle: "Accès au support",
                    message_site: "L'ACL de support et la clé SSH fournies ici bénéficient d'un accès SSH pendant 24 heures.",
                    message_gateway: "L'ACL de support et la clé SSH fournies ici bénéficient d'un accès SSH à cet Edge Service pendant 24 heures.",
                    networks: "ACL de support",
                    publicKey: "Clé publique SSH",
                    success_site: "Accès au support activé pour le site « {{name}} » pendant 24 heures.",
                    success_gateway: "Accès au support activé pour l’Edge Service « {{name}} » pendant 24 heures.",
                    description: {
                        networks_site: "Liste séparée par des virgules des réseaux devant bénéficier d'un accès SSH à votre site.",
                        networks_gateway: "Liste séparée par des virgules des réseaux devant bénéficier d'un accès SSH à votre Edge Service.",
                        publicKey_site: "La clé publique OpenSSH pour accéder à votre site.",
                        publicKey_gateway: "La clé publique OpenSSH pour accéder à votre Edge Service."
                    },
          cloudGatewayMessage: "Afin d'accéder à un Edge Service pour un WAN virtuel, vous devez aussi activer l'Accès au support sur l'<0>Application gérée via Azure</0>."
                },
                restart: {
                    buttonLabel: "Redémarrer",
                    box: {
                        menuChoice: "Redémarrer un site",
                        dialogTitle: "Redémarrer un site",
                        confirmMessage: "Cliquez sur CONFIRMER redémarrera ce site. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                        confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera les deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                    },
                    vpn: {
                        menuChoice: "Redémarrer un service VPN",
                        dialogTitle: "Redémarrer un service VPN",
                        confirmMessage: "Cliquez sur CONFIRMER redémarrera le service VPN pour cet appareil. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                        confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera le service VPN des deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                    },
                    firmware: {
                        menuChoice: "Redémarrage de micrologiciel",
                        dialogTitle: "Redémarrer un micrologiciel de pare-feu",
                        confirmMessage: "Cliquez sur CONFIRMER redémarrera le micrologiciel pour cet appareil. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                        confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera le micrologiciel des deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                    }
                }
            },
            security: {
                securityEnabled: "Sécurité activée",
                menuTitle: "SÉCURITÉ",
                pageTitle: "Sécurité",
                comingSoon: "Prochainement",
                protection: {
                    tabTitle: "Protection contre les maliciels",
                    settings: {
                        tabTitle: "Paramètres",
                        protection: "Protection contre les maliciels",
                        name: "Nom",
                        action: "Action",
                        description: "Description",
                        order: "Ordre",
                        source: "Source",
                        destination: "Destination",
                        defaultAction: "Action par défaut",
                        inputs: {
                            enabled: "Activé"
                        },
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            description: "Une brève description de votre règle.",
                            action: "L'action à prendre pour le trafic correspondant à cette stratégie."
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        }
                    }
                },
                addRuleButtonText: "Ajouter une règle",
                sourceCriteria: {
                    any: "Tous",
                    section: "Critères de source",
                    type: "Type",
                    site: "Site",
                    internet: "Internet",
                    siteNetwork: "Réseaux",
                    source: "Source",
                    application: "Application réseau personnalisée",
                    applications: "Applications réseau personnalisées",
                    allSites: "Tous sites",
                    allNetworks: "Tous réseaux",
                    addSite: "Ajouter un site",
                    editSite: "Modifier un site",
                    network: "IP/Réseau",
                    userOrGroup: "Utilisateur/Groupe",
                    users: "Utilisateurs",
                    groups: "Groupes",
                    userOrGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
                    userOrGroupDisabled: "Aucun Répertoire d'utilisateurs configuré. Voir 'Identité > Paramètres' pour ajouter un Répertoire d'utilisateurs.",
                    userOrGroupDisabledShort: "Aucun Répertoire d'utilisateurs configuré.",
                    description: "Sélectionnez votre type de source.",
                    applicationDescription: "Sélectionnez votre application source.",
                    networkDescription: "Insérez votre réseau source.",
                    siteDescription: "Sélectionnez votre site source.",
                    userDescription: "Sélectionnez votre ou vos utilisateurs sources.",
                    groupDescription: "Sélectionnez votre ou vos groupes sources.",
                    gatewayNetwork: "Réseaux",
                    allOnpremGateways: "Tous les services Private Edge",
                    addOnpremGateway: "Ajouter un service Private Edge",
                    editOnpremGateway: "Modifier un service Private Edge",
                    onpremGateway: "Service Private Edge",
                    onpremGatewayDescription: "Sélectionnez votre service Private Edge source"
                },
                destinationCriteria: {
                    section: "Critères de destination",
                    type: "Type",
                    destination: "Destination",
                    site: "Site",
                    siteNetwork: "Réseaux",
                    allSites: "Tous sites",
                    allNetworks: "Tous réseaux",
                    addSite: "Ajouter un site",
                    editSite: "Modifier un site",
                    network: "IP/Réseau",
                    application: "Application",
                    domain: "Domaine",
                    category: "Catégorie d'URL",
                    onPremGateway: "Service Private Edge",
                    description: "Sélectionnez votre type de destination.",
                    applicationDescription: "Sélectionnez votre application de destination.",
                    siteDescription: "Sélectionnez votre site de destination.",
                    gatewayDescription: "Sélectionnez votre service Private Edge de destination.",
                    networkDescription: "Sélectionnez votre réseau de destination.",
                    gateway: "Edge Service",
                    ingressSite: "Site",
                    customCategory: "Catégories personnalisées",
                    gatewayNetwork: "Réseaux",
                    onpremGateway: "Service Private Edge",
                    allOnpremGateways: "Tous les services Private Edge",
                    addOnpremGateway: "Ajouter un service Private Edge",
                    editOnpremGateway: "Modifier un service Private Edge",
                    onpremGatewayDescription: "Sélectionnez votre service Private Edge de destination."
                },
                targetCriteria: {
                    section: "Critères de cible"
                },
                tables: {
                    allSites: "Tous sites",
                    allGateways: "Tous les Edge Services",
                    source: "Source",
                    sourceNetwork: "IP/Réseau",
                    sourceApplication: "Application réseau personnalisée",
                    sourceSite: "Site",
                    sourceType: "Type de source",
                    destination: "Destination",
                    destinationSite: "Site",
                    destinationApplication: "Application",
                    destinationCategories: "Catégorie d'URL",
                    destinationNetwork: "IP/Réseau",
                    destinationDomain: "Domaine",
                    destinationType: "Type de destination",
                    destinationGateway: "Edge Service de destination",
                    destinationWan: "WAN de destination",
                    user: "Utilisateur",
                    group: "Groupe",
                    destinationCustomCategories: "Catégorie personnalisée",
                    allOnpremGateways: "Tous les services Private Edge",
                    sourceOnpremGateway: "Service Private Edge",
                    destinationOnpremGateway: "Service Private Edge"
                },
                applicationControl: {
                    siteTabTitle: "ACL de site",
                    gatewayTabTitle: "ACL de Edge Service",
                    settings: {
                        tabTitle: "Paramètres",
                        siteApplicationControl: "ACL de site",
                        gatewayApplicationControl: "ACL de Edge Service",
                        order: "Ordre",
                        name: "Nom",
                        action: "Action",
                        description: "Description",
                        defaultAction: "Action par défaut",
                        source: "Source",
                        destination: "Destination",
                        scope: "Portée",
                        allSites: "Tous sites",
                        allGateways: "Tous les Edge Services",
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            description: "Une brève description de votre règle.",
                            action: "L'action à prendre (bloquer ou autoriser) pour le trafic correspondant à cette stratégie.",
                            scope: "Le ou les sites auxquels appliquer cette règle.",
                            icmp: "Autorisez les dispositifs à communiquer via ICMP."
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        },
                        icmp: "ICMP"
                    },
                    noData: {
                        edgeServiceAcl: {
                            iconHeader: "Commencez par ajouter une ACL d’Edge Service !",
                            labelButton: "Ajouter une ACL d’Edge Service",
                            textHeader: "Une ACL d’Edge Service : c'est quoi ?",
                            text: "Pour des motifs de sécurité, vous souhaitez souvent restreindre l'accès à votre ou vos services.<0 /> This Access Control List lets you configure who is allowed to access the service(s) and how"
                        },
                        siteAcl: {
                            iconHeader: "Commencez par ajouter une ACL de site !",
                            labelButton: "Ajouter une ACL de site",
                            textHeader: "Une ACL de site : c'est quoi ?",
                            text: "Pour des motifs de sécurité, vous souhaitez souvent restreindre l'accès à votre ou vos sites.<0 /> This Access Control List lets you configure who is allowed to access the site(s) and how"
                        },
                        appResource: {
                            iconHeader: "Commencez par ajouter des Apps et Ressources !",
                            labelButton: "Ajouter une application personnalisée",
                            textHeader: "Une application personnalisée : c'est quoi ?",
                            text: "Pour un environnement SD-WAN en exploitation, votre configuration doit connaître les applications en usage.<0 /> Bien qu'un grand nombre d'applications soient prises en charge dès le départ, certaines peuvent ne pas former partie de l'offre initiale.<0 />Vous pouvez ajouter des applications réseau et Web qui ne sont pas prises en charge ici."
                        }
                    }
                },
                urlFiltering: {
                    tabTitle: "Filtrage URL",
                    settings: {
                        tabTitle: "Paramètres",
                        order: "Ordre",
                        name: "Nom",
                        description: "Description",
                        appliesTo: "S'applique à",
                        action: "Action",
                        type: "Type",
                        rule: "Destination",
                        domains: "Domaine",
                        categories: "Catégorie d'URL",
                        defaultAction: "Action par défaut",
                        destinationCriteria: "Critères de destination",
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            description: "Une brève description de votre règle.",
                            action: "L'action à prendre (bloquer ou autoriser) pour le trafic correspondant à cette stratégie.",
                            type: "Fondez cette politique sur la correspondance avec le nom de domaine ou la catégorie d'URL.",
                            domains: "Les domaines utilisés pour correspondre à cette stratégie.",
                            silent: "Bloquer la règle silencieusement.",
                            customCategories: "Les catégories personnalisées utilisées pour correspondre à cette stratégie."
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        validation: {
                            emptyCategories: "doit avoir au moins une catégorie sélectionnée."
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        },
                        silent: "Silencieux",
                        customCategories: "Catégories personnalisées",
                        destinationDomains: "Domaines ({{domains.0}}, +{{additional}})",
                        destinationDomains_1: "Domaines ({{domains.0}})",
                        destinationCategories: "Catégories ({{activeCategories.0.name}}, +{{additional}})",
                        destinationCategories_1: "Catégories ({{activeCategories.0.name}})",
                        destinationCustomCategories: "Catégories personnalisées ({{activeCategories.0.name}}, +{{additional}})",
                        destinationCustomCategories_1: "Catégories personnalisées ({{activeCategories.0.name}})"
                    },
                    exceptions: {
                        tabTitle: "Explicite",
                        order: "Ordre",
                        name: "Nom",
                        description: "Description",
                        action: "Action",
                        type: "Type",
                        rule: "Règle",
                        domain: "Domaine",
                        category: "Catégorie d'URL",
                        domains: "Nom d'hôte de destination",
                        categories: "Catégorie d'URL",
                        source: "Source",
                        sourceNetwork: "IP/Réseau source",
                        sourceSite: "Site source",
                        allSites: "Tous sites",
                        allNetworks: "Tous réseaux",
                        sourceSiteNetwork: "Réseaux",
                        sourceType: "Type",
                        network: "IP/Réseau",
                        site: "Site",
                        sourceSites: "Site",
                        addSite: "Ajouter un site",
                        editSite: "Modifier un site",
                        destinations: "Destination",
                        destination: "IP/Réseau de destination",
                        destinationType: "Type",
                        destinationCategories: "Catégorie d'URL de destination",
                        destinationDomains: "Domaine de destination",
                        sectionTitle: "Critères",
                        sourceCriteria: "Critères de source",
                        destinationCriteria: "Critères de destination",
                        descriptions: {
                            name: "Un nom unique pour cette stratégie explicite.",
                            description: "Une brève description de cette stratégie.",
                            action: "L'action à prendre (bloquer ou autoriser) pour le trafic correspondant à cette stratégie.",
                            categories: "Les catégories utilisées pour correspondre à cette stratégie.",
                            domains: "Les domaines utilisés pour correspondre à cette stratégie.",
                            customCategories: "Les catégories personnalisées utilisées pour correspondre à cette stratégie.",
                            silent: "Bloquer la règle silencieusement."
                        },
                        validation: {
                            sourceDest: "doit fournir au moins une source ou destination"
                        },
                        create: {
                            buttonText: "Ajouter",
                            dialogTitle: "Ajouter Explicite"
                        },
                        edit: {
                            dialogTitle: "Modifier Explicite"
                        },
                        delete: {
                            title: "Supprimer Explicite",
                            body: "Êtes-vous certain de vouloir supprimer cette exception ?"
                        },
                        silent: "Silencieux",
                        customCategories: "Catégories personnalisées"
                    }
                },
                urlCategories: {
                    tabTitle: "Catégories d'URL",
                    settings: {
                        tabTitle: "Paramètres",
                        urlCategories: "Catégories d'URL",
                        description: "Cocher une super catégorie d'URL ou une catégorie d'URL pour la bloquer"
                    },
                    blocklists: {
                        tabTitle: "Autoriser/Bloquer listes",
                        description: "Définir les URL, IP ou réseaux à bloquer /autoriser en permanence",
                        inputs: {
                            blacklist: "Toujours bloquer",
                            whitelist: "Toujours autoriser"
                        }
                    },
                    exceptions: {
                        tabTitle: "Explicite",
                        categories: "Super catégories",
                        subCategories: "Catégories",
                        mode: "Autoriser/Bloquer",
                        allow: "Autoriser",
                        block: "Bloquer",
                        users: "Utilisateurs",
                        groups: "Groupes",
                        source: "IP/Réseau source",
                        time: "Temps",
                        validateCategory: "Au moins une super catégorie d'URL ou une catégorie d'URL doit être fournie",
                        validateSource: "Au moins un utilisateur, une source ou un groupe doit être fourni",
                        delete: {
                            title: "Supprimer Explicite",
                            body: "Êtes-vous certain de vouloir supprimer cette exception ? La suppression deviendra permanente uniquement après l'enregistrement."
                        },
                        create: {
                            title: "Ajouter nouvelle Explicite",
                            button: "Ajouter"
                        },
                        edit: {
                            title: "Modifier Explicite"
                        }
                    }
                },
                ips: {
                    tabTitle: "IPS",
                    settings: {
                        tabTitle: "Paramètres",
                        falsePositivesTabTitle: "Faux positifs",
                        ips: "Prévention d'intrusion",
                        name: "Nom",
                        action: "Action",
                        description: "Description",
                        block: "Bloquer",
                        mode: "Mode",
                        log: "Journal",
                        order: "Ordre",
                        source: "Source",
                        exploits: "Exploit",
                        destination: "Destination",
                        defaultAction: "Action par défaut",
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            description: "Une brève description de votre règle.",
                            action: "L'action à prendre pour le trafic correspondant à cette stratégie."
                        },
                        inputs: {
                            enabled: "Activé"
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        }
                    }
                },
                sslInterception: {
                    tabTitle: "Inspection SSL",
                    settings: {
                        tabTitle: "Certificats",
                        sslInterception: "Inspection SSL",
                        name: "Nom",
                        action: "Action",
                        description: "Description",
                        order: "Ordre",
                        source: "Source",
                        destination: "Destination",
                        defaultAction: "Action par défaut",
                        explicitTabTitle: "Paramètres",
                        status: "Statut",
                        issuer: "Émetteur",
                        startDate: "Date de début",
                        endDate: "Date de fin",
                        makeActive: "Rendre actif",
                        active: "Actif",
                        activeCertificate: "Certificat actif",
                        makeCertificateActive: "Rendre actif",
                        sslCertificates: "Certificats d'inspection SSL",
                        sslCertificateAuthorities: "Autorité de certification de confiance",
                        uploadP12: "Télécharger PKCS12",
                        uploadPEM: "Télécharger PEM ou CRT",
                        passphrase: "Phrase secrète",
                        downloadCertificate: "Télécharger un certificat de client",
                        certificates: {
                            create: {
                                label: "Ajouter un certificat",
                                dialogTitle: "Télécharger un nouveau certificat"
                            },
                            edit: {
                                dialogTitle: "Modifier des détails de certificat"
                            },
                            delete: {
                                title: "Supprimer un certificat",
                                body: "Êtes-vous certain de vouloir supprimer ce certificat ?",
                                disabledMessage: "Impossible de supprimer le certificat actif."
                            }
                        },
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            certificateName: "Un nom unique pour votre certificat.",
                            description: "Une brève description de votre règle.",
                            certificateDescription: "Une brève description de votre certificat.",
                            action: "L'action à prendre pour le trafic correspondant à cette stratégie.",
                            uploadP12: "Téléchargez votre certificat PKS12 pour. Il doit contenir une clé privée.",
                            uploadPEM: "Téléchargez un certificat PEM ou CRT.",
                            passphrase: "La phrase secrète de déverrouillage du certificat PKS12 (si nécessaire)."
                        },
                        inputs: {
                            enabled: "Activé"
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        }
                    }
                },
                ingressNatRules: {
                    settings: {
                        order: "Ordre",
                        name: "Nom",
                        target: "Cible",
                        description: "Description",
                        source: "Source",
                        destination: "Destination",
                        gateway: "Edge Service",
                        wan: "WAN",
                        targetApplicationResource: "Application/Ressource",
                        descriptions: {
                            name: "Un nom unique pour votre règle.",
                            description: "Une brève description de votre règle.",
                            target: "La cible du trafic correspondant à cette règle.",
                            wan: "Sélectionnez votre interface WAN en fonction de l'IP publique souhaitée.",
                            patPublicPort: "Votre port public PAT de destination. Ce champ est obligatoire si la cible est une application réseau sans port. Si aucun port n'est fourni, alors le port configuré de l'application cible (ou le premier port de la plage de ports configurés) est utilisé."
                        },
                        create: {
                            buttonText: "Ajouter une règle",
                            dialogTitle: "Ajouter une nouvelle règle"
                        },
                        edit: {
                            dialogTitle: "Modifier une règle"
                        },
                        delete: {
                            title: "Supprimer une règle",
                            body: "Êtes-vous certain de vouloir supprimer cette règle ?"
                        },
                        IPAddress: "Adresse"
                    },
                    noData: {
                        iconHeader: "Commencez par ajouter une règle de NAT d'entrée !",
                        labelButton: "Ajouter une règle de NAT d'entrée",
                        textHeader: "Un NAT d'entrée : c'est quoi ?",
                        text: "Si vous souhaitez rendre des ressources de réseau interne disponibles pour le public, utilisez cette section pour définir les paramètres de ces connexions.<0 /><0 />Please note that the internal service needs to run on a static public IP address."
                    }
                }
            },
            customApps: {
                menuTitle: "APPS PERSONNALISÉES",
                pageTitle: "Applications personnalisées",
                table: {
                    name: "Nom",
                    description: "Description",
                    protocol: "Protocole",
                    destination: "Destinations",
                    category: "Catégorie",
                    type: "Type"
                },
                dialogs: {
                    create: {
                        buttonText: "Nouvelle application personnalisée",
                        dialogTitleWeb: "Nouvelle application Web personnalisée",
                        dialogTitleNetwork: "Nouvelle application réseau personnalisée",
                        menuLabelWeb: "Nouvelle application Web personnalisée",
                        menuLabelNetwork: "Nouvelle application réseau personnalisée"
                    },
                    edit: {
                        dialogTitle: "Modifier une application personnalisée"
                    },
                    general: {
                        sectionTitle: "Général",
                        name: "Nom",
                        description: "Description",
                        descriptions: {
                            name: "Un nom unique pour cette application personnalisée.",
                            description: "Une brève description de cette application personnalisée."
                        }
                    },
                    endpoints: {
                        protocol: "Protocole",
                        sectionTitle: "Points finaux d'application",
                        destination: "Destination",
                        port: "Port",
                        firstPort: "Premier Port",
                        lastPort: "Dernier Port",
                        descriptions: {
                            protocol: "Le protocole utilisé par cette application personnalisée",
                            destination: "L'IP, le domaine ou le réseau de destination auquel cette application personnalisée envoie le trafic.",
                            webAppDestination: "Le nom d'hôte de destination auquel cette application personnalisée envoie le trafic.",
                            port: "Le port utilisé par cette application personnalisée",
                            firstPort: "FACULTATIF : Le premier port de la plage de ports utilisé par cette application personnalisée.",
                            lastPort: "FACULTATIF : Le dernier port de la plage de ports utilisé par cette application personnalisée."
                        }
                    },
                    category: "Catégorie",
                    descriptions: {
                        category: "La catégorie à laquelle assigner cette application personnalisée."
                    }
                },
                delete: {
                    title: "Supprimer {{name}}",
                    body: "Êtes-vous certain de vouloir supprimer cette application personnalisée ?"
                }
            },
            sites: {
                auditLog: {
                    tabTitle: "Journal d'audit",
                    pageTitle: "Journal d'audit"
                },
                connectionHistory: {
                    tabTitle: "Connexions récentes",
                    pageTitle: "Connexions récentes",
                    waitingForConnections: "Connexion au site. L'établissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
                    emptyData: "Aucune connexion récente...",
                    pauseRequestsButton: "Pause",
                    resumeRequestsButton: "Reprendre"
                },
                dashboard: {
                    tabTitle: "Tableau de bord",
                    statusCardTitle: "Statut",
                    servicesCardTitle: "Services",
                    licensesCardTitle: "Licences",
                    locationsCardTitle: "Emplacement",
                    gateway: {
                        tabTitle: "Edge Service",
                        name: "Nom",
                        region: "Région",
                        hubName: "Hub",
                        vwan: "VWAN",
                        size: "Taille"
                    },
                    siteStatus: {
                        title: "Information",
                        model: "Modèle",
                        licenses: "Licences",
                        serials: "Numéro(s) de série",
                        uptime: "Actif depuis",
                        time: "Heure actuelle",
                        release: "Version",
                        hostname: "Nom d'hôte"
                    },
                    licenses: {
                        type: "Type",
                        state: "État",
                        status: "Statut",
                        expirationDate: "Date d'expiration",
                        serial: "Numéro de série",
                        noData: "S/O"
                    },
                    boxResources: {
                        title: "Ressources",
                        cpuLoad: "CPU",
                        cpuLoadError: "Une erreur a été détectée dans la CPU. Veuillez redémarrer le dispositif. Si cette erreur persiste, veuillez contacter le support.",
                        memory: "Mémoire",
                        diskInformation: "Disque dur",
                        diskInformationError: "Une erreur a été détectée dans l'une des partitions de disque. Veuillez redémarrer le dispositif. Si cette erreur persiste, veuillez contacter le support."
                    },
                    interfaces: {
                        title: "Interfaces",
                        status: "Statut",
                        name: "Nom",
                        speed: "Vitesse",
                        duplex: "Duplex",
                        errors: "Erreurs",
                        state: {
                            ok: "OK",
                            warning: "Avertissement",
                            error: "Erreur",
                            unknown: "Inconnu",
                            loading: " "
                        }
                    },
                    deploymentEvents: {
                        title: "Événements de déploiements",
                        state: "État",
                        status: "Statut",
                        created: "Date"
                    },
                    deploymentStatus: {
                        title: "Statut de déploiement {{serial}}",
                        status: "Statut",
                        action: "Action",
                        message: "Message",
                        registered: "Enregistré",
                        lastSeen: "Vu en dernier",
                        completed: "Terminé",
                        localIp: "IP locale",
                        remoteIp: "IP publique",
                        siteDeploying: "Déploiement en cours"
                    },
                    deploymentLocation: {
                        title: "Emplacement de déploiement"
                    },
                    firmware: {
                        tabTitle: "Micrologiciel",
                        version: "Version",
                        hotfixes: "Correctifs installés",
                        window: "Mise à jour suivante",
                        nextUpdate: "Entre {{from}} et {{to}}"
                    },
                    hotfixes: {
                        tabTitle: "Correctifs",
                        status: "Statut",
                        name: "Nom",
                        state: {
                            error: "Erreur",
                            ok: "OK",
                            unknown: "Inconnu",
                            warning: "En cours",
                            loading: " "
                        }
                    },
                    updates: {
                        tabTitle: "Mises à jour de configuration",
                        lastUpdate: "Dernière mise à jour",
                        lastSeen: "Vu en dernier",
                        remoteIp: "IP distante"
                    },
                    restart: {
                        buttonLabel: "Redémarrer",
                        box: {
                            menuChoice: "Redémarrer un site",
                            dialogTitle: "Redémarrer un site",
                            confirmMessage: "Cliquez sur CONFIRMER redémarrera ce site. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                            confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera les deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                        },
                        vpn: {
                            menuChoice: "Redémarrer un service VPN",
                            dialogTitle: "Redémarrer un service VPN",
                            confirmMessage: "Cliquez sur CONFIRMER redémarrera le service VPN pour cet appareil. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                            confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera le service VPN des deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                        },
                        firmware: {
                            menuChoice: "Redémarrage de micrologiciel",
                            dialogTitle: "Redémarrer un micrologiciel de pare-feu",
                            confirmMessage: "Cliquez sur CONFIRMER redémarrera le micrologiciel pour cet appareil. 5 minutes peuvent être nécessaires. <0/><0/>Êtes-vous certain de vouloir procéder ?",
                            confirmMessage_plural: "Cliquez sur CONFIRMER redémarrera le micrologiciel des deux appareils de votre paire HA. 5 minutes peuvent être nécessaires. <0/> Seuls les appareils déclarés comme étant en ligne seront affectés. <0/><0/>Êtes-vous certain de vouloir procéder ?"
                        }
                    }
                },
                delete: {
                    body: "Êtes-vous certain de vouloir supprimer ce site ?",
                    title: "Supprimer site {{name}}"
                },
                dialog: {
                    create: {
                        buttonText: "Nouveau site",
                        sc: {
                            createSC: "Nouveau dispositif IoT",
                            dialogTitle: "Nouveau dispositif IoT",
                            menuItemDisabled: "Les dispositifs IoT nécessitent un Edge Service"
                        },
                        tvt: {
                            createTVT: "Nouveau dispositif T/VT",
                            dialogTitle: "Nouveau site"
                        }
                    },
                    wans: {
                        sectionTitle: "WAN",
                        message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une désignation WAN et LAN.",
                        message2_site: "Combien de connexions WAN voulez-vous configurer pour ce site ?",
                        message2_gateway: "Combien de connexions WAN voulez-vous configurer pour cet Edge Service ?",
                        size: "Nombre de WAN",
                        setup: "Configurer un lien WAN {{step}}",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        type: "Type",
                        providerPinning: "Spécification de fournisseur",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        pin: "PIN de SIM pour {{serial}}",
                        invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
                        SCmessage: "Votre dispositif a un seul port WAN à configurer.",
                        SCmessage_failover: "Votre dispositif peut avoir jusqu'à 2 connexions WAN (une primaire et une de basculement).",
                        SCconfigureFailover: "Voulez-vous configurer un basculement pour ce site ?",
                        failover: "Basculement WAN",
                        setupSCPrimary: "Configurer un lien WAN primaire",
                        setupSCFailover: "Configurer un lien WAN de basculement",
                        descriptions: {
                            name: "Un nom unique pour ce lien WAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            type: "Le type de réseau à utiliser pour ce lien.",
                            providerPinning: "Le groupe de fournisseurs sur lequel épingler ce lien. Les groupes de fournisseurs sont utilisés par les stratégies SD-WAN pour définir le comportement et le débit du trafic.",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau pour ce lien.",
                            gateway: "L'adresse IP de la passerelle associée à ce lien.",
                            pin: "Le PIN de la carte SIM installée dans ce dispositif."
                        }
                    },
                    lans: {
                        sectionTitle: "LAN",
                        message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour un usage WAN et LAN. Vous avez alloué {{wans}} ports au WAN.",
                        SCmessage: "Votre dispositif dispose d'un total de {{portsAvailable}} ports LAN.",
                        message2: "Combien de connexions LAN voulez-vous configurer pour ce site ?",
                        size: "Nombre de LAN",
                        setup: "Configurer un lien WAN {{step}}",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        dhcpEnable: "Serveur DHCP",
                        dhcpDescription: "Description",
                        dhcpFirstIp: "Première adresse IP",
                        dhcpLastIp: "Dernière adresse IP",
                        dhcpDomain: "Domaine DHCP",
                        dhcpSectionTitle: "Configuration DHCP",
                        wifiSectionTitle: "Configuration Wi-Fi",
                        wifi: {
                            ssid: "SSID",
                            securityMode: "Mode de sécurité",
                            passphrase: "Phrase secrète",
                            passphraseConfirm: "Confirmer la phrase secrète"
                        },
                        descriptions: {
                            dhcpEnable: "Active le serveur DHCP pour ce lien LAN.",
                            name: "Un nom unique pour ce lien LAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau à utiliser pour le réseau créé.",
                            dhcpDescription: "Description personnalisée pour ce réseau DHCP",
                            dhcpFirstIp: "Première adresse IP allouable dans ce réseau DHCP.",
                            dhcpLastIp: "Dernière adresse IP allouable dans ce réseau DHCP.",
                            dhcpDomain: "Nom de domaine pour ce service DHCP.",
                            wifi: {
                                ssid: "Le SSID du réseau Wi-Fi.",
                                securityMode: "Le mode de sécurité Wi-Fi à utiliser.",
                                passphrase: "La phrase secrète pour le réseau."
                            }
                        }
                    },
                    advanced: {
                        sectionTitle: "Paramètres avancés",
                        updateWindowTZ: "Fuseau horaire de fenêtre de mise à jour",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire",
                        descriptions: {
                            updateWindowTZ: "Le fuseau horaire pour ce site, utilisé pour déterminer sa fenêtre de mise à jour.",
                            primaryDns: "Définissez manuellement l'adresse du DNS primaire.",
                            secondaryDns: "Définissez manuellement l'adresse du DNS secondaire."
                        }
                    },
                    submit: {
                        message: "Veuillez examiner les détails de votre site et cliquer sur Enregistrer",
                        name: "Nom",
                        description: "Description",
                        serial: "Numéro de série",
                        gateway: "Edge Service",
                        noGateway: "(Aucun)",
                        timezone: "Fuseau horaire",
                        status: "Statut de connexion",
                        peeringAddress: "Adresse d'appairage",
                        wan: "WAN",
                        lan: "LAN",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire"
                    },
                    result: {
                        siteCreated: "Le site a bien été créé",
                        connectPort: "Assurez-vous de brancher le port <0>{{port}}</0> de l'appareil suivant dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                        connectPortSc: "Assurez-vous de brancher le port WAN de l'appareil ci-dessous dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
                        connectPort_plural: "Assurez-vous de brancher le port <0>{{port}}</0> des appareils suivants dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de vos appareils. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur les appareils.",
                        HAMessage: "Connectez le port <0>P1</0> des deux appareils directement avec un câble de réseau. La synchronisation HA démarre automatiquement.",
                        campus: "Pour en savoir plus, voir <0>Barracuda Campus</0>",
                        serial: "Numéro de série : {{serial}}",
                        nonstandardPort: "Avertissement : Cette configuration utilise un port non-standard : <0>{{ports}}</0>. Assurez-vous qu'il est activé sur votre appareil virtuel.",
                        nonstandardPort_plural: "Avertissement : Cette configuration utilise des ports non-standard : <0>{{ports}}</0>. Assurez-vous qu'ils sont activés sur votre appareil virtuel."
                    },
                    steps: {
                        result: "Terminé",
                        submit: "Synthèse"
                    },
                    types: {
                        dynamic: "Dynamique (DHCP)",
                        configure_on_branch: "Configuré sur branche"
                    },
                    validation: {
                        duplicateNetwork: "un réseau correspondant à ce port/LAN virtuel a déjà été configuré",
                        duplicateWwan: "un seul WWAN peut être configuré",
                        duplicateSCwan: "un seul WAN dynamique ou statique peut être configuré",
                        duplicateExpressRoute: "un seul itinéraire express peut être configuré",
                        duplicateName: "nom déjà utilisé pour une autre connexion",
                        secondaryDns: "ne doit pas être défini en l'absence de DNS primaire",
                        availableAsn: "cette valeur ASN est indisponible"
                    }
                },
                liveLog: {
                    tabTitle: "Connexions actives",
                    pageTitle: "Connexions actives",
                    waitingForConnections: "Connexion au site. L'établissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
                    emptyData: "Aucune connexion actuellement active...",
                    pauseRequestsButton: "Pause",
                    resumeRequestsButton: "Reprendre"
                },
                menuTitle: "SITES",
                pageTitle: "Sites",
                settings: {
                    tabTitle: "Paramètres",
                    basic: {
                        tabTitle: "Informations basiques",
                        name: "Nom",
                        model: "Modèle",
                        gateway: "Edge Service",
                        serials: "Numéro(s) de série",
                        descriptions: {
                            gateway: "Le service Barracuda SecureEdge Edge auquel ce site est connecté.",
                            name: "Un nom unique pour votre site.",
                            model: "Le modèle de dispositif pour ce site.",
                            serials: "Le ou les numéros de séries de dispositif pour ce site."
                        },
                        serialEdit: {
                            table: {
                                status: "Statut",
                                serial: "Numéro de série",
                                add: "Ajouter second"
                            },
                            addTitle: "Ajouter un numéro de série",
                            editTitle: "Échanger un numéro de série",
                            deleteTitle: "Retirer un numéro de série",
                            edit: "Sélectionnez à la suite le numéro de série de l'appareil de remplacement que vous voulez joindre à ce site. Les appareils peuvent être remplacés uniquement par un autre du même modèle.",
                            add: "Le fait de joindre un second appareil à votre site active la fonctionnalité HA. Veuillez sélectionner à la suite le numéro de série de vos appareils.",
                            delete1: "Êtes-vous certain de vouloir retirer le numéro de série {{serial}} de ce site ?",
                            delete2: "Vous allez retirer la fonctionnalité HA et désactiver l'appareil associé.",
                            save: "Les modifications seront prises en compte après l'enregistrement.",
                            saveDelete: "Après l'enregistrement vous pouvez déconnecter l'appareil.",
                            saveAdd: "Après l'enregistrement, vous pouvez connecter le nouvel appareil en commençant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                            saveEdit: "Après l'enregistrement, vous pouvez déconnecter l'ancien appareil. Connectez alors le nouvel appareil en commençant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
                            saveHA: "En outre, connectez le port <0>P1</0> du nouvel appareil et de l'ancien directement avec un câble de réseau. La synchronisation HA démarre automatiquement.",
                            saveEditSecondary: "L'ancien appareil a été configuré pour HA. Avant de pouvoir l'utiliser à nouveau, vous devez créer une nouvelle image.",
                            saveDeleteSecondary: "Cet appareil a été configuré pour HA. Avant de pouvoir l'utiliser à nouveau, vous devez créer une nouvelle image.",
                            saveDeletePrimary: "L'autre appareil joint à ce site a été configuré pour HA. Vous devez créer une nouvelle image après l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous à un temps d'arrêt de votre site jusqu'à ce que ce processus soit terminé."
                        }
                    },
                    wan: {
                        tabTitle: "WAN",
                        addTitle: "Ajouter une nouvelle interface WAN",
                        addButtonText: "Ajouter une interface WAN",
                        editTitle: "Modifier une interface WAN",
                        deleteTitle: "Supprimer une interface WAN",
                        deleteMessage: "Êtes-vous certain de vouloir supprimer cette interface WAN ? <0> La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        type: "Type",
                        providerPinning: "Spécification de fournisseur",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        pin: "PIN de SIM pour {{serial}}",
                        scPin: "PIN de SIM",
                        invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
                        portWarning: "Les rangées grisées ci-dessus indiquent que le port sélectionné n'a pas été activé sur l'appareil.",
                        failover: "Basculement HA",
                        network: "Réseau",
                        doublePort: "Port",
                        doubleVirtualLanId: "ID de LAN virtuel",
                        doubleAddress: "Adresse IP",
                        doubleNetmask: "Masque réseau",
                        virtualTransferNetwork: "Réseau de transfert virtuel",
                        asn: "ASN itinéraire express",
                        expressRoute: "Itinéraire express",
                        primary: "Primaire",
                        secondary: "Secondaire",
                        validatePublicIp: "doit être une IP publique pour la connectivité utilisateur",
                        descriptions: {
                            name: "Un nom unique pour ce lien WAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            type: "Le type de réseau à utiliser pour ce lien.",
                            providerPinning: "Le groupe de fournisseurs sur lequel épingler ce lien. Les groupes de fournisseurs sont utilisés par les stratégies SD-WAN pour définir le comportement et le débit du trafic.",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau pour ce lien.",
                            gateway: "L'adresse IP de la passerelle associée à ce lien.",
                            pin: "Le PIN de la carte SIM installée dans ce dispositif.",
                            failover: "En cas d'activation, le port est surveillé et un événement de basculement HA est exécuté en cas de perte de la connexion. Disponible uniquement sur un site avec HA activée",
                            doublePort: "Les ports de dispositif à utiliser pour les liens primaires et secondaires.",
                            doubleVirtualLanId: "ID LAN virtuel en option pour les liens primaire et secondaire (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            doubleAddress: "L'adresse IP à assigner aux liens primaire et secondaire. Il doit s'agir de la première IP utilisable des plages configurées dans Azure pour chaque connexion (ex. si 172.16.0.4/30 est configurée dans Azure, alors renseignez l'adresse IP 172.16.0.5).",
                            doubleNetmask: "Le suffixe de masque réseau CIDR du réseau pour les liens primaires et secondaires.",
                            asn: "Un ASN BGP autogénéré pour ce lien. Il ne sera pas en conflit avec d'autres ASN gérés au sein du service Cloud Gen WAN.",
                            virtualTransferNetwork: "Réseau utilisé pour réserver les IP des interfaces de transfert virtuel."
                        }
                    },
                    lan: {
                        tabTitle: "LAN",
                        sectionTitle: "Interfaces LAN",
                        addTitle: "Ajouter une nouvelle interface LAN",
                        addButtonText: "Ajouter une interface LAN",
                        editTitle: "Modifier une interface LAN",
                        deleteTitle: "Supprimer une interface LAN",
                        deleteMessage1: "Êtes-vous certain de vouloir supprimer cette interface LAN ? L'effet sera le suivant :",
                        deleteMessage2: "Toutes les réservations et la configuration DHCP correspondantes seront retirées.",
                        deleteMessage3: "Toutes les règles de sécurité utilisant cette interface LAN seront mises à jour ou retirées.",
                        deleteMessage4: "La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        port: "Port",
                        virtualLanId: "ID de LAN virtuel",
                        address: "Adresse IP",
                        netmask: "Masque réseau",
                        dhcpEnable: "Serveur DHCP",
                        dhcpFirst: "Première adresse IP",
                        dhcpLast: "Dernière adresse IP",
                        dhcpDomain: "Domaine DHCP",
                        dhcpRange: "Plage DHCP",
                        portWarning: "Les rangées grisées ci-dessus indiquent que le port sélectionné n'a pas été activé sur l'appareil.",
                        failover: "Basculement HA",
                        descriptions: {
                            name: "Un nom unique pour ce lien LAN.",
                            port: "Le port du dispositif à utiliser pour ce lien.",
                            virtualLanId: "ID LAN virtuel en option pour réseau (utilisé en cas de connexion à un commutateur compatible LAN virtuel).",
                            address: "L'adresse IP à assigner au port.",
                            netmask: "Le suffixe de masque réseau CIDR de réseau à utiliser pour le réseau créé.",
                            dhcpEnable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                            dhcpFirst: "La première IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                            dhcpLast: "La dernière IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                            dhcpDomain: "Le nom de domaine à assigner au serveur DHCP.",
                            failover: "En cas d'activation, le port est surveillé et un événement de basculement HA est exécuté en cas de perte de la connexion. Disponible uniquement sur un site avec HA activée"
                        }
                    },
                    route: {
                        tabTitle: "Itinéraire",
                        sectionTitle: "Itinéraires statiques",
                        addTitle: "Ajouter un nouvel itinéraire statique",
                        addButtonText: "Ajouter un itinéraire statique",
                        editTitle: "Modifier un itinéraire statique",
                        deleteTitle: "Supprimer un itinéraire statique",
                        deleteMessage: "Êtes-vous certain de vouloir supprimer cet itinéraire statique ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        name: "Nom",
                        address: "Réseau cible",
                        netmask: "Masque réseau",
                        gateway: "Passerelle",
                        descriptions: {
                            name: "Un nom unique pour cet itinéraire.",
                            address: "L'adresse réseau cible pour cet itinéraire.",
                            netmask: "Le suffixe de masque réseau CIDR du réseau cible.",
                            gateway: "La passerelle à utiliser pour cet itinéraire."
                        }
                    },
                    dhcp: {
                        tabTitle: "DHCP",
                        networksSectionTitle: "Réseaux DHCP",
                        leasesSectionTitle: "Baux DHCP",
                        reservationsSectionTitle: "Réservations DHCP",
                        dhcpEnable: "Activer un service DHCP",
                        addNetworkTitle: "Ajouter un nouveau réseau DHCP",
                        addNetworkButtonText: "Ajouter un réseau",
                        editNetworkTitle: "Modifier un réseau DHCP",
                        deleteNetworkTitle: "Supprimer un réseau DHCP",
                        deleteNetworkMessage: "Êtes-vous certain de vouloir supprimer ce réseau DHCP ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        networks: {
                            networkSectionTitle: "Réseau",
                            basicSectionTitle: "Basique",
                            basicTabTitle: "Basique",
                            advancedSectionTitle: "Avancé",
                            advancedTabTitle: "Avancé",
                            enable: "Plage DHCP active",
                            port: "Nom LAN",
                            description: "Description",
                            firstIp: "Première adresse IP",
                            lastIp: "Dernière adresse IP",
                            domain: "Nom de domaine",
                            dnsServers: "Serveurs DNS",
                            bootFileName: "Nom du fichier de démarrage",
                            defaultLeaseTime: "Durée du bail par défaut",
                            maxLeaseTime: "Durée du bail maximum",
                            winsServers: "Serveurs WINS",
                            tftpHostname: "Nom d'hôte TFTP",
                            tftpIpAddress: "Adresse IP TFTP",
                            vendorOptions: "Options de fournisseur",
                            validateMaxLeaseTime: "ne doit pas être inférieure à la durée du bail par défaut"
                        },
                        blockUnknownMac: "Autoriser uniquement les adresses MAC connues",
                        addReservationTitle: "Ajouter une nouvelle réservation DHCP",
                        addReservationButtonText: "Ajouter une réservation",
                        editReservationTitle: "Modifier une réservation DHCP",
                        deleteReservationTitle: "Supprimer une réservation DHCP",
                        deleteReservationMessage: "Êtes-vous certain de vouloir supprimer cette réservation DHCP ? La suppression deviendra permanente uniquement après l'enregistrement.",
                        leases: {
                            reservationSectionTitle: "Baux",
                            port: "Nom LAN",
                            ip: "Adresse IP",
                            mac: "Adresse MAC",
                            reserve: "IP réservée",
                            noLeases: "Aucun bail actuellement actif. L'affichage des nouveaux baux peut prendre environ 2 minutes."
                        },
                        reservations: {
                            reservationSectionTitle: "Réservation",
                            name: "Nom",
                            description: "Description",
                            port: "Nom LAN",
                            ip: "Adresse IP",
                            mac: "Adresse MAC",
                            clientId: "ID client",
                            hostname: "Nom d'hôte",
                            validateMac: "doit être une adresse MAC valide",
                            validateHostname: "doit uniquement contenir des caractères alphanumériques, des « - » ou «  »",
                            duplicate: "doit être unique dans le LAN",
                            duplicateName: "doit être unique"
                        },
                        descriptions: {
                            dhcpEnable: "Activez le prestataire de services DHCP pour ce site.",
                            blockUnknownMac: "En cas d'activation, seules les adresses MAC listées dans le tableau suivant peuvent utiliser le service DHCP.",
                            networks: {
                                enable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                                description: "Indiquez une description optionnelle pour cette configuration de serveur DHCP.",
                                port: "L'interface LAN sur laquelle ce réseau DHCP doit être configuré.",
                                firstIp: "La première IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                                lastIp: "La dernière IP disponible pour l'assignation DHCP. Elle doit appartenir au réseau défini par le masque réseau et l'IP de port.",
                                domain: "En option, définissez un nom de domaine à assigner au serveur DHCP.",
                                dnsServers: "En option, définissez une liste de serveurs DNS à utiliser.",
                                bootFileName: "En option, définissez le nom d'un fichier de démarrage initial qui est chargé par un client. Il devrait être identifiable pour un protocole de transfert de fichier quelconque que le client pourrait utiliser afin de charger le fichier.",
                                defaultLeaseTime: "La longueur par défaut en secondes à assigner à un bail.",
                                maxLeaseTime: "La longueur maximum en secondes à assigner à un bail.",
                                winsServers: "En option, ajoutez une ou plusieurs adresses IP de serveurs WINS.",
                                tftpHostname: "En option, définissez un nom d'hôte d'un serveur TFTP.",
                                tftpIpAddress: "En option, définissez l'adresse IP d'un serveur TFTP.",
                                vendorOptions: "En option, définissez une ou plusieurs options de fournisseur séparées par « ; »."
                            },
                            reservations: {
                                name: "Un nom servant à identifier cette réservation.",
                                description: "Description optionnelle pour cette réservation.",
                                port: "L'interface LAN sur laquelle effectuer cette réservation.",
                                ip: "L'IP à réserver pour le client.",
                                mac: "L'adresse MAC pour identifier le client.",
                                clientId: "L'identificateur client qui identifie le client hôte en cas de demande d'adresse IP.",
                                hostname: "Nom d'hôte à assigner à cette IP."
                            }
                        }
                    },
                    advanced: {
                        tabTitle: "Paramètres avancés",
                        primaryDns: "DNS primaire",
                        secondaryDns: "DNS secondaire",
                        rootPassword: "Mot de passe racine",
                        rootPasswordConfirmation: "Confirmer le mot de passe racine",
                        updateWindowTZ: "Fuseau horaire de fenêtre de mise à jour",
                        disableOMS: "Désactiver Log Analytics",
                        disableSecurityPolicy: "Désactiver Sécurité",
                        disableIpsPolicy: "Désactiver IPS",
                        omsNote: "Vous ignorez ainsi les paramètres de Log Analytics par défaut.",
                        descriptions: {
                            updateWindowTZ: "Le fuseau horaire pour ce site, utilisé pour déterminer sa fenêtre de mise à jour.",
                            primaryDns: "Définissez manuellement l'adresse du DNS primaire.",
                            secondaryDns: "Définissez manuellement l'adresse du DNS secondaire.",
                            rootPassword: "Le mot de passe utilisateur racine pour le site.",
                            disableOMS: "Désactivez les flux de journaux vers Azure Log Analytics pour ce site.",
                            disableSecurityPolicy: "Désactivez toutes les stratégies de sécurité pour ce site.",
                            disableIpsPolicy: "Désactivez la stratégie IPS pour ce site."
                        },
                        downloadBootstrapConfigDialog: {
                            button: "bouton",
                            buttons: "boutons",
                            buttonLabel: "Configuration d'amorçage",
                            title: "Télécharger une configuration d'amorçage",
                            dialogMessage: "Les appareils doivent automatiquement se connecter au SecureEdge et télécharger leur configuration (dans la mesure où ils bénéficient d'une connexion Internet compatible DHCP).\n\nIf an internet connection cannot be easily provided, you can configure the appliance using the \"Barracuda Firewall Install\" tool and a USB flash drive. See <0>here</0> for documentation on this tool.\n\nUse the {{button}} below to download the configuration files. These files should be provided as \"CGW Configurations\" during the Barracuda Firewall Install setup.",
                            dialogMessageSc: "Les appareils doivent automatiquement se connecter au SecureEdge et télécharger leur configuration (dans la mesure où ils bénéficient d'une connexion Internet compatible DHCP).\n\nUse the {{button}} below to download the configuration file.\nThis file should be copied to the root directory of a USB Flash drive.\nIf the USB storage is present during boot the configuration will be applied.",
                            downloadButtonLabel: "Télécharger",
                            serial: "Numéro de série {{serial}}"
                        }
                    },
                    confirm: "Certains des ports sélectionnés ne sont pas actuellement activés pour ce site. Êtes-vous certain de vouloir procéder ?"
                },
                table: {
                    name: "Nom",
                    description: "Description",
                    serial: "Numéro de série",
                    model: "Modèle",
                    gateway: "Edge Service",
                    cloudVirtualWan: "Cloud VWAN",
                    privateCloud: "Aucun",
                    status: "Statut de connexion",
                    peeringAddress: "Adresse d'appairage",
                    asn: "Numéro AS",
                    lan: "LAN",
                    wan: "WAN",
                    linkAddresses: "Adresses",
                    noSites: "Aucun site trouvé.",
                    noIot: "Aucun dispositif IoT trouvé.",
                    dynamic: "Dynamique",
                    wwan: "WWAN",
                    vpnSite: "Téléchargez la configuration VPN du client.",
                    vpnSiteDisabled: "La fonctionnalité de la connectivité utilisateur doit être activée et un WAN statique doit être alloué pour que la connectivité utilisateur puisse exécuter cette action.",
                    noGateway: "(Aucun)",
                    licenseType: "Type de licence",
                    licenseExpiration: "Expiration de licence",
                    bridge: "Pont"
                },
                siteNotFound: "Impossible de trouver le site que vous recherchez.",
                noData: {
                    iconHeader: "Commencez par ajouter un site !",
                    textHeader: "Un site : c'est quoi ?",
                    text: "Un site indique un endroit connecté (et protégé) par un dispositif de site.<0 />A site device can be deployed as a hardware or virtual appliance - whatever best meets your needs.<0 />Adding a site device to your network setup is a simple and straight-forward process.<0 /><0 />Don’t have a site yet?",
                    demoLicenseLink: "<0>Obtenir une licence de démo</0>"
                }
            },
            gateways: {
                menuTitle: "EDGE SERVICES",
                pageTitle: "Services Edge",
                noData: {
                    iconHeader: "Commencez par ajouter un Edge Service !",
                    textHeader: "Un Edge Service : c'est quoi ?",
                    text: "Un Edge Service agit comme un point de connexion pour vos sites et vos agents d'accès à distance.<0 /> Les sites et les agents choisissent automatiquement l’Edge Service disponible le plus proche pour optimiser la qualité de service."
                },
                table: {
                    asn: "Numéro AS",
                    name: "Nom",
                    type: "Type",
                    upstream: "Amont",
                    region: "Emplacement",
                    instances: "Instances",
                    size: "Taille",
                    HA: "(HA)",
                    upstream_gateway: "Edge Service amont",
                    scaleUnit: "Unité d'échelle",
                    sites: "Sites",
                    status: "Statut de connexion",
                    vpnGateway: "Téléchargez la configuration VPN du client.",
                    vpnGatewayDisabled: "La fonctionnalité de la connectivité utilisateur doit être activée pour effectuer cette action.",
                    privateCloud: "Service Private Edge",
                    managedCloud: "Edge Service",
                    noGateway: "(Aucun)",
                    mbit: "Mo",
                    filters: {
                        sizeSelector: "Type de taille",
                        scaleUnit: "Unité d'échelle",
                        model: "Modèle",
                        bandwidth: "Bande passante"
                    }
                },
                dashboard: {
                    locationsCardTitle: "Emplacement",
                    tabTitle: "Tableau de bord",
                    connectedSites: {
                        tabTitle: "Sites connectés"
                    },
                    firmware: {
                        tabTitle: "Micrologiciel",
                        version: "Version",
                        hotfixes: "Correctifs installés",
                        window: "Mise à jour suivante",
                        nextUpdate: "Entre {{from}} et {{to}}"
                    }
                },
                liveLog: {
                    tabTitle: "Connexions actives",
                    pageTitle: "Connexions actives",
                    waitingForConnections: "Connexion à l’Edge Service. L'établissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
                    emptyData: "Aucune connexion actuellement active...",
                    pauseRequestsButton: "Pause",
                    resumeRequestsButton: "Reprendre"
                },
                connectionHistory: {
                    pageTitle: "Connexions récentes",
                    tabTitle: "Connexions récentes",
                    waitingForConnections: "Connexion à l’Edge Service. L'établissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
                    emptyData: "Aucune connexion récente...",
                    pauseRequestsButton: "Pause",
                    resumeRequestsButton: "Reprendre"
                },
                delete: {
                    body: "Êtes-vous certain de vouloir supprimer cet Edge Service ?",
                    title: "Supprimer l’Edge Service {{name}}",
                    forbidden: "Pour supprimer cet Edge Service, veuillez supprimer d'abord tous les sites et Edge Services qui lui sont attachés."
                },
                dialog: {
                    basic: {
                        name: "Nom",
                        sectionTitle: "Informations basiques",
                        tabTitle: "Basique"
                    },
                    create: {
                        buttonText: "Nouvel Edge Service",
                        cloud: {
                            menuLabel: "Edge Service pour WAN virtuel",
                            dialogTitle: "Générer un jeton d’Edge Service"
                        },
                        onPrem: {
                            menuLabel: "Service Private Edge",
                            dialogTitle: "Nouveau service Private Edge",
                            serials: {
                                sectionTitle: "Appareils service Private Edge"
                            }
                        },
                        managed: {
                            menuLabel: "Edge Service",
                            dialogTitle: "Nouvel Edge Service",
                            name: "Nom",
                            region: "Région",
                            bandwidth: "Bande passante",
                            bandwidthAvailable: "{{availableBandwidth}} Mbps disponibles",
                            invalidBandwidth: "Bande passante sélectionnable maxi. : {{availableBandwidth}} Mo. Réduisez la sélection de bande passante ou achetez une capacité additionnelle pour créer ce Service Edge."
                        }
                    },
                    edit: {
                        dialogTitle: "Modifier un Edge Service"
                    },
                    result: {
                        managedAppText: "OTP d'app géré",
                        message: "Veuillez copier le jeton suivant et le coller dans votre déploiement <0>Edge Service pour WAN virtuel</0>.",
                        managedAppOtp: "Ce jeton est uniquement valide pour un seul déploiement et expirera dans 30 minutes."
                    },
                    steps: {
                        result: "Terminé",
                        submit: "Configurer"
                    },
                    validation: {
                        duplicateName: "ne doit pas être un nom d’Edge Service en doublon"
                    }
                },
                settings: {
                    tabTitle: "Paramètres",
                    labels: {
                        secureInternetTraffic: "Sécuriser le Trafic Internet des VNET"
                    },
                    descriptions: {
                        secureInternetTraffic: "Activez l'inspection du trafic Internet depuis ce réseau virtuel avec cet Edge Service."
                    },
                    editBandwidthWarning: "Le changement de bande passante entraîne l'indisponibilité temporaire de l’Edge Service qui doit redémarrer.",
                    managed: {
                        bandwidth: {
                            invalidBandwidth: "Bande passante sélectionnable maxi. : {{availableBandwidth}} Mo. Réduisez la sélection de bande passante ou achetez une capacité additionnelle pour mettre à jour ce Service Edge."
                        }
                    }
                },
                auditLog: {
                    tabTitle: "Journal d'audit",
                    pageTitle: "Journal d'audit"
                },
                gatewayNotFound: "Impossible de trouver l’Edge Service que vous recherchez."
            },
            logs: {
                pageTitle: "Journaux et reporting",
                audit: "Journal d'audit",
                notifications: "Notifications",
                notificationsMessage: "Configurez les notifications pour les événements systèmes et administratifs de tous les espaces de travail.",
                auditLog: {
                    message: "Journal d'audit indiquant les événements systèmes et administratifs de tous les espaces de travail.",
                    labels: {
                        status: "Statut",
                        created: "Créé",
                        type: "Type",
                        event: "Nom",
                        serial: "Numéro de série",
                        description: "Description",
                        remoteAddress: "IP publique",
                        createdUsername: "Administrateur"
                    }
                },
                connectionHistory: {
                    detailsTitle: "Détails de connexion",
                    labels: {
                        application: "Application",
                        count: "Nombre",
                        destinationIP: "IP de destination",
                        destinationPort: "Port de destination",
                        information: "Information",
                        ipProtocol: "Protocole IP",
                        lastTime: "Dernière fois",
                        nextHop: "Prochain saut",
                        sourceIP: "IP source",
                        sourceMac: "MAC source",
                        state: "État",
                        urlCategory: "Catégorie d'URL",
                        user: "Utilisateur"
                    }
                },
                liveLog: {
                    detailsTitle: "Détails de connexion",
                    labels: {
                        agentId: "ID d'agent",
                        appName: "Contexte d'application",
                        application: "Application",
                        connectionTrafficState: "État de connexion",
                        destinationBytesDescription: "Octets sortants",
                        destinationIp: "IP de destination",
                        destinationInterface: "Interface de destination",
                        destinationPort: "Port de destination",
                        idleTime: "Inactivité depuis",
                        ipProtocol: "Protocole IP",
                        protocol: "Protocole",
                        protocolId: "ID de protocole",
                        serial: "Numéro de série",
                        sourceBytesDescription: "Octets entrants",
                        sourceIp: "IP source",
                        sourcePort: "Port source",
                        sourceNat: "NAT source",
                        speedDescription: "Vitesse",
                        startTime: "Heure de début",
                        state: "État",
                        target: "Cible",
                        type: "Type",
                        totalBytesDescription: "Octets totaux",
                        urlCategories: "Catégories d'URL",
                        user: "Utilisateur",
                        vpnRoute: "Itinéraire VPN"
                    },
                    connectionState: {
                        initiate: "Trafic unidirectionnel actif",
                        fail: "Trafic unidirectionnel inactif",
                        sync: "Trafic bidirectionnel",
                        closing: "Fermeture de connexion",
                        established: "Connexion établie",
                        httpDeny: "Connexion non établie"
                    }
                }
            },
            policies: {
                menuTitle: "SD-WAN",
                sdwan: {
                    tabTitle: "Catégories d'application",
                    search: "Rechercher des applications",
                    table: {
                        category: "Catégorie",
                        apps: "Applications",
                        customApps: "Applications personnalisées",
                        priority: "Priorité",
                        action: "Action",
                        fallback: "Solution de repli",
                        loadBalancing: "Équilibrage de charge",
                        reset: "Réinitialiser"
                    }
                },
                explicit: {
                    delete: {
                        title: "Supprimer {{name}}",
                        body: "Êtes-vous certain de vouloir supprimer cette stratégie ?"
                    },
                    tabTitle: "Catégories de priorité",
                    table: {
                        order: "Ordre",
                        name: "Nom",
                        apps: "Applications",
                        sources: "Source",
                        user: "Utilisateur",
                        traffic: "Type de trafic",
                        priority: "Priorité",
                        action: "Action",
                        fallback: "Solution de repli",
                        loadBalancing: "Équilibrage de charge"
                    },
                    noData: {
                        iconHeader: "Commencez par ajouter une priorité de stratégie SD-WAN ici !",
                        labelButton: "Ajouter une stratégie de priorité",
                        text: "Votre configuration SecureEdge offre un ensemble commun de stratégies SD-WAN prêtes à l'emploi. <0 />But if your setup requires a different SD-WAN profile for certain applications, you can define your custom policies here."
                    }
                },
                dialogs: {
                    explicit: {
                        create: {
                            buttonText: "Ajouter une stratégie",
                            dialogTitle: "Nouvelle stratégie"
                        },
                        edit: {
                            dialogTitle: "Modifier une stratégie"
                        },
                        general: {
                            sectionTitle: "Général",
                            name: "Nom",
                            description: "Description",
                            descriptions: {
                                name: "Un nom unique pour cette stratégie.",
                                description: "Une brève description de cette stratégie."
                            }
                        },
                        criteria: {
                            sectionTitle: "Critères",
                            applications: "Applications",
                            sources: "IP/Réseau source",
                            trafficType: "Type de trafic",
                            descriptions: {
                                applications: "Applications spécifiques auxquelles appliquer cette stratégie. Au moins une application ou source doit être fournie.",
                                sources: "IP/Réseau sources spécifiques auxquels appliquer cette stratégie. Au moins une application ou source doit être fournie.",
                                trafficType: "En option, définissez le type de trafic spécifique auquel appliquer cette politique."
                            }
                        },
                        action: {
                            sectionTitle: "Action",
                            priority: "Priorité",
                            action: "Action",
                            fallback: "Solution de repli",
                            loadBalancing: "Équilibrage de charge",
                            forwardErrorCorrection: {
                                label: "Optimisation du dernier kilomètre",
                                options: {
                                    on: "Activé",
                                    off: "Désactivé"
                                }
                            },
                            descriptions: {
                                priority: "Le niveau de priorité à appliquer au trafic correspondant à cette stratégie.",
                                action: "L'action à prendre sur le trafic correspondant à cette stratégie.",
                                fallback: "Le comportement de solution de repli à appliquer au trafic correspondant à cette stratégie.",
                                loadBalancing: "Le comportement d'équilibrage de charge à appliquer au trafic correspondant à cette stratégie.",
                                forwardErrorCorrection: "Active l'optimisation du dernier kilomètre pour le trafic correspondant à cette stratégie. Les réseaux avec des pertes peuvent être corrigés mais la consommation de bande passante par les clients augmente."
                            }
                        },
                        validation: {
                            required: "une application ou source est nécessaire"
                        }
                    }
                }
            },
            sdwanExplicitPolicies: {
                delete: {
                    title: "Supprimer la stratégie {{name}}",
                    body: "Êtes-vous certain de vouloir supprimer cette stratégie ?"
                }
            },
            audit: {
                menuTitle: "JOURNAL D'AUDIT",
                pageTitle: "Journal d'audit",
                labels: {
                    created: "Créé",
                    type: "Type",
                    event: "Nom",
                    serial: "Numéro de série",
                    description: "Description",
                    status: "Statut",
                    createdUsername: "Administrateur",
                    remoteAddress: "IP publique"
                },
                filter: {
                    created: {
                        days1: "1 jour",
                        days3: "3 jours",
                        weeks1: "1 semaine",
                        weeks2: "2 semaines",
                        months1: "1 mois",
                        months2: "2 mois",
                        months3: "3 mois",
                        months6: "6 mois",
                        months9: "9 mois",
                        years1: "1 an"
                    }
                }
            },
            settings: {
                menuTitle: "PARAMÈTRES",
                pageTitle: "Paramètres",
                tabs: {
                    workspaces: {
                        title: "Espaces de travail",
                        pageTitle: "Gérer des espaces de travail",
                        fields: {
                            name: "Nom",
                            description: "Description",
                            associations: "Sites / Edge Services"
                        },
                        dialog: {
                            create: {
                                buttonLabel: "Nouvel espace de travail",
                                dialogTitle: "Nouvel espace de travail"
                            },
                            edit: "Modifier un espace de travail"
                        },
                        delete: {
                            body: "Êtes-vous certain de vouloir supprimer cet espace de travail ?",
                            title: "Supprimer {{name}}",
                            disabled: {
                                notEmpty: "Les espaces de travail ne peuvent pas être supprimés si un site ou un Edge Service leur est encore associé.",
                                lastWorkspace: "Vous devez avoir au moins un espace de travail."
                            }
                        },
                        none: "Aucun espace de travail disponible"
                    },
                    workspaceSettings: {
                        title: "Paramètres d'espace de travail"
                    },
                    updates: {
                        title: "Fenêtres de mise à jour",
                        firmware: {
                            title: "Fenêtres de mise à jour de micrologiciel",
                            description: "Les fenêtres de mise à jour du micrologiciel doivent durer au moins 3 heures et être exécutées dans le fuseau horaire local de l'appareil.",
                            noWindowsMessage: "Aucune fenêtre de mise à jour définie",
                            noUpdatesMessage: "Aucune mise à jour disponible à installer.",
                            noAppliancesMessage: "Aucun appareil ne nécessite actuellement une mise à jour.",
                            default: {
                                label: "Fenêtre de mise à jour par défaut :",
                                to: "à",
                                from: "de",
                                setting: "{{from}} <0>à</0> {{to}}"
                            },
                            create: {
                                buttonText: "Nouvelle fenêtre de mise à jour",
                                dialogTitle: "Ajouter une nouvelle fenêtre de mise à jour"
                            },
                            edit: {
                                dialogTitle: "Modifier une fenêtre de mise à jour"
                            },
                            delete: {
                                title: "Supprimer une fenêtre de mise à jour",
                                message: "Une fois supprimée, le site ou l’Edge Service associé à cette fenêtre de mise à jour utilisera la fenêtre de mise à jour par défaut."
                            },
                            fields: {
                                appliance: "Site / Edge Service",
                                applianceDescription: "Edge Service ou site autonome à mettre à jour durant la fenêtre de mise à jour",
                                from: "De",
                                fromDescription: "Début de la période de temps pendant laquelle les mises à jour du micrologiciel peuvent être automatiquement installées. La fenêtre horaire est relative au fuseau horaire local automatiquement détecté de chaque Edge Service ou site autonome.",
                                to: "À",
                                toDescription: "Fin de la période de temps pendant laquelle les mises à jour du micrologiciel peuvent être automatiquement installées. La fenêtre horaire est relative au fuseau horaire local automatiquement détecté de chaque Edge Service ou site autonome.",
                                windowLength: "Vous avez ainsi une fenêtre de mise à jour du micrologiciel de {{duration}}."
                            },
                            updateNow: {
                                buttonLabel: "Installer maintenant",
                                disabledMessage: "Aucune mise à jour disponible.",
                                dialogTitle: "Installer des mises à jour maintenant",
                                dialogMessage: "La mise à jour va maintenant supprimer toutes les fenêtres de mise à jour programmées et mettre à jour immédiatement tous les Edge Services et sites autonomes. Les sites avec un Edge Service débuteront leur mise à jour une fois leur Edge Service mis à jour. Un temps d'arrêt est possible."
                            },
                            validation: {
                                insufficientWindow: "La fenêtre de mise à jour doit durer au moins 3 heures"
                            },
                            editDefault: {
                                dialogTitle: "Modifier une fenêtre de mise à jour par défaut"
                            }
                        },
                        security: {
                            title: "Fenêtre de mise à jour de sécurité",
                            window: "Fenêtre de mise à jour de correctif",
                            windowDescription: "La période de temps pendant laquelle les mises à jour de correctif peuvent être automatiquement installées. La fenêtre horaire est relative au fuseau horaire local détecté automatiquement pour chaque Edge Service ou site. Le fuseau horaire peut être ignoré dans la configuration du site."
                        },
                        hotfixUpdateWindow: "Fenêtre de mise à jour de correctif",
                        validation: {
                            invalidStartTime: "heure de début invalide",
                            invalidEndTime: "heure de fin invalide"
                        }
                    },
                    oms: {
                        title: "Azure Monitor",
                        enabled: "Activé",
                        id: "ID d'espace de travail",
                        key: "Clé d'espace de travail",
                        descriptions: {
                            oms: "Activez uniquement si vous utilisez Microsoft Operations Management Suite (OMS). C'est en voie d'obsolescence."
                        },
                        clientId: "ID client",
                        clientSecret: "Secret de client",
                        correlationId: "ID de corrélation",
                        region: "Région",
                        resourceGroup: "Groupe de ressources",
                        subscriptionId: "ID d'abonnement",
                        tenantId: "ID de client",
                        oms: "Azure Log Analytics (OMS)",
                        disabled: "Désactivé",
                        introduction: "Grâce à Azure Monitor, vous recherchez, analysez et visualisez les données de machine générées par vos systèmes informatiques et votre infrastructure technologique pour en savoir plus sur votre réseau. Dirigez les journaux vers votre compte Azure Monitor pour approfondir le traitement des données de journal de votre configuration."
                    },
                    global: {
                        title: "Paramètres globaux"
                    },
                    forwardedDomains: {
                        title: "Domaines transférés",
                        domains: "Domaines",
                        descriptions: {
                            domains: "Domaines DNS à transférer vers Azure pour résolution"
                        },
                        domain: "Domaine",
                        forwarders: "Serveur",
                        button: "Ajouter",
                        duplicateName: "nom de domaine déjà utilisé",
                        addDomain: "Ajouter un domaine",
                        editDomain: "Modifier un domaine",
                        deleteDomain: "Supprimer un domaine"
                    },
                    vmActivation: {
                        title: "Activation de VM",
                        firstName: "Prénom",
                        lastName: "Nom",
                        companyName: "Nom de société",
                        phoneNumber: "Numéro de téléphone",
                        address: "Ligne d'adresse 1",
                        address2: "Ligne d'adresse 2",
                        city: "Ville",
                        country: "Pays",
                        emailAddress: "Adresse e-mail",
                        state: "État",
                        zip: "Code postal",
            billingDetailsSection: "Détails de facturation",
            billingAddressSection: "Adresse de facturation"
                    },
                    notification: {
                        title: "Notifications",
                        delete: "Supprimer une notification ",
                        labels: {
                            name: "Nom",
                            events: "Événements",
                            status: "Statut",
                            administrators: "Administrateurs",
                            actions: "Actions"
                        },
                        descriptions: {
                            name: "Un nom unique pour cette notification.",
                            events: "Les événements pour lesquels envoyer des notifications.",
                            status: "Les types de statut pour lesquels envoyer des notifications.",
                            administrators: "Les adresses e-mail d'administrateurs auxquelles envoyer des notifications.",
                            actions: "Les actions pour lesquelles envoyer des notifications."
                        },
                        components: {
                            button: {
                                add: "Ajouter",
                                edit: "Modifier",
                                delete: "Supprimer",
                                save: "Enregistrer",
                                cancel: "Annuler"
                            },
                            title: {
                                editNotification: "Modifier une notification",
                                createNotification: "Créer une notification"
                            }
                        },
                        action: {
                            deleteNotification: "Êtes-vous certain de vouloir supprimer cette notification ?"
                        }
                    },
                    sdwanConnectorNetwork: {
                        title: "Connecteur",
                        subMenu: "Plage de réseau client",
                        description: "Le réseau client sert à assigner des IP à des ressources derrière les connecteurs."
                    },
                    reportCreatorAcl: {
                        title: "Report Creator",
                        subMenu: "Réseaux autorisés",
                        description: "Saisissez une liste des réseaux et adresses IP privés où l'outil Report Creator va rechercher des dispositifs de site et des Services Edge."
                    },
                    dcAgent: {
                        title: "Agent DC",
                        description: "Saisissez l'adresse IP de l'Agent DC que vous avez configuré.",
                        ipAddress: "Adresse IP"
                    },
                    dhcpServer: {
                        title: "Serveur de relais DHCP",
                        description: "Saisissez l'adresse IP du serveur DHCP que vous voulez utiliser.",
                        ipAddress: "Adresse IP"
          },
          SIPProxy: {
            title: "Proxy SIP",
            UdpTimeout: "Temporisation UDP",
            enabled: "Activé",
            sipProxySetting: "Proxy SIP",
            cardDescription: "Le proxy SIP sert d'intermédiaire entre les points finaux comme les téléphones sur IP, les dispositifs mobiles et les équipements de conférence.",
            description: {
              UdpTimeout: "Réglez la temporisation UDP (en secondes) à utiliser quand le proxy SIP est désactivé.",
              sipProxySetting: "Le proxy SIP utilise par défaut une temporisation de 60s."
            }
                    }
                }
            },
            statusBanner: {
                subscriptionExpiresSoon: "Votre abonnement va expirer dans {{days}} jours",
                subscriptionExpiresOneDay: "Votre abonnement va expirer dans 1 jour",
                subscriptionExpiresToday: "Votre abonnement expire dans moins de 1 jour",
                deprecatedExpiresSoon: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans {{days}} jours si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}.",
                deprecatedExpiresOneDay: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans 1 jour si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}",
                deprecatedExpiresToday: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans moins d'un jour si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}",
                updateAvailable: "Une nouvelle mise à jour du micrologiciel est disponible ! Planifiez une fenêtre de maintenance avant {{date}} pour démarrer le processus de mise à jour.",
                pamBandwidthCapacity: "Votre bande passante de service Edge actuelle dépasse votre quantité licenciée. Veuillez contacter le service commercial pour obtenir plus de bande passante."
            },
            states: {
                pending: "En cours",
                completed: "Terminé",
                online: "En ligne",
                offline: "Hors ligne",
                failed: "Échoué",
                error: "Erreur",
                degraded: "Dégradé",
                connecting: "Connexion en cours",
                unknown: "Inconnu",
                loading: " "
            },
            validation: {
                alphaNumericDash: "doit uniquement contenir des chiffres, des lettres et des tirets",
                alphaNumericDashNoHaSuffix: "ne doit pas contenir le suffixe « -ha »",
                alphaNumericDashSpace: "doit uniquement contenir des chiffres, des lettres, des tirets et des espaces",
                alphaNumericDotsDash: "doit uniquement contenir des chiffres, des lettres, des points et des tirets",
                alphaNumericDotsColonsDash: "doit uniquement contenir des chiffres, des lettres, des points, des deux points et des tirets",
        customKeyword: "doit contenir uniquement un maximum de 128 chiffres, lettres, tirets, points, apostrophes ou espaces. Doit commencer et se terminer par un caractère alphanumérique.",
                uuid: "doit être un UUID/GUID valide"
            },
            footer: {
                serial: "  | Numéro de série d'abonnement : {{serial}}"
            },
            users: {
                table: {
                    fullName: "Nom complet",
                    email: "E-mail",
                    deviceCount: "Nombre d'appareils",
                    enrollmentCompleted: {
                        label: "Inscription terminée",
                        yes: "Inscription terminée",
                        no: "Inscription en cours"
                    },
                    enrollmentLink: "Copier un lien d'inscription",
                    enrollmentLinkCopied: "Lien d'inscription copié dans le presse-papiers",
                    resendInvite: "Renvoyer une invitation",
                    tamperProof: "Inviolable",
                    devicePreConnect: "Connexion préalable de dispositif",
                    webFiltering: "Filtrage Web",
                    optionsOverride: "Priorité utilisateur",
                    on: "Activé",
          off: "Désactivé",
          tpmEnforcement: "Exécuter TPM"
                },
                delete: {
                    title: "Désinscrire l'utilisateur",
                    body: "Êtes-vous certain de vouloir retirer cet utilisateur ?"
                },
                dialog: {
                    buttonText: "Inscrire des utilisateurs",
                    disabledButton: "Vous devez d'abord configurer un Répertoire d'utilisateurs et un Prestataire d'identité dans la page Identité > Paramètres."
                },
                noData: {
                    iconHeader: "Commencez par ajouter des utilisateurs !",
                    labelButton: "Ajouter et inviter des utilisateurs",
                    textHeader: "Pourquoi les utilisateurs sont-ils si importants pour SecureEdge Access ?",
                    text: "SecureEdge Access contrôle l'accès et les privilèges des utilisateurs sur votre réseau. C'est donc ici que vous déterminez qui peut accéder et débuter le processus d'inscription des SecureEdge Access Agents.",
                    firstTitle: "1",
                    firstStep: "Envoyer une invitation d'inscription",
                    secondTitle: "2",
                    secondStep: "Demandez à votre utilisateur de télécharger, installer et procéder lui-même à l'inscription de l'agent.",
                    textFooter: "Fini. Ça marche !"
                },
                settings: {
                    global: "Priorité utilisateur",
                    introduction: "Ignorez les réglages Global ZTNA pour cet utilisateur.",
                    tamperProof: "Inviolable",
                    devicePreConnect: "Connexion préalable de dispositif",
                    deviceLimit: "Limite de dispositif utilisateur",
                    webFiltering: "Filtrage Web",
                    enabled: "activé",
                    disabled: "désactivé",
                    overrideEnabled: "utilisant les réglages spécifiés à la suite",
                    overrideDisabled: "utilisant les réglages Global ZTNA",
                    descriptions: {
                        tamperProof: "Empêche l'utilisateur de désactiver SecureEdge Access Agent. Pour les dispositifs exploités sous iOS, iPadOS ou Android, une solution MDM est obligatoire.",
            deviceLimit: "dispositifs par utilisateur",
            tpmEnforcement: "Module de plateforme de confiance. Stockage sécurisé et non-exportable du certificat d'authentification du dispositif en utilisant la puce TPM sur votre dispositif."
          },
          tpmEnforcement: "Exécution TPM"
                }
            },
            devices: {
                table: {
                    userName: "Utilisateur",
                    email: "E-mail",
                    brand: "Marque de dispositif",
                    model: "Modèle de dispositif",
                    hardwareModel: "Modèle de matériel",
                    os: "Système d'exploitation",
                    osFlavour: "Nuance OS",
                    osVersion: "Version OS",
                    enrollmentDate: "Date d'inscription"
                },
                delete: {
                    body: "Êtes-vous certain de vouloir supprimer ce dispositif ?",
                    title: "Supprimer un dispositif"
                },
                noData: {
                    iconHeader: "Aucun dispositif inscrit trouvé !",
                    action: "Ajouter et inviter des utilisateurs",
                    textHeader: "Un dispositif inscrit : c'est quoi ?",
                    text: "Dès que vous avez invité un utilisateur à débuter le processus d'auto-inscription et qu'il a terminé les étapes d'intégration, tous les dispositifs pour cet utilisateur sur lesquels des SecureEdge Access Agents sont installés sont listés ici."
                }
            },
            firewalls: {
                table: {
                    name: "Nom",
                    serials: "Numéro de série",
                    isCcManaged: "Géré par CC",
                    model: "Modèle"
                },
                delete: {
                    title: "Déconnecter le CloudGen Firewall  {{name}}",
                    body: "Êtes-vous certain de vouloir déconnecter ce CloudGen Firewall du SecureEdge Service ?",
                    forbidden: "Vous ne pouvez pas supprimer les intégrations CloudGen Firewall si elles sont configurées comme point final."
                },
                dialog: {
                    create: {
                        buttonText: "Jeton d'enregistrement",
                        dialogTitle: "Générer un jeton d'enregistrement"
                    },
                    result: {
                        message: "Veuillez copier le jeton suivant et le coller dans votre <0>formulaire d'activation de Firewall Admin</0>.",
                        firewallText: "Jeton d'enregistrement de pare-feu",
                        firewallOtp: "Ce jeton est uniquement valide pour un seul pare-feu et expirera dans 30 minutes."
                    }
                },
                noData: {
                    iconHeader: "Organisez un déploiement CloudGen Firewall !",
                    labelButton: "Obtenez un jeton d'enregistrement",
                    textHeader: "Vous voulez ajouter un CloudGen Firewall à votre configuration ?",
                    text: "Si vous avez déjà un Barracuda CloudGen Firewall en activité, vous pouvez aussi demander ce déploiement pour un point d'entrée pour vos Access Agents. La demande d'un déploiement CloudGen Firewall se limite à copier le jeton d'enregistrement dans la console de gestion pour CloudGen Firewall.<0 /><0 />Notez simplement qu'après la demande du CloudGen Firewall, le composant d'accès à distance n'est plus géré par l'outil Firewall Admin."
                }
            },
            integration: {
                pageTitle: "Intégration",
                firewalls: "CloudGen Firewall",
                ipSecV2: {
                    create: "Créer Tunnel IPsec",
                    createButton: "Ajouter Tunnel IPsec",
                    edit: "Modifier Tunnel IPsec",
                    general: "Général",
                    network: "Réseau",
                    phases: "Phases",
                    sourceDest: "Source/Destination",
                    success: "Succès",
                    successMessage: "Nouveau Tunnel IPSec correctement créé",
                    pageTitle: "VPN IPsec",
                    table: {
                        status: "Statut",
                        name: "Nom",
                        description: "Description",
                        enabled: "Activé",
                        gateway: "Passerelle distante",
                        localNetworks: "Réseaux locaux",
                        localBgp: "BGP local",
                        remoteNetworks: "Réseaux distants",
                        remoteBgp: "BGP distant",
                        type: "Type",
                        siteWanInterface: "Pair SecureEdge",
                        peerIp: "IP Pair",
                        duration: "Durée",
                        encryption: "Cryptage",
                        filters: {
                            enabled: {
                                enabled: "Activé",
                                disabled: "Désactivé"
                            },
                            secureEdgePeer: {
                                peerType: "Type de pair",
                                edgeService: "Service Edge",
                                site: "Site"
                            }
                        },
                        ellipsisMenu: {
                            restart: "Redémarrer le Tunnel IPSec"
                        }
                    },
                    cloudCantInitiate: "seuls les services Edge privés et les sites peuvent être réglés pour initier des tunnels",
                    dialog: {
                        create: {
                            buttonText: "Ajouter tunnel"
                        },
                        delete: {
                            body: "Êtes-vous certain de vouloir supprimer ce Tunnel IPsec ?",
                            title: "Supprimer Tunnel IPsec {{name}}"
                        },
                        restart: {
                            ok: "Ok",
                            cancel: "Annuler",
                            body: "Êtes-vous certain de vouloir supprimer ce Tunnel IPsec ?",
                            title: "Redémarrer Tunnel IPSec {{name}}",
                            message: "Merci de patienter...le tunnel redémarre"
                        },
                        generalSection: {
                            descriptionMessage: "Pour créer un nouveau tunnel, définissez les paramètres suivants pour le configurer.",
                            tunnelStatus: "Activer",
                            general: {
                                title: "Informations générales",
                                name: "Nom",
                                description: "Description",
                                initiatesTunnel: "Initier tunnel",
                                restartSa: "Redémarrer SA",
                                descriptions: {
                                    title: "Description d'informations générales",
                                    name: "Un nom unique pour ce Tunnel IPsec.",
                                    description: "Description optionnelle du tunnel.",
                                    tunnelStatus: "La désactivation désactive temporairement le tunnel.",
                                    initiatesTunnel: "Activez pour que le dispositif SecureEdge initie le Tunnel IPsec."
                                }
                            },
                            authentication: {
                                title: "Authentification",
                                authentication: "Authentification",
                                sharedSecret: "Secret partagé",
                                serverCert: "Certificat de serveur",
                                caCert: "Certificat CA",
                                x509Condition: "Condition X509",
                                exploit509: "Exploitation 509",
                                descriptions: {
                                    authentication: "La méthode que le Tunnel IPsec devrait utiliser pour l'authentification.",
                                    sharedSecret: "La clé prépartagée à utiliser pour l'authentification.",
                                    serverCert: "Description de Certificat de serveur",
                                    caCert: "Description de Certificat CA",
                                    x509Condition: "Description de condition X509",
                                    exploit509: "Description d'Exploitation 509"
                                }
                            }
                        },
                        sourceDestSection: {
                            descriptionMessage: "Configurez la source et la destination du tunnel que vous voulez ajouter.",
                            enableBgp: "Activer BGP",
                            descriptions: {
                                enableBgp: "Activer BGP"
                            },
                            source: {
                                title: "Source",
                                type: "Type",
                                peer: "Pair",
                                ipAddresses: "Passerelles paires",
                                initiatesTunnel: "Initie",
                                wanInterface: "Interface WAN",
                                localId: "ID local",
                                networkAddresses: "Adresses réseau",
                                networkAddressBgp: "Adresse d'appairage",
                                asnBgp: "ASN",
                                descriptions: {
                                    ipAddresses: "Les adresses IP publiques du service Edge sélectionné. Obligatoire pour la configuration de votre dispositif distant.",
                                    localId: "ID IPsec du côté local du tunnel.",
                                    networkAddresses: "Les réseaux atteignables du côté local du tunnel.",
                                    networkAddressBgp: "L'adresse IP utilisée pour la communication BGP du côté local.",
                                    asnBgp: "Le Numéro de système autonome du côté local",
                                    enableBgp: "Activez pour que les réseaux atteignables sur les sites locaux et distants soient échangés dynamiquement via BGP."
                                },
                                sourceTypeData: {
                                    gateway: "Service Edge",
                                    site: "Site"
                                },
                                localWans: "Adresse",
                                primaryAddress: "Adresse primaire",
                                additionalAddresses: "Adresses additionnelles"
                            },
                            destination: {
                                title: "Destination",
                                remoteGatewayName: "Nom",
                                remoteGateway: "Passerelle distante",
                                remoteId: "ID distant",
                                networkAddresses: "Adresses réseau",
                                networkAddressBgp: "Adresse d'appairage",
                                asnBgp: "ASN",
                                multiInputTitle: "Passerelle distante",
                                multiInputTitleLabel: "Destinations",
                                descriptions: {
                                    remoteId: "ID IPsec du côté distant du tunnel.",
                                    networkAddresses: "Les réseaux atteignables du côté distant du tunnel.",
                                    networkAddressBgp: "L'adresse IP utilisée pour la communication BGP du côté distant.",
                                    asnBgp: "Le Numéro de système autonome du côté distant",
                                    remoteGateway: "L'IP ou FQDN du côté distant du tunnel. Si le côté local n'initie pas le tunnel, le paramètre peut être réglé sur 0.0.0.0/0."
                                }
                            }
                        },
                        phasesSection: {
                            descriptionMessage: "Le tunnel offre 2 phases. Configurez vos paramètres de sécurité préférés pour chacune de ces phases.",
                            phase1: {
                                title: "Phase 1",
                                encryption: "Cryptage",
                                hash: "Hachage",
                                dhGroup: "Groupe DH",
                                proposalHandling: "Traitement de proposition",
                                lifetime: "Durée de vie",
                                descriptions: {
                                    proposalHandling: "En cas de réglage sur strict uniquement, l'algorithme de hachage et cryptage et le groupe Diffie Hellman peuvent être utilisés. Autrement, en cas de réglage sur négocier, l'initiateur peut sélectionner un jeu différent d'algorithmes et de Groupe DH.",
                                    lifetime: "Le nombre de secondes avant que l'IPsec (SA) ne soit recrypté."
                                }
                            },
                            phase2: {
                                title: "Phase 2",
                                lifetime: "Durée de vie",
                                trafficVolume: "Volume de trafic activé",
                                trafficVolumeKb: "Volume de trafic Ko",
                                encryption: "Cryptage",
                                hash: "Hachage",
                                dhGroup: "Groupe DH",
                                proposalHandling: "Traitement de proposition",
                                descriptions: {
                                    proposalHandling: "En cas de réglage sur strict uniquement, l'algorithme de hachage et cryptage et le groupe Diffie Hellman peuvent être utilisés. Autrement, en cas de réglage sur négocier, l'initiateur peut sélectionner un jeu différent d'algorithmes et de Groupe DH.",
                                    lifetime: "Le nombre de secondes avant que l'IPsec (SA) ne soit recrypté.",
                                    trafficVolume: "En cas d'activation, le recryptage peut être déclenché sur la base du volume de trafic.",
                                    trafficVolumeKb: "Le nombre de Ko avant que le SA ne soit recrypté."
                                }
                            }
                        },
                        networkSection: {
                            descriptionMessage: "Configurez les paramètres de réseau. Ce sont des options avancées, sans être obligatoire pour un tunnel général.",
                            networkSettings: {
                                title: "Paramètres de réseau",
                                oneTunnelSubnet: "Un tunnel VPN par paire de sous-réseau",
                                universalTrafficSelectors: "Sélecteurs de trafic universels",
                                forceUdpEncapsulation: "Encapsulation UDP forcée",
                                ikeReauthentication: "Réauthentification IKE",
                                descriptions: {
                                    oneTunnelSubnet: "Créez un SA par paire de réseaux local et distant.",
                                    universalTrafficSelectors: "L'activation autorise tout le trafic via le tunnel.",
                                    forceUdpEncapsulation: "Utilisez le port UDP 4500 pour l'enveloppe de tunnel IPsec même si aucun mécanisme NAT n'est détecté.",
                                    ikeReauthentication: "Authentification chaque fois que le SA est recrypté."
                                }
                            },
                            deadPeerDetection: {
                                title: "Détection de pair mort",
                                actionDetected: "Action en cas de détection",
                                delay: "Délai",
                                descriptions: {
                                    actionDetected: "Action quand le côté distant ne répond pas aux messages DPD. L'effacement annule le tunnel et un redémarrage est tenté pour rétablir le tunnel sans que le DPD ne soit désactivé.",
                                    delay: "Délai en secondes après lequel le message de maintien du DPD actif est envoyé si aucun autre trafic n'est reçu."
                                }
                            }
                        }
                    },
                    noData: {
                        buttonText: "Ajouter une connexion IPsec",
                        iconHeader: "Commencez par ajouter une connexion IPsec !",
                        labelButton: "Ajouter une connexion IPsec",
                        textHeader: "Pourquoi intégrer une connexion IPsec ?",
                        text: "Les déploiements SecureEdge utilisent le VPN Barracuda TINA propriétaire pour une connectivité sécurisée et fiable. Consultez cette page de configuration pour connecter des déploiements de tiers capables d'utiliser IPsec IKEv2. Dès que la configuration est terminée, vous pouvez vérifier le statut de votre connexion yourIPsec VPN sur cette page."
                    }
                },
                azureMonitor: "Azure Monitor"
            },
            copy: {
                label: "Copier dans le presse-papiers"
            },
            subscription: {
                pam: {
                    menuTitle: "Abonnements",
                    pageTitle: "Profil",
                    pageSubTitle: "Abonnements",
                    table: {
                        subscription: "Abonnement",
                        serial: "Numéro de série de licence",
                        amount: "Quantité",
                        startDate: "Date de début",
                        expirationDate: "Date d'expiration",
                        noData: {
                            iconHeader: "Commencez par ajouter un abonnement !",
                            textHeader: "Comment obtenir une licence d'abonnement ?",
                            text: "Les abonnements donnent accès à des fonctionnalités additionnelles dans votre déploiement SecureEdge.<0/> Par exemple, si vous voulez ajouter des sièges SecureEdge Access pour votre personnel à distance, utilisez simplement cette interface pour ajouter le nombre nécessaire de sièges à votre déploiement.<0/> Pour obtenir le meilleur abonnement pour vos besoins, contactez simplement Barracuda Sales (barracuda.com/contact-sales)."
                        },
                        information: "Information",
                        value: "{{value}}",
                        value_34: "{{value}} Utilisateurs",
                        value_24: "{{value}} Mbps",
                        msp: "MSP facturés"
                    }
                }
            },
            customCategories: {
                table: {
                    name: "Nom",
                    description: "Description",
                    details: "Détails",
                    detailCategories: "Catégories ({{activeCategories.0.name}}, +{{additional}}) ",
                    detailCategories_1: "Catégories ({{activeCategories.0.name}}) ",
                    detailDomains: "Domaines inclus ({{domains.0}}, +{{additionalDomains}})",
                    detailDomains_1: "Domaine inclus ({{domains.0}})",
                    domainsExcluded: "Domaines exclus",
                    domainsIncluded: "Domaines inclus",
                    noData: "Aucune règle de catégorie personnalisée trouvée."
                },
                dialog: {
                    create: {
                        buttonText: "Ajouter catégorie personnalisée",
                        dialogTitle: "Nouvelle catégorie personnalisée"
                    },
                    edit: {
                        dialogTitle: "Modifier catégorie personnalisée"
                    },
                    name: "Nom",
                    description: "Description",
                    details: "Détails",
                    includedDomains: "Domaines inclus",
                    excludedDomains: "Domaines exclus",
                    descriptions: {
                        name: "Un nom unique pour cette règle.",
                        description: "Une brève description de cette règle.",
                        includedDomains: "La liste des domaines affectés par cette règle.",
                        excludedDomains: "La liste des domaines non-affectés par cette règle."
                    },
                    validation: {
                        emptyCategories: "doit fournir au moins un domaine ou catégorie"
                    },
                    delete: {
                        title: "Supprimer {{name}}",
                        body: "Êtes-vous certain de vouloir supprimer cette règle ? Toutes les règles de sécurité utilisant cette catégorie personnalisée seront mises à jour ou retirées."
                    }
                }
            },
            syslog: {
                pageTitle: "Streaming Syslog",
                certificateCName: "Nom commun de certificat",
                certificateExpiryDate: "Date d'expiration de certificat",
        enable: "Streaming Syslog",
                logs: "Journaux",
                port: "Port",
                protocol: "Protocole",
                securityProtocol: "Protocole de sécurité",
        server: "IP ou nom de serveur Syslog",
                syslogOverride: "Désactiver le streaming Syslog",
                uploadSslCert: "Télécharger Certificat SSL",
                descriptions: {
                    syslogOverride_site: "Désactivez le streaming Syslog pour ce site.",
                    syslogOverride_gateway: "Désactivez le streaming Syslog pour ce service Edge."
        },
        syslogStreamingTile: "Streaming Syslog",
        syslogDescription: "Vous pouvez activer le Streaming Syslog pour un espace de travail complet mais aussi le désactiver pour un site spécifique, pour un Private Edge, pour un Edge Service pour VWAN ou pour un Edge Service hébergé."
            },
            xdr: {
                pageTitle: "Barracuda XDR",
        enable: "Streaming de journal",
                sites: "Sites",
                gateways: "Services Edge",
        hostname: "Nom d'hôte XDR",
                port: "Port",
        xdrDescription: "Configurez Barracuda SecureEdge à la suite pour diffuser des journaux vers les services Barracuda XDR afin de détecter les menaces et d'assurer une réponse sur incident face aux événements malveillants. Une configuration et un abonnement Barracuda XDR valides sont obligatoires. Vous trouverez un complément d'informations <0> ici </0>.",
        streamingEnabled: "Activé"
            },
            webFilterSettings: {
                safeSearch: "SafeSearch",
                silentAdBlocking: "Blocage de publicité silencieux",
        title: "Paramètres",
        helpText: "Le filtrage Web SecureEdge est compatible avec l'exécution de SafeSearch au niveau du réseau et bloque en silence la Catégorie d'URL \"les Publicités ou les Bannières\".<0/>Vous évitez ainsi tout accès à un contenu inapproprié via des moteurs de recherche populaires et YouTube."
            },
            webMonitorPolicies: {
        webConfigHelpText: "Activez ou désactivez la surveillance des recherches Web de vos utilisateurs pour des mots clés spécifiques. En cas d'activation, vous pouvez diffuser en streaming les correspondances de mot clé (recueillies dans le Web Monitor Alert Log qui forme partie de la collection Service Logs) vers le serveur Syslog de votre choix pour traitement ultérieur.<0/> Par esprit pratique, sélectionnez parmi les catégories de mots clés prédéfinis ou ajoutez des mots clés personnalisés correspondant explicitement à vos exigences.",
                enabled: "Activer la surveillance Web",
        webMonitoringTitle: "Surveillance Web",
                customKeywordTitle: "Jeu de mots clés personnalisés",
                customKeywordHelpText: "Saisissez les mots clés personnalisés dans le champ de texte et cliquez sur le bouton \"+\" pour les ajouter à la liste de surveillance.",
                customKeywords: "Mots clés personnalisés",
        monitored: "Surveillé",
        keywordCategoriesTitle: "Catégories de mots clés",
        keywordCategoriesHelpText: "Utilisez des catégories de mots clés prédéfinis. Pour en savoir plus sur ces catégories, <0>cliquez ici</0>.",
        toggleEnabled: "Activé"
            },
            customResponse: {
                setup: "Configurer une page de réponse personnalisée",
        setupHelpText: "Vous pouvez configurer ici votre page de réponse personnalisée. Une fois votre texte enregistré, cette page de réponse est affichée pour tous vos utilisateurs actifs.",
                barracudaBranding: "Marquage Barracuda",
                customLogo: "Logo personnalisé",
                customText: "Texte personnalisé",
        showBarracudaBranding: "Afficher marquage",
                descriptions: {
                    customText: "Affichage sous le logo personnalisé.<0/> Laissez-le vide pour n'utiliser aucun texte personnalisé.",
                    customLogo: "Affichage au sommet. Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge."
                }
            },
            identity: {
                menuTitle: "IDENTITÉ",
                pageTitle: "Identité",
                pageSubtitle: "Paramètres",
                identityProviders: {
                    title: "Prestataires d'identité",
                    addNotification: "Prestataire d'identité correctement ajoutés",
                    noDataMessage: "Il semble que vous ne disposiez pas encore d'un Prestataire d'identité configuré.",
                    clickBelow: "Cliquez sur le bouton à la suite pour en ajouter un.",
                    createIdentityProvider: "Ajouter Prestataire d'identité",
                    saml: {
                        informationNeeded: "Informations nécessaires pour votre Prestataire d'identité",
                        entityId: "ID d'entité",
                        acsURL: "URL ACS"
                    },
                    add: {
                        title: "Ajouter Prestataire d'identité",
                        createStep: "Paramètres d'identification SAML",
                        setupStep: "Configurer SAML",
                        button: "Ajouter",
                        buttonDisabled: "Vous ne pouvez avoir qu'un seul Prestataire d'identité à la fois.",
                        types: {
                            email: "E-mail",
                            entraid: "Microsoft Entra ID",
                            bcc: "Barracuda Cloud Control",
                            google: "Google Workspace",
                            oidc: "OpenID Connect",
                            saml: "SAML 2.0"
                        },
                        options: {
                            provider: "Prestataire",
                            displayName: "Nom d'affichage",
                            tenantId: "ID Tenant",
                            clientId: "ID Client",
                            clientSecret: "Secret de client",
                            discoveryURL: "URL découverte",
                            scope: "Portée",
                            googleWorkspaceDomain: "Domaine Google Workspace",
                            signInEndpoint: "Point final d'authentification",
                            signingCert: "Certificat de signature"
                        },
                        redirectUri: "URI de redirection"
                    },
                    table: {
                        name: "Nom",
                        type: "Type",
                        status: "Statut",
                        noData: "Aucun Prestataire d'identité trouvé."
                    },
                    authorizationStatusField: {
                        needs_consent: "Autorisation nécessaire",
                        active: "Actif",
                        error: "Erreur"
                    },
                    menu: {
                        test: "Tester Prestataire d'identité",
                        setupSaml: "Configurer SAML"
                    },
                    delete: {
                        title: "Retirer Prestataire d'identité",
                        body: "Êtes-vous certain de vouloir retirer ce Prestataire d'identité ?"
                    }
                },
                userDirectories: {
                    title: "Identité",
                    subtitle: "Répertoires d'utilisateurs",
                    tableName: "Répertoires d'utilisateurs",
                    noDataMessage: "Il semble que vous ne disposiez pas encore d'un Répertoire d'utilisateurs configuré.",
                    clickBelow: "Cliquez sur le bouton à la suite pour en ajouter un.",
                    createDirectory: "Ajouter Répertoire d'utilisateurs",
                    createDirectoryDialogTitle: "Ajouter Répertoire d'utilisateurs",
                    editDirectory: "Modifier Répertoire d'utilisateurs",
                    displayName: "Nom",
                    type: "Type",
                    status: "Statut",
                    statusField: {
                        running: "En cours",
                        cancelled: "Annulé",
                        errored: "En erreur",
                        completed: "Terminé",
                        pending: "En attente",
                        unknown: "Inconnu",
                        needs_consent: "Autorisation nécessaire"
                    },
                    sync: "Sync",
                    syncResult: "Résultat de synchronisation",
                    syncedNotification: "Synchronisation de Répertoire d'utilisateurs démarrée",
                    syncErrors: "Erreurs de synchronisation",
                    users_one: "{{count}} Utilisateur",
                    users_other: "{{count}} Utilisateurs",
                    groups_one: "{{count}} Groupe",
                    groups_other: "{{count}} Groupes",
                    syncStatus: "{{users}}, {{groups}}, Synchronisé",
                    lastSuccessfulSync: "Dernière synchronisation",
                    lastSyncAt: "Dernière synchronisation le {{date}}",
                    neverSynced: "Jamais synchronisé",
                    delete: {
                        body: "Êtes-vous certain de vouloir supprimer ce Répertoire d'utilisateurs ?",
                        body2: "Si vous modifiez ou supprimez le Répertoire d'utilisateurs pour votre compte, les stratégies de sécurité et d'accès existantes pourraient ne plus fonctionner comme prévu. Veuillez les examiner et les corriger, si nécessaire. Les utilisateurs déjà inscrits auront toujours accès. Veuillez utiliser la page Utilisateurs inscrits pour gérer ceux à supprimer.",
                        title: "Supprimer Répertoire d'utilisateurs {{name}}"
                    },
                    form: {
                        title: "Répertoire",
                        displayName: "Nom d'affichage",
                        tenantId: "ID Tenant",
                        type: {
                            label: "Type de répertoire",
                            bcc: "Barracuda Cloud Control",
                            entraid: "Microsoft Entra ID",
                            okta: "Okta",
                            ldap: "LDAP",
                            google: "Google Workspace"
                        },
                        ldap: {
                            steps: {
                                userDirectory: "Infos de Répertoire d'utilisateurs",
                                auth: "Authentification",
                                tls: "Paramètres TLS"
                            },
                            host: {
                                label: "Hôte",
                                placeholder: "ex. : ldap.barracuda.com"
                            },
                            port: {
                                label: "Port",
                                placeholder: "ex. : 389"
                            },
                            profile: {
                                label: "Profil",
                                placeholder: "Sélectionner profil",
                                ad: "Annonce",
                                custom: "Personnalisé"
                            },
                            userSearchBase: {
                                label: "Base de recherche d'utilisateur",
                                placeholder: "ex. : dc=mydomain.com,dc=com"
                            },
                            groupSearchBase: {
                                label: "Base de recherche de groupe",
                                placeholder: "ex. : dc=mydomain.com,dc=com"
                            },
                            authenticationMethod: {
                                label: "Méthode d'authentification",
                                placeholder: "Sélectionner méthode d'authentification",
                                anon: "Anon",
                                simple: "Simple",
                                saslExternal: "SASL External",
                                saslKerberos: "SASL Kerberos",
                                ntlm: "NTLM"
                            },
                            username: "Nom d'utilisateur",
                            password: "Mot de passe",
                            saslCredentials: "Identifiants SASL",
                            useTls: {
                                tlsSettings: "Paramètres TLS",
                                none: "Aucun",
                                tls: "TLS",
                                startTls: "StartTLS",
                                encryptionMethod: "Méthode de cryptage",
                                ldapSni: {
                                    label: "Nom d'hôte SNI",
                                    placeholder: "ex. : example.org"
                                },
                                ldapPrivkey: {
                                    label: "Clé privée",
                                    placeholder: "Collez votre clé privée ici"
                                },
                                ldapPrivkeyPassword: "Mot de passe",
                                ldapPubkey: {
                                    label: "Clé publique",
                                    placeholder: "Collez votre clé publique ici"
                                },
                                ldapCacerts: {
                                    label: "Certificats CA",
                                    placeholder: "Collez vos certificats CA ici"
                                },
                                ldapCheckCerts: {
                                    label: "Vérifier certificat",
                                    description: "Vérifiez si les certificats de serveur reçus sont fiables"
                                },
                                ldapCheckHostname: {
                                    label: "Vérifier nom d'hôte",
                                    description: "Valider nom d'hôte du certificat"
                                },
                                ldapCertAdditionalNames: {
                                    label: "Noms additionnels de certificat",
                                    placeholder: "ex. : example.org,company.com,dc1.example.org"
                                }
              },
              connectorIps: {
                warning: "Pour utiliser notre connecteur LDAP, vous devez autoriser le trafic des IP suivantes :"
                            }
                        },
                        okta: {
                            domainname: "Nom de domaine Okta",
                            authToken: "Jeton d'authentification Okta"
                        },
                        google: {
                            googleWorkspaceDomain: "Domaine Google Workspace"
                        },
                        bcc: {
                            environment: "Environnement"
                        }
                    }
                },
                users: {
                    title: "Utilisateurs",
                    subtitle: "Utilisateurs",
                    tableName: "Utilisateurs",
                    name: "Nom",
                    email: "E-mail",
                    noDataMessage: "Aucun utilisateur trouvé.",
                    nameOrEmail: "Utilisateur"
                },
                groups: {
                    title: "Groupes",
                    subtitle: "Groupes",
                    tableName: "Groupes",
                    name: "Nom",
                    noDataMessage: "Aucun groupe trouvé."
                }
            }
        }
    }
} as const;