import React from "react";
import {
    BooleanInput,
    FormSection,
    getArrayDataContent,
    getDataContent,
    HiddenInput,
    SelectArrayInput,
    SelectInput,
    TextInput,
    useCrudProps,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useTranslation} from "react-i18next";
import {categoryIcons} from "../../../../../components/iconMapping";
import {CustomAppsIcon} from "@cuda-react/icons";
import {get} from "lodash";

const resourceTypeChoices = [
    {key: "public", name: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.publicResource"},
    {key: "internal", name: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.internalResource"}
];

const hideInput = (key: any, type: any) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

export const defaultPolicy = {
    accessCriteria: {
        users: [],
        groups: []
    }
};

export const BasicPolicyInputs = () => {
    const [translate] = useTranslation();
    const securityPostureChoices = getArrayDataContent(useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0].data);
    const userOrGroupStatus = useCrudProps(apiResources.userOrGroupEnabled)[0]?.data;
    const userOrGroupDisabled = getDataContent(userOrGroupStatus) !== true;

    return (
        <React.Fragment>
            <TextInput
                label="tesseract.endpoint.remoteAccessPolicies.name"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.name"
                source="name"
                validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                isRequired
            />
            <TextInput
                label="tesseract.endpoint.remoteAccessPolicies.description"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.description"
                source="description"
                validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
            />
            <FormSection title="tesseract.endpoint.remoteAccessPolicies.resourcesSection.title">
                <SelectInput
                    label="tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourceTypeLabel"
                    description="tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourceTypeDescription"
                    source="resourceCriteria.type"
                    choices={resourceTypeChoices}
                    isRequired
                />
                <SelectArrayInput
                    label="tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourcesLabel"
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.applications"
                    source="resourceCriteria.publicApps"
                    resource={apiResources.zeroTrustApplications}
                    optionValue="id"
                    filterKey="applicationId"
                    options={{
                        dropdownAutoWidth: true
                    }}
                    iconMap={categoryIcons}
                    optionIcon="categoryId"
                    hide={hideInput("resourceCriteria.type", "public")}
                    isRequired
                />
                <SelectArrayInput
                    source="resourceCriteria.internalApps"
                    label="tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourcesLabel"
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.applications"
                    resource={apiResources.customApps}
                    optionValue="applicationId"
                    options={{
                        dropdownAutoWidth: true
                    }}
                    hide={hideInput("resourceCriteria.type", "internal")}
                    icon={<CustomAppsIcon/>}
                    isRequired
                />
            </FormSection>
            <FormSection title="tesseract.endpoint.remoteAccessPolicies.accessCriteriaSection">
                <SelectArrayInput
                    label="tesseract.endpoint.remoteAccessPolicies.users"
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.users"
                    source="accessCriteria.users"
                    // @ts-ignore
                    resource={!userOrGroupDisabled && apiResources.users}
                    disabled={userOrGroupDisabled}
                    error={userOrGroupDisabled ? translate("tesseract.security.sourceCriteria.userOrGroupDisabled") : undefined}
                    optionValue="name"
                    optionText="username"
                    optionTooltip="name"
                    options={{dropdownAutoWidth: true, optionSubText: "name", filterKey: "name"}}
                    icon={<User/>}
                />
                <SelectArrayInput
                    label="tesseract.endpoint.remoteAccessPolicies.groups"
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.groups"
                    source="accessCriteria.groups"
                    // @ts-ignore
                    resource={!userOrGroupDisabled && apiResources.groups}
                    disabled={userOrGroupDisabled}
                    error={userOrGroupDisabled ? translate("tesseract.security.sourceCriteria.userOrGroupDisabled") : undefined}
                    optionValue="id"
                    options={{dropdownAutoWidth: true, optionSubText: "directoryName"}}
                    icon={<UserGroup/>}
                />
                <SelectInput
                    label="tesseract.endpoint.remoteAccessPolicies.devicePosture"
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.securityPosture"
                    source="accessCriteria.securityPosture"
                    choices={securityPostureChoices}
                />
                <HiddenInput
                    source="accessCriteria.securityInspection"
                    hiddenValue={true}
                    hide={hideInput("resourceCriteria.type", "internal")}
                />
                <BooleanInput
                    description="tesseract.endpoint.remoteAccessPolicies.descriptions.securityInspection"
                    label="tesseract.endpoint.remoteAccessPolicies.securityInspection"
                    source="accessCriteria.securityInspection"
                    disable={hideInput("resourceCriteria.type", "public")}
                />
            </FormSection>
        </React.Fragment>
    );
};

export default BasicPolicyInputs;