import React from 'react';
import {IncidentBaseCard} from "../../baseCards";
import {VirusIcon} from "@cuda-react/icons";
import {useReportingData} from "../../../contexts";
import {AtdDataPoint, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {colorFunctionError, numberFormatter} from '../../../utils';
import {sortBy} from "lodash";
import {useTranslation} from 'react-i18next';
import {useTheme} from "@mui/material";

export interface MalwareCardProps extends DashboardCardProps {
}

/**
 * Shows the total number of malware detected and the top of the list
 *
 * @param props
 * @constructor
 */
export const MalwareCard = (props: MalwareCardProps) => {
    const {preview} = props;
    const {data, loading, addFilter} = useReportingData("MalwareCard", preview);
    const {atdDataPoints = []} = data || {};
    const [translate] = useTranslation();
    const theme = useTheme();
    const maxValue = Math.max(...atdDataPoints.map((atd: AtdDataPoint) => atd.numberOfSessions));
    const total = numberFormatter(atdDataPoints.reduce((atdTotal: number, atd: AtdDataPoint) => atdTotal + atd.numberOfSessions, 0));

    return (
        <IncidentBaseCard
            icon={<VirusIcon/>}
            title="stratosReporting.malwareCard.title"
            entries={sortBy(atdDataPoints, "numberOfSessions").reverse().map((atd: AtdDataPoint) => ({
                title: atd.label,
                value: props.size === CardSizes.small ?
                    numberFormatter(atd.numberOfSessions) :
                    translate("stratosReporting.malwareCard.hits", {
                        count: atd.numberOfSessions,
                        formattedCount: numberFormatter(atd.numberOfSessions)
                    }),
                icon: <VirusIcon size={20}/>,
                barProgress: (100.0 / maxValue) * atd.numberOfSessions,
                color: colorFunctionError((100.0 / maxValue) * atd.numberOfSessions, theme),
                onClick: (atd.label && addFilter) ? () => addFilter({key: atd.id, name: atd.label}, "atdDataPoints") : undefined,
                tooltipMessage: (atd.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
            }))}
            total={total}
            loading={loading && !data.atdDataPoints}
            noData={!loading && !data.atdDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
            {...props}
        />
    );
};