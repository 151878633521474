import {BaseBarCard} from "../../baseCards";
import React from "react";
import {bytesFormatter, colorFunctionSuccess} from "../../../utils";
import {CategoryDataPoint, DashboardCardProps} from "../../../typesAndConstants";
import {useReportingData} from "../../../contexts";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {AppCategoriesIcon} from "../../icons";
import {useTheme} from "@mui/material";

export interface TopAllowedCategoriesTrafficCardProps extends DashboardCardProps {
}

export const TopAllowedCategoriesTrafficCard = (props: TopAllowedCategoriesTrafficCardProps) => {
    const {preview, size} = props;
    const {data, loading, addFilter} = useReportingData("TopAllowedUrlCategoriesPerTrafficCard", preview);
    const {sdwanCategoryDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...sdwanCategoryDataPoints.map((category: CategoryDataPoint) => category.numberOfBytes));
    const entries: BarGridEntry[] = sortBy(sdwanCategoryDataPoints, "numberOfBytes").reverse().map((category: CategoryDataPoint) => ({
        title: category.label,
        value: bytesFormatter(category.numberOfBytes),
        barProgress: (100.0 / maxValue) * category.numberOfBytes,
        color: colorFunctionSuccess((100.0 / maxValue) * category.numberOfBytes, theme),
        // TODO: This icon can come from our set of icons for each category... as per the SDWAN page
        icon: <AppCategoriesIcon size={20}/>,
        onClick: (category.label && addFilter) ? () => addFilter(category.id, "sdwanCategoryDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.topAllowedCategoriesTraffic.title">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            loading={loading && !data.sdwanCategoryDataPoints}
            noData={!loading && !data.sdwanCategoryDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};