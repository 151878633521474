import React from 'react';
import {StatusIconField, TextField} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, StatisticsTable} from '@stratos/reporting';

export interface ApplianceInterfacesCardProps extends DashboardCardProps {
    additionalProps: {
        details: {
            id?: string | number;
            activeSerial?: string;
            gatewayType?: string;
        };
        type: "gateways" | "sites";
    };
}

export const ApplianceInterfacesCard: React.FC<ApplianceInterfacesCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const unsupported = additionalProps?.type === "gateways" && additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";

    return (
        <BaseCard
            id="ApplianceInterfacesCard"
            title="tesseract.appliances.dashboard.details.interfaces.title"
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        >
            <StatisticsTable
                resource={details.activeSerial ? (type !== "sites" ? apiResources.configurationOnPremInterfaces : apiResources.configurationInterfaces) : undefined}
                pollInterval={300000}
                params={{id: details.activeSerial, siteId: details.id}}
                formatData={(data) => data?.interfaces || []}
            >
                <StatusIconField
                    label="tesseract.appliances.dashboard.details.interfaces.status"
                    source="state"
                    tooltipSource="state"
                    tooltipPrefix="tesseract.appliances.dashboard.details.interfaces.state."
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.interfaces.name"
                    source="name"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.interfaces.speed"
                    source="speedDescription"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.interfaces.duplex"
                    source="duplex"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.interfaces.errors"
                    source="errors"
                />
            </StatisticsTable>
        </BaseCard>
    );
};