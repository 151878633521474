import React from 'react';
import {BaseBarCard} from "../../baseCards";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {useTranslation} from "react-i18next";
import {useReportingData} from "../../../contexts";
import {CardSizes, DashboardCardProps, DomainDataPoint} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {AppCategoriesIcon} from "../../icons";
import {useTheme} from "@mui/material";

export interface TopBlockedApplicationCategoriesCardProps extends DashboardCardProps {
}

export const TopBlockedApplicationCategoriesCard = (props: TopBlockedApplicationCategoriesCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopBlockedApplicationCategoriesCard", preview);
    const {blockedSdwanCategoryDataPoints = []} = data || {};
    const maxEntry = Math.max(...blockedSdwanCategoryDataPoints.map((domainDataPoint: DomainDataPoint) => domainDataPoint.numberOfSessions));
    const theme = useTheme();

    const entries: BarGridEntry[] = sortBy(blockedSdwanCategoryDataPoints, "numberOfSessions").reverse().map((domainDataPoint: DomainDataPoint) => ({
        title: domainDataPoint.label,
        value: size === CardSizes.small ?
            numberFormatter(domainDataPoint.numberOfSessions) :
            translate("stratosReporting.topBlockedDomainsCard.sessions", {formattedCount: numberFormatter(domainDataPoint.numberOfSessions)}),
        icon: <AppCategoriesIcon size={20}/>,
        barProgress: (100.0 / maxEntry) * domainDataPoint.numberOfSessions,
        color: colorFunctionError((100.0 / maxEntry) * domainDataPoint.numberOfSessions, theme),
        onClick: addFilter ? () => addFilter(domainDataPoint.id, "sdwanCategoryDataPoints") : undefined,
        tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            {...props}
            entries={entries}
            title="stratosReporting.blockedApplicationCategories.title"
            loading={loading && !data.blockedSdwanCategoryDataPoints}
            noData={!loading && !data.blockedSdwanCategoryDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};