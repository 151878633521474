import React from "react";
import {
    ConnectedFormPage,
    FormSection,
    usePreview,
    useMixpanel,
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {Divider} from "@barracuda-internal/bds-core";
import {parseJson} from "../../../utils/jsonUtils";
import {useSiteInputValidations} from "../../../hooks/createEditSiteHooks";
import AzureLogAnalyticsOMS from "./AzureLogAnalyticsOMS/AzureLogAnalyticsOMS";
import AzureLogAnalyticsDaemon from "./AzureLogAnalyticsDaemon/AzureLogAnalyticsDaemon";

export const AzureMonitor = () => {
    const inputValidations = useSiteInputValidations();
    const registerAction = useMixpanel("Log Analytics");
    const isAzureDaemon = usePreview("azureDaemon");

    return (
        <ConnectedFormPage
            title="tesseract.integration.pageTitle"
            subtitle="tesseract.integration.azureMonitor"
            resource={apiResources.settings}
            formatRequestData={(data) => ({
                settings: {
                    oms_workspace: JSON.stringify(data?.settings?.oms_workspace?.enabled ? data?.settings?.oms_workspace : {enabled: false}),
                    azure_log_analytics: JSON.stringify(data?.settings?.azure_log_analytics?.enabled ? data?.settings?.azure_log_analytics : {enabled: false})
                }
            })}
            formatResourceData={(data) => {
                const azureMonitor = parseJson(data?.settings?.azure_monitor || "{\"enabled\":false}");
                const azureLogAnalytics = parseJson(data?.settings?.azure_log_analytics || "{\"enabled\":false}");
                return {
                    settings: {
                        oms_workspace: parseJson(data?.settings?.oms_workspace || "{\"enabled\":false,\"id\":\"\",\"key\":\"\"}"),
                        azure_log_analytics: azureLogAnalytics?.enabled ? azureLogAnalytics : {
                            enabled: false,
                            clientId: "",
                            clientSecret: "",
                            tenantId: "",
                            immutableId: "",
                            resourceGroup: "",
                            stream: "",
                            logsUrl: ""
                        }
                    }
                };
            }}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    oms_enabled: parseJson(response.settings.oms_workspace).enabled,
                    azure_log_analytics: parseJson(response.settings.azure_log_analytics).enabled,
                });
            }}
            clearOnSuccess
            canReset
        >
            <FormSection title="tesseract.integration.azureMonitor" newStyle hideBorder>
                {isAzureDaemon ?
                    <>
                        <AzureLogAnalyticsDaemon />
                        <Divider sx={{marginTop: 1, marginBottom: 4}}/>
                    </>
                : null}
                <AzureLogAnalyticsOMS />
            </FormSection>
        </ConnectedFormPage>
    );
};

export default AzureMonitor;