import React, {useEffect, useRef} from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CopyToClipboardButton from '../../../../../../../components/CopyToClipboardButton';
import apiResources from '../../../../../../../apiResources';
import {
    ActionButtonsField,
    ConnectedTable,
    ConnectedTableRefresh,
    CrudTypes,
    CustomField,
    useCrudFetch,
    useCrudProps,
    LoadingMessage,
} from '@cuda-react/core';
import {Box, IconButton} from '@barracuda-internal/bds-core';
import {SCIMToken} from '../../../UserDirectoriesTypes';
import {SCIMTokenField} from './SCIMTokenField';
import {Refresh} from '@barracuda-internal/bds-core/dist/Icons/Core';
import useDemoApi from '../../../../../../../hooks/useDemoApi';

const i18nScope = 'tesseract.identity.userDirectories.form.scim';

interface SCIMInfo {
    id: string;
    create: boolean;
}
export const SCIMInfo = ({id, create}: SCIMInfo) => {
    const [translate] = useTranslation();
    const tokensApi = useDemoApi(
        apiResources.directoriesTokens,
        apiResources.demoDirectoriesTokens
    );

    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);

    const configurationApi = useDemoApi(
        apiResources.directoriesConfiguration,
        apiResources.demoDirectoriesConfiguration
    );
    const [{data: configData}, configLoading] = useCrudProps(
        configurationApi
    );

    const tokenApi = useDemoApi(apiResources.directoriesTokens, apiResources.demoSingleDirectoryTokens);
    const [, , performPutToken] = useCrudFetch(
        CrudTypes.UPDATE,
        tokenApi,
        {
            dirId: id,
        }
    );

    useEffect(() => {
        // Create primary and secondary tokens automatically if the directory is new
        if (create) {
            performPutToken({id: 'primary'})
                .then(() => performPutToken({id: 'secondary'}))
                .then(() => tableRefreshRef.current?.());
        }
    }, [id]);

    return (
        <>
            <Box display='flex' flexDirection='column' gap={1}>
                <Typography mb={2}>
                    {translate(`${i18nScope}.information.subtext`)}
                </Typography>
                <Box mt={2} mb={4}>
                    <Typography color='secondary' mb={1} fontWeight='bold'>
                        {translate(`${i18nScope}.information.scimEndpoint`)}
                    </Typography>
                    {configLoading ? (
                        <LoadingMessage />
                    ) : (
                        <Typography color='secondary'>
                            {configData?.scimEndpoint || ''}{' '}
                            <CopyToClipboardButton
                                value={configData?.scimEndpoint || ''}
                                iconOnly
                            />
                        </Typography>
                    )}
                </Box>
                <ConnectedTable
                    hasTitle
                    resource={tokensApi}
                    params={{dirId: id}}
                    tableName='Tokens'
                    minCellWidth={100}
                    pollInterval={300000}
                    sortable={false}
                    refreshRef={tableRefreshRef}
                >
                    <CustomField
                        source='name'
                        label={`${i18nScope}.information.name`}
                        width={150}
                        render={(value: any, token: SCIMToken) =>
                            ({
                                primary: translate(
                                    `${i18nScope}.information.primaryToken`
                                ),
                                secondary: translate(
                                    `${i18nScope}.information.secondaryToken`
                                ),
                            }?.[token.id] ?? value.name)
                        }
                    />
                    <CustomField
                        source='id'
                        label={`${i18nScope}.information.token`}
                        render={(value: any, token: SCIMToken) => (
                            <SCIMTokenField token={token.token} />
                        )}
                    />
                    <ActionButtonsField width={82} source='id' alwaysVisible>
                        <IconButton
                            onClick={(event: any, data?: any) => {
                                performPutToken({dirId: id, id: data.id}).then(
                                    () => tableRefreshRef.current?.()
                                );
                            }}
                        >
                            <Refresh />
                        </IconButton>
                    </ActionButtonsField>
                </ConnectedTable>
            </Box>
        </>
    );
};