import React from 'react';
import {getDataContent, useCrudProps} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import {useTheme} from "@mui/material";
import apiResources from "./../../../../apiResources";
import {BaseBarCard, DashboardCardProps} from "@stratos/reporting";
import {colorFunction} from "@stratos/reporting/lib/utils";

export interface ApplianceResourcesCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            activeSerial?: string;
            gatewayType?: string;
        };
        type: "gateways" | "sites";
    };
}

export const ApplianceResourcesCard: React.FC<ApplianceResourcesCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const {activeSerial, id} = details || {};
    const [translate] = useTranslation();
    const theme = useTheme();
    const unsupported = additionalProps?.type === "gateways" && additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";
    const diskPartitions = getDataContent(useCrudProps(
        activeSerial ? (type !== "sites" ? apiResources.configurationOnPremResources : apiResources.configurationResources) : undefined,
        {
            id: activeSerial,
            siteId: id
        }
    )[0]?.data);
    const barEntries = [
        {
            title: translate("tesseract.appliances.dashboard.details.boxResources.cpuLoad"),
            value: get(diskPartitions, "cpuLoad.minute1") + "%",
            color: colorFunction(get(diskPartitions, "cpuLoad.minute1"), theme),
            barProgress: get(diskPartitions, "cpuLoad.minute1"),
            tooltipMessage: get(diskPartitions, "cpuLoad.description", "")
        },
        {
            title: translate("tesseract.appliances.dashboard.details.boxResources.memory"),
            value: get(diskPartitions, "memory.usage") + "%",
            color: colorFunction(get(diskPartitions, "memory.usage"), theme),
            barProgress: get(diskPartitions, "memory.usage"),
            tooltipMessage: get(diskPartitions, "memory.description", "")
        },
        {
            title: translate("tesseract.appliances.dashboard.details.boxResources.diskInformation"),
            value: get(diskPartitions, "totalDiskUsage.usage") + "%",
            color: colorFunction(get(diskPartitions, "totalDiskUsage.usage"), theme),
            barProgress: get(diskPartitions, "totalDiskUsage.usage"),
            tooltipMessage: get(diskPartitions, "totalDiskUsage.description", "")
        },
        ...diskPartitions?.diskPartitionsUsage?.map((value: any, key: any) => ({
            title: value.mountpoint,
            value: get(diskPartitions, `diskPartitionsUsage[${key}].usage`) + "%",
            color: colorFunction(get(diskPartitions, `diskPartitionsUsage[${key}].usage`), theme),
            barProgress: get(diskPartitions, `diskPartitionsUsage[${key}].usage`),
            tooltipMessage: get(diskPartitions, `diskPartitionsUsage[${key}].description`, "")
        })) || []
    ];

    return (
        <BaseBarCard
            title="tesseract.appliances.dashboard.details.boxResources.title"
            entries={barEntries}
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        />
    );
};