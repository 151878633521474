import {SitesTunnelLocationsCard} from "./SitesTunnelLocationsCard";
import {SiteSummaryCard} from "./SiteSummaryCard";
import {SiteLicensesCard} from "./SiteLicensesCard";
import {SiteGatewayCard} from "./SiteGatewayCard";
import {FirmwareDetailsCard} from "./FirmwareDetailsCard";
import {FirmwareHotfixesCard} from "./FirmwareHotfixesCard";
import {ConfigurationUpdatesCard} from "./ConfigurationUpdatesCard";
import {SiteStatusCard} from "./SiteStatusCard";
import {VPNTunnelsSitesCard} from "./VPNTunnelsSitesCard";
import {ApplianceServicesCard} from "./ApplianceServicesCard";
import {ApplianceInterfacesCard} from "./ApplianceInterfacesCard";
import {ApplianceResourcesCard} from "./ApplianceResourcesCard";
import {DeploymentStatusCard} from "./DeploymentStatusCard";
import {DeploymentLocationsCard} from "./DeploymentLocationsCard";
import {DeploymentEventsCard} from "./DeploymentEventsCard";
import {GatewayTunnelLocationsCard} from "./GatewayTunnelLocationsCard";
import {GatewaySummaryCard} from "./GatewaySummaryCard";
import {IoTTunnelLocationsCard} from "./IoTTunnelLocationsCard";
import {IoTStatusCard} from "./IoTStatusCard";
import {VPNTunnelsGatewayCard} from "./VPNTunnelsGatewayCard";
import {GatewayLicensesCard} from "./GatewayLicensesCard";

export const SiteDashboardCards = {
    SitesTunnelLocationsCard: {
        component: SitesTunnelLocationsCard,
        name: "tesseract.appliances.dashboard.tunnelLocations.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteSummaryCard: {
        component: SiteSummaryCard,
        name: "tesseract.appliances.dashboard.summary.siteStatus.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteLicensesCard: {
        component: SiteLicensesCard,
        name: "tesseract.appliances.dashboard.summary.licenses.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteGatewayCard: {
        component: SiteGatewayCard,
        name: "tesseract.appliances.dashboard.summary.gateway.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    FirmwareDetailsCard: {
        component: FirmwareDetailsCard,
        name: "tesseract.appliances.dashboard.firmware.details.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    FirmwareHotfixesCard: {
        component: FirmwareHotfixesCard,
        name: "tesseract.appliances.dashboard.firmware.hotfixes.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ConfigurationUpdatesCard: {
        component: ConfigurationUpdatesCard,
        name: "tesseract.appliances.dashboard.firmware.updates.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteStatusCard: {
        component: SiteStatusCard,
        name: "tesseract.appliances.dashboard.siteStatus.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    IoTStatusCard: {
        component: IoTStatusCard,
        name: "tesseract.appliances.dashboard.iotStatus.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    VPNTunnelsSitesCard: {
        component: VPNTunnelsSitesCard,
        name: "tesseract.dashboard.cards.vpn.status.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceServicesCard: {
        component: ApplianceServicesCard,
        name: "tesseract.appliances.dashboard.details.servicesCardTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceInterfacesCard: {
        component: ApplianceInterfacesCard,
        name: "tesseract.appliances.dashboard.details.interfaces.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceResourcesCard: {
        component: ApplianceResourcesCard,
        name: "tesseract.appliances.dashboard.details.boxResources.title",
        type: "tesseract.dashboard.cards.categories.status"
    }
} as const;

const ExpertSiteDashboardCards = {
    DeploymentStatusCard: {
        component: DeploymentStatusCard,
        name: "tesseract.appliances.dashboard.deployment.status.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    DeploymentLocationsCard: {
        component: DeploymentLocationsCard,
        name: "tesseract.appliances.dashboard.deployment.location.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    DeploymentEventsCard: {
        component: DeploymentEventsCard,
        name: "tesseract.appliances.dashboard.deployment.events.title",
        type: "tesseract.dashboard.cards.categories.status"
    }
};

export const useSiteDashboardCards = (expert?: boolean) => expert ? {...SiteDashboardCards, ...ExpertSiteDashboardCards} : SiteDashboardCards;

export const GatewayDashboardCards = {
    GatewayTunnelLocationsCard: {
        component: GatewayTunnelLocationsCard,
        name: "tesseract.appliances.dashboard.tunnelLocations.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    GatewaySummaryCard: {
        component: GatewaySummaryCard,
        name: "tesseract.appliances.dashboard.summary.siteStatus.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    GatewayLicensesCard: {
        component: GatewayLicensesCard,
        name: "tesseract.appliances.dashboard.summary.licenses.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    FirmwareDetailsCard: {
        component: FirmwareDetailsCard,
        name: "tesseract.appliances.dashboard.firmware.details.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    FirmwareHotfixesCard: {
        component: FirmwareHotfixesCard,
        name: "tesseract.appliances.dashboard.firmware.hotfixes.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ConfigurationUpdatesCard: {
        component: ConfigurationUpdatesCard,
        name: "tesseract.appliances.dashboard.firmware.updates.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteStatusCard: {
        component: SiteStatusCard,
        name: "tesseract.appliances.dashboard.siteStatus.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    IoTStatusCard: {
        component: IoTStatusCard,
        name: "tesseract.appliances.dashboard.iotStatus.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    VPNTunnelsGatewayCard: {
        component: VPNTunnelsGatewayCard,
        name: "tesseract.dashboard.cards.vpn.status.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceServicesCard: {
        component: ApplianceServicesCard,
        name: "tesseract.appliances.dashboard.details.servicesCardTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceInterfacesCard: {
        component: ApplianceInterfacesCard,
        name: "tesseract.appliances.dashboard.details.interfaces.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ApplianceResourcesCard: {
        component: ApplianceResourcesCard,
        name: "tesseract.appliances.dashboard.details.boxResources.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
};

export const IoTDashboardCards = {
    IoTTunnelLocationsCard: {
        component: IoTTunnelLocationsCard,
        name: "tesseract.appliances.dashboard.tunnelLocations.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteSummaryCard: {
        component: SiteSummaryCard,
        name: "tesseract.appliances.dashboard.summary.siteStatus.title",
        type: "tesseract.dashboard.cards.categories.status"
    },
    SiteGatewayCard: {
        component: SiteGatewayCard,
        name: "tesseract.appliances.dashboard.summary.gateway.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    FirmwareDetailsCard: {
        component: FirmwareDetailsCard,
        name: "tesseract.appliances.dashboard.firmware.details.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    },
    ConfigurationUpdatesCard: {
        component: ConfigurationUpdatesCard,
        name: "tesseract.appliances.dashboard.firmware.updates.tabTitle",
        type: "tesseract.dashboard.cards.categories.status"
    }
} as const;

export const useIoTDashboardCards = (expert?: boolean) => expert ? {...IoTDashboardCards, ...ExpertSiteDashboardCards} : IoTDashboardCards;