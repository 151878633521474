import React from 'react';
import {StatusIconField, TextField} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, StatisticsTable} from "@stratos/reporting";
import {getVpnTunnelStatusIcon} from "../../../../components/iconMapping";

export interface VPNTunnelsGatewayCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id: number | string;
        };
        gatewayType: "on-prem" | "cloud" | "managed";
    };
}

const flattenVPNTunnels = (content: any) => content.flatMap((instance: any) => instance.tunnels);

export const VPNTunnelsGatewayCard: React.FC<VPNTunnelsGatewayCardProps> = (props) => {
    const {additionalProps, preview, size} = props;
    const {details, gatewayType} = additionalProps || {};

    // Determine which API resource to use based on the gatewayType
    const apiResourcesLink = gatewayType === "on-prem"
        ? apiResources.configurationOnPremVPNTunnels
        : apiResources.configurationGatewayVPNTunnels;

    return (
        <BaseCard
            id="VPNTunnelsGatewayCard"
            title="tesseract.appliances.dashboard.details.vpnTunnels.title"
            size={size}
            preview={preview}
        >
            <StatisticsTable
                resource={preview ? undefined : apiResourcesLink}
                params={{id: details?.id}}
                formatData={(data: any) => Array.isArray(data) ? flattenVPNTunnels(data) : []}
                noDataMessage="tesseract.dashboard.cards.vpn.offline.noData"
            >
                <StatusIconField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.status"
                    source="status"
                    icon={getVpnTunnelStatusIcon}
                    textSource="status"
                    textPrefix="tesseract.appliances.dashboard.details.vpnTunnels.state."
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.name"
                    source="name"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.peer"
                    source="peer"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.local"
                    source="local"
                />
                <TextField
                    label="tesseract.appliances.dashboard.details.vpnTunnels.type"
                    source="type"
                />
            </StatisticsTable>
        </BaseCard>
    );
};