import React from "react";
import {LocationsCardContent} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {useTheme} from "@mui/material";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

export interface IoTTunnelLocationsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: number | string,
        }
    }
}

export const IoTTunnelLocationsCard = (props: IoTTunnelLocationsCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const theme = useTheme();

    return (
        <BaseCard
            id="IoTTunnelLocationsCard"
            title="tesseract.appliances.dashboard.tunnelLocations.title"
            size={size}
            preview={preview}
        >
            <LocationsCardContent
                params={{id: details?.id}}
                resource={apiResources.siteTunnelLocations}
                series={[
                    {
                        label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                        color: theme.palette.general.purple,
                    },
                    {
                        label: "tesseract.appliances.dashboard.tunnelLocations.iot.label",
                        color: theme.palette.general.turquoise,
                        source: "sites"
                    }
                ]}
            />
        </BaseCard>
    );
};