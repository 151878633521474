import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunctionSuccess, numberFormatter} from "../../../utils";
import {CardSizes, DashboardCardProps, UrlCategoryDataPoint} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {Trans, useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {sortBy} from "lodash";
import {UrlCategoriesIcon} from "@cuda-react/icons";
import {useTheme} from "@mui/material";

/**
 * Shows a list of allowed url categories and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopAllowedUrlCategoriesPerSessionCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const [translate] = useTranslation();
    const {data, loading, addFilter} = useReportingData("TopAllowedUrlCategoriesPerSessionCard", preview);
    const {urlCategoryDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...urlCategoryDataPoints.map((category: UrlCategoryDataPoint) => category.numberOfSessions));
    const entries: BarGridEntry[] = sortBy(urlCategoryDataPoints, "numberOfSessions").reverse().map((category: UrlCategoryDataPoint) => ({
        title: category.label,
        value: size === CardSizes.small ?
            numberFormatter(category.numberOfSessions) :
            translate("stratosReporting.topAllowedUrlCategoriesCard.sessions", {
                count: category.numberOfSessions,
                formattedCount: numberFormatter(category.numberOfSessions)
            }),
        barProgress: (100.0 / maxValue) * category.numberOfSessions,
        color: colorFunctionSuccess((100.0 / maxValue) * category.numberOfSessions, theme),
        icon: <UrlCategoriesIcon size={20}/>,
        onClick: (category.label && addFilter) ? () => addFilter({key: category.id, name: category.label}, "urlCategoryDataPoints") : undefined,
        tooltipMessage: (category.label && addFilter) ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title={
                <Trans i18nKey="stratosReporting.topAllowedUrlCategoriesCard.titlePerSession">
                    <Typography variant="body1" component="span"/>
                </Trans>
            }
            size={size}
            preview={preview}
            loading={loading && !data.urlCategoryDataPoints}
            noData={!loading && !data.urlCategoryDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};