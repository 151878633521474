import {Chip, IconButton, Tooltip} from "@barracuda-internal/bds-core";
import {PageNext, PagePrevious} from "@barracuda-internal/bds-core/dist/Icons/Core";
import get from "lodash/get";
import React, {useState} from "react";
import {useChoices} from "../../../hooks";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {Theme, useTheme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = (theme: Theme) => createStyles({
    iconButton: {
        zIndex: 2,
        display: "inline-block",
        marginRight: theme.spacing(2),
        marginTop: 5,
        height: 30,
        verticalAlign: "middle"
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        marginLeft: -(theme.spacing(1))
    },
    chip: {
        margin: 4,
        maxWidth: "100%"
    },
    chipIcon: {
        marginLeft: 10,
        marginTop: 6
    },
    chipUnknown: {
        color: theme.palette.error.main,
    }
});

const useStyles = makeOverrideableStyles("ActionButtonsField", styles);

export interface ChipArrayFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * array of choice objects to search in.
     */
    choices?: any[],
    /**
     * Key to filter by when calling resource.
     * That is mainly used for Select/SearchSelect inputs where you want to show on the select list only the ones that haven't been already selected.
     */
    filterKey?: string,
    /**
     * icon to display next to each chip. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each chip. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: { [key: string]: React.ReactNode },
    /**
     * the maximum length of each chip.
     */
    maxChipLength?: number,
    /**
     * the maximum number of chips.
     */
    maxChips?: number,
    /**
     * if provided, the chips are rendered with delete icons. This callback is then called when a chip delete icon is clicked.
     * @function
     * @param {*} value the data value for the chip.
     */
    onDelete?: (value: any) => void,
    /**
     * dot-notation path to the property in each choice object that defines the icon to use from 'iconMaps'.
     * OR a method that takes the current value and data and returns the icon name to use from 'iconMaps'.
     */
    optionIcon?: string | ((value: any, data: any) => string | undefined),
    /**
     * dot-notation path to the property in each choice object that defines the tooltip string to display OR a method
     * that takes the current valuye and data and returns the tooltip string.
     */
    optionTooltip?: string | ((value: any, data: any) => string | undefined),
    /**
     * dot-notation path to the property in each choice object that defines the text to display in the chip.
     */
    optionText?: string,
    /**
     * dot-notation path to the property in each choice object that defines the value for matching against the data.
     */
    optionValue?: string,
    /**
     * function to render the content of the Chip
     * @function
     * @param {*} value the data value of the current chip
     * @param {object} data the full data array
     * @param {object} choice the choice object for the current chip
     * @param {object} choiceData the corresponding data for the current chip, matched from the choices prop
     * @returns {node} the content to display in the chip
     */
    render?: (value: any, data?: any, choice?: any, choiceData?: any) => React.ReactNode,
    /**
     * the resource to call to fetch the choices
     */
    resource?: string,
    /**
     * if the data array contains objects, instead of values, this identifies the value within each object that should be compared to the choice's optionValue.
     */
    sourceValue?: string,
    /**
     * the content to render for all chips where the value does not match any of the given choices.
     */
    unknownChipContent?: string | React.ReactNode
}

/**
 * Renders an array of Chips with the values gotten from a source.
 *
 * If the value you are searching for is deep in the data Array, you can use the sourceValue prop to search in the next level of the array.
 * Based on the material-ui "Chip" field.
 */
export const ChipArrayField = (props: ChipArrayFieldProps) => {
    const {
        choices,
        data = {},
        filterKey,
        icon,
        iconMap,
        maxChips = 5,
        maxChipLength = 30,
        onDelete,
        optionIcon,
        optionText = "name",
        optionValue = "key",
        optionTooltip,
        render,
        resource,
        source = "",
        sourceValue = "",
        unknownChipContent = "cuda.inputs.selectArray.unknownChipText"
    } = props;
    const classes = useStyles(props);
    const theme = useTheme();
    const [translate] = useTranslation();
    const unknownChip = typeof unknownChipContent === "string" ? translate(unknownChipContent) : unknownChipContent;
    const [minimized, setMinimized] = useState(true);
    const field = get(data, source) || [];
    const fieldArray = Array.isArray(field) ? field : [field];
    const resolvedFieldArray = sourceValue ? fieldArray.map((value) => get(value, sourceValue)) : fieldArray;
    const [resourceData] = useChoices(
        resolvedFieldArray,
        {
            choices,
            filterKey: filterKey || optionValue,
            optionValue,
            resource,
            params: {
                pagination: {
                    page: 1,
                    perPage: resolvedFieldArray.length
                }
            }
        }
    );
    const dataToUse = (resource || choices) ? resourceData.map((chipData) => get(chipData, optionText)) : resolvedFieldArray;
    const slicedFields = minimized ? dataToUse.slice(0, maxChips) : dataToUse;
    const chipValues = slicedFields.map((val, index) =>
        render ? render(val, data, fieldArray.find((field) => get(field, sourceValue, field) === get(resourceData[index], optionValue)), resourceData[index])
            : translate(val));
    const chipIcons = slicedFields.map((val, index) => {
        if (iconMap) {
            if (typeof optionIcon === "function") {
                const iconIndex = optionIcon(val, data);
                return iconIndex && iconMap[iconIndex] || icon || null;
            }
            return (optionIcon ? iconMap[get(resourceData[index], optionIcon) || get(fieldArray[index], optionIcon)] : iconMap[val]) || icon || null;
        }
        return icon || null;
    });
    const chipTooltips = slicedFields.map((value, index) => {
        if (typeof optionTooltip === "function") {
            return optionTooltip(value, data);
        }
        return optionTooltip ? get(resourceData[index], optionTooltip) || get(fieldArray[index], optionTooltip) : undefined;
    });

    const renderChips = (chips: any[], rootIndex: number = -1): React.ReactNode[] => chips.flatMap((val: any, index: number) => {
        const iconIndex = rootIndex > -1 ? rootIndex : index;
        if (Array.isArray(val)) {
            return renderChips(val, iconIndex);
        }

        return (
            <Tooltip
                key={"Chip-" + (rootIndex !== undefined ? (rootIndex + "-") : "") + index}
                title={chipTooltips[iconIndex] ? translate(chipTooltips[iconIndex]) : undefined}
                placement="top"
                arrow
            >
                <Chip
                    label={typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : (val ? val :
                        <span className={classes.chipUnknown}>
                            {unknownChip}
                        </span>
                    )}
                    className={classes.chip}
                    icon={chipIcons[iconIndex] ? (
                        <span className={classes.chipIcon}>
                            {chipIcons[iconIndex]}
                        </span>
                    ) : undefined}
                    onDelete={onDelete ? () => onDelete(val) : undefined}
                />
            </Tooltip>
        );
    });

    return (
        <div id={"chip-array-field-" + source.replace(/[.]/g, "-")} className={classes.root}>
            {renderChips(chipValues).concat(
                dataToUse.length > maxChips ? [
                    (
                        <IconButton
                            key="button"
                            className={classes.iconButton}
                            onClick={() => setMinimized(!minimized)}
                            color="primary"
                        >
                            {minimized ? <PageNext style={{color: theme.palette.primary.main}}/> :
                                <PagePrevious style={{color: theme.palette.primary.main}}/>}
                        </IconButton>
                    )
                ] : []
            )}
        </div>
    );
};

export default ChipArrayField;