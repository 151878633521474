export default {
    translation: {
        tesseract: {
            productName: "Barracuda SecureEdge",
            dashboard: {
                menuTitle: "ダッシュボード",
                pageTitle: "ダッシュボード",
                emptyTemplate: "空",
                cards: {
                    gateway: {
                        cardTitle: "エッジサービスステータス",
                        online: "オンライン",
                        pending: "保留中",
                        degraded: "機能低下",
                        offline: "オフライン"
                    },
                    iotStatusCard: {
                        healthy: "オンライン",
                        pending: "保留中",
                        connecting: "接続中",
                        degraded: "ダウングレード済",
                        offline: "オフライン",
                        name: "名前",
                        gateway: "ゲートウェイ",
                        aggregateState: "ステータス",
                        type: "モデル"
                    },
                    status: {
                        site: {
                            tabTitle: "サイトステータス"
                        },
                        iot: {
                            tabTitle: "IoT ステータス"
                        },
                        applianceConfiguration: {
                            tabTitle: "デバイス設定 ステータス",
                            completed: "完了",
                            connecting: "接続中",
                            failed: "失敗",
                            pending: "保留中"
                        }
                    },
                    vpn: {
                        locations: {
                            tabTitle: "マップ",
                            gateways: {
                                label: "エッジサービス",
                                linkedSites: "接続されたサイト/IoT",
                                fields: {
                                    name: "名前",
                                    sites: "接続されたサイト",
                                    iot: "接続された IoT",
                                    vwan: "ワークスペース",
                                    hub: "ハブ",
                                    region: "リージョン",
                                    size: "サイズ"
                                }
                            },
                            sites: {
                                label: "サイト",
                                linkedHubs: "接続されたエッジサービス",
                                fields: {
                                    name: "名前",
                                    location: "場所",
                                    gateway: "エッジサービス",
                                    providers: "プロバイダー数"
                                }
                            },
                            iot: {
                                label: "IoT",
                                linkedHubs: "接続されたエッジサービス",
                                fields: {
                                    name: "名前",
                                    location: "場所",
                                    gateway: "エッジサービス",
                                    providers: "プロバイダー数"
                                }
                            },
                            standaloneSites: {
                                label: "スタンドアロンサイト"
                            }
                        },
                        status: {
                            tabTitle: "トンネルステータス",
                            ok: "OK",
                            warning: "警告",
                            error: "エラー",
                            online: "オンライン",
                            pending: "保留中",
                            connecting: "接続中",
                            degraded: "ダウングレード済",
                            offline: "オフライン"
                        },
                        offline: {
                            tabTitle: "オフライントンネル",
                            gatewayName: "エッジサービス名",
                            gatewayStatus: "エッジサービスステータス",
                            siteName: "サイト/IoT名",
                            siteStatus: "サイト/IoT ステータスステータス",
                            siteLocation: "サイト/IoTの場所",
                            noData: "オフラインのトンネルは見つかりませんでした。"
                        }
                    },
                    recentEvents: {
                        adminTabTitle: "最近の管理イベント",
                        systemTabTitle: "最近のシステムイベント",
                        latest: "最新 {{length}}"
                    },
                    categories: {
                        status: "ステータス"
                    }
                },
                status: "ステータス",
                security: "セキュリティ",
                webFilter: "ウェブフィルター",
                filters: {
                    timeframe: "時間",
                    appliance: "エッジサービス/サイト",
                    noAppliances: "なし",
                    timeframes: {
                        lastDay: "過去24時間",
                        lastMonth: "先月",
                        lastWeek: "先週",
                        lastQuarter: "直前3ヵ月間"
          },
          sourceIp: "送信元IP",
          riskLevel: "リスクレベル",
          application: "アプリケーション",
          destinationDomain: "宛先ドメイン",
          targetIp: "ターゲットIP",
          user: "ユーザー",
          urlCategory: "URLカテゴリー",
          malwareFile: "マルウェアファイル",
          ipsEvent: "IPSイベント",
          addFilter: "フィルターを追加",
          geoSource: "Geo送信元",
          geoDestination: "Geo宛先",
          resetFilter: "フィルターをリセット",
          riskLevelChoice_0: "低",
          riskLevelChoice_1: "低 - 中",
          riskLevelChoice_2: "中",
          riskLevelChoice_3: "中 - 高",
          riskLevelChoice_4: "高",
          sdwanCategory: "アプリのカテゴリー",
          activeFilters: "<0/>が付いているウィジェットには、すべての有効なフィルターが適用されています。",
          disabledFilters: "現在のフィルターは、表示されているいずれのウィジェットにも適用されません。",
          addFilterMessage: "上の「フィルターを追加」またはウィジェットラインをクリックして、フィルターを作成します。"
                },
                ztnaDashboard: "ゼロトラストアクセス",
                ztnaUserDashboard: "ユーザーダッシュボード"
            },
            network: {
                pageTitle: "インフラストラクチャ",
                vmActivation: "VMアクティベーション",
        vmActivationMessage: "すべてのワークスペースでの仮想マシン（VM）の自動アクティベーションについての請求明細を入力してください。この操作は、仮想マシンの画像を初めて使用する場合のみ必要となります。",
                gateways: "エッジサービス",
                sites: "サイト",
                iot: {
                    menuTitle: "IoT",
                    noData: {
                        iconHeader: "まずは IoTデバイスを追加しましょう!",
                        labelButton: "IoTデバイスを追加します。",
                        textHeader: "IoTデバイスとは何ですか？",
                        text: "IoTデバイスは、Barracuda Secure Connectorアプライアンスによって接続 (および保護) されている IoTデバイスの場所を示します。Secure Connectorアプライアンスは、ハードウェアまたは仮想アプライアンスとして導入できます。どちらでもニーズに最も合うものを選択できます。"
                    }
                },
                settings: "設定",
                firewalls: "CloudGen Firewalls",
                sdwanConnector: {
                    menuTitle: "コネクター",
                    pageTitle: "コネクタ",
                    table: {
                        status: "ステータス",
                        name: "名前",
                        gateway: "エッジサービス",
                        internalResources: "内部リソース",
                        provisioningDate: "プロビジョニング日",
                        servers: "サーバー",
                        ellipsisMenu: {
                            downloadLinux: "Linuxエージェントをダウンロード",
                            downloadWindows: "Windowsエージェントをダウンロード",
                            token: "登録トークンを生成する。"
                        },
                        hostnames: "ホスト名"
                    },
                    delete: {
                        body: "このコネクタを削除してもよろしいですか？",
                        title: "コネクタ{{name}}を削除する。"
                    },
                    dialog: {
                        add: "追加",
                        menuItemDisabled: "コネクタにはエッジサービスが必要です。",
                        basic: {
                            name: "名前",
                            sectionTitle: "基本情報",
                            tabTitle: "基本"
                        },
                        create: {
                            dialogTitle: "コネクタを追加"
                        },
                        edit: {
                            dialogTitle: "コネクタを編集"
                        },
                        poolNetwork: {
                            sectionTitle: "ルーティングされた IP",
                            descriptionMessage: "最初のコネクタを追加する前に、クライアントネットワーク範囲を設定しておく必要があります。クライアントネットワークは、コネクタの内側にあるリソースに IPを割り当てるために使用されます。",
                            network: "クライアントネットワーク"
                        },
                        general: {
                            sectionTitle: "全般",
                            name: "名前",
                            description: "説明",
                            appliance: "エッジサービス"
                        },
                        servers: {
                            sectionTitle: "サーバー",
                            serviceName: "サーバー",
                            itemName: "サーバー",
                            hostname: "ホスト名",
                            internalIp: "内部 IP",
                            category: "カテゴリー"
                        },
                        summary: {
                            sectionTitle: "概要",
                            name: "コネクター {{connector}}の概要",
                            connectorName: "コネクター名",
                            serviceName: "サービス名",
                            hostname: "ホスト名"
                        },
                        completed: {
                            sectionTitle: "コネクタ \"{{connector}}\"は正常に作成されました。",
                            sectionTitleNextSteps: "次の手順",
                            sectionMessage: "使用を開始するには、以下の手順に従ってください。",
                            sectionSteps: "コネクターをダウンロードしてインストールしてください。",
                            sectionSteps2: "OTP トークンを使用してコネクタをプロビジョニングする:",
                            sectionSteps3: "接続が確立されていることを確認する。",
                            downloadButtonLinux: "Linux版ダウンロード",
                            downloadButtonWindows: "Windows版ダウンロード"
                        },
                        description: {
                            poolNetwork: {
                                network: "CIDR表記でのクライアントネットワーク範囲"
                            },
                            general: {
                                sectionTitle: "全般",
                                name: "コネクターの一意の名前",
                                description: "コネクタの簡略な説明",
                                appliance: "設定したいエッジサービスデバイスを選択します。"
                            },
                            servers: {
                                sectionTitle: "サーバー",
                                serviceName: "サーバーの固有名",
                                hostname: "サーバー関連のホスト名",
                                internalIp: "サーバー関連の内部IP",
                                category: "カテゴリー"
                            }
                        },
                        token: {
                            dialogTitle: "登録トークンを生成",
                            message: "\"{{sec}}\"用に新しいトークンを生成",
                            subLabel: "コネクタトークン"
                        }
                    },
                    noData: {
                        iconHeader: "まずはコネクタを追加しましょう！",
                        labelButton: "コネクターを追加",
                        textHeader: "コネクタとは何ですか？",
                        text: "特定のサービスを従業員が利用できるようにすることは、難しい作業です。<0 /> コネクタを使用すると、Windowsサービスとサーバーをネットワークに簡単に接続し、専用のサイト デバイスを必要とせずに従業員が利用できるようにすることができます。"
                    }
                },
                customCategories: "カスタムカテゴリー"
            },
            endpoint: {
                pageTitle: "ゼロトラストアクセス",
                devices: "デバイス",
                settings: "設定",
                zeroTrustAccess: "ゼロトラストアクセス",
                zeroTrustSettingsTitle: "設定",
                pointToSite: "ポイントツーサイト",
                users: "登録済みユーザー",
                appCatalog: {
                    pageTitle: "アプリケーションカタログ",
                    textHeader: "アプリケーション カタログとは何ですか？",
                    text: "アプリケーションカタログでは直ぐアクセスできるように SecureEdge Accessエージェントアプリに表示されるアプリケーションを定義できます。<0 />このアクセス機能は外部と内部のアプリケーションともに機能します。<br/><br/>このセクションは間もなく利用可能になります。",
                    startText: "アプリケーションカタログではクイックアクセス用の SecureEdge Accessエージェントアプリに表示させるアプリケーションを決めておくことができます。<0 />このクイックアクセス機能は外部アプリケーションにも内部アプリケーションにも有効です。",
                    startTextHeader: "アプリケーションを追加して開始しましょう！",
                    buttonText: "アプリケーションを追加",
                    iconText: "アイコン",
                    name: "名前",
                    application: "アプリケーション",
                    launchURL: "立ち上げ用URL",
                    visibility: "可視性",
                    create: {
                        title: "アプリケーションを追加",
                        displayName: "表示名",
                        displayIcon: "表示アイコン",
                        associatedApplication: "関連アプリケーション",
                        users: "ユーザー",
                        groups: "グループ",
                        descriptions: {
                            icon: "アプリケーションアイコンをアップロードします。",
                            iconInfo: "対応ファイル形式：JPEG、PNG、GIF、SVG",
                            name: "アプリケーションの固有名",
                            application: "関連アプリケーションを選びます。",
                            launchUrl: "立ち上げ用URLを指定します。",
                            visibility: "リンクの表示を選択します。",
                            users: "選択したアプリへのアクセスを許可するユーザーを選択します。ユーザーまたはグループが選択されていない場合、選択したアプリはすべてのユーザーからアクセス可能になります。",
                            groups: "選択したアプリへのアクセスを許可するグループを選択します。ユーザーまたはグループが選択されていない場合、選択したアプリはすべてのユーザーからアクセス可能になります。"
                        }
                    },
                    edit: {
                        title: "アプリケーションの編集"
                    },
                    delete: {
                        body: "このアプリケーションを削除してもよろしいですか？",
                        title: "{{name}}を削除します"
                    },
                    validate: {
                        userGroupRequired: "少なくとも1人のユーザーまたはグループが必要です",
                        tenantIdRequired: "ユーザーとグループは利用できません。まず、[アイデンティティ] > [設定] ページでユーザー ディレクトリを追加する必要があります。"
                    }
                },
                enrollEmailDialog: {
                    addEmail: "ユーザーまたはEメールアドレスを追加",
                    body: "本文",
                    customMessage: "招待メッセージ",
                    defaultContent: "こんにちは！ \n\nあなたは、Barracuda SecureEdgeユーザー接続に追加されました。 \n\nアクセスするには、次の手順に従ってください: \n\n1.{{link}}に移動します。\n2.Barracuda VPN クライアントをダウンロードしてインストールします。\n3.\"Enroll now\"をクリックしてプロセスを完了します。\n\nこれで、Windowsまたは macOSクライアントから Barracuda SecureEdgeに接続できるようになりました。",
                    defaultSubject: "Barracuda SecureEdgeに登録されました。",
                    zeroTrustSubject: "Barracuda SecureEdge Accessエージェントを登録していただくためのご招待です",
                    emailTitle: "ユーザーを登録",
                    resendEmailTitle: "登録案内を再送",
                    noHtml: "以下の特殊文字は使うことができません: <>",
                    recipients: "宛先",
                    users: "ユーザー",
                    send: "送信",
                    subject: "件名",
                    enrollMessage: "SecureEdge Accessに登録するユーザーまたはグループを選択します。ユーザーは、エージェントの最初の起動時にサインインする必要があります。この進行状況は、[アクセス] > [ユーザー] ページで追跡できます。<0>残りのライセンス数は{{count}}です。</0>",
                    sendMessage: "選択したユーザーとグループに次のメッセージが送信されます。招待を送信してもよろしいですか？",
                    successHeader_plural: "ご登録の招待状は{{count}}人のユーザーに送信中です！",
                    successHeader: "ご登録の招待状は{{count}}人のユーザーに送信中です！",
                    successMessage_plural: "ユーザーテーブルで各ユーザーの登録状況を確認することができます。",
                    successMessage: "ユーザーテーブルでこのユーザーの登録状況を確認することができます。",
                    emailTemplate: {
                        hello: "{{username}}さん！",
                        message: "Barracuda SecureEdge Accessエージェントを登録していただくためのご招待です。このエージェントでエンタープライズリソースへのアクセスの安全が確保され、お使いのデバイスはサイバー脅威から保護されます。",
                        clickStart: "セルフエンロールメントを開始するには\"Start\"をクリックしてください。",
                        start: "スタート",
                        copyLink: "または、SecureEdge Accessエージェントに下記のリンクを直接コピーしても登録することができます。",
                        exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
                    },
                    addUsers: "ユーザー追加",
                    addGroups: "グループ追加",
                    userNotificationSectionTitle: "ユーザー通知 (オプション)",
                    enrollmentEmail: {
                        label: "登録用メールアドレス",
                        enabled: "はい - エージェントダウンロードリンクを含む",
                        disabled: "いいえ - サイレントインストール"
                    },
                    groups: "グループ",
                    enroll: "登録",
                    enrollMessageOld: "SecureEdge Accessに登録するユーザーまたはグループを選択します。ユーザーは、エージェントの最初の起動時にサインインする必要があります。この進行状況は、[アクセス] > [ユーザー] ページで追跡できます。",
                    enrollMessage_plural: "SecureEdge Accessに登録するユーザーまたはグループを選択します。ユーザーは、エージェントの最初の起動時にサインインする必要があります。この進行状況は、[アクセス] > [ユーザー] ページで追跡できます。<0>残りのライセンス数は{{count}}です。</0>",
                    resolvedUsers: "選択したユーザー/グループには、残りのライセンス数よりも多くのユーザーがいます。下のメニューを使用して、今すぐ登録したくないユーザーを削除するか、戻って別のユーザー/グループを選択してください。",
                    successHeaderOld: "ご登録の招待状は'{{users}}'さん宛に送信中です！",
                    successHeaderOld_plural: "ご登録の招待状は{{count}}人のユーザーに送信中です！",
                    noEmailSuccessHeader: "{{count}}人のユーザーを登録中です！",
                    noEmailSuccessHeader_plural: "ご登録の招待状を{{count}}人のユーザーに送信しています！",
                    validate: {
                        userGroupRequired: "少なくとも1人のユーザーまたはグループが必要です。",
                        userRequired: "少なくともユーザー一名が必要です。",
                        seatLimit: "{{currentCount}}ユーザーを登録してください(あと{{maxCount}}ライセンス残っています。)"
                    },
                    reenrollMessage: "選択したユーザーに登録招待メールを再送信します。",
                    noSeats: "残りライセンスはありません。",
                    seats: "残りライセンス数は{{count}}です。",
                    seats_plural: "あと{{count}}ライセンス残っています。",
                    unSuccessHeaderAllUsersAlreadyEnrolled: "選択されたユーザーはすでに登録されています。",
                    unSuccessHeaderAllUsersAlreadyEnrolled_plural: "選択されたすべてのユーザーはすでに登録されています。",
                    alreadyEnrolledUsersUnsuccessful_emailplural: "メールは送信されません。",
                    alreadyEnrolledUsers: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。",
                    alreadyEnrolledUsers_workspace: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。 (他のアカウントかワークスペース内の{{unenrollableCount}})",
                    alreadyEnrolledUsers_email: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。このためこのユーザーにメールは送信されません。",
                    alreadyEnrolledUsers_emailworkspace: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。(他のアカウントかワークスペース内の{{unenrollableCount}}) このためメールは送信されません。",
                    alreadyEnrolledUsers_plural: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。",
                    alreadyEnrolledUsers_workspaceplural: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。(他のアカウントかワークスペース内の{{unenrollableCount}})",
                    alreadyEnrolledUsers_emailplural: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。このためこれらのユーザーにメールは送信されません。",
                    alreadyEnrolledUsers_emailworkspaceplural: "選択されたユーザーのうち {{totalCount}} はすでに登録されています。(他のアカウントかワークスペース内の{{unenrollableCount}}) このためメールは送信されません。"
                },
                remoteAccessPolicies: {
                    delete: {
                        title: "ゼロトラストアクセスポリシー {{name}} を削除します。",
                        body: "このゼロトラストアクセスポリシーを削除してもよろしいですか？"
                    },
                    order: "順番",
                    name: "名前",
                    description: "説明",
                    groupsGrantedAccess: "アクセスを許可されたグループ",
                    resources: "リソース",
                    devicePosture: "デバイスポスチャー",
                    usersGrantedAccess: "アクセスを許可されたユーザー",
                    zeroTrustAccess: "ゼロトラストアクセス",
                    policy: "ポリシー",
                    create: "ゼロトラストアクセスポリシーを作成",
                    edit: "ゼロトラストアクセスポリシーを編集",
                    success: "成功",
                    successMessage: "新しいゼロトラストアクセスポリシーが正常に作成されました。",
                    createButton: "ポリシーの追加",
                    deviceAttributes: "デバイスポスチャー",
                    resourcesSection: {
                        title: "リソース",
                        internalResource: "内部リソース (カスタム アプリ))",
                        publicResource: "パブリックエンドポイント(SaaS)",
                        resourceTypeLabel: "リソース タイプ",
                        resourceTypeDescription: "エントリーポイントで内部からのみアクセス可能なリソースの場合は \"インターナル\" を選択、パブリックにアクセス可能なリソースの場合は \"パブリック\" を選択します。",
                        resourcesLabel: "リソース"
                    },
                    applications: "アプリケーション",
                    accessCriteriaSection: "アクセスの基準",
                    users: "ユーザー",
                    groups: "グループ",
                    securityInspection: "セキュリティ検査",
                    screenLock: "画面ロックが有効",
                    firewall: "ファイアウォールが有効",
                    jailbroken: "ジェイルブレイクされたデバイスをブロック",
                    encryption: "ディスク暗号化を強制する",
                    appUpdates: "Barracuda SecureEdgeエージェントの更新",
                    minimumVersion: "最小バージョン",
                    platform: "プラットフォーム",
                    osUpdates: "OSの更新",
                    antivirus: "ウィルス対策が有効",
                    descriptions: {
                        name: "ポリシーの一意の名前",
                        description: "ポリシーの概略",
                        resourceType: "カスタムアプリケーションまたは外部サードパーティーアプリケーションのいずれかを設定します。",
                        applications: "このポリシーの対象となるアプリケーション。",
                        users: "選択したアプリへのアクセスを許可するユーザーを選択します。ユーザーまたはグループが選択されていない場合、選択したアプリはすべてのユーザーからアクセス可能になります。",
                        groups: "選択したアプリへのアクセスを許可するグループを選択します。ユーザーまたはグループが選択されていない場合、選択したアプリはすべてのユーザーからアクセス可能になります。",
                        securityPosture: "設定済みのデバイスポスチャを強制するか、違反をログに記録するのみかを選択してください。",
                        securityInspection: "有効にすると、選択したアプリケーションへのトラフィックはトンネル経由でルーティングされ、より詳細なセキュリティ検査が可能になり、エントリーポイントのポリシーが適用されます。無効にすると、トラフィックは通常どおりインターネット経由でルーティングされます。",
                        screenLock: "ユーザーにデバイスの画面ロックを設定するよう要求します。対応プラットフォーム: Android、iOS",
                        firewall: "ユーザーにデバイスのファイアウォールを有効にして構成するよう要求します。対応プラットフォーム: macOS、Windows",
                        jailbroken: "ジェイルブレイクされたデバイスがリソースにアクセスするのを防ぎます。対応プラットフォーム: Android、iOS",
                        encryption: "ユーザーにディスク暗号化を設定するよう要求します。対応プラットフォーム: Android、iOS、mac OS、Windows",
                        appUpdates: "ユーザーに Barracuda SecureEdge アクセスエージェントを最新バージョンに更新するよう要求します。対応プラットフォーム: Android、iOS、 Linux、mac OS、Windows",
                        osUpdates: "デバイスの OSを設定された最小バージョンに更新するようユーザに要求します。対応プラットフォーム: Android、iOS、mac OS、Windows",
                        antivirus: "ユーザーのデバイスにアンチウィルスサービスを設定するように要求します。プラットフォーム: Windows"
                    },
                    validatePlatform: "OSは選択済みです。",
                    validateDeviceCriteria: "少なくとも1つのセキュリティ機能が有効になっていなければなりません。",
                    lastMileOptimization: "ラストマイル最適化",
                    noData: {
                        iconHeader: "ゼロトラストアクセスポリシーの追加開始してください！",
                        labelButton: "ゼロトラストアクセスポリシーを追加",
                        textHeader: "ゼロトラストアクセスポリシーとは何ですか？",
                        text: "このインターフェースではユーザーのデバイスがネットワークにアクセスするために準拠する必要がある要件を定義することができます。<0 /> たとえば、デバイスに最新の OS アップデートを適用し、オンボード セキュリティを有効にすることを要求したり、ジェイルブレイクされたデバイスをブロックしたりすることもできます。"
                    }
                },
                pointsOfEntry: {
                    title: "エントリーポイント",
                    table: {
                        name: "名前",
                        type: "タイプ",
                        size: "サイズ",
                        location: "場所",
                        publicIp: "パブリックIP",
                        noData: "エントリーポイントが設定されていません。ゼロトラストアクセスを使用するには、少なくとも 1つのエントリーポイントを設定する必要があります。"
                    },
                    createButton: "エントリーポイントの追加",
                    create: {
                        title: "エントリーポイントの追加",
                        configurationType: "タイプ",
                        site: "サイト",
                        gateway: "エッジサービス",
                        firewall: "ファイアウォール",
                        ipAddress: "パブリックIP",
                        sites: "サイト",
                        gateways: "エッジサービス",
                        firewalls: "ファイアウォール",
                        descriptions: {
                            sites: "エントリーポイントとして追加するサイト",
                            gateways: "エントリーポイントとして追加するエッジサービス",
                            firewalls: "エントリーポイントとして追加するファイアウォール"
                        },
                        validateSelectOne: "少なくとも 1つのファイアウォール、サイト、またはエッジサービスを選択する必要があります。"
                    },
                    edit: {
                        title: "エントリーポイントの編集",
                        name: "名前",
                        ipAddress: "パブリックIP",
                        descriptions: {
                            ipAddress: "このファイアウォールにアクセスできるパブリックIPアドレス"
                        }
                    },
                    delete: {
                        title: "エントリーポイント {{name}}を削除",
                        body: "このエントリーポイントを削除してもよろしいですか？"
                    },
                    noData: {
                        iconHeader: "まずエントリーポイントを追加してください!",
                        labelButton: "エントリーポイントの追加",
                        textHeader: "エントリーポイントとは何ですか？",
                        text: "エントリーポイントは、SecureEdge Access Agentの接続ポイントとして機能します。<0 />エージェントは、最適なサービス品質を実現するために、利用可能な最も近いエントリーポイントを自動的に選択します。"
                    }
                },
                zeroTrustSettings: {
                    agentWebFiltering: "エージェントウェブフィルタリング",
                    dnsSuffix: "DNS サフィックス",
                    downloadCertificateHelpText: "このルート証明書をダウンロードしてすべてのクライアントに展開します CloudGen Firewallクライアントからサイトへのセットアップと SecureEdge Access Agentとの並行操作を確実にするためです。",
                    downloadCertificateLabel: "証明書のダウンロード",
                    networkSectionTitle: "アクセスエージェント ネットワーク構成",
                    clientNetworkDescription: "クライアントネットワークは、SecureEdge Access Agentト経由で接続するクライアントに IPを割り当てるために使用されます。これはプールに分割され、各エントリーポイントに配布されます。",
                    manualConfigurationEnabled: "手動設定",
                    clientNetworkRange: "クライアント ネットワーク",
                    clientNetworkSize: "このクライアントネットワークはエントリーポイントごとに少なくとも{{connections}}つのクライアント接続を提供します。",
                    clientNetworkPoolSize: "プールビットマスク",
                    validatePoolSize: "クライアント ネットワークより小さくする必要があります。",
                    enforce: "強制",
                    disable: "無効",
                    globalZtnaSettings: "アクセス設定"
                },
                ztnaUsers: {
                    dashboardTabTitle: "ダッシュボード",
                    settingsTabTitle: "設定",
                    userNotFound: "ユーザーが見つかりません"
                }
            },
            securityAndAccess: {
                pageTitle: "セキュリティ ポリシー",
                apps: "アプリとリソース",
                network: "ネットワークACL",
                gateway: "エッジサービスACL",
                site: "サイトACL",
                sdwan: "SD-WAN",
                categories: "アプリケーションカテゴリー",
                explicit: "オーバーライドカテゴリー",
                security: "セキュリティ",
                malware: "Advanced Threat Protection",
                ssl: "SSLインスペクション",
                ips: "IPS",
                ingressNatRules: "イングレスNAT",
                urlFilter: "ポリシー",
                webFilter: "ウェブフィルター",
                webFilterSettings: "設定",
                webMonitorPolicies: "ウェブ モニタリング",
                settings: "設定",
                customResponse: "レスポンス メッセージ"
            },
            appliances: {
                dashboard: {
                    deployment: {
                        status: {
                            title: "デプロイメントステータス",
                            status: "ステータス",
                            action: "アクション",
                            message: "メッセージ",
                            registered: "登録済み",
                            lastSeen: "最終確認",
                            completed: "完了",
                            localIp: "ローカルIP",
                            remoteIp: "パブリックIP"
                        },
                        events: {
                            title: "デプロイメントイベント",
                            state: "状況",
                            status: "ステータス",
                            created: "日付",
                            siteDeploying: "デプロイメント中"
                        },
                        location: {
                            title: "デプロイメントの場所"
                        }
                    },
                    details: {
                        servicesCardTitle: "サービス",
                        interfaces: {
                            title: "インターフェース",
                            status: "ステータス",
                            name: "名前",
                            speed: "スピード",
                            duplex: "デュプレックス",
                            errors: "エラー",
                            state: {
                                ok: "OK",
                                warning: "警告",
                                error: "エラー",
                                unknown: "不明",
                                loading: " "
                            }
                        },
                        boxResources: {
                            title: "リソース",
                            cpuLoad: "CPU",
                            cpuLoadError: "CPUにエラーが検出されました。デバイスを再起動してください。このエラーが再発する場合は、Barracuda Supportにご連絡ください。",
                            memory: "メモリー",
                            diskInformation: "ディスク",
                            diskInformationError: "ディスクパーティションの1つでエラーが検出されました。デバイスを再起動してください。このエラーが再発する場合は、Barracuda Supportにご連絡ください。"
                        },
                        vpnTunnels: {
                            title: "VPNトンネル",
                            instanceId: "インスタンス シリアル",
                            status: "ステータス",
                            name: "名前",
                            peer: "ピア",
                            local: "ローカル",
                            type: "タイプ",
                            state: {
                                UP: "アップ",
                                DOWN: "ダウン",
                                INACTIVE: "非アクティブ",
                                FAIL: "ダウン - 失敗",
                                STANDBY: "ダウン - スタンバイ",
                                "DOWN (PASSIVE)": "ダウン - パッシブ",
                                "DOWN (FAIL)": "ダウン - 失敗",
                                "DOWN (STANDBY)": "ダウン - スタンバイ",
                                "DOWN (DISABLED)": "無効",
                                PENDING: "保留中"
                            }
                        }
                    },
                    firmware: {
                        details: {
                            tabTitle: "ファームウェア",
                            version: "バージョン",
                            hotfixes: "適用済ホットフィックス",
                            window: "次回更新",
                            nextUpdate: "{{from}}から{{to}}の間"
                        },
                        hotfixes: {
                            tabTitle: "ホットフィックス",
                            status: "ステータス",
                            name: "名前"
                        },
                        updates: {
                            tabTitle: "設定更新日時",
                            lastUpdate: "前回更新日時",
                            lastSeen: "前回確認日時",
                            remoteIp: "リモートIP"
                        },
                        state: {
                            error: "エラー",
                            ok: "OK",
                            unknown: "不明",
                            warning: "保留中",
                            loading: " "
                        }
                    },
                    siteStatus: {
                        tabTitle: "接続されたサイト"
                    },
                    iotStatus: {
                        tabTitle: "接続された IoT"
                    },
                    summary: {
                        siteStatus: {
                            title: "ステータス",
                            model: "モデル",
                            serials: "シリアル",
                            serial: "シリアル",
                            release: "リリース"
                        },
                        licenses: {
                            title: "ライセンス",
                            type: "タイプ",
                            state: "状況",
                            status: "ステータス",
                            expirationDate: "満了日",
                            serial: "シリアル",
                            noData: "N/A"
                        },
                        gateway: {
                            tabTitle: "エッジサービス",
                            noGateway: "このサイトにはエッジサービスが設定されていません。",
                            name: "名前",
                            region: "リージョン",
                            hubName: "ハブ",
                            vwan: "Cloud VWAN",
                            size: "サイズ",
                            publicIP: "パブリックIP",
                            publicIPs: "パブリックIP",
                            serial: "シリアル",
                            instanceNotFound: "インスタンスが見つかりません"
                        },
                        status: {
                            tabTitle: "ステータス",
                            hub: "ハブ",
                            region: "リージョン",
                            vwan: "Cloud VWAN",
                            connectedSites: "接続サイト"
                        }
                    },
                    tunnelLocations: {
                        title: "マップ",
                        gateways: {
                            label: "エッジサービス",
                            fields: {
                                name: "名前",
                                sites: "接続されたサイト",
                                iot: "接続された IoT",
                                vwan: "ワークスペース",
                                hub: "ハブ",
                                region: "リージョン",
                                size: "サイズ"
                            }
                        },
                        iot: {
                            label: "IoT"
                        },
                        sites: {
                            label: "サイト",
                            fields: {
                                name: "名前",
                                location: "場所",
                                gateway: "Eエッジサービス",
                                providers: "プロバイダー数"
                            }
                        },
                        standaloneSites: {
                            title: "マップ",
                            label: "スタンドアロンサイト"
                        }
                    }
                },
                dialog: {
                    createType: {
                        sectionTitle: "エッジサービスの種類",
                        new: "新しいエッジサービスの作成",
                        promote: "既存のサイトをエッジサービスに昇格"
                    },
                    siteSelection: {
                        sectionTitle: "サイトの選択",
                        mainMessage: "プライベートエッジサービスに昇格するサイトを選びます。",
                        secondaryMessage: "注：昇格すると、関連付けられているすべてのポリシー (ACL、URLフィルターなど) が削除されます。",
                        haWarning: "このサイトは HAではありません。プライベート エッジ サービスには HAを使用することをお勧めします。",
                        site: "サイト"
                    },
                    basic: {
                        gateway: "エッジサービス",
                        upstream_gateway: "アップストリーム エッジサービス",
                        noGateway: "(なし)",
                        name: "名前",
                        sectionTitle: "基本情報",
                        tabTitle: "基本",
                        rootPassword: "rootパスワード",
                        rootPasswordConfirmation: "rootパスワード確認入力",
                        descriptions: {
                            gateway_site: "このサイトが接続する Barracuda SecureEdgeエッジサービス",
                            gateway_gateway: "このエッジサービスが接続する Barracuda SecureEdgeエッジサービスです。",
                            name_site: "サイトの固有名。",
                            name_gateway: "エッジサービスの固有名。",
                            rootPassword_site: "サイトの rootユーザーパスワード。",
                            rootPassword_gateway: "エッジサービスの rootユーザーパスワード。",
                            upstream_gateway: "このエッジ サービスが接続する Barracuda SecureEdgeアップストリーム エッジサービスです。"
                        }
                    },
                    serials: {
                        gatewayMessage: "設定するエッジ サービスのアプライアンスを選択します。2つのアプライアンスを選択して、高可用性クラスタを作成することをお勧めします。",
                        TVTSiteMessage: "設定するサイト アプライアンスを選択します。1つのアプライアンスを選択するか、高可用性クラスタを設定する場合は 2つのアプライアンスを選択できます。",
                        SCSiteMessage: "設定する Secure Connectorアプライアンスを選択します。",
                        haMessage: "<1>注:</1> HAクラスタのアプライアンスは両方とも同じモデルで、同じファームウェア バージョンである必要があります。",
                        sectionTitle_site: "サイトアプライアンス",
                        sectionTitle_gateway: "エッジサービスのデバイス",
                        addAppliance: "シリアル番号とリンクコード/ライセンストークンを使って不足しているアプライアンスを追加します。",
                        linkingCode: "コード/トークン",
                        table: {
                            serial: "シリアル",
                            model: "モデル",
                            location: "場所",
                            orderId: "注文ID",
                            orderDate: "注文日"
                        }
                    },
                    wans: {
                        sectionTitle: "WAN",
                        message: "お使いのデバイスには WANと LANに割当可能な合計{{portsAvailable}}個のポートがあります。",
                        message2_site: "このサイトにいくつの WAN接続を設定しますか。",
                        message2_gateway: "このエッジサービスではいくつの WAN接続を設定しますか。",
                        size: "WANの下図",
                        setup: "WANリンク{{step}}を設定",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        type: "タイプ",
                        providerPinning: "プロバイダーのピンニング",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        pin: "{{serial}}用 SIMの PIN",
                        invalidPinFormat: "SIMの Pinは 4つの数字です。",
                        SCmessage: "デバイスには設定すべき単一の WANポートがあります。",
                        SCmessage_failover: "デバイスには 2つの WAN(プライマリとフェイルオーバー)までが接続可能です。",
                        SCconfigureFailover: "このサイトのフェイルオーバーを設定しますか？。",
                        failover: "WAN フェイルオーバー",
                        setupSC: "プライマリ WANリンクの設定",
                        setupSC_0: "プライマリ WANリンクの設定",
                        setupSC_1: "フェイルオーバー WANリンクの設定",
                        descriptions: {
                            name: "この WANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプション仮想 LAN ID （仮想 LAN サポートのあるスイッチに接続する場合に使用されます）。",
                            type: "このリンク用に使用するネットワークタイプ。",
                            providerPinning: "このリンクのピンニング先プロバイダーグループ。プロバイダーグループはトラフィックフローと動作を定義するために SD-WANポリシーで使用されます。",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "このリンクのネットワーク CIDRネットマスクサフィックス。",
                            gateway: "このリンクに関連付けられているゲートウェイ IPアドレス",
                            pin: "このデバイスにインストールされている SIMカード用の個人識別番号"
                        },
                        bridgeType: {
                            name: "ブリッジ",
                            address: "IPアドレス",
                            gateway: "ゲートウェイ",
                            lanPorts: "LANポート",
                            netmask: "ネットマスク",
                            wanPort: "WANポート",
                            descriptions: {
                                address: "ポートに割り当てる IPアドレス",
                                gateway: "このリンクに関連付けられたゲートウェイIPアドレス",
                                lanPorts: "使用するLANポート",
                                netmask: "ネットワークCIDRネットマスクのサフィックス",
                                wanPort: "使用するWANポート"
                            }
                        },
                        pppoeType: {
                            name: "ユーザー名",
                            password: "パスワード",
                            description: {
                                name: "このPPPoEの固有名。",
                                password: "一意のパスワード"
                            }
                        },
                        validation: {
                            mode: "エッジ サービスが選択されている場合はブリッジにできません。"
                        }
                    },
                    lans: {
                        sectionTitle: "LAN",
                        message: "お使いのデバイスには、WANと LANに割当可能な合計{{portsAvailable}}ポートがあり、WANに{{wans}}が割り当てられています。",
                        message2_site: "このサイトにいくつの LAN接続を設定しますか。",
                        message2_gateway: "このエッジサービスではいくつのLAN接続を設定しますか。",
                        size: "LAN本数",
                        setup: "LANリンク{{step}}を設定",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        dhcpEnable: "DHCP サーバー",
                        dhcpDescription: "説明",
                        dhcpFirstIp: "先頭の IPアドレス",
                        dhcpLastIp: "最後の IPアドレス",
                        dhcpDomain: "DHCPドメイン",
                        descriptions: {
                            dhcpEnable: "この LANリンクで DHCPサーバーを有効にします",
                            name: "この LANリンクのための固有名",
                            port: "このリンクに使用するデバイスのポート",
                            virtualLanId: "ネットワークのオプションの仮想 LAN ID (仮想 LAN をサポートするスイッチに接続する場合に使用)",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "作成したネットワークに使用するネットワーク CIDRネットマスクサフィック",
                            dhcpDescription: "この DHCPネットワーク用のカスタム詳細",
                            dhcpFirstIp: "この DHCPネットワーク内で割当可能な先頭の IPアドレス",
                            dhcpLastIp: "この DHCPネットワーク内で割当可能な最後の IPアドレス",
                            dhcpDomain: "この DHCPServiceのドメイン名",
                            wifi: {
                                ssid: "WiFiネットワークの SSID",
                                securityMode: "使用したい Wi-Fiセキュリティモード",
                                passphrase: "ネットワーク用パスフレーズ"
                            },
                            type: "インターフェース タイプ。デフォルトの LAN インターフェースまたはブリッジとして設定できます。"
                        },
                        type: "タイプ",
                        bridgeType: {
                            default: "シングル ポート",
                            name: "ブリッジ",
                            address: "IPアドレス",
                            lanPorts: "ポート",
                            netmask: "ネットマスク",
                            description: {
                                address: "ポートに割り当てる IPアドレス",
                                lanPorts: "使用するLANポート",
                                netmask: "ネットワークCIDRネットマスクのサフィックス"
                            }
                        },
                        dhcpRelayType: "DHCP",
                        validation: {
                            dhcpRelayType: "SecureEdgeが設定されているときは有効にすることができません。"
                        }
                    },
                    advanced: {
                        sectionTitle: "詳細設定",
                        updateWindowTZ: "更新ウィンドウのタイムゾーン",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS",
                        descriptions: {
                            updateWindowTZ_site: "このサイトのタイムゾーン。このサイトの更新ウィンドウを決定するために使用されます。",
                            updateWindowTZ_gateway: "エッジサービスのタイムゾーン。このサイトの更新ウィンドウを決定するために使用されます。",
                            primaryDns: "プライマリ DNSアドレスを手動で設定してください。",
                            secondaryDns: "セカンダリ DNSアドレスを手動で設定してください。"
                        }
                    },
                    submit: {
                        message_site: "サイトの詳細を確認して「保存」をクリックします。",
                        message_gateway: "エッジサービスの詳細を確認して「保存」をクリックしてください。",
                        name: "名前",
                        model: "モデル",
                        description: "説明",
                        serial: "シリアル",
                        gateway: "エッジサービス",
                        noGateway: "(なし)",
                        upstream_gateway: "アップリンク エッジサービス",
                        timezone: "タイムゾーン",
                        status: "接続ステータス",
                        peeringAddress: "ピアリングアドレス",
                        wan: "WAN",
                        lan: "LAN",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS",
                        haWarning: "このサイトはHAではなく、HAはプライベートエッジサービス用に使用することをお勧めします。"
                    },
                    result: {
                        success_site: "サイトは正常に作成されました。",
                        success_gateway: "エッジサービスは正常に作成されました。",
                        connectPort: "デバイスの自動構成を可能にするために、下記デバイスのポート<0>{{port}}</0>を DHCP対応のインターネット接続に接続していることを確認してください。別のポートを使用したい場合はデバイスで直接設定することができます。",
                        connectPort_plural: "デバイスの自動構成を可能にするために、下記のデバイスのポート<0>{{port}}</0>を DHCP対応のインターネット接続に接続していることを確認してください。別のポートを使用したい場合はデバイスで直接設定することができます。",
                        HAMessage: "両方のデバイスのポート<0>P1</0> をネットワーク ケーブルで直結します。HA 同期が自動的に開始されます。",
                        campus: "詳細は<0>Barracuda Campus</0>をご覧ください",
                        serial: "シリアル: {{serial}}",
                        nonstandardPort: "警告: この設定は標準以外のポートを使用します: <0>{{ports}}</0>。これが仮想デバイスで有効なことを確認してください。",
                        nonstandardPort_plural: "警告: この設定は標準以外のポートを使用します: <0>{{ports}}</0>。これらが仮想デバイスで有効なことを確認してください。"
                    },
                    promoteResult: {
                        primaryMessage: "サイトはエッジサービスに正常に昇格しました。",
                        secondaryMessage: "これで、新規または既存のサイトの接続を開始できます。"
                    },
                    steps: {
                        result: "完了",
                        submit: "要約"
                    },
                    types: {
                        dynamic: "動的 (DHCP)",
                        configure_on_branch: "ブランチで設定済み"
                    },
                    validation: {
                        duplicateNetwork: "このポート/仮想 LAN IDと合致するネットワークはすでに設定されています",
                        duplicateWwan: "1つの WWAN のみ設定可能です。",
                        duplicateExpressRoute: "1つのエクスプレス ルートのみ設定できます。",
                        duplicateName: "他の接続で名前がすでに使用されています。",
                        secondaryDns: "プライマリ DNSがない場合は設定しないでください。",
                        availableAsn: "この ASN値は利用できません。",
                        publicIp: "IPアドレスはパブリック範囲になければなりません。",
                        noLans: "少なくとも 1つの LANを有することが条件です。",
                        exceededPPoE: "最大4つの WAN PPPoEが設定できます。",
                        oneInlineBridge: "1つの WANブリッジのみ設定できます。"
                    }
                },
                settings: {
                    tabTitle: "設定",
                    basic: {
                        tabTitle: "基本情報",
                        name: "名前",
                        model: "モデル",
                        gateway: "エッジサービス",
                        serials: "シリアル",
                        descriptions: {
                            gateway_site: "このサイトが接続する Barracuda SecureEdgeエッジサービス",
                            gateway_gateway: "このエッジサービスが接続する Barracuda SecureEdgeエッジサービス",
                            name_site: "サイトの固有名",
                            name_gateway: "エッジサービ固有名",
                            model_site: "このサイト用のデバイスモデル",
                            model_gateway: "このエッジサービ用のデバイスモデル",
                            serials_site: "このサイト用のデバイスシリアル",
                            serials_gateway: "このエッジサービ用のデバイスシリアル"
                        },
                        serialEdit: {
                            table: {
                                status: "ステータス",
                                serial: "シリアル",
                                add: "二番目を追加"
                            },
                            addTitle: "シリアルを追加",
                            editTitle: "シリアルをスワップ",
                            deleteTitle: "シリアルを削除",
                            edit_site: "このサイトに接続する交換アプライアンスのシリアルを以下から選択します。アプライアンスは、同じモデルの別のアプライアンスとのみ交換できます。",
                            edit_gateway: "このエッジサービスに接続する交換用アプライアンスのシリアル番号を以下から選択します。アプライアンスは、同じモデルの別のアプライアンスとのみ交換できます。",
                            edit: "このサイトに接続する交換用アプライアンスのシリアル番号を以下から選択します。アプライアンスは、同じモデルの別のアプライアンスとのみ交換できます。",
                            add_site: "サイトに 2台目のアプライアンスを接続すると、高可用性機能がオンになります。アプライアンスのシリアル番号を以下から選択してください。",
                            add_gateway: "エッジサービスに 2 台目のアプライアンスを接続すると、高可用性機能がオンになります。以下からアプライアンスのシリアルを選択してください。",
                            add: "サイトに 2 台目のアプライアンスを接続すると、高可用性機能がオンになります。以下からアプライアンスのシリアルを選択してください。",
                            delete1_site: "このサイトからシリアル {{serial}} を削除してもよろしいですか？",
                            delete1_gateway: "このエッジサービスからシリアル {{serial}} を削除してもよろしいですか？",
                            delete1: "このサイトからシリアル {{serial}} を削除してもよろしいですか？",
                            delete2: "これにより、高可用性機能が削除され、関連するアプライアンスが無効になります。",
                            save: "変更は保存後に有効になります。",
                            saveDelete: "保存後、デバイスを切断できます。",
                            saveAdd: "保存したら、新しいアプライアンスを接続できます。最初にアプライアンスのポート<0>P4</0>を DHCP 対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                            saveSCAdd: "保存したら、新しいアプライアンスを接続し、WANポートを DHCP対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。",
                            saveEdit: "保存したら、古いアプライアンスを切断することができます。次に、新しいアプライアンスを接続し、最初にアプライアンスのポート<0>P4</0>を DHCP対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                            saveSCEdit: "保存したら、古いアプライアンスを切断することができます。次に、新しいアプライアンスを接続し、WAN ポートを DHCP対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。",
                            saveHA: "さらに、新しいアプライアンスと既存のアプライアンスの両方のポート<0>P1</0>をネットワーク ケーブルで直接接続します。HA 同期が自動的に開始されます。",
                            saveEditSecondary: "古いアプライアンスは HA用に構成されています。再度使用するには、再イメージ化する必要があります。",
                            saveDeleteSecondary: "このアプライアンスは HA 用に構成されています。再度使用するには、再イメージ化する必要があります。",
                            saveDeletePrimary_site: "このサイトに接続されている他のアプライアンスは HA用に構成されています。保存後に再イメージ化して、非 HAアプライアンスとして再構成する必要があります。このプロセスが完了するまで、サイトでダウンタイムが発生します。",
                            saveDeletePrimary_gateway: "このエッジサービスに接続されている他のアプライアンスは HA用に構成されています。保存後に再イメージ化して、非 HAアプライアンスとして再構成する必要があります。このプロセスが完了するまで、エッジサービスでダウンタイムが発生します。",
                            saveDeletePrimary: "このサイトに接続されている他のアプライアンスは HA用に構成されています。保存後に再イメージ化して、非 HAアプライアンスとして再構成する必要があります。このプロセスが完了するまで、サイトでダウンタイムが発生します。そのプロセスが完了するまで、サイトにアクセスできません。"
                        }
                    },
                    wan: {
                        tabTitle: "WAN",
                        addTitle: "新規 WANインターフェースを追加",
                        addButtonText: "WANインターフェースを追加",
                        addSCButtonText: "WANフォールバックを追加",
                        editTitle: "WANインターフェースを編集",
                        deleteTitle: "WANインターフェースを削除",
                        deleteMessage: "この WANインターフェイスを削除してもよろしいですか？<0/>この WAN に関連付けられている接続とポリシー (IPsec トンネルなど) は自動的に削除されます。<0/>削除は保存時に反映されます。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        type: "タイプ",
                        providerPinning: "プロバイダーの固定",
                        fallback: "フォールバック",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        pin: "{{serial}}の SIM PIN",
                        scPin: "SIM PIN",
                        invalidPinFormat: "SIMの PINは 4桁の数字である必要があります。",
                        portWarning: "上記のグレーアウトされた行は選択されたポートがデバイスで有効でないことを示します。",
                        failover: "HAフェイルオーバー",
                        network: "ネットワーク",
                        doublePort: "ポート",
                        doubleVirtualLanId: "仮想 LAN ID",
                        doubleAddress: "IPアドレス",
                        doubleNetmask: "ネットマスク",
                        virtualTransferNetwork: "仮想転送ネットワーク",
                        asn: "エクスプレスルート ASN",
                        expressRoute: "エクスプレスルート",
                        primary: "プライマリ",
                        secondary: "セカンダリ",
                        validatePublicIp: "ユーザー接続用のパブリック IP である必要があります。",
                        validateOnePort: "動的/静的 WAN ポート 1つと WWAN 1つだけを構成できます。",
                        descriptions: {
                            name: "この WANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプションの仮想 LAN ID (仮想 LAN をサポートするスイッチに接続する場合に使用)",
                            type: "このリンク用に使用するネットワークタイプ",
                            providerPinning: "このリンクの固定先プロバイダーグループ。プロバイダーグループはトラフィックフローと動作を定義するために SD-WAN ポリシーで使用されます。",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "このリンクのネットワーク CIDR ネットマスク サフィックス",
                            gateway: "このリンクに関連付けられているゲートウェイ IPアドレス",
                            pin: "このデバイスにインストールされている SIMカード用の個人識別番号。",
                            failover: "有効にすると、ポートはモニターされ、接続が失われた場合に HAフェイルオーバーが実行されます。HAが有効なときのみ利用可能です。",
                            doublePort: "プライマリリンクとセカンダリリンク用に使用されるデバイスのポート",
                            doubleVirtualLanId: "プライマリ リンクとセカンダリ リンクのオプションの仮想 LAN ID (仮想 LAN をサポートするスイッチに接続する場合に使用)",
                            doubleAddress: "これらは、各接続に対して Azureで構成されている範囲の最初の使用可能な IPである必要があります (たとえば、Azure で 172.16.0.4/30 が構成されている場合、入力する IP アドレスは 172.16.0.5 です)。",
                            doubleNetmask: "プライマリリンクとセカンダリリンク用ネットワーク CIDR ネットマスク サフィックス",
                            asn: "このリンク用に自動生成された BGP ASN。これで SecureEdgeに管理される他のASNと競合しません。",
                            virtualTransferNetwork: "仮想転送インターフェース用に IPを予約するために使用されるネットワーク",
                            healthCheck: {
                                enabled: "有効にすると、インターフェースがその健全性を定期的に点検します。",
                                mode: "使用するヘルスチェック モード",
                                targets: "ICMP ヘルスチェックの対象となる IP アドレス"
                            },
                            userNamePassword: "256 文字未満にする必要があります。",
                            mtu: "1492 (含む)個までとします。",
                            additionalAddresses: "ポートに割当てる追加のIPアドレス"
                        },
                        bridge: "ブリッジ",
                        advanced: "詳細設定",
                        basic: "基本",
                        userName: "ユーザー名",
                        password: "パスワード",
                        mtu: "MTUサイズ",
                        healthCheck: {
                            enabled: "ヘルスチェックを有効化",
                            mode: "ヘルスチェック",
                            targets: "ヘルスチェックターゲット"
                        },
                        warning: "WANタイプを変更すると関連している IPSecトンネルまたはイングレス NATのルールが削除されます。",
                        additionalAddresses: "追加の IPアドレス",
                        addressWarning: "警告：削除した追加の WAN IPアドレスが 1つの IPSecトンネルまたはイングレス NATのルールで使用中であった場合、これらの変更を保存すると関連付けられているルールが削除されることになります。"
                    },
                    lan: {
                        tabTitle: "LAN",
                        sectionTitle: "LANインターフェース",
                        addTitle: "新規 LANインターフェースを追加",
                        addButtonText: "LANインターフェースを追加",
                        editTitle: "LANインターフェースを編集",
                        deleteTitle: "LANインターフェースを削除",
                        deleteMessage1: "この LAN インターフェイスを削除してもよろしいですか？ 削除すると次のようになります:",
                        deleteMessage2: "一致する DHCP構成と予約がすべて削除されます。",
                        deleteMessage3: "ここの LAN インターフェイスを使用するセキュリティ ルールがすべて更新または削除されます。",
                        deleteMessage4: "削除は保存時に反映されます。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        dhcpEnable: "DHCP サーバー",
                        dhcpFirst: "先頭の IPアドレス",
                        dhcpLast: "最後の IPアドレス",
                        dhcpDomain: "DHCPドメイン",
                        dhcpRange: "DHCP範囲",
                        portWarning: "上記の灰色の行は、選択したポートがアプライアンスで有効になっていないことを示します。",
                        failover: "HAフェイルオーバー",
                        descriptions: {
                            name: "この LANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプション仮想 LAN ID （仮想 LAN サポートのあるスイッチに接続する場合に使用されます）。",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "作成したネットワークに使用するネットワーク CIDRネットマスク サフィックス",
                            dhcpEnable: "このリンクのデバイスで DHCPサーバーを有効にします。",
                            dhcpFirst: "DHCP割り当てに使用できる最初の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                            dhcpLast: "DHCP 割り当てに使用できる最後の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                            dhcpDomain: "DHCP サーバーに割り当てるドメイン名",
                            failover: "有効にすると、ポートが監視され、接続が失われた場合、HAフェイルオーバーが実行されます。HA が有効になっている場合にのみ使用できます。"
                        },
                        wifi: {
                            validateSsid: "英数字または文字「-」のみを含める必要があります。"
                        }
                    },
                    route: {
                        tabTitle: "ルート",
                        sectionTitle: "スタティックルート",
                        addTitle: "新規スタティックルートを追加",
                        addButtonText: "スタティックルートを追加",
                        editTitle: "スタティックルートを編集",
                        deleteTitle: "スタティックルートを削除",
                        deleteMessage: "このスタティックルートを削除してもよろしいですか？ 削除は保存時に反映されます。",
                        name: "名前",
                        address: "ターゲットネットワーク",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        descriptions: {
                            name: "このルートの固有名",
                            address: "このルートのターゲットネットワークアドレス",
                            netmask: "ターゲットネットワークの CIDRネットマスク サフィックス",
                            gateway: "このルートに使用するゲートウェイ"
                        }
                    },
                    dhcp: {
                        tabTitle: "DHCP",
                        networksSectionTitle: "DHCPネットワーク",
                        leasesSectionTitle: "DHCPリース",
                        reservationsSectionTitle: "DHCP予約",
                        dhcpEnable: "DHCPサービスを有効にする",
                        addNetworkTitle: "新規 DHCPネットワークを追加",
                        addNetworkButtonText: "ネットワークを追加",
                        editNetworkTitle: "DHCPネットワークを編集",
                        deleteNetworkTitle: "DHCPネットワークを削除",
                        deleteNetworkMessage: "この DHCPネットワークを削除してもよろしいですか？ 削除は保存時に反映されます。",
                        networks: {
                            networkSectionTitle: "ネットワーク",
                            basicSectionTitle: "基本",
                            basicTabTitle: "基本",
                            advancedSectionTitle: "詳細設定",
                            advancedTabTitle: "詳細設定",
                            enable: "DHCPステータス",
                            port: "LAN名",
                            description: "説明",
                            firstIp: "先頭の IPアドレス",
                            lastIp: "最後の IPアドレス",
                            domain: "ドメイン名",
                            dnsServers: "DNSサーバー",
                            bootFileName: "ブートファイル名",
                            defaultLeaseTime: "デフォルトのリース時間",
                            maxLeaseTime: "最大リース時間",
                            winsServers: "WINSサーバー",
                            tftpHostname: "TFTP ホスト名",
                            tftpIpAddress: "TFTP IPアドレス",
                            vendorOptions: "ベンダーオプション",
                            validateMaxLeaseTime: "デフォルトのリース時間より短くすることはできません。",
                            dhcpRelayType: "DHCP",
                            disabled: "無効",
                            dhcpRelay: "DHCPリレー",
                            dhcpService: "DHCPサービス"
                        },
                        blockUnknownMac: "既知の MACアドレスのみ許可",
                        addReservationTitle: "新規 DHCP予約を追加",
                        addReservationButtonText: "予約を追加",
                        editReservationTitle: "DHCP予約を編集",
                        deleteReservationTitle: "DHCP予約を削除",
                        deleteReservationMessage: "このDHCP予約を削除してもよろしいですか？ 削除は保存時に反映されます。",
                        leases: {
                            reservationSectionTitle: "リース",
                            port: "LAN 名",
                            ip: "IPアドレス",
                            mac: "MACアドレス",
                            reserve: "予約IP",
                            noLeases: "現在有効なリースはありません。新規リースが表示されるまで最大 2分かかる場合があります。"
                        },
                        reservations: {
                            reservationSectionTitle: "予約",
                            name: "名前",
                            description: "説明",
                            port: "LAN 名",
                            ip: "IPアドレス",
                            mac: "MACアドレス",
                            clientId: "クライアントID",
                            hostname: "ホスト名",
                            validateMac: "有効なMACアドレスである必要があります。",
                            validateHostname: "英数字、「-」または「_」の文字のみを含める必要があります。",
                            duplicate: "LAN内で固有である必要があります。",
                            duplicateName: "固有である必要があります。"
                        },
                        descriptions: {
                            dhcpEnable_site: "このサイトの DHCPサービスプロバイダーを有効にします。",
                            dhcpEnable_gateway: "このエッジサービスの DHCPサービスプロバイダーを有効にします。",
                            blockUnknownMac: "有効にすると、下の表に記載されている MACアドレスのみが DHCPサービスを使用できるようになります。",
                            networks: {
                                enable: "このリンクのデバイスで DHCPサーバーを有効にします。",
                                description: "この DHCP サーバー構成の説明をオプションで入力します。",
                                port: "この DHCPネットワークの設定に使用するLAN インターフェース",
                                firstIp: "DHCP 割り当てに使用できる最初の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                                lastIp: "DHCP 割り当てに使用できる最後の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                                domain: "オプションとして、DHCP サーバーに割り当てるドメイン名を設定します。",
                                dnsServers: "オプションとして、使用する DNSサーバーリストを設定します。",
                                bootFileName: "オプションとして、クライアントが読み込むべき初期ブートファイル名を設定します。これはクライアントがファイルを読み込むのに使用すると見込まれるファイル転送プロトコルを問わず認識可能であるべきです。",
                                defaultLeaseTime: "リースに割り当てるデフォルトの秒数",
                                maxLeaseTime: "リースに割り当てる最大秒数",
                                winsServers: "オプションとして、1つまたは複数の WINSサーバー IPアドレスを追加します。",
                                tftpHostname: "オプションとして、TFTPサーバーにホスト名を付けます。",
                                tftpIpAddress: "オプションとして、TFTPサーバーの IPアドレスを設定します。",
                                vendorOptions: "オプションとして、1つまたは複数の「;」（セミコロン）区切りでベンダーオプションを設定します。"
                            },
                            reservations: {
                                name: "この予約を識別するために使用する名前",
                                description: "この予約に関する任意の詳細",
                                port: "この予約を行うための LANインターフェース",
                                ip: "クライアント用に予約するIP",
                                mac: "クライアントの識別用 MACアドレス",
                                clientId: "IPアドレスを要請する際にホストクライアントを識別するクライアント識別子",
                                hostname: "この IPに割り当てるホスト名"
                            },
                            dhcpRelayType: "DHCPタイプを選んでください。"
                        },
                        validation: {
                            dhcpEnable: "WAN ブリッジが構成されている場合は有効にできません"
                        }
                    },
                    advanced: {
                        tabTitle: "詳細設定",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS",
                        rootPassword: "rootのパスワード",
                        rootPasswordConfirmation: "rootのパスワード確認入力",
                        updateWindowTZ: "更新ウィンドウのタイムゾーン",
                        disableOMS: "ログ分析を無効にする。",
                        disableSecurityPolicy: "セキュリティを無効にする。",
                        disableIpsPolicy: "IPSを無効にする",
                        omsNote: "これによりデフォルトのログ分析設定は上書きされます。",
                        descriptions: {
                            updateWindowTZ_site: "このサイトのタイムゾーン。このサイトの更新ウィンドウを決定するために使用されます。",
                            updateWindowTZ_gateway: "エッジサービスのタイムゾーン。このサイトの更新ウィンドウを決定するために使用されます。",
                            primaryDns: "プライマリ DNSアドレスを手動で設定します。",
                            secondaryDns: "セカンダリ DNSアドレスを手動で設定します。",
                            rootPassword_site: "サイトの rootユーザーパスワード",
                            rootPassword_gateway: "エッジサービスの rootユーザーパスワード",
                            disableOMS_site: "このサイトの Azure ログ Analytics へのログストリーミングを無効にします。",
                            disableOMS_gateway: "このエッジサービスの Azure ログ Analyticsへのログストリーミングを無効にします。",
                            disableSecurityPolicy_site: "このサイトのすべてのセキュリティポリシーを無効にする。",
                            disableSecurityPolicy_gateway: "このエッジサービスのすべてのセキュリティポリシーを無効にする。",
                            disableIpsPolicy_site: "このサイトの IPSポリシーを無効にする。",
              disableIpsPolicy_gateway: "このエッジサービスの IPSポリシーを無効にする。",
              secureOnPremNetwork: "ダウンストリームサイトのLANネットワークからのすべてのインターネットトラフィックのバックホールと確保を有効に設定します。",
              proxyConnect: "Proxy接続を有効に設定します。デフォルトでは、ポート3128と8080を使用して各LANネットワーク向けに主要IPでソケットを作成しますが、下でご自身のソケットを設定することもできます。",
              proxyConnectSockets: "Proxy接続に使用するソケットを指定してください。"
            },
            secureOnPremNetwork: "LANからのセキュアインターネット<br/>トラフィック",
            proxyConnect: "Proxy接続を有効化",
            proxyConnectSockets: "Proxy接続ソケット",
            validation: {
              proxyConnect: "は有効なソケットでなければなりません"
                        }
                    },
                    confirmPortNotActivated: "選択されたポートの一部はこのデバイスでは現在有効になっていません。",
                    confirmPortNotActivated_site: "選択されたポートの一部はこのサイトでは現在有効になっていません。",
                    confirmPortNotActivated_gateway: "選択されたポートの一部は、現在このエッジサービスについては有効になっていません。",
                    confirmPointOfEntryBroken: "このデバイスはエントリーポイントとして設定されており、固定パブリック IPが割り当てられた WANが必要です。固定パブリック IP無しで保存するとエントリーポイント機能が無くなります。",
                    confirmPointOfEntryBroken_site: "このサイトはエントリーポイントとして設定されており、 固定パブリックIPが割り当てられた WANが必要です。固定パブリック IP無しで保存するとエントリーポイント機能が無くなります。",
                    confirmPointOfEntryBroken_gateway: "このエッジサービスはエントリーポイントとして設定されており、固定パブリック IPが割り当てられたWANが必要です。固定パブリッ クIP無しで保存するとエントリーポイント機能が無くなります。",
                    confirm: "続行してもよろしいですか？"
                },
                support: {
                    buttonText: "サポートアクセス",
                    dialogTitle: "サポートアクセス",
                    message_site: "ここに入力するサポート ACLと SSHキーにより 24時間このサイトへの SSHアクセスが許可されます。",
                    message_gateway: "ここに入力するサポート ACLと SSHキーにより 24時間このエッジサービスへの SSHアクセスが許可されます。",
                    networks: "サポート ACL",
                    publicKey: "SSH公開鍵",
                    success_site: "サイト '{{name}}' のサポートアクセスは 24時間有効です。",
                    success_gateway: "エッジサービス '{{name}}' サポートアクセスは 24時間有効です。",
                    description: {
                        networks_site: "サイトへの SSH アクセスを許可するネットワークのカンマ区切りリスト",
                        networks_gateway: "エッジサービスへの SSH アクセスを許可するネットワークのカンマ区切りリスト",
                        publicKey_site: "サイトへのアクセスを許可する OpenSSH公開鍵",
                        publicKey_gateway: "Edge サービスへのアクセスを許可する OpenSSH公開鍵"
                    },
          cloudGatewayMessage: "Virtual WAN向けのEdge Serviceにアクセスするには、<0>Azureマネージド アプリケーション</0>でサポート アクセスも有効にする必要があります。"
                },
                restart: {
                    buttonLabel: "再起動",
                    box: {
                        menuChoice: "サイトの再起動",
                        dialogTitle: "サイトの再起動",
                        confirmMessage: "「確定」をクリックするとこのサイトが再起動し、これには最大5分を要することもあります。<0/><0/> 続行してもよろしいですか？",
                        confirmMessage_plural: "「確定」をクリックすると HAペアの両方のデバイスが再起動し、これには最大5分を要することもあります。<0/> これはオンラインとして報告済みデバイスにのみ影響します。<0/><0/> 続行してもよろしいですか？"
                    },
                    vpn: {
                        menuChoice: "VPNサービスの再起動",
                        dialogTitle: "VPNサービスの再起動",
                        confirmMessage: "「確定」をクリックするとこのデバイスのための VPNサービスが再起動し、これには最大5分を要することもあります。<0/><0/> 続行してもよろしいですか？",
                        confirmMessage_plural: "「確定」をクリックすると HAペア内の両方のデバイスで VPNサービスが再起動し、これには最大5分を要することもあります。<0/> これはオンラインとして報告済みデバイスにのみ影響します。<0/><0/> 続行してもよろしいですか？"
                    },
                    firmware: {
                        menuChoice: "ファームウェアの再起動",
                        dialogTitle: "ファイアウォールのファームウェアの再起動",
                        confirmMessage: "「確定」をクリックするとこのデバイスのファームウェアが再起動し、これには最大5分を要することもあります。<0/><0/> 続行してもよろしいですか？",
                        confirmMessage_plural: "「確定」をクリックすると御社の HAペア内の両方のデバイスでファームウェアが再起動し、これには最大 5分を要することもあります。<0/> これはオンラインとして報告済みデバイスにのみ影響します。<0/><0/> 続行してもよろしいですか？"
                    }
                }
            },
            security: {
                securityEnabled: "セキュリティが有効",
                menuTitle: "セキュリティ",
                pageTitle: "セキュリティ",
                comingSoon: "近日公開",
                protection: {
                    tabTitle: "マルウェアプロテクション",
                    settings: {
                        tabTitle: "設定",
                        protection: "マルウェアプロテクション",
                        name: "名前",
                        action: "アクション",
                        description: "説明",
                        order: "順番",
                        source: "ソース",
                        destination: "宛先",
                        defaultAction: "デフォルトアクション",
                        inputs: {
                            enabled: "有効"
                        },
                        descriptions: {
                            name: "ルールの固有名",
                            description: "ルールの説明",
                            action: "このポリシーに一致したトラフィックに対して実行するアクション"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除してもよろしいですか？"
                        }
                    }
                },
                addRuleButtonText: "ルール追加",
                sourceCriteria: {
                    any: "任意",
                    section: "ソースの判断基準",
                    type: "タイプ",
                    site: "サイト",
                    internet: "インターネット",
                    siteNetwork: "ネットワーク",
                    source: "ソース",
                    application: "カスタム ネットワーク アプリケーション",
                    applications: "カスタム ネットワーク アプリケーション",
                    allSites: "全てのサイト",
                    allNetworks: "全てのネットワーク",
                    addSite: "サイト追加",
                    editSite: "サイト編集",
                    network: "IP/ネットワーク",
                    userOrGroup: "ユーザー/グループ",
                    users: "ユーザー",
                    groups: "グループ",
                    userOrGroupRequired: "少なくとも 1人のユーザーまたはグループが必要です。",
                    userOrGroupDisabled: "ユーザーディレクトリが設定されていません。「アイデンティティ > 設定」でユーザーディレクトリを追加してください。",
                    userOrGroupDisabledShort: "ユーザーディレクトリが設定されていません。",
                    description: "ソースを選択してください。",
                    applicationDescription: "ソースアプリケーションを選択してください。",
                    networkDescription: "ソースネットワークを挿入します。",
                    siteDescription: "ソースサイトを選択してください。",
                    userDescription: "ソースユーザーを選択してください。",
                    groupDescription: "ソースグループを選択してください。",
                    gatewayNetwork: "ネットワーク",
                    allOnpremGateways: "全てのプライベートエッジサービス",
                    addOnpremGateway: "プライベートエッジサービスの追加",
                    editOnpremGateway: "プライベートエッジサービスの編集",
                    onpremGateway: "プライベートエッジサービス",
                    onpremGatewayDescription: "ソースのプライベートエッジサービスを選択してください。"
                },
                destinationCriteria: {
                    section: "宛先の判断基準",
                    type: "タイプ",
                    destination: "宛先",
                    site: "サイト",
                    siteNetwork: "ネットワーク",
                    allSites: "全てのサイト",
                    allNetworks: "全てのネットワーク",
                    addSite: "サイト追加",
                    editSite: "サイト編集",
                    network: "IP/ネットワーク",
                    application: "アプリケーション",
                    domain: "ドメイン",
                    category: "URLカテゴリー",
                    onPremGateway: "プライベートエッジサービス",
                    description: "宛先のタイプを選択してください。",
                    applicationDescription: "宛先のアプリケーションを選択してください。",
                    siteDescription: "宛先のサイトを選択してください。",
                    gatewayDescription: "ターゲットのプライベートエッジサービスを選択してください。",
                    networkDescription: "宛先ネットワークを選択してください。",
                    gateway: "エッジサービス",
                    ingressSite: "サイト",
                    customCategory: "カスタムカテゴリー",
                    gatewayNetwork: "ネットワーク",
                    onpremGateway: "プライベートエッジサービス",
                    allOnpremGateways: "全てのプライベートエッジサービス",
                    addOnpremGateway: "プライベートエッジサービスの追加",
                    editOnpremGateway: "プライベートエッジサービスの編集",
                    onpremGatewayDescription: "ターゲットのプライベートエッジサービスを選択してください。"
                },
                targetCriteria: {
                    section: "対象の判断基準"
                },
                tables: {
                    allSites: "全てのサイト",
                    allGateways: "全てのエッジサービス",
                    source: "ソース",
                    sourceNetwork: "IP/ネットワーク",
                    sourceApplication: "カスタム ネットワークアプリケーション",
                    sourceSite: "サイト",
                    sourceType: "ソースタイプ",
                    destination: "宛先",
                    destinationSite: "サイト",
                    destinationApplication: "アプリケーション",
                    destinationCategories: "URLカテゴリー",
                    destinationNetwork: "IP/ネットワーク",
                    destinationDomain: "ドメイン",
                    destinationType: "宛先タイプ",
                    destinationGateway: "宛先エッジサービス",
                    destinationWan: "宛先 WAN",
                    user: "ユーザー",
                    group: "グループ",
                    destinationCustomCategories: "カスタムカテゴリー",
                    allOnpremGateways: "全てのプライベートエッジサービス",
                    sourceOnpremGateway: "プライベートエッジサービス",
                    destinationOnpremGateway: "プライベートエッジサービス"
                },
                applicationControl: {
                    siteTabTitle: "サイト ACL",
                    gatewayTabTitle: "エッジサービス ACL",
                    settings: {
                        tabTitle: "設定",
                        siteApplicationControl: "サイト ACL",
                        gatewayApplicationControl: "エッジサービス ACL",
                        order: "順番",
                        name: "名前",
                        action: "アクション",
                        description: "説明",
                        defaultAction: "デフォルトのアクション",
                        source: "ソース",
                        destination: "宛先",
                        scope: "スコープ",
                        allSites: "全てのサイト",
                        allGateways: "全てのエッジサービス",
                        descriptions: {
                            name: "ルールの固有名",
                            description: "ルールの説明",
                            action: "このポリシーに一致したトラフィックに対して実行するアクション（ブロックまたは許可）",
                            scope: "このルールを適用するサイト",
                            icmp: "デバイスに ICMPを介する通信を許可します。"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除してもよろしいですか？"
                        },
                        icmp: "ICMP"
                    },
                    noData: {
                        edgeServiceAcl: {
                            iconHeader: "まず、エッジ サービス ACLを追加してください！",
                            labelButton: "エッジサービス ACLを追加",
                            textHeader: "エッジサービス ACLとは何ですか？",
                            text: "セキュリティ上の理由から、サービスへのアクセスを制限することが必要な場合があります。<0 /> このアクセス制御リストを使用すると、サービスへのアクセスを許可するユーザーとそのアクセス方法を設定できます。"
                        },
                        siteAcl: {
                            iconHeader: "まず、サイト ACLを追加してください！",
                            labelButton: "サイト ACLを追加",
                            textHeader: "サイト ACLとは何ですか？",
                            text: "セキュリティの観点から、サイトへのアクセスを制限したい場合がよくあります。<0 /> このアクセス制御リストを使用すると、サイトへのアクセスを許可するユーザーとそのアクセス方法を設定できます。"
                        },
                        appResource: {
                            iconHeader: "まずはアプリとリソースを追加してください！",
                            labelButton: "カスタムアプリケーションを追加する",
                            textHeader: "カスタムアプリケーションとは何ですか？",
                            text: "SD-WAN環境を機能させるには、利用中のアプリケーションを把握する必要があります。<0 /> 多数のアプリケーションが最初からサポートされていますが、特定のアプリケーションはこの初期セットに含まれていない場合があります。<0 />ここで、サポートされていないネットワーク アプリケーションと Web アプリケーションを追加できます。"
                        }
                    }
                },
                urlFiltering: {
                    tabTitle: "URLフィルタリング",
                    settings: {
                        tabTitle: "設定",
                        order: "順番",
                        name: "名前",
                        description: "説明",
                        appliesTo: "適用先",
                        action: "アクション",
                        type: "タイプ",
                        rule: "宛先",
                        domains: "ドメイン",
                        categories: "URLカテゴリー",
                        defaultAction: "デフォルトのアクション",
                        destinationCriteria: "宛先の判断基準",
                        descriptions: {
                            name: "ルールの固有名",
                            description: "ルールの説明",
                            action: "ポリシーにマッチしたトラフィックに対して実行するアクション（ブロックまたは許可）",
                            type: "ポリシーが URLカテゴリまたはドメイン名のどちらにマッチするか選択します。",
                            domains: "ポリシーマッチングに使用するドメイン",
                            silent: "ルールをサイレントブロックします。",
                            customCategories: "このポリシーマッチングに使用されているカスタムカテゴリー"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        validation: {
                            emptyCategories: "少なくとも1つのカテゴリーを選択する必要があります。"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除してもよろしいですか？"
                        },
                        silent: "サイレントブロック",
                        customCategories: "カスタムカテゴリー",
                        destinationDomains: "ドメイン({{domains.0}}、+{{additional}})",
                        destinationDomains_1: "ドメイン ({{domains.0}})",
                        destinationCategories: "カテゴリー ({{activeCategories.0.name}}, +{{additional}})",
                        destinationCategories_1: "カテゴリー ({{activeCategories.0.name}})",
                        destinationCustomCategories: "カスタムカテゴリー({{activeCategories.0.name}}, +{{additional}})",
                        destinationCustomCategories_1: "カスタムカテゴリー({{activeCategories.0.name}})"
                    },
                    exceptions: {
                        tabTitle: "明示的",
                        order: "順番",
                        name: "名前",
                        description: "説明",
                        action: "アクション",
                        type: "タイプ",
                        rule: "ルール",
                        domain: "ドメイン",
                        category: "URLカテゴリー",
                        domains: "宛先ホスト名",
                        categories: "URLカテゴリー",
                        source: "ソース",
                        sourceNetwork: "ソースIP/ネットワーク",
                        sourceSite: "ソースサイト",
                        allSites: "全てのサイト",
                        allNetworks: "全てのネットワーク",
                        sourceSiteNetwork: "ネットワーク",
                        sourceType: "タイプ",
                        network: "IP/ネットワーク",
                        site: "サイト",
                        sourceSites: "サイト",
                        addSite: "サイト追加",
                        editSite: "サイト編集",
                        destinations: "宛先",
                        destination: "宛先IP/ネットワーク",
                        destinationType: "タイプ",
                        destinationCategories: "宛先 URLカテゴリー",
                        destinationDomains: "宛先ドメイン",
                        sectionTitle: "判断基準",
                        sourceCriteria: "ソースの判断基準",
                        destinationCriteria: "宛先の判断基準",
                        descriptions: {
                            name: "この明示的ポリシーの固有名",
                            description: "このポリシーの説明",
                            action: "このポリシーに一致したトラフィックに対して実行するアクション（ブロックまたは許可）",
                            categories: "このポリシーのマッチングに使用するカテゴリー",
                            domains: "このポリシーに一致するために使用されるドメイン名",
                            customCategories: "このポリシーにマッチングさせるために使用されているカスタムカテゴリー",
                            silent: "ルールをサイレントにブロックします。"
                        },
                        validation: {
                            sourceDest: "少なくとも1つのソースまたは宛先を指定する必要があります。"
                        },
                        create: {
                            buttonText: "追加",
                            dialogTitle: "明示的ポリシーを追加"
                        },
                        edit: {
                            dialogTitle: "明示的ポリシーを編集"
                        },
                        delete: {
                            title: "明示的ポリシーを削除",
                            body: "この例外を削除してもよろしいですか？"
                        },
                        silent: "サイレントブロック",
                        customCategories: "カスタムカテゴリー"
                    }
                },
                urlCategories: {
                    tabTitle: "URLカテゴリー",
                    settings: {
                        tabTitle: "設定",
                        urlCategories: "URLカテゴリー",
                        description: "ブロックするためにはURLスーパーカテゴリーまたは URLカテゴリーにチェックマークを入れます。"
                    },
                    blocklists: {
                        tabTitle: "許可/ブロックリスト",
                        description: "恒久的にブロックまたは許可するURL、IPまたはネットワークを定義します。",
                        inputs: {
                            blacklist: "常にブロック",
                            whitelist: "常に許可"
                        }
                    },
                    exceptions: {
                        tabTitle: "明示的",
                        categories: "スーパーカテゴリー",
                        subCategories: "カテゴリー",
                        mode: "許可/ブロック",
                        allow: "許可",
                        block: "ブロック",
                        users: "ユーザー",
                        groups: "グループ",
                        source: "ソースIP/ネットワーク",
                        time: "時間",
                        validateCategory: "少なくとも 1つのURLスーパーカテゴリーまたは URLカテゴリーを指定する必要があります。",
                        validateSource: "少なくとも 1つのソースまたはユーザーまたはグループを指定する必要があります。",
                        delete: {
                            title: "明示的ポリシーを削除",
                            body: "この例外を削除しても本当によろしいですか？ 削除は保存時に反映されます。"
                        },
                        create: {
                            title: "新規明示的ポリシーを追加",
                            button: "追加"
                        },
                        edit: {
                            title: "明示的ポリシーを編集"
                        }
                    }
                },
                ips: {
                    tabTitle: "IPS",
                    settings: {
                        tabTitle: "設定",
                        falsePositivesTabTitle: "誤検知",
                        ips: "侵入防止",
                        name: "名前",
                        action: "アクション",
                        description: "説明",
                        block: "ブロック",
                        mode: "モード",
                        log: "ログ",
                        order: "順番",
                        source: "ソース",
                        exploits: "エクスプロイト",
                        destination: "宛先",
                        defaultAction: "既定のアクション",
                        descriptions: {
                            name: "ルールの固有名",
                            description: "ルールの説明",
                            action: "このポリシーに一致するトラフィックに対して行うアクション"
                        },
                        inputs: {
                            enabled: "有効"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除してもよろしいですか？"
                        }
                    }
                },
                sslInterception: {
                    tabTitle: "SSLインスペクション",
                    settings: {
                        tabTitle: "証明書",
                        sslInterception: "SSLインスペクション",
                        name: "名前",
                        action: "アクション",
                        description: "説明",
                        order: "順番",
                        source: "ソース",
                        destination: "宛先",
                        defaultAction: "デフォルトのアクション",
                        explicitTabTitle: "設定",
                        status: "ステータス",
                        issuer: "発行者",
                        startDate: "開始日",
                        endDate: "終了日",
                        makeActive: "有効にする",
                        active: "状態",
                        activeCertificate: "有効な証明書",
                        makeCertificateActive: "有効にする",
                        sslCertificates: "SSLインスペクション証明書",
                        sslCertificateAuthorities: "信頼済み証明書発行機関",
                        uploadP12: "PKCS12をアップロード",
                        uploadPEM: "PEMまたは CRTをアップロード",
                        passphrase: "パスフレーズ",
                        downloadCertificate: "クライアント証明書をダウンロード",
                        certificates: {
                            create: {
                                label: "証明書を追加",
                                dialogTitle: "新規証明書をアップロード"
                            },
                            edit: {
                                dialogTitle: "証明書の詳細を編集"
                            },
                            delete: {
                                title: "証明書を削除",
                                body: "証明書を削除してもよろしいですか？",
                                disabledMessage: "有効な証明書は削除できません。"
                            }
                        },
                        descriptions: {
                            name: "ルールの固有名",
                            certificateName: "証明書の固有名",
                            description: "ルールの説明",
                            certificateDescription: "証明書の説明",
                            action: "このポリシーに一致するトラフィックに対して行うアクション",
                            uploadP12: "PKS12 証明書をアップロードします。秘密鍵が含まれている必要があります。",
                            uploadPEM: "PEM または CRT 証明書をアップロードします。",
                            passphrase: "PKS12証明書をロック解除するためのパスフレーズ(必要な場合)。"
                        },
                        inputs: {
                            enabled: "有効"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除しても本当によろしいですか"
                        }
                    }
                },
                ingressNatRules: {
                    settings: {
                        order: "順番",
                        name: "名前",
                        target: "ターゲット",
                        description: "説明",
                        source: "ソース",
                        destination: "宛先",
                        gateway: "エッジサービス",
                        wan: "WAN",
                        targetApplicationResource: "アプリケーション/リソース",
                        descriptions: {
                            name: "ルールの固有名",
                            description: "ルールの説明",
                            target: "このルールで一致するトラフィックのターゲット",
                            wan: "必要なパブリック IPに応じて WANインターフェースを選択します。",
                            patPublicPort: "宛先 PATパブリック ポート。ターゲットがポートのないネットワーク アプリケーションである場合、これは必須フィールドです。ポートが指定されていない場合は、ターゲット アプリケーションの構成済みポート (または構成済みポート範囲の最初のポート) が使用されます。"
                        },
                        create: {
                            buttonText: "ルール追加",
                            dialogTitle: "新しいルールの追加"
                        },
                        edit: {
                            dialogTitle: "ルールの編集"
                        },
                        delete: {
                            title: "ルールの削除",
                            body: "このルールを削除してもよろしいですか？"
                        },
                        IPAddress: "住所"
                    },
                    noData: {
                        iconHeader: "イングレス NATルールを追加して開始してください！",
                        labelButton: "イングレス NATルールを追加",
                        textHeader: "イングレス NATとは何ですか？",
                        text: "内部ネットワークのリソースをパブリックに利用可能にする場合、このセクションを使い、パブリックにする接続用のパラメータを定義してください。<0 /><0 />内部サービスは静的パブリック IP アドレスで実行する必要があることに注意してください。"
                    }
                }
            },
            customApps: {
                menuTitle: "カスタムアプリ",
                pageTitle: "カスタムアプリケーション",
                table: {
                    name: "名前",
                    description: "説明",
                    protocol: "プロトコル",
                    destination: "宛先",
                    category: "カテゴリー",
                    type: "タイプ"
                },
                dialogs: {
                    create: {
                        buttonText: "新規カスタム アプリケーション",
                        dialogTitleWeb: "新規カスタム ウェブ アプリケーション",
                        dialogTitleNetwork: "新規カスタム ネットワーク アプリケーション",
                        menuLabelWeb: "新規カスタム ウェブ アプリケーション",
                        menuLabelNetwork: "新規カスタム ネットワーク アプリケーション"
                    },
                    edit: {
                        dialogTitle: "カスタム アプリケーションの編集"
                    },
                    general: {
                        sectionTitle: "全般",
                        name: "名前",
                        description: "説明",
                        descriptions: {
                            name: "このカスタム アプリケーションの固有名",
                            description: "このカスタム アプリケーションの説明"
                        }
                    },
                    endpoints: {
                        protocol: "プロトコル",
                        sectionTitle: "アプリケーション エンドポイント",
                        destination: "宛先",
                        port: "ポート",
                        firstPort: "最初のポート",
                        lastPort: "最後のポート",
                        descriptions: {
                            protocol: "このカスタム アプリケーションによって使用されるプロトコル",
                            destination: "このカスタム アプリケーションがトラフィックを送信する宛先 IP、ドメインまたはネットワーク",
                            webAppDestination: "このカスタム アプリケーションがトラフィックを送信する宛先ホスト名",
                            port: "このカスタム アプリケーションによって使用されるポート",
                            firstPort: "オプション: このカスタム アプリケーションによって使用されるポート範囲の最初のポート",
                            lastPort: "オプション: このカスタム アプリケーションによって使用されるポート範囲の最後のポート"
                        }
                    },
                    category: "カテゴリー",
                    descriptions: {
                        category: "このカスタム アプリケーションを割当るカテゴリー"
                    }
                },
                delete: {
                    title: "{{name}}を削除します。",
                    body: "このカスタム アプリケーションを削除してもよろしいですか？"
                }
            },
            sites: {
                auditLog: {
                    tabTitle: "監査Log",
                    pageTitle: "監査Log"
                },
                connectionHistory: {
                    tabTitle: "最近の接続",
                    pageTitle: "最近の接続",
                    waitingForConnections: "サイトに接続しています。接続が確立されるまで最大 2分かかる場合があります。お待ちください...",
                    emptyData: "最近の接続はありません...",
                    pauseRequestsButton: "一時停止",
                    resumeRequestsButton: "再開"
                },
                dashboard: {
                    tabTitle: "ダッシュボード",
                    statusCardTitle: "ステータス",
                    servicesCardTitle: "Services",
                    licensesCardTitle: "ライセンス",
                    locationsCardTitle: "マップ",
                    gateway: {
                        tabTitle: "エッジサービス",
                        name: "名前",
                        region: "リージョン",
                        hubName: "ハブ",
                        vwan: "VWAN",
                        size: "サイズ"
                    },
                    siteStatus: {
                        title: "情報",
                        model: "モデル",
                        licenses: "ライセンス",
                        serials: "シリアル",
                        uptime: "稼働時間",
                        time: "現在時刻",
                        release: "リリース",
                        hostname: "ホスト名"
                    },
                    licenses: {
                        type: "タイプ",
                        state: "状況",
                        status: "ステータス",
                        expirationDate: "満了日",
                        serial: "シリアル",
                        noData: "N/A"
                    },
                    boxResources: {
                        title: "リソース",
                        cpuLoad: "CPU",
                        cpuLoadError: "CPUにエラーが検出されました。デバイスを再起動してください。このエラーが再発する場合は、Barracuda Supportにご連絡ください。",
                        memory: "メモリー",
                        diskInformation: "ハードディスク",
                        diskInformationError: "ディスクパーティションの1つでエラーが検出されました。デバイスを再起動してください。このエラーが再発する場合は、Barracuda Supportにご連絡ください。"
                    },
                    interfaces: {
                        title: "インターフェース",
                        status: "ステータス",
                        name: "名前",
                        speed: "スピード",
                        duplex: "デュプレックス",
                        errors: "エラー",
                        state: {
                            ok: "OK",
                            warning: "警告",
                            error: "エラー",
                            unknown: "不明",
                            loading: " "
                        }
                    },
                    deploymentEvents: {
                        title: "配備イベント",
                        state: "状況",
                        status: "ステータス",
                        created: "日付"
                    },
                    deploymentStatus: {
                        title: "配備ステータス {{serial}}",
                        status: "ステータス",
                        action: "アクション",
                        message: "メッセージ",
                        registered: "登録済み",
                        lastSeen: "最終確認",
                        completed: "完了",
                        localIp: "ローカルIP",
                        remoteIp: "パブリックIP",
                        siteDeploying: "配備中です"
                    },
                    deploymentLocation: {
                        title: "デプロイメントの場所"
                    },
                    firmware: {
                        tabTitle: "ファームウェア",
                        version: "バージョン",
                        hotfixes: "適用済ホットフィックス",
                        window: "次回更新",
                        nextUpdate: "{{from}}から{{to}}の間"
                    },
                    hotfixes: {
                        tabTitle: "ホットフィックス",
                        status: "ステータス",
                        name: "名前",
                        state: {
                            error: "エラー",
                            ok: "OK",
                            unknown: "不明",
                            warning: "保留中",
                            loading: " "
                        }
                    },
                    updates: {
                        tabTitle: "設定更新日時",
                        lastUpdate: "前回更新日時",
                        lastSeen: "前回確認日時",
                        remoteIp: "リモートIP"
                    },
                    restart: {
                        buttonLabel: "再起動",
                        box: {
                            menuChoice: "サイトの再起動",
                            dialogTitle: "サイトの再起動",
                            confirmMessage: "「確定」をクリックするとこのサイトが再起動します。これには最大 5 分かかる場合があります。<0/><0/> 続行してもよろしいですか？",
                            confirmMessage_plural: "「確定」をクリックすると HAペアの両方のアプライアンスが再起動します。これには最大 5分かかる場合があります。<0/> これはオンラインとして報告されたアプライアンスにのみ影響します。<0/><0/> 続行してもよろしいですか？"
                        },
                        vpn: {
                            menuChoice: "VPNサービスの再起動",
                            dialogTitle: "VPNサービスの再起動",
                            confirmMessage: "「確定」をクリックすると、このアプライアンスの VPNサービスが再起動します。これには最大 5分かかる場合があります。<0/><0/> 続行してもよろしいですか？",
                            confirmMessage_plural: "「確定」をクリックすると、HAペアの両方のアプライアンスの VPNサービスが再起動します。これには最大 5分かかる場合があります。<0/> これはオンラインとして報告済みデバイスにしか影響しません。<0/><0/> 続行してもよろしいですか？"
                        },
                        firmware: {
                            menuChoice: "ファームウェアの再起動",
                            dialogTitle: "ファイアウォールファームウェアの再起動",
                            confirmMessage: "「確定」をクリックするとこのデバイスのfirmwareが再起動し、これには最大5分を要することもあります。<0/><0/> 続行してもよろしいですか？",
                            confirmMessage_plural: "「確定」をクリックすると、HAペアの両方のアプライアンスのファームウェアが再起動されます。これには最大 5 分かかる場合があります。<0/> これは、オンラインとして報告されているアプライアンスにのみ影響します。<0/><0/> 続行してもよろしいですか？"
                        }
                    }
                },
                delete: {
                    body: "このサイトを削除してもよろしいですか？",
                    title: "サイト{{name}}を削除"
                },
                dialog: {
                    create: {
                        buttonText: "新しいサイト",
                        sc: {
                            createSC: "新規 IoTデバイス",
                            dialogTitle: "新規 IoTデバイス",
                            menuItemDisabled: "新規 IoTデバイスにはエッジサービスが必要です"
                        },
                        tvt: {
                            createTVT: "新規 T/VTデバイス",
                            dialogTitle: "新しいサイト"
                        }
                    },
                    wans: {
                        sectionTitle: "WAN",
                        message: "お使いのデバイスには WANと LANに割当可能な合計{{portsAvailable}}個のポートがあります。",
                        message2_site: "このサイトにいくつの WAN接続を設定しますか？",
                        message2_gateway: "このエッジサービスではいくつのWAN接続を設定しますか？",
                        size: "WANの数",
                        setup: "WANリンク{{step}}を設定します。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        type: "タイプ",
                        providerPinning: "プロバイダーの固定",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        pin: "{{serial}}用 SIMの PIN",
                        invalidPinFormat: "SIMの PINは 4 桁の数字である必要があります。",
                        SCmessage: "バイスには設定する WANポートが 1つあります。",
                        SCmessage_failover: "デバイスには最大 2つの WAN接続 (プライマリとフェイルオーバー) を設定できます。",
                        SCconfigureFailover: "このサイトのフェイルオーバーを設定しますか？",
                        failover: "WANフェイルオーバー",
                        setupSCPrimary: "プライマリ WANリンクを設定します。",
                        setupSCFailover: "フェイルオーバー WANリンクを設定します。",
                        descriptions: {
                            name: "この WANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプションの仮想 LAN ID (仮想 LAN をサポートするスイッチに接続する場合に使用します。)",
                            type: "このリンクに使用するネットワーク タイプ",
                            providerPinning: "このリンクの固定先プロバイダーグループ。プロバイダーグループは SD-WANポリシーでトラフィックフローと動作を定義するために使用されます。",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "このリンクのネットワーク CIDR ネットマスク サフィックス",
                            gateway: "このリンクに関連付けられているゲートウェイ IPアドレス",
                            pin: "このデバイスにインストールされている SIMカード用の個人識別番号"
                        }
                    },
                    lans: {
                        sectionTitle: "LAN",
                        message: "デバイスには、WANと LANの両方の指定に使用できる合計{{portsAvailable}}ポートがあり、WANに{{wans}}ポートが割り当てられています。",
                        SCmessage: "このデバイスには合計{{portsAvailable}}個の LANポートが利用可能です。",
                        message2: "このサイトにいくつの LAN接続を設定しますか？",
                        size: "LANの数",
                        setup: "LANリンク{{step}}を設定します。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        dhcpEnable: "DHCPサーバー",
                        dhcpDescription: "説明",
                        dhcpFirstIp: "先頭の IPアドレス",
                        dhcpLastIp: "最後の IPアドレス",
                        dhcpDomain: "DHCPドメイン",
                        dhcpSectionTitle: "DHCP設定",
                        wifiSectionTitle: "WiFi設定",
                        wifi: {
                            ssid: "SSID",
                            securityMode: "セキュリティモード",
                            passphrase: "パスフレーズ",
                            passphraseConfirm: "パスフレーズの確認"
                        },
                        descriptions: {
                            dhcpEnable: "この LANリンクの DHCPサーバーを有効にします。",
                            name: "この LANリンクの固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプション仮想 LAN ID （仮想 LAN サポートのあるスイッチに接続する場合に使用されます。）",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "作成したネットワークに使用するネットワーク CIDRネットマスクサフィックス",
                            dhcpDescription: "この DHCPネットワーク用のカスタム説明",
                            dhcpFirstIp: "この DHCPネットワーク内で割当可能にする先頭の IPアドレス",
                            dhcpLastIp: "この DHCPネットワーク内で割当可能にする最後の IPアドレス",
                            dhcpDomain: "この DHCPサービスのドメイン名",
                            wifi: {
                                ssid: "WiFiネットワークの SSID",
                                securityMode: "使用する Wi-Fiセキュリティモード",
                                passphrase: "ネットワーク用パスフレーズ"
                            }
                        }
                    },
                    advanced: {
                        sectionTitle: "詳細設定",
                        updateWindowTZ: "更新ウィンドウのタイムゾーン",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS",
                        descriptions: {
                            updateWindowTZ: "このサイトの更新ウィンドウを決定するために使用されるサイトのタイムゾーン",
                            primaryDns: "プライマリ DNSアドレスを手動で設定します。",
                            secondaryDns: "セカンダリ DNSアドレスを手動で設定します。"
                        }
                    },
                    submit: {
                        message: "サイトの詳細を確認して「保存」をクリックしえtください。",
                        name: "名前",
                        description: "説明",
                        serial: "シリアル",
                        gateway: "エッジサービス",
                        noGateway: "(なし)",
                        timezone: "タイムゾーン",
                        status: "接続ステータス",
                        peeringAddress: "ピアリングアドレス",
                        wan: "WAN",
                        lan: "LAN",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS"
                    },
                    result: {
                        siteCreated: "サイトは正常に作成されました。",
                        connectPort: "デバイスの自動構成を可能にするために、下記のデバイスのポート<0>{{port}}</0>を DHCP対応のインターネット接続に接続してください。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                        connectPortSc: "デバイスの自動構成を可能にするために、以下のアプライアンスの WANポートを DHCP対応のインターネット接続に接続してください。",
                        connectPort_plural: "デバイスの自動構成を可能にするために、下記のデバイスのポート<0>{{port}}</0>を DHCP対応のインターネット接続に接続してください。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                        HAMessage: "両方のアプライアンスのポート<0>P1</0>をネットワーク ケーブルで直接接続してください。 HA同期が自動的に開始されます。",
                        campus: "詳細は<0>Barracuda Campus</0>をご覧ください",
                        serial: "シリアル: {{serial}}",
                        nonstandardPort: "警告: この構成では、非標準ポート<0>{{ports}}</0>が使用されています。仮想アプライアンスで有効になっていることを確認してください。",
                        nonstandardPort_plural: "警告: この構成では、非標準ポート<0>{{ports}}</0>が使用されています。仮想アプライアンスで有効になっていることを確認してください。"
                    },
                    steps: {
                        result: "完了",
                        submit: "概要"
                    },
                    types: {
                        dynamic: "動的 (DHCP)",
                        configure_on_branch: "ブランチで設定済み"
                    },
                    validation: {
                        duplicateNetwork: "このポート/仮想 LAN IDに一致するネットワークはすでに設定されています",
                        duplicateWwan: "WWANは 1つだけ構成できます。",
                        duplicateSCwan: "動的または静的 WAN は 1つだけ構成できます。",
                        duplicateExpressRoute: "エクスプレス ルートは 1つだけ構成できます。",
                        duplicateName: "他の接続で名前がすでに使用されていま。す",
                        secondaryDns: "プライマリ DNSがない場合は設定しないでください。",
                        availableAsn: "この ASN値は利用できません。"
                    }
                },
                liveLog: {
                    tabTitle: "ライブ接続",
                    pageTitle: "ライブ接続",
                    waitingForConnections: "サイトに接続しています。接続が確立されるまでに最大 2分かかる場合があります。お待ちください...",
                    emptyData: "現在アクティブなライブ接続はありません...",
                    pauseRequestsButton: "一時停止",
                    resumeRequestsButton: "再開"
                },
                menuTitle: "サイト",
                pageTitle: "サイト",
                settings: {
                    tabTitle: "設定",
                    basic: {
                        tabTitle: "基本情報",
                        name: "名前",
                        model: "モデル",
                        gateway: "エッジサービス",
                        serials: "シリアル",
                        descriptions: {
                            gateway: "このサイトが接続されている Barracuda SecureEdge エッジサービス",
                            name: "サイトの固有名",
                            model: "このサイト用のデバイスモデル",
                            serials: "このサイト用のデバイスシリアル"
                        },
                        serialEdit: {
                            table: {
                                status: "ステータス",
                                serial: "シリアル",
                                add: "二番目を追加"
                            },
                            addTitle: "シリアルを追加",
                            editTitle: "シリアルをスワップ",
                            deleteTitle: "シリアルを削除",
                            edit: "このサイトに接続する交換用アプライアンスのシリアルを以下から選択します。アプライアンスは、同じモデルの別のアプライアンスとのみ交換できます。",
                            add: "サイトに 2 台目のアプライアンスを接続すると、高可用性機能がオンになります。アプライアンスのシリアルを以下から選択してください。",
                            delete1: "このサイトからシリアル {{serial}} を削除してもよろしいですか？",
                            delete2: "これにより、高可用性機能が削除され、関連するアプライアンスが無効になります。",
                            save: "変更は保存後に有効になります。",
                            saveDelete: "保存後、デバイスを切断できます。",
                            saveAdd: "保存したら、新しいアプライアンスを接続できます。最初にアプライアンスのポート<0>P4</0>を DHCP 対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                            saveEdit: "保存したら、古いアプライアンスを切断できます。次に、新しいアプライアンスを接続します。最初にアプライアンスのポート<0>P4</0>を DHCP 対応のインターネット接続に接続して、アプライアンスの自動構成を可能にします。別のポートを使用する場合は、アプライアンスで直接構成できます。",
                            saveHA: "さらに、新しいアプライアンスと既存のアプライアンスの両方のポート<0>P1</0>をネットワーク ケーブルで直接接続します。HA 同期が自動的に開始されます。",
                            saveEditSecondary: "古いアプライアンスは HA 用に設定されています。再度使用するには、再イメージ化する必要があります。",
                            saveDeleteSecondary: "このアプライアンスは HA 用に設定されています。再度使用するには、再イメージ化する必要があります。",
                            saveDeletePrimary: "このサイトに接続されている他のアプライアンスは HA 用に構成されています。保存後に再イメージ化して、非 HAアプライアンスとして再構成する必要があります。このプロセスが完了するまで、サイトのダウンタイムが発生します。"
                        }
                    },
                    wan: {
                        tabTitle: "WAN",
                        addTitle: "新規 WANインターフェースを追加",
                        addButtonText: "WANインターフェースを追加",
                        editTitle: "WANインターフェースを編集",
                        deleteTitle: "WANインターフェースを削除",
                        deleteMessage: "この WANインターフェースを削除してもよろしいですか？ <0> 削除は保存時に反映されます。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        type: "タイプ",
                        providerPinning: "プロバイダーの固定",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        pin: "{{serial}}用 SIMの PIN",
                        scPin: "SIM PIN",
                        invalidPinFormat: "SIMの PINは 4 桁の数字である必要があります。",
                        portWarning: "上記のグレー表示の行は、選択したポートがアプライアンスで有効になっていないことを示します。",
                        failover: "HA フェイルオーバー",
                        network: "ネットワーク",
                        doublePort: "ポート",
                        doubleVirtualLanId: "仮想 LAN ID",
                        doubleAddress: "IPアドレス",
                        doubleNetmask: "ネットマスク",
                        virtualTransferNetwork: "仮想転送ネットワーク",
                        asn: "エクスプレスルート ASN",
                        expressRoute: "エクスプレスルート",
                        primary: "プライマリ",
                        secondary: "セカンダリ",
                        validatePublicIp: "ユーザー接続用のパブリック IP である必要があります。",
                        descriptions: {
                            name: "この WANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート。",
                            virtualLanId: "ネットワークのオプション仮想 LAN ID （仮想 LAN サポートのあるスイッチに接続する場合に使用されます。）",
                            type: "このリンク用に使用するネットワークタイプ",
                            providerPinning: "このリンクのピンニング先プロバイダーグループ。プロバイダーグループはトラフィックフローと動作を定義するために SD-WAN ポリシーで使用されます。",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "このリンクのネットワーク CIDR ネットマスク サフィックス",
                            gateway: "このリンクに関連付けられているゲートウェイ IPアドレス",
                            pin: "このデバイスにインストールされている SIMカード用の個人識別番号",
                            failover: "有効にすると、ポートが監視され、接続が失われた場合に HAフェイルオーバーが実行されます。HAが有効なサイトでのみ使用できます。",
                            doublePort: "プライマリ リンクとセカンダリ リンクに使用するデバイス ポート",
                            doubleVirtualLanId: "プライマリ リンクとセカンダリ リンクのオプションの仮想 LAN ID (仮想 LAN をサポートするスイッチに接続する場合に使用)",
                            doubleAddress: "プライマリ リンクとセカンダリ リンクに割り当てる IPアドレス。これらは、各接続に対して Azureで構成されている範囲の最初の使用可能な IP である必要があります (たとえば、Azure で 172.16.0.4/30 が構成されている場合、入力する IP アドレスは 172.16.0.5 です。)",
                            doubleNetmask: "プライマリリンクとセカンダリリンク用ネットワーク CIDR ネットマスク サフィックス",
                            asn: "このリンクの自動生成された BGP ASN。これは、Cloud Gen WANサービス内で管理されている他の ASNと競合しません。",
                            virtualTransferNetwork: "仮想転送インターフェース用にIPを予約するために使用されるネットワーク"
                        }
                    },
                    lan: {
                        tabTitle: "LAN",
                        sectionTitle: "LANインターフェース",
                        addTitle: "新規 LANインターフェースを追加",
                        addButtonText: "LANインターフェースを追加",
                        editTitle: "LANインターフェースを編集",
                        deleteTitle: "LANインターフェースを削除",
                        deleteMessage1: "この LAN インターフェイスを削除してもよろしいですか？ 削除すると次のようになります:",
                        deleteMessage2: "一致する DHCP設定および予約は削除されます。",
                        deleteMessage3: "この LAN インターフェイスを使用しているセキュリティルールがすべて更新または削除されます。",
                        deleteMessage4: "削除は保存時に反映されます。",
                        name: "名前",
                        port: "ポート",
                        virtualLanId: "仮想 LAN ID",
                        address: "IPアドレス",
                        netmask: "ネットマスク",
                        dhcpEnable: "DHCP サーバー",
                        dhcpFirst: "先頭の IPアドレス",
                        dhcpLast: "最後の IPアドレス",
                        dhcpDomain: "DHCPドメイン",
                        dhcpRange: "DHCP範囲",
                        portWarning: "上記のグレーアウトされた行は選択されたポートがデバイスで有効でないことを示します。",
                        failover: "HAフェイルオーバー",
                        descriptions: {
                            name: "この LANリンクのための固有名",
                            port: "このリンクに使用するデバイスポート",
                            virtualLanId: "ネットワークのオプション仮想 LAN ID （仮想 LAN サポートのあるスイッチに接続する場合に使用されます。）",
                            address: "ポートに割り当てる IPアドレス",
                            netmask: "作成したネットワークに使用するネットワーク CIDRネットマスク サフィックス",
                            dhcpEnable: "このリンク用にデバイスで DHCPサーバーを有効にします。",
                            dhcpFirst: "DHCP 割り当てに使用できる最初の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                            dhcpLast: "DHCP 割り当てに使用できる最後の IP。ポート IP とネットマスクで定義されたネットワーク内にある必要があります。",
                            dhcpDomain: "DHCP サーバーに割り当てるドメイン名",
                            failover: "有効にすると、ポートが監視され、接続が失われた場合に HA フェイルオーバーが実行されます。 HA対応サイトでのみ利用可能です。"
                        }
                    },
                    route: {
                        tabTitle: "ルート",
                        sectionTitle: "スタティックルート",
                        addTitle: "新規スタティックルートを追加",
                        addButtonText: "スタティックルートを追加",
                        editTitle: "スタティックルートを編集",
                        deleteTitle: "スタティックルートを削除",
                        deleteMessage: "このスタティックルートを削除してもよろしいですか？ 削除は保存時に反映されます。",
                        name: "名前",
                        address: "ターゲットネットワーク",
                        netmask: "ネットマスク",
                        gateway: "ゲートウェイ",
                        descriptions: {
                            name: "このルートの固有名",
                            address: "このルートのターゲットネットワークアドレス",
                            netmask: "ターゲットネットワークの CIDRネットマスク サフィックス",
                            gateway: "このルートに使用するゲートウェイ"
                        }
                    },
                    dhcp: {
                        tabTitle: "DHCP",
                        networksSectionTitle: "DHCPネットワーク",
                        leasesSectionTitle: "DHCPリース",
                        reservationsSectionTitle: "DHCP予約",
                        dhcpEnable: "DHCP Serviceを有効にする",
                        addNetworkTitle: "新規 DHCPネットワークを追加",
                        addNetworkButtonText: "ネットワークを追加",
                        editNetworkTitle: "DHCPネットワークを編集",
                        deleteNetworkTitle: "DHCPネットワークを削除",
                        deleteNetworkMessage: "この DHCPネットワークを削除しますか？ 削除は保存時に反映されます。",
                        networks: {
                            networkSectionTitle: "ネットワーク",
                            basicSectionTitle: "基本",
                            basicTabTitle: "基本",
                            advancedSectionTitle: "詳細設定",
                            advancedTabTitle: "詳細設定",
                            enable: "DHCP範囲が有効",
                            port: "LAN 名",
                            description: "説明",
                            firstIp: "先頭の IPアドレス",
                            lastIp: "最後の IPアドレス",
                            domain: "ドメイン名",
                            dnsServers: "DNSサーバー",
                            bootFileName: "ブートファイル名",
                            defaultLeaseTime: "デフォルトのリース時間",
                            maxLeaseTime: "最大リース時間",
                            winsServers: "WINSサーバー",
                            tftpHostname: "TFTPホスト名",
                            tftpIpAddress: "TFTP IPアドレス",
                            vendorOptions: "ベンダーオプション",
                            validateMaxLeaseTime: "既デフォルトのリース時間より短くすることはできません。"
                        },
                        blockUnknownMac: "既知の MAC アドレスのみを許可します。",
                        addReservationTitle: "新規 DHCP予約を追加",
                        addReservationButtonText: "予約を追加",
                        editReservationTitle: "DHCP予約を編集",
                        deleteReservationTitle: "DHCP予約を削除",
                        deleteReservationMessage: "このDHCP予約を削除してもよろしいですか？ 削除は保存時に反映されます。",
                        leases: {
                            reservationSectionTitle: "リース",
                            port: "LAN 名",
                            ip: "IPアドレス",
                            mac: "MAC アドレス",
                            reserve: "予約IP",
                            noLeases: "現在アクティブなリースはありません。新しいリースが表示されるまで最大 2分かかる場合があります。"
                        },
                        reservations: {
                            reservationSectionTitle: "予約",
                            name: "名前",
                            description: "説明",
                            port: "LAN 名",
                            ip: "IPアドレス",
                            mac: "MAC アドレス",
                            clientId: "クライアントID",
                            hostname: "ホスト名",
                            validateMac: "有効なMACアドレスでなければなりません。",
                            validateHostname: "英数字、「-」または「_」の文字のみを含める必要があります。",
                            duplicate: "LAN内で固有である必要があります。",
                            duplicateName: "固有である必要があります。"
                        },
                        descriptions: {
                            dhcpEnable: "このサイトの DHCP サービスプロバイダーを有効にします。",
                            blockUnknownMac: "有効にすると、下の表に記載されている MACアドレスのみが DHCPサービスを使用できます。",
                            networks: {
                                enable: "このリンクのデバイスで DHCPサーバーを有効にします。",
                                description: "この DHCP サーバー構成の説明を任意で入力します。",
                                port: "この DHCPネットワークを構成する LANインターフェース",
                                firstIp: "DHCP割り当てに使用できる最初の IP。ポート IPとネットマスクで定義されたネットワーク内にある必要があります。",
                                lastIp: "DHCP割り当てに使用できる最後の IP。ポート IPとネットマスクで定義されたネットワーク内にある必要があります。",
                                domain: "DHCPサーバーに割り当てるドメイン名を任意で設定します。",
                                dnsServers: "使用する DNS サーバーのリスト",
                                bootFileName: "オプションで、クライアントによってロードされる初期ブート ファイルの名前を設定します。クライアントがファイルをロードするために使用すると予想されるファイル転送プロトコルで認識できる必要があります。",
                                defaultLeaseTime: "リースに割り当てられるデフォルトの長さ (秒)",
                                maxLeaseTime: "リースに割り当てられる最大の長さ (秒)",
                                winsServers: "オプションで、1つ以上の WINSサーバー IPアドレスを追加します。",
                                tftpHostname: "オプションで、TFTPサーバーのホスト名を設定します。",
                                tftpIpAddress: "オプションで TFTP サーバーの IPアドレスを設定します。",
                                vendorOptions: "オプションとして、1つまたは複数の「;」（セミコロン）区切りでベンダーオプションを設定します。"
                            },
                            reservations: {
                                name: "この予約を識別するために使用する名前",
                                description: "この予約のオプションの説明",
                                port: "この予約を行うための LANインターフェース",
                                ip: "クライアント用に予約するIP",
                                mac: "クライアントの識別用 MACアドレス",
                                clientId: "IPアドレスを要請する際にホストクライアントを識別するクライアント識別子",
                                hostname: "この IPに割り当てるホスト名"
                            }
                        }
                    },
                    advanced: {
                        tabTitle: "詳細設定",
                        primaryDns: "プライマリ DNS",
                        secondaryDns: "セカンダリ DNS",
                        rootPassword: "rootのパスワード",
                        rootPasswordConfirmation: "rootのパスワード確認入力",
                        updateWindowTZ: "更新ウィンドウのタイムゾーン",
                        disableOMS: "ログ分析を無効にする。",
                        disableSecurityPolicy: "セキュリティを無効にする。",
                        disableIpsPolicy: "IPSを無効にする。",
                        omsNote: "これにより既定のログ分析設定は上書きされます。",
                        descriptions: {
                            updateWindowTZ: "このサイトの更新ウィンドウを決定するために使用されるサイトのタイムゾーン",
                            primaryDns: "プライマリ DNSアドレスを手動で設定してください。",
                            secondaryDns: "セカンダリ DNSアドレスを手動で設定してください。",
                            rootPassword: "サイトの rootユーザーパスワード",
                            disableOMS: "このサイトの Azure Log Analyticsへのログのストリーミングを無効にします。",
                            disableSecurityPolicy: "このサイトのすべてのセキュリティポリシーを無効にする。",
                            disableIpsPolicy: "このサイトの IPSポリシーを無効にする。"
                        },
                        downloadBootstrapConfigDialog: {
                            button: "ボタン",
                            buttons: "ボタン",
                            buttonLabel: "Bootstrap設定",
                            title: "Bootstrap設定のダウンロード",
                            dialogMessage: "デバイスは DHCP対応のインターネット接続に接続されている限り自動的に SecureEdgeに接続し、デバイス設定をダウンロードします。\n\nインターネット接続が簡単に提供できない場合は、 \"Barracuda Firewall Install\" ツールと USBフラッシュドライブを使用してアプライアンスを構成できます。このツールに関するドキュメントについては、 <0>こちら</0>を参照してください。\n\n 以下の {{button}} を使用して構成ファイルをダウンロードします。これらのファイルは、Barracuda Firewall Install セットアップ中に \"CGWの構成\" として提供される必要があります。",
                            dialogMessageSc: "デバイスは DHCP対応のインターネット接続に接続されている限り自動的に SecureEdgeに接続し、デバイス設定をダウンロードします。\n\n以下の {{button}} を使用して構成ファイルをダウンロードします。\nこのファイルは、USBフラッシュ ドライブのルート ディレクトリにコピーする必要があります。\n起動時に USBストレージが存在する場合は、構成が適用されます。",
                            downloadButtonLabel: "ダウンロード",
                            serial: "シリアル {{serial}}"
                        }
                    },
                    confirm: "選択したポートの一部は現在このサイトでは有効になっていません。続行してもよろしいですか？"
                },
                table: {
                    name: "名前",
                    description: "説明",
                    serial: "シリアル",
                    model: "モデル",
                    gateway: "エッジサービス",
                    cloudVirtualWan: "Cloud VWAN",
                    privateCloud: "なし",
                    status: "接続ステータス",
                    peeringAddress: "ピアリングアドレス",
                    asn: "AS 番号",
                    lan: "LAN",
                    wan: "WAN",
                    linkAddresses: "アドレス",
                    noSites: "サイトが見つかりません。",
                    noIot: "IoT デバイスが見つかりません。",
                    dynamic: "動的",
                    wwan: "WWAN",
                    vpnSite: "クライアント VPN 構成をダウンロードします。",
                    vpnSiteDisabled: "このアクションを実行するには、ユーザー接続機能が有効になっており、ユーザー接続用に静的 WANが割り当てられている必要があります。",
                    noGateway: "(なし)",
                    licenseType: "ライセンスタイプ",
                    licenseExpiration: "ライセンス有効期限",
                    bridge: "ブリッジ"
                },
                siteNotFound: "お探しのサイトが見つかりませんでした。",
                noData: {
                    iconHeader: "まずサイトを追加してください！",
                    textHeader: "サイトとは何ですか？",
                    text: "サイトとは、サイト デバイスによって接続 (および保護) されている場所を指します。<0 />音声入力による翻訳</0>サイト デバイスは、ハードウェアまたは仮想アプライアンスとして展開できます。どちらでもニーズに最も合ったものを選択できます。<0 />サイト デバイスをネットワーク セットアップに追加するのは、シンプルでわかりやすいプロセスです。<0 /><0 />まだサイトをお持ちではありませんか？",
                    demoLicenseLink: "<0>デモ ライセンスを取得</0>"
                }
            },
            gateways: {
                menuTitle: "エッジサービス",
                pageTitle: "エッジサービス",
                noData: {
                    iconHeader: "まずはエッジ サービスを追加しましょう!",
                    textHeader: "エッジサービスとは何ですか？",
                    text: "エッジ サービスは、サイトとリモート アクセス エージェントの接続ポイントとして機能します。<0 />サイトとエージェントは両方とも、最適なサービス品質を実現するために、最も近い利用可能なエッジ サービスを自動的に選択します。"
                },
                table: {
                    asn: "AS番号",
                    name: "名前",
                    type: "タイプ",
                    upstream: "アップリンク",
                    region: "場所",
                    instances: "インスタンス",
                    size: "サイズ",
                    HA: "HA",
                    upstream_gateway: "アップリンク エッジサービス",
                    scaleUnit: "スケールユニット",
                    sites: "サイト",
                    status: "接続ステータス",
                    vpnGateway: "クライアント VPN 構成をダウンロードします。",
                    vpnGatewayDisabled: "このアクションを実行するには、ユーザー接続機能を有効にする必要があります。",
                    privateCloud: "プライベートエッジサービス",
                    managedCloud: "エッジサービス",
                    noGateway: "(なし)",
                    mbit: "Mbit",
                    filters: {
                        sizeSelector: "サイズタイプ",
                        scaleUnit: "スケールユニット",
                        model: "モデル",
                        bandwidth: "帯域幅"
                    }
                },
                dashboard: {
                    locationsCardTitle: "マップ",
                    tabTitle: "ダッシュボード",
                    connectedSites: {
                        tabTitle: "接続されたサイト"
                    },
                    firmware: {
                        tabTitle: "ファームウェア",
                        version: "バージョン",
                        hotfixes: "適用済ホットフィックス",
                        window: "次回更新",
                        nextUpdate: "{{from}}から{{to}}の間"
                    }
                },
                liveLog: {
                    tabTitle: "ライブ接続",
                    pageTitle: "ライブ接続",
                    waitingForConnections: "エッジサービスに接続しています。接続が確立されるまで最大 2 分を要することもあります。お待ちください...",
                    emptyData: "現在アクティブなライブ接続はありません...",
                    pauseRequestsButton: "一時停止",
                    resumeRequestsButton: "再開"
                },
                connectionHistory: {
                    pageTitle: "最近の接続",
                    tabTitle: "最近の接続",
                    waitingForConnections: "エッジサービスに接続しています。接続が確立されるまでに最大 2 分かかる場合があります。お待ちください...",
                    emptyData: "現在アクティブなライブ接続はありません...",
                    pauseRequestsButton: "一時停止",
                    resumeRequestsButton: "再開"
                },
                delete: {
                    body: "このエッジサービスを削除してもよろしいですか？",
                    title: "エッジサービス {{name}}を削除します。",
                    forbidden: "このエッジ サービスを削除するには、まず接続されているすべてのエッジ サービスとサイトを削除してください。"
                },
                dialog: {
                    basic: {
                        name: "名前",
                        sectionTitle: "基本情報",
                        tabTitle: "基本"
                    },
                    create: {
                        buttonText: "新規エッジサービス",
                        cloud: {
                            menuLabel: "仮想WAN用エッジサービス",
                            dialogTitle: "エッジサービス トークンを生成"
                        },
                        onPrem: {
                            menuLabel: "プライベートエッジサービス",
                            dialogTitle: "新規プライベートエッジサービス",
                            serials: {
                                sectionTitle: "プライベートエッジサービス アプライアンス"
                            }
                        },
                        managed: {
                            menuLabel: "エッジサービス",
                            dialogTitle: "新規エッジサービス",
                            name: "名前",
                            region: "リージョン",
                            bandwidth: "帯域幅",
                            bandwidthAvailable: "{{availableBandwidth}} Mbpsが利用可能",
                            invalidBandwidth: "選択可能な最大帯域幅：{{availableBandwidth}} Mbit。この EdgeService を作成するには、帯域幅の選択を減らすか、追加の容量を購入してください。"
                        }
                    },
                    edit: {
                        dialogTitle: "エッジサービスの編集"
                    },
                    result: {
                        managedAppText: "マネージドアプリの OTP",
                        message: "次のトークンをコピーして<0>仮想WAN用エッジサービス</0>デプロイメントに貼り付けてください。",
                        managedAppOtp: "このトークンは単一のデプロイメントにのみ有効で、30分後に期限切れになります。"
                    },
                    steps: {
                        result: "完了",
                        submit: "設定"
                    },
                    validation: {
                        duplicateName: "重複するエッジサービス名であってはなりません"
                    }
                },
                settings: {
                    tabTitle: "設定",
                    labels: {
                        secureInternetTraffic: "VNETからのセキュアインターネットトラフィック"
                    },
                    descriptions: {
                        secureInternetTraffic: "このエッジサービスによるこの仮想 ネットワークでのインターネットトラフィックの検査を有効にします。"
                    },
                    editBandwidthWarning: "帯域幅を変更すると、エッジサービスを再起動する必要があるため、一時的に利用できなくなります。",
                    managed: {
                        bandwidth: {
                            invalidBandwidth: "選択可能な最大帯域幅：{{availableBandwidth}} Mbit。このエッジサービスを更新するには、帯域幅の選択を減らすか、追加の容量を購入してください。。"
                        }
                    }
                },
                auditLog: {
                    tabTitle: "監査Log",
                    pageTitle: "監査Log"
                },
                gatewayNotFound: "検索しているエッジサービスが見つかりませんでした。"
            },
            logs: {
                pageTitle: "ログとレポート",
                audit: "監査ログ",
                notifications: "通知",
                notificationsMessage: "すべてのワークスペースで管理イベントとシステム イベントの通知を構成します。",
                auditLog: {
                    message: "すべてのワークスペースの管理およびシステム イベントを示す監査Log",
                    labels: {
                        status: "ステータス",
                        created: "作成者",
                        type: "タイプ",
                        event: "名前",
                        serial: "シリアル",
                        description: "内容",
                        remoteAddress: "パブリックIP",
                        createdUsername: "管理者"
                    }
                },
                connectionHistory: {
                    detailsTitle: "接続詳細",
                    labels: {
                        application: "アプリケーション",
                        count: "カウント",
                        destinationIP: "宛先 IP",
                        destinationPort: "宛先ポート",
                        information: "情報",
                        ipProtocol: "IPプロトコル",
                        lastTime: "前回",
                        nextHop: "ネクストホップ",
                        sourceIP: "送信元 IP",
                        sourceMac: "送信元 MAC",
                        state: "状況",
                        urlCategory: "URLカテゴリー",
                        user: "ユーザー"
                    }
                },
                liveLog: {
                    detailsTitle: "接続の詳細",
                    labels: {
                        agentId: "エージェントID",
                        appName: "アプリケーションコンテキスト",
                        application: "アプリケーション",
                        connectionTrafficState: "接続状態",
                        destinationBytesDescription: "送信バイト数",
                        destinationIp: "宛先 IP",
                        destinationInterface: "宛先インターフェース",
                        destinationPort: "宛先ポート",
                        idleTime: "アイドルタイム",
                        ipProtocol: "IPプロトコル",
                        protocol: "プロトコル",
                        protocolId: "プロトコル ID",
                        serial: "シリアル",
                        sourceBytesDescription: "受信バイト数",
                        sourceIp: "送信元IP",
                        sourcePort: "送信元ポート",
                        sourceNat: "送信元NAT",
                        speedDescription: "スピード",
                        startTime: "開始時刻",
                        state: "状況",
                        target: "ターゲット",
                        type: "タイプ",
                        totalBytesDescription: "合計バイト数",
                        urlCategories: "URLカテゴリー",
                        user: "ユーザー",
                        vpnRoute: "VPNルート"
                    },
                    connectionState: {
                        initiate: "半二重トラフィック有効",
                        fail: "半二重トラフィック無効",
                        sync: "全二重トラフィック",
                        closing: "接続を終了しています。",
                        established: "接続が確立されました。",
                        httpDeny: "接続は確立されませんでした。"
                    }
                }
            },
            policies: {
                menuTitle: "SD-WAN",
                sdwan: {
                    tabTitle: "アプリケーションカテゴリー",
                    search: "アプリケーションの検索",
                    table: {
                        category: "カテゴリー",
                        apps: "アプリケーション",
                        customApps: "カスタム アプリケーション",
                        priority: "優先度",
                        action: "アクション",
                        fallback: "フォールバック",
                        loadBalancing: "負荷分散",
                        reset: "リセット"
                    }
                },
                explicit: {
                    delete: {
                        title: "{{name}}を削除",
                        body: "このポリシーを削除してもよろしいですか？"
                    },
                    tabTitle: "オーバーライドカテゴリー",
                    table: {
                        order: "順番",
                        name: "名前",
                        apps: "アプリケーション",
                        sources: "ソース",
                        user: "ユーザー",
                        traffic: "トラフィックタイプ",
                        priority: "優先度",
                        action: "アクション",
                        fallback: "フォールバック",
                        loadBalancing: "負荷分散"
                    },
                    noData: {
                        iconHeader: "SD-WANポリシーのオーバーライドをここに追加して開始してください！",
                        labelButton: "オーバーライドポリシーを追加",
                        text: "SecureEdgeの構成はすぐに使えるSD-WANポリシーの共通セットを提供します。<0 />ただし、セットアップで特定のアプリケーションに対して異なる SD-WAN プロファイルが必要な場合は、ここでカスタム ポリシーを定義できます。"
                    }
                },
                dialogs: {
                    explicit: {
                        create: {
                            buttonText: "ポリシーを追加",
                            dialogTitle: "新規ポリシー"
                        },
                        edit: {
                            dialogTitle: "ポリシーの編集"
                        },
                        general: {
                            sectionTitle: "全般",
                            name: "名前",
                            description: "説明",
                            descriptions: {
                                name: "このポリシーの固有名",
                                description: "このポリシーの説明"
                            }
                        },
                        criteria: {
                            sectionTitle: "判断基準",
                            applications: "アプリケーション",
                            sources: "ソースIP/ネットワーク",
                            trafficType: "トラフィックタイプ",
                            descriptions: {
                                applications: "このポリシーを適用する特定のアプリケーション。少なくとも 1 つのアプリケーションまたはソースを指定する必要があります。",
                                sources: "このポリシーを適用する特定のソース IP/ネットワーク。少なくとも 1 つのアプリケーションまたはソースを指定する必要があります。",
                                trafficType: "オプションとして、このポリシーを適用する特定のトラフィックタイプを定義します。"
                            }
                        },
                        action: {
                            sectionTitle: "アクション",
                            priority: "優先度",
                            action: "アクション",
                            fallback: "フォールバック",
                            loadBalancing: "負荷分散",
                            forwardErrorCorrection: {
                                label: "ラストマイル最適化",
                                options: {
                                    on: "オン",
                                    off: "オフ"
                                }
                            },
                            descriptions: {
                                priority: "このポリシーに一致するトラフィックに適用する優先度レベル",
                                action: "このポリシーに一致するトラフィックに対して実行するアクション",
                                fallback: "このポリシーに一致するトラフィックに適用するフォールバック動作",
                                loadBalancing: "このポリシーに一致するトラフィックに適用する負荷分散動作",
                                forwardErrorCorrection: "このポリシーに一致するトラフィックに対してラスト マイル最適化をオンにします。これにより、損失の多いネットワークを改善できますが、クライアントによる帯域幅の使用量は増加します。"
                            }
                        },
                        validation: {
                            required: "アプリケーションまたはソースが必要です。"
                        }
                    }
                }
            },
            sdwanExplicitPolicies: {
                delete: {
                    title: "ポリシー {{name}}を削除",
                    body: "このポリシーを削除してもよろしいですか？"
                }
            },
            audit: {
                menuTitle: "監査ログ",
                pageTitle: "監査ログ",
                labels: {
                    created: "作成済",
                    type: "タイプ",
                    event: "名前",
                    serial: "シリアル",
                    description: "説明",
                    status: "ステータス",
                    createdUsername: "管理者",
                    remoteAddress: "パブリックIP"
                },
                filter: {
                    created: {
                        days1: "1日",
                        days3: "3日",
                        weeks1: "1週間",
                        weeks2: "2週間",
                        months1: "1ヶ月",
                        months2: "2ヶ月",
                        months3: "3ヶ月",
                        months6: "6ヶ月",
                        months9: "9ヶ月",
                        years1: "1年"
                    }
                }
            },
            settings: {
                menuTitle: "設定",
                pageTitle: "設定",
                tabs: {
                    workspaces: {
                        title: "ワークスペース",
                        pageTitle: "ワークスペースを管理",
                        fields: {
                            name: "名前",
                            description: "説明",
                            associations: "サイト/エッジサービス"
                        },
                        dialog: {
                            create: {
                                buttonLabel: "新規ワークスペース",
                                dialogTitle: "新規ワークスペース"
                            },
                            edit: "ワークスペースを編集"
                        },
                        delete: {
                            body: "このワークスペースを削除してもよろしいですか？",
                            title: "{{name}}を削除します。",
                            disabled: {
                                notEmpty: "関連付けられたサイトまたはエッジサービスがある限りワークスペースを削除することはできません。",
                                lastWorkspace: "少なくとも 1つのワークスペースが必要です。"
                            }
                        },
                        none: "利用可能なワークスペースはありません。"
                    },
                    workspaceSettings: {
                        title: "ワークスペース設定"
                    },
                    updates: {
                        title: "更新ウィンドウ",
                        firmware: {
                            title: "ファームウェア更新ウィンドウ",
                            description: "ファームウェア更新ウィンドウは少なくとも 3時間以上必要であり、デバイスの場所の現地時間帯で実行されます。",
                            noWindowsMessage: "更新ウィンドウは未設定です",
                            noUpdatesMessage: "インストール可能な更新はありません。",
                            noAppliancesMessage: "現在、更新を要するデバイスはありません。",
                            default: {
                                label: "既定の更新ウィンドウ:",
                                to: "終了時間",
                                from: "開始時間",
                                setting: "{{from}} <0>～</0> {{to}}"
                            },
                            create: {
                                buttonText: "新規更新ウィンドウ:",
                                dialogTitle: "新規更ウィンドウを追加"
                            },
                            edit: {
                                dialogTitle: "更新ウィンドウを編集"
                            },
                            delete: {
                                title: "更新ウィンドウを削除",
                                message: "削除されると、この更新ウィンドウに関連付けられたサイトまたはエッジサービスは、デフォルトの更新ウィンドウを使用します。"
                            },
                            fields: {
                                appliance: "サイト/エッジサービス",
                                applianceDescription: "更新ウィンドウ中に更新されるエッジ サービスまたはスタンドアロン サイト",
                                from: "開始",
                                fromDescription: "ファームウェア更新を自動的にインストールできる期間の開始時刻。時間枠は、各エッジサービスまたはスタンドアロンサイトの自動的に検出されたローカル タイムゾーンを利用します。",
                                to: "宛先",
                                toDescription: "ファームウェア更新を自動的にインストールできる期間の開始時刻。時間枠は、各エッジサービスまたはスタンドアロンサイトの自動的に検出されたローカル タイムゾーンを利用します。",
                                windowLength: "これはファームウェア更新枠 {{duration}}を提供します。"
                            },
                            updateNow: {
                                buttonLabel: "今すぐインストール",
                                disabledMessage: "利用可能なアップデートはありません。",
                                dialogTitle: "今すぐアップデートをインストール",
                                dialogMessage: "今すぐ更新すると、スケジュールされた更新ウィンドウがすべて削除され、すべてのエッジサービスとスタンドアロンサイトが直ちに更新されます。エッジサービスのあるサイトは、エッジサービスの更新が完了すると更新を開始します。これにより、ダウンタイムが発生する可能性があります。"
                            },
                            validation: {
                                insufficientWindow: "更新時間枠は少なくとも 3時間以上必要です。"
                            },
                            editDefault: {
                                dialogTitle: "デフォルトの更新ウィンドウを編集"
                            }
                        },
                        security: {
                            title: "セキュリティ更新ウィンドウ",
                            window: "ホットフィックス更新ウィンドウ",
                            windowDescription: "ホットフィックスの更新を自動的にインストールできる期間。この時間枠は各エッジサービス またはサイトの自動的に検出された現地の時間帯に関連しており、サイト構成において上書き可能です。"
                        },
                        hotfixUpdateWindow: "ホットフィックス更新ウィンドウ",
                        validation: {
                            invalidStartTime: "無効な開始時刻",
                            invalidEndTime: "無効な終了時刻"
                        }
                    },
                    oms: {
                        title: "Azureモニター",
                        enabled: "有効",
                        id: "ワークスペース ID",
                        key: "ワークスペースキー",
                        descriptions: {
                            oms: "Microsoft Operations Management Suite (OMS) を使用する場合にのみ有効にします。これは廃止予定です。"
                        },
                        clientId: "クライアントID",
                        clientSecret: "クライアントシークレット",
                        correlationId: "相関性ID",
                        region: "リージョン",
                        resourceGroup: "リソースグループ",
                        subscriptionId: "サブスクリプションID",
                        tenantId: "テナントID",
                        oms: "Azure Log Analytics (OMS)",
                        disabled: "無効",
                        introduction: "Azure Monitorでは ITシステムおよび技術基盤によって生成されるマシンデータの検索、解析、視覚化が可能であり、ネットワークに関する知見が深まります。ログsをAzureモニターアカウントに流し込んで環境のログsデータをさらに処理することができます。"
                    },
                    global: {
                        title: "全体設定"
                    },
                    forwardedDomains: {
                        title: "転送されたドメイン",
                        domains: "ドメイン",
                        descriptions: {
                            domains: "Azureへ解決のために転送るす必要のある DNSドメイン"
                        },
                        domain: "ドメイン",
                        forwarders: "サーバー",
                        button: "追加",
                        duplicateName: "ドメイン名はすでに使用されています",
                        addDomain: "ドメインを追加",
                        editDomain: "ドメインを編集",
                        deleteDomain: "ドメインを削除"
                    },
                    vmActivation: {
                        title: "VMアクティベーション",
                        firstName: "名前",
                        lastName: "苗字",
                        companyName: "会社名",
                        phoneNumber: "電話番号",
                        address: "住所 1",
                        address2: "住所 2",
                        city: "都市",
                        country: "国",
                        emailAddress: "メールアドレス",
                        state: "都道府県",
                        zip: "郵便番号",
            billingDetailsSection: "請求の詳細",
            billingAddressSection: "請求先住所"
                    },
                    notification: {
                        title: "通知",
                        delete: "通知を削除 ",
                        labels: {
                            name: "名前",
                            events: "イベント",
                            status: "ステータス",
                            administrators: "管理者",
                            actions: "アクション"
                        },
                        descriptions: {
                            name: "この通知の固有名",
                            events: "通知を送信するイベント",
                            status: "通知を送信するステータスタイプ",
                            administrators: "通知を送信する管理者のメール アドレス",
                            actions: "通知を送信するアクション"
                        },
                        components: {
                            button: {
                                add: "追加",
                                edit: "編集",
                                delete: "削除",
                                save: "保存",
                                cancel: "キャンセル"
                            },
                            title: {
                                editNotification: "通知を編集",
                                createNotification: "通知を作成"
                            }
                        },
                        action: {
                            deleteNotification: "この通知を本当に削除してもよろしいですか？"
                        }
                    },
                    sdwanConnectorNetwork: {
                        title: "コネクタ",
                        subMenu: "クライアントネットワーク範囲",
                        description: "クライアントネットワークを使用してコネクタの背後にあるリソースに IPを割り当てます。"
                    },
                    reportCreatorAcl: {
                        title: "Report Creator",
                        subMenu: "許可されているネットワーク",
                        description: "Report Creatorツールがサイトのデバイスとエッジサービスを問い合わせるプライベートネットワークおよび IPアドレスのリストを入力します。"
                    },
                    dcAgent: {
                        title: "DCエージェント",
                        description: "構成した DCエージェントの IPアドレスを入力します。",
                        ipAddress: "IPアドレス"
                    },
                    dhcpServer: {
                        title: "DHCPリレーサーバー",
                        description: "使用する DHCP サーバーの IP アドレスを入力します。",
                        ipAddress: "IPアドレス"
          },
          SIPProxy: {
            title: "SIP Proxy",
            UdpTimeout: "UDPのタイムアウト",
            enabled: "有効",
            sipProxySetting: "SIP Proxy",
            cardDescription: "SIP Proxyは、IP電話、モバイルデバイス、会議機器などのエンドポイント間の仲介役として機能します。",
            description: {
              UdpTimeout: "SIP Proxyが無効の場合に使用するには、UDPタイムアウトを設定してください（単位：秒）。",
              sipProxySetting: "SIP Proxyはデフォルトのタイムアウトである60秒を使用します。"
            }
                    }
                }
            },
            statusBanner: {
                subscriptionExpiresSoon: "サブスクリプションは{{days}} 日後に期限切れになります。",
                subscriptionExpiresOneDay: "サブスクリプションはあと 1日で期限切れになります。",
                subscriptionExpiresToday: "サブスクリプションは満了まで 1日を切りました。",
                deprecatedExpiresSoon: "ご利用中の Azureサブスクリプションプランはもはやサポートされていません。新しいプランに切り替えない限りこのサービスはあと {{days}} 日で満了となります。プランを{{linkStart}}ここで{{linkEnd}}変更してください。",
                deprecatedExpiresOneDay: "ご利用中の Azureサブスクリプションプランはもはやサポートされていません。このサービスは新しいプランに変更しない場合、あと 1日で満了となります。プランを{{linkStart}}ここで{{linkEnd}}変更してください。",
                deprecatedExpiresToday: "ご利用中の Azureサブスクリプションプランはもはやサポートされていません。このサービスは新しいプランに変更しない場合、このサービスは 1 日以内に期限切れになります。プランを{{linkStart}}ここで{{linkEnd}}変更してください。",
                updateAvailable: "新しいファームウェア アップデートが利用可能です！ 更新プロセスを開始するには、{{date}}までにメンテナンス ウィンドウをスケジュールしてください。",
                pamBandwidthCapacity: "現在使用しているエッジサービスの帯域幅はライセンスされている帯域幅を超過しています。さらに帯域幅をご購入いただくため営業部にご連絡ください。"
            },
            states: {
                pending: "保留中",
                completed: "完了",
                online: "オンライン",
                offline: "オフライン",
                failed: "失敗",
                error: "エラー",
                degraded: "ダウングレード",
                connecting: "接続中",
                unknown: "不明",
                loading: " "
            },
            validation: {
                alphaNumericDash: "数字、文字、ハイフンのみを含める必要があります。",
                alphaNumericDashNoHaSuffix: "「-ha」サフィックスを含めることはできません。",
                alphaNumericDashSpace: "数字、文字、ハイフン、スペースのみを含める必要があります。",
                alphaNumericDotsDash: "数字、文字、ドット、ハイフンのみを含める必要があります。",
                alphaNumericDotsColonsDash: "数字、文字、ドット、コロン、ハイフンのみを含める必要があります。",
        customKeyword: "数字、文字、ハイフン、ピリオド、アポストロフィー、スペース最大128文字のみお使いいただけます。最初と最後の文字は必ずアルファベット文字にしてください。",
                uuid: "有効な UUID/GUID である必要があります"
            },
            footer: {
                serial: " | サブスクリプションシリアル番号: {{serial}}"
            },
            users: {
                table: {
                    fullName: "氏名",
                    email: "メールアドレス",
                    deviceCount: "デバイス台数",
                    enrollmentCompleted: {
                        label: "登録完了",
                        yes: "登録完了",
                        no: "登録保留中"
                    },
                    enrollmentLink: "登録用リンクをコピー",
                    enrollmentLinkCopied: "登録用リンクはクリップボードにコピーされました",
                    resendInvite: "招待を再送",
                    tamperProof: "タンパープルーフ",
                    devicePreConnect: "デバイス プレログオン",
                    webFiltering: "ウェブフィルター",
                    optionsOverride: "ユーザーオーバーライド",
                    on: "オン",
          off: "オフ",
          tpmEnforcement: "TPMを強制実行"
                },
                delete: {
                    title: "ユーザーの登録解除",
                    body: "このユーザーを削除してもよろしいですか？"
                },
                dialog: {
                    buttonText: "ユーザーを登録",
                    disabledButton: "まずは、[アイデンティティ] > [設定] ページに移動して、ユーザー ディレクターとアイデンティティ プロバイダーを構成する必要があります。"
                },
                noData: {
                    iconHeader: "まずはユーザーを追加してください！",
                    labelButton: "ユーザーを追加して招待",
                    textHeader: "SecureEdge にとってユーザーが重要な理由は何ですか？",
                    text: "SecureEdgeは、ネットワーク上のユーザーのアクセスと権限を制御します。したがって、ここで、誰がアクセスできるかを判断し、SecureEdge Access Agentの登録プロセスを開始できます。",
                    firstTitle: "1",
                    firstStep: "登録招待を送ります",
                    secondTitle: "2",
                    secondStep: "ユーザーにエージェントをダウンロードしてインストールさらに自己登録するようご案内します。",
                    textFooter: "これで完了です。これで始める用意ができました！"
                },
                settings: {
                    global: "ユーザーの上書き",
                    introduction: "このユーザーのグローバル ZTNA設定を上書きします。",
                    tamperProof: "タンパープルーフ",
                    devicePreConnect: "デバイスプレログオン",
                    deviceLimit: "ユーザーデバイス制限",
                    webFiltering: "ウェブフィルター",
                    enabled: "有効",
                    disabled: "無効",
                    overrideEnabled: "下記の指定設定を使用",
                    overrideDisabled: "グローバル ZTNA設定を使用",
                    descriptions: {
                        tamperProof: "ユーザーが SecureEdgeアクセスエージェントを無効にできないようにします。 iOS、iPadOS、または Android を実行するデバイスには、MDMソリューションが必要です。",
            deviceLimit: "ユーザーあたりのデバイス数",
            tpmEnforcement: "信頼できるプラットフォームモジュール。お使いのデバイスでTPMチップを使用してデバイス認証証明書のエクスポートを禁止し、安全に保存できます。"
          },
          tpmEnforcement: "TPMの強制実行"
                }
            },
            devices: {
                table: {
                    userName: "ユーザー",
                    email: "メールアドレス",
                    brand: "メーカー",
                    model: "モデル",
                    hardwareModel: "ハードウェアモデル",
                    os: "OS",
                    osFlavour: "OS種別",
                    osVersion: "OSバージョン",
                    enrollmentDate: "登録日"
                },
                delete: {
                    body: "このデバイスを削除してもよろしいですか？",
                    title: "デバイスを削除"
                },
                noData: {
                    iconHeader: "登録されたデバイスがみつかりません！",
                    action: "ユーザーを追加して招待",
                    textHeader: "登録されたデバイスとは何ですか？",
                    text: "ユーザーを招待して自己登録プロセスを開始し、ユーザーがオンボーディング手順を完了すると、SecureEdge Access Agentがインストールされているこのユーザーのすべてのデバイスがここにリストされます。"
                }
            },
            firewalls: {
                table: {
                    name: "名前",
                    serials: "シリアル",
                    isCcManaged: "CC管理",
                    model: "モデル"
                },
                delete: {
                    title: "CloudGen Firewall {{name}} を切断",
                    body: "この CloudGen ファイアウォールを SecureEdge サービスから切断してもよろしいですか？",
                    forbidden: "エンドポイントとして構成されている場合、CloudGen Firewallの統合を削除することはできません。"
                },
                dialog: {
                    create: {
                        buttonText: "登録トークン",
                        dialogTitle: "登録トークンを生成"
                    },
                    result: {
                        message: "次のトークンをコピーして<0>ファイアウォール管理者アクティベーションフォーム</0>に貼り付けてください。",
                        firewallText: "ファイアウォール登録トークン",
                        firewallOtp: "このトークンは単一のファイアウォールに対してのみ有効で、30分後に期限切れになります。"
                    }
                },
                noData: {
                    iconHeader: "CloudGen Firewall の展開に接続します！",
                    labelButton: "登録トークンを取得します。",
                    textHeader: "CloudGen Firewallを現在の構成に追加しますか",
                    text: "すでに Barracuda CloudGen ファイアウォールが稼働している場合は、この展開をアクセス エージェントのエントリ ポイントとして要求することもできます。 CloudGen Firewall の展開を要求するのは、CloudGen Firewall の管理コンソールで登録トークンをコピーするのと同じくらい簡単です。<0 /><0 /> CloudGen Firewallを要求すると、リモート アクセス コンポーネントは Firewall Adminツールで制御できなくなることに注意してください。"
                }
            },
            integration: {
                pageTitle: "統合",
                firewalls: "CloudGenファイアウォール (CloudGen Firewall)",
                ipSecV2: {
                    create: "IPsecトンネルを作成",
                    createButton: "IPsecトンネルを追加",
                    edit: "IPsecトンネルを編集",
                    general: "全般",
                    network: "ネットワーク",
                    phases: "フェーズ",
                    sourceDest: "送信元／送信先",
                    success: "成功",
                    successMessage: "新規IPsecトンネルは正常に作成されました",
                    pageTitle: "IPsec VPN",
                    table: {
                        status: "ステータス",
                        name: "名前",
                        description: "説明",
                        enabled: "有効",
                        gateway: "リモートゲートウェイ",
                        localNetworks: "ローカルネットワーク",
                        localBgp: "ローカルBGP",
                        remoteNetworks: "リモートネットワーク",
                        remoteBgp: "リモートBGP",
                        type: "タイプ",
                        siteWanInterface: "SecureEdgeピア",
                        peerIp: "ピア IP",
                        duration: "継続時間",
                        encryption: "暗号化",
                        filters: {
                            enabled: {
                                enabled: "有効",
                                disabled: "無効"
                            },
                            secureEdgePeer: {
                                peerType: "ピアタイプ",
                                edgeService: "エッジサービス",
                                site: "サイト"
                            }
                        },
                        ellipsisMenu: {
                            restart: "IPsecトンネルを再起動"
                        }
                    },
                    cloudCantInitiate: "トンネルを開始するように設定できるのはサイトとプライベート エッジ サービスのみです。",
                    dialog: {
                        create: {
                            buttonText: "トンネルを追加"
                        },
                        delete: {
                            body: "この IPsec トンネルを削除してもよろしいですか？",
                            title: "IPsecトンネル {{name}} を削除"
                        },
                        restart: {
                            ok: "OK",
                            cancel: "キャンセル",
                            body: "この IPsec トンネルを再起動してもよろしいですか？",
                            title: "IPsecトンネル {{name}} を再起動",
                            message: "トンネルを再起動しています。お待ちください"
                        },
                        generalSection: {
                            descriptionMessage: "新規トンネルを作成するには以下の設定を行って構成してください。",
                            tunnelStatus: "有効",
                            general: {
                                title: "一般情報",
                                name: "名前",
                                description: "説明",
                                initiatesTunnel: "トンネルを開始",
                                restartSa: "SAを再起動",
                                descriptions: {
                                    title: "一般情報説明",
                                    name: "このIPsecトンネルの固有名",
                                    description: "トンネルオプションの説明",
                                    tunnelStatus: "オフにすると、トンネルが一時的に無効になります。",
                                    initiatesTunnel: "有効にすると、SecureEdge デバイスが IPsec トンネルを開始します。"
                                }
                            },
                            authentication: {
                                title: "認証",
                                authentication: "認証",
                                sharedSecret: "共有のシークレット",
                                serverCert: "サーバー証明書",
                                caCert: "CA証明書",
                                x509Condition: "X509条件",
                                exploit509: "エクスプロイト509",
                                descriptions: {
                                    authentication: "IPsecトンネルが認証のために使用する方法",
                                    sharedSecret: "認証のための使用する事前共有キー。",
                                    serverCert: "サーバー証明書の説明",
                                    caCert: "CA証明書の説明",
                                    x509Condition: "X509条件の説明",
                                    exploit509: "エクスプロイト509の説明"
                                }
                            }
                        },
                        sourceDestSection: {
                            descriptionMessage: "追加するトンネルの送信元と送信先を設定します。",
                            enableBgp: "BGPを有効化",
                            descriptions: {
                                enableBgp: "BGPを有効化"
                            },
                            source: {
                                title: "ソース",
                                type: "タイプ",
                                peer: "ピア",
                                ipAddresses: "ピアゲートウェイ",
                                initiatesTunnel: "開始",
                                wanInterface: "WANインターフェース",
                                localId: "ローカルID",
                                networkAddresses: "ネットワークアドレス",
                                networkAddressBgp: "ピアリングアドレス",
                                asnBgp: "ASN",
                                descriptions: {
                                    ipAddresses: "選択したエッジ サービスのパブリック IP アドレス。リモート デバイスを構成するときに必要です。",
                                    localId: "トンネルのローカル側の IPsec ID",
                                    networkAddresses: "トンネルのローカルサイドで到達可能なネットワーク",
                                    networkAddressBgp: "ローカル側で BGP 通信に使用される IP アドレス",
                                    asnBgp: "ローカルサイドの自律システム番号",
                                    enableBgp: "ローカル サイトとリモート サイトで到達可能なネットワークを BGP 経由で動的に交換できるようにします。"
                                },
                                sourceTypeData: {
                                    gateway: "エッジサービス",
                                    site: "サイト"
                                },
                                localWans: "アドレス",
                                primaryAddress: "プライマリアドレス",
                                additionalAddresses: "追加のアドレス"
                            },
                            destination: {
                                title: "宛先",
                                remoteGatewayName: "名前",
                                remoteGateway: "リモートゲートウェイ",
                                remoteId: "リモートID",
                                networkAddresses: "ネットワークアドレス",
                                networkAddressBgp: "ピアリングアドレス",
                                asnBgp: "ASN",
                                multiInputTitle: "リモートゲートウェイ",
                                multiInputTitleLabel: "宛先",
                                descriptions: {
                                    remoteId: "トンネルのリモート側の IPsec ID",
                                    networkAddresses: "トンネルのリモート側で到達可能なネットワーク",
                                    networkAddressBgp: "リモート側で BGP 通信に使用される IP アドレス",
                                    asnBgp: "リモートサイドの自律システム番号",
                                    remoteGateway: "トンネルのリモート側の FQDN または IP。ローカル側がトンネルを開始しない場合は、0.0.0.0/0 に設定できます。"
                                }
                            }
                        },
                        phasesSection: {
                            descriptionMessage: "トンネルには 2 つのフェーズがあります。各フェーズに優先するセキュリティ設定を設定してください。",
                            phase1: {
                                title: "フェーズ1",
                                encryption: "暗号化",
                                hash: "ハッシュ",
                                dhGroup: "DHグループ",
                                proposalHandling: "プロポーザル",
                                lifetime: "有効期限",
                                descriptions: {
                                    proposalHandling: "厳密に設定すると、選択した暗号化、ハッシュ アルゴリズム、および Diffie Hellmanグループのみを使用できます。それ以外の場合、ネゴシエートに設定すると、イニシエーターは別のアルゴリズム セットと DHグループを選択できます。",
                                    lifetime: "IPsec (SA)が再キーされるまでの秒数。"
                                }
                            },
                            phase2: {
                                title: "フェーズ2",
                                lifetime: "有効期限",
                                trafficVolume: "トラフィック量 有効",
                                trafficVolumeKb: "トラフィック量 KB",
                                encryption: "暗号化",
                                hash: "ハッシュ",
                                dhGroup: "DHグループ",
                                proposalHandling: "プロポーザル",
                                descriptions: {
                                    proposalHandling: "厳密に設定すると、選択した暗号化、ハッシュ アルゴリズム、および Diffie Hellmanグループのみを使用できます。それ以外の場合、ネゴシエートに設定すると、イニシエーターは別のアルゴリズム セットと DHグループを選択できます。",
                                    lifetime: "IPsec (SA)が再キーされるまでの秒数。",
                                    trafficVolume: "有効にすると、トラフィック量に基づいて再キー化をトリガーできます。",
                                    trafficVolumeKb: "SAが再キーされるまでの Kb数"
                                }
                            }
                        },
                        networkSection: {
                            descriptionMessage: "ネットワーク設定を構成します。これらは高度なオプションであり、一般的なトンネルでは必須ではありません。",
                            networkSettings: {
                                title: "ネットワーク設定",
                                oneTunnelSubnet: "サブネットペア当たり 1つのVPNトンネル",
                                universalTrafficSelectors: "ユニバーサル トラフィック セレクター",
                                forceUdpEncapsulation: "UDPカプセル化を強制",
                                ikeReauthentication: "IKE再認証",
                                descriptions: {
                                    oneTunnelSubnet: "ローカル ネットワークとリモート ネットワークのペアごとに 1つの SAを作成します。",
                                    universalTrafficSelectors: "有効にすると、すべてのトラフィックがトンネルを通過できるようになります。",
                                    forceUdpEncapsulation: "NATが検出されていない場合でも、IPsecトンネルエンベロープに UDPポート 4500を使用します。",
                                    ikeReauthentication: "SA のキーが再設定されるたびに認証します。"
                                }
                            },
                            deadPeerDetection: {
                                title: "デッドピア検出",
                                actionDetected: "検出時のアクション",
                                delay: "遅延",
                                descriptions: {
                                    actionDetected: "リモートサイドが DPDメッセージに応答しない場合に実行される動作。「clear」でトンネルを終了し、「restart」でトンネル再確立が試行され、「none」は DPDを無効にします。",
                                    delay: "他のトラフィックを受信していない場合にDPDの「keep alive」メッセージが送られるまでの時間（秒）"
                                }
                            }
                        }
                    },
                    noData: {
                        buttonText: "IPsec接続を追加",
                        iconHeader: "まずは IPsec 接続を追加します！",
                        labelButton: "IPsec接続を追加",
                        textHeader: "IPsec接続を統合すべき理由は何ですか？",
                        text: "SecureEdge展開では、安全で信頼性の高い接続のために独自の Barracuda TINA VPNを使用します。IPsec IKEv2を使用できるサードパーティ展開に接続するには、この構成ページを参照してください。設定が完了すると、このページで IPsec VPN接続のステータスを確認できるようになります。"
                    }
                },
                azureMonitor: "Azureモニター (Azure Monitor)"
            },
            copy: {
                label: "クリップボードにコピー"
            },
            subscription: {
                pam: {
                    menuTitle: "サブスクリプション",
                    pageTitle: "プロファイル",
                    pageSubTitle: "サブスクリプション",
                    table: {
                        subscription: "サブスクリプション",
                        serial: "ライセンスのシリアル番号",
                        amount: "総数",
                        startDate: "開始日",
                        expirationDate: "有効期限",
                        noData: {
                            iconHeader: "まずはサブスクリプションを追加しましょう！",
                            textHeader: "サブスクリプション ライセンスを取得するには？",
                            text: "サブスクリプションで SecureEdgeディプロイメントに追加機能を有効化します。<0/>たとえば、リモートワーカー用に SecureEdge Accessライセンスを追加する場合は、このインターフェイスを使用して、展開に必要なライセンス数を追加するだけです。<0/>ニーズに最適なサブスクリプションを取得するには、Barracudaの営業担当者までお問い合わせください。"
                        },
                        information: "情報",
                        value: "{{value}}",
                        value_34: "{{value}} ユーザー",
                        value_24: "{{value}} Mbps",
                        msp: "MSP請求書"
                    }
                }
            },
            customCategories: {
                table: {
                    name: "名前",
                    description: "説明",
                    details: "詳細",
                    detailCategories: "カテゴリー ({{activeCategories.0.name}}, +{{additional}}) ",
                    detailCategories_1: "カテゴリー ({{activeCategories.0.name}}) ",
                    detailDomains: "ドメイン({{domains.0}}, +{{additionalDomains}})を含む",
                    detailDomains_1: "ドメイン({{domains.0}})を含む",
                    domainsExcluded: "除外されたドメイン",
                    domainsIncluded: "含まれるドメイン",
                    noData: "カスタムカテゴリーのルールが見つかりません。"
                },
                dialog: {
                    create: {
                        buttonText: "カスタムカテゴリーを追加",
                        dialogTitle: "新規カスタムカテゴリー"
                    },
                    edit: {
                        dialogTitle: "カスタムカテゴリーを編集"
                    },
                    name: "名前",
                    description: "説明",
                    details: "詳細",
                    includedDomains: "含まれるドメイン",
                    excludedDomains: "除外されたドメイン",
                    descriptions: {
                        name: "このルールの固有名",
                        description: "このルールの内容",
                        includedDomains: "このルールで影響を受けるドメインのリスト",
                        excludedDomains: "このルールで影響を受けない全ドメインのリスト"
                    },
                    validation: {
                        emptyCategories: "少なくとも 1 つのカテゴリまたはドメインを指定する必要があります。"
                    },
                    delete: {
                        title: "{{name}}を削除します。",
                        body: "このルールを削除してもよろしいですか？ このカスタムカテゴリを使用するセキュリティルールはすべて更新または削除されます。"
                    }
                }
            },
            syslog: {
                pageTitle: "Syslogストリーミング",
                certificateCName: "証明書の共通名",
                certificateExpiryDate: "証明書の有効期限",
        enable: "Syslogストリーミング",
                logs: "ログ",
                port: "ポート",
                protocol: "プロトコル",
                securityProtocol: "セキュリティプロトコル",
        server: "Syslogサーバー名またはIP",
                syslogOverride: "Syslogストリーミングを無効化",
                uploadSslCert: "SSL証明書をアップロード",
                descriptions: {
                    syslogOverride_site: "このサイトでは Syslogストリーミングを無効にします。",
                    syslogOverride_gateway: "このエッジサービスについては Syslogストリーミングを無効にします。"
        },
        syslogStreamingTile: "Syslogストリーミング",
        syslogDescription: "Syslogストリーミングは、ワークスペース全体を対象に有効に設定できますが、特定のサイト、Private Edge、VWAN向けのEdge Service、ホストされたEdge Serviceを対象に無効にすることもできます。"
            },
            xdr: {
                pageTitle: "Barracuda XDR",
        enable: "ログストリーミング",
                sites: "サイト",
                gateways: "エッジサービス",
        hostname: "XDRホスト名",
                port: "ポート",
        xdrDescription: "脅威を検出し、悪意のあるイベントに対してインシデントレスポンスを提供するため、以下でBarracuda XDRサービスにログがストリーミングされるようにBarracuda SecureEdgeを設定してください。Barracuda XDRの有効なサブスクリプション設定が必要となります。詳細は、<0>こちら</0>でご確認いただけます。",
        streamingEnabled: "有効"
            },
            webFilterSettings: {
                safeSearch: "セーフサーチ",
                silentAdBlocking: "サイレント広告ブロック",
        title: "設定",
        helpText: "SecureEdgeウェブフィルタリングは、ネットワークレベルでのSafeSearchの強制実行に対応しており、URLカテゴリー \"広告またはバナー\"をブロックします。<0/>これにより、人気の検索エンジンやYouTube経由で不適切なコンテンツにアクセスすることが防止されます。"
            },
            webMonitorPolicies: {
        webConfigHelpText: "ユーザーによる特定のキーワードを用いたウェブ検索のモニタリングを有効または無効にします。有効に設定されている場合、一致するキーワード（Service Logの収集の一部であるWeb Monitorアラートログで収集された一致するキーワード）を希望のSyslogサーバーにストリーミングし、処理を進めることができます。<0/>利便性のため、事前に定義されたキーワードのカテゴリーから選択うるか、条件と明らかに一致するカスタムキーワードを追加してください。",
                enabled: "ウェブモニタリングを有効化",
        webMonitoringTitle: "ウェブモニタリング",
                customKeywordTitle: "カスタムキーワードセット",
                customKeywordHelpText: "テキストフィールドにカスタムキーワードを入力して「+」ボタンをクリックし、これをモニタリングリストに追加します。",
                customKeywords: "カスタムキーワード",
        monitored: "モニター対象",
        keywordCategoriesTitle: "キーワードカテゴリー",
        keywordCategoriesHelpText: "事前に定義されたこれらのキーワードカテゴリーを使用してください。これらのカテゴリーに関する詳細は、<0>こちらをクリック</0>してご確認ください。",
        toggleEnabled: "有効"
            },
            customResponse: {
                setup: "カスタム レスポンス ページの設定",
        setupHelpText: "ここではカスタムレスポンスページを設定できます。テキストを保存すると、このレスポンスページがアクティブユーザー全員に表示されます。",
                barracudaBranding: "Barracudaブランディング",
                customLogo: "カスタムロゴ",
                customText: "カスタムテキスト",
                showBarracudaBranding: "ブランディングを表示",
                descriptions: {
                    customText: "カスタムロゴの下に表示されます。<0/>カスタムテキストを使用しない場合はここを空欄にしておいてください。",
                    customLogo: "上部に表示されます。対応ファイル形式：JPEG、PNG、GIF、SVG"
                }
            },
            identity: {
                menuTitle: "アイデンティティ",
                pageTitle: "アイデンティティ",
                pageSubtitle: "設定",
                identityProviders: {
                    title: "IDプロバイダー",
                    addNotification: "IDプロバイダーは正常に追加されました",
                    noDataMessage: "まだIDプロバイダーがまだ構成されていないようです。",
                    clickBelow: "下のボタンをクリックして追加してください。",
                    createIdentityProvider: "IDプロバイダーを追加",
                    saml: {
                        informationNeeded: "IDプロバイダーに必要な情報",
                        entityId: "エンティティーID",
                        acsURL: "ACS URL"
                    },
                    add: {
                        title: "IDプロバイダーの追加",
                        createStep: "SAMLサインイン設定",
                        setupStep: "SAMLの設定",
                        button: "追加",
                        buttonDisabled: "一度に設定できる IDプロバイダーは 1つだけです。",
                        types: {
                            email: "メールアドレス",
                            entraid: "Microsoft Entra ID",
                            bcc: "Barracuda Cloud Control",
                            google: "Googleワークスペース",
                            oidc: "OpenID Connect",
                            saml: "SAML 2.0"
                        },
                        options: {
                            provider: "プロバイダー",
                            displayName: "表示名",
                            tenantId: "テナントID",
                            clientId: "クライアントID",
                            clientSecret: "クライアントシークレット",
                            discoveryURL: "検出 URL",
                            scope: "スコープ",
                            googleWorkspaceDomain: "Googleワークスペースドメイン",
                            signInEndpoint: "ログインエンドポイント",
                            signingCert: "署名証明書"
                        },
                        redirectUri: "リダイレクトURI"
                    },
                    table: {
                        name: "名前",
                        type: "タイプ",
                        status: "ステータス",
                        noData: "IDプロバイダーが見つかりません。"
                    },
                    authorizationStatusField: {
                        needs_consent: "認証が必要です",
                        active: "アクティブ",
                        error: "エラー"
                    },
                    menu: {
                        test: "IDプロバイダーをテスト",
                        setupSaml: "SAMLの設定"
                    },
                    delete: {
                        title: "IDプロバイダーを削除",
                        body: "このIDプロバイダーを削除してもよろしいですか？"
                    }
                },
                userDirectories: {
                    title: "アイデンティティ",
                    subtitle: "ユーザーディレクトリ",
                    tableName: "ユーザーディレクトリ",
                    noDataMessage: "ユーザーディレクトリがまだ構成されていないようです。",
                    clickBelow: "下のボタンをクリックして追加してください。",
                    createDirectory: "ユーザーディレクトリを追加",
                    createDirectoryDialogTitle: "ユーザーディレクトリを追加",
                    editDirectory: "ユーザーディレクトリを編集",
                    displayName: "名前",
                    type: "タイプ",
                    status: "ステータス",
                    statusField: {
                        running: "実行中",
                        cancelled: "キャンセルされました。",
                        errored: "エラーが発生しました。",
                        completed: "完了",
                        pending: "処理中",
                        unknown: "不明",
                        needs_consent: "承認が必要です。"
                    },
                    sync: "同期",
                    syncResult: "同期結果",
                    syncedNotification: "ユーザーディレクトリの同期が開始されました。",
                    syncErrors: "同期エラー",
                    users_one: "{{count}} ユーザー",
                    users_other: "{{count}} ユーザー",
                    groups_one: "{{count}} グループ",
                    groups_other: "{{count}} グループ",
                    syncStatus: "{{users}}、{{groups}}、同期済み",
                    lastSuccessfulSync: "最終同期",
                    lastSyncAt: "前回同期日 {{date}}",
                    neverSynced: "同期されていません。",
                    delete: {
                        body: "このユーザー ディレクトリを削除してもよろしいですか？",
                        body2: "アカウントのユーザーディレクトリを変更または削除すると、既存のセキュリティおよびアクセスポリシーが期待どおりに機能しなくなる可能性があります。ポリシーを確認し、必要に応じて修正してください。すでに登録されているユーザーは引き続きアクセスできます。削除するユーザーを管理するには、「登録済みユーザー」ページを使用してください。",
                        title: "ユーザーディレクトリ {{name}}を削除します。"
                    },
                    form: {
                        title: "ディレクトリ",
                        displayName: "表示名",
                        tenantId: "テナントID",
                        type: {
                            label: "ディレクトリタイプ",
                            bcc: "Barracuda Cloud Control",
                            entraid: "Microsoft Entra ID",
                            okta: "Okta",
                            ldap: "LDAP",
                            google: "Googleワークスペース"
                        },
                        ldap: {
                            steps: {
                                userDirectory: "ユーザーディレクトリ情報",
                                auth: "認証",
                                tls: "TLS設定"
                            },
                            host: {
                                label: "ホスト",
                                placeholder: "例：ldap.barracuda.com"
                            },
                            port: {
                                label: "ポート",
                                placeholder: "例：389"
                            },
                            profile: {
                                label: "プロファイル",
                                placeholder: "プロファイル選択",
                                ad: "広告",
                                custom: "カスタム"
                            },
                            userSearchBase: {
                                label: "ユーザー検索ベース",
                                placeholder: "例：dc=mydomain.com,dc=com"
                            },
                            groupSearchBase: {
                                label: "グループ検索ベース",
                                placeholder: "例：dc=mydomain.com,dc=com"
                            },
                            authenticationMethod: {
                                label: "認証方法",
                                placeholder: "認証方法を選択",
                                anon: "Anon",
                                simple: "Simple",
                                saslExternal: "SASL外部",
                                saslKerberos: "SASL Kerberos",
                                ntlm: "NTLM"
                            },
                            username: "ユーザー名",
                            password: "パスワード",
                            saslCredentials: "SASL認証情報",
                            useTls: {
                                tlsSettings: "TLS設定",
                                none: "なし",
                                tls: "TLS",
                                startTls: "StartTLS",
                                encryptionMethod: "暗号化方式",
                                ldapSni: {
                                    label: "SNIホスト名",
                                    placeholder: "例：example.org"
                                },
                                ldapPrivkey: {
                                    label: "秘密鍵",
                                    placeholder: "ここに秘密鍵を貼り付けます。"
                                },
                                ldapPrivkeyPassword: "パスワード",
                                ldapPubkey: {
                                    label: "公開鍵",
                                    placeholder: "ここに公開鍵を貼り付けます。"
                                },
                                ldapCacerts: {
                                    label: "CA証明書",
                                    placeholder: "ここに CA証明書を貼り付けます。"
                                },
                                ldapCheckCerts: {
                                    label: "証明書を確認してください。",
                                    description: "受信したサーバー証明書の信頼性を確認してください。"
                                },
                                ldapCheckHostname: {
                                    label: "ホスト名を確認",
                                    description: "証明書のホスト名を検証します。"
                                },
                                ldapCertAdditionalNames: {
                                    label: "証明書の追加名",
                                    placeholder: "例：example.org,company.com,dc1.example.org"
                                }
                            },
                            connectorIps: {
                warning: "当社のLDAPコネクタを使用するには、次のIPからのトラフィックを許可する必要があります："
                            }
                        },
                        okta: {
                            domainname: "Oktaドメイン名",
                            authToken: "Okta認証トークン"
                        },
                        google: {
                            googleWorkspaceDomain: "Googleワークスペースドメイン"
                        },
                        bcc: {
                            environment: "環境"
                        }
                    }
                },
                users: {
                    title: "ユーザー",
                    subtitle: "ユーザー",
                    tableName: "ユーザー",
                    name: "名前",
                    email: "メールアドレス",
                    noDataMessage: "ユーザーは見つかりませんでした。",
                    nameOrEmail: "ユーザー"
                },
                groups: {
                    title: "グループ",
                    subtitle: "グループ",
                    tableName: "グループ",
                    name: "名前",
                    noDataMessage: "グループは見つかりませんでした。"
                }
            }
        }
    }
} as const;