import React from "react";
import {BaseBarCard} from "../../baseCards";
import {bytesFormatter, colorFunction} from "../../../utils";
import {DashboardCardProps, UserDataPoint} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {UsersIcon} from "../../icons";
import {useTheme} from "@mui/material";

/**
 * Shows a list of top users and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopUsersCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const {data, loading, addFilter} = useReportingData("TopUsersCard", preview);
    const {userDataPoints = []} = data || {};
    const theme = useTheme();

    const maxValue = Math.max(...userDataPoints.map((user: UserDataPoint) => user.numberOfBytes));
    const entries: BarGridEntry[] = userDataPoints.map((user: UserDataPoint) => ({
        title: user.label,
        value: bytesFormatter(user.numberOfBytes),
        barProgress: (100.0 / maxValue) * user.numberOfBytes,
        color: colorFunction((100.0 / maxValue) * user.numberOfBytes, theme),
        icon: <UsersIcon size={20}/>,
        //Removing the filter completely until it is fixed on the platform
        // onClick: addFilter ? () => addFilter(user.id, "userDataPoints") : undefined,
        // tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.topUsersCard.title"
            size={size}
            preview={preview}
            loading={loading && !data.userDataPoints}
            noData={!loading && !data.userDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};