import React from "react";
import {CrudTypes, getArrayDataContent, useCrudSubscription} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {DashboardCardProps, RecentEventsCard} from "@stratos/reporting";
import {reverse, sortBy} from "lodash";

export interface DeploymentEventsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            serials: string[];
        }
    }
}

export const DeploymentEventsCard = (props: DeploymentEventsCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const {serials} = details || {};
    const [dataSerial1, loading1] = useCrudSubscription(CrudTypes.GET, serials?.[0] ? apiResources.applianceDeploymentEvents : undefined, {id: serials?.[0]}, {pollInterval: 300000});
    const [dataSerial2, loading2] = useCrudSubscription(CrudTypes.GET, serials?.[1] ? apiResources.applianceDeploymentEvents : undefined, {id: serials?.[1]}, {pollInterval: 300000});
    const combinedData = reverse(sortBy([...getArrayDataContent(dataSerial1.data), ...getArrayDataContent(dataSerial2.data)], "created").map((event) => ({
        ...event,
        status: event.state,
        eventName: event.name
    })));

    return (
        <RecentEventsCard
            title="tesseract.appliances.dashboard.deployment.events.title"
            entries={combinedData}
            size={size}
            preview={preview}
            total={combinedData.length}
            footerKey="tesseract.dashboard.cards.recentEvents.latest"
            loading={loading1 || loading2}
        />
    );
};