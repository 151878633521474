import {DashboardCardProps, RiskDataPoint} from "../../../typesAndConstants";
import React from "react";
import {useReportingData} from "../../../contexts";
import {sortBy} from "lodash";
import {RiskCard} from "../../baseCards";
import {bytesFormatter, getRiskColor, getRiskLevel} from "../../../utils";
import {useTheme} from "@mui/material";

export interface ApplicationRiskCardProps extends DashboardCardProps {
}

export const ApplicationRiskCard = (props: ApplicationRiskCardProps) => {
    const {preview} = props;
    const {data, loading, addFilter} = useReportingData("ApplicationRiskCard", preview);
    const {riskDataPoints = []} = data || {};
    const theme = useTheme();

    const traffic = riskDataPoints.reduce(
        ({total, riskTotal}: { total: number, riskTotal: number }, riskDataPoint: RiskDataPoint) => ({
            total: total + riskDataPoint.numberOfBytes,
            riskTotal: riskTotal + (riskDataPoint.numberOfBytes * parseInt(riskDataPoint.id))
        }),
        {total: 0, riskTotal: 0}
    );
    const riskLevel = getRiskLevel(traffic.riskTotal / traffic.total);
    const entries = sortBy(riskDataPoints, "id").map((riskDataPoint: RiskDataPoint) => ({
        title: `stratosReporting.applicationRisk.${getRiskLevel(riskDataPoint.id) || "low"}`,
        value: bytesFormatter(riskDataPoint.numberOfBytes),
        barProgress: (100.0 / traffic.total) * riskDataPoint.numberOfBytes,
        color: getRiskColor(riskDataPoint.id, theme),
        onClick: addFilter ? () => addFilter(riskDataPoint.id, "riskDataPoints") : undefined,
        tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <RiskCard
            {...props}
            riskLevel={riskLevel}
            entries={entries}
            loading={loading}
            noData={!loading && !data.riskDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};