import React from "react";
import {
    CrudTypes,
    CustomField,
    formatDateTime,
    getArrayDataContent,
    getDataContent,
    ListCardContent,
    TextField,
    useCrudSubscription
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import apiResources from "./../../../../apiResources";
import {get} from "lodash";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

export interface FirmwareDetailsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            gatewayType?: "on-prem" | "cloud" | "managed";
            id?: string | number;
            activeSerial?: string;
            modelSeries?: "S" | "T";
        };
        type: "gateways" | "sites";
    };
}

export const FirmwareDetailsCard: React.FC<FirmwareDetailsCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const {id, gatewayType, activeSerial, modelSeries} = details || {};
    const [translate] = useTranslation();
    const isCloud = !(type === "sites" || gatewayType === "on-prem");
    const [applianceFirmwareData] = useCrudSubscription(
        CrudTypes.GET,
        isCloud
            ? apiResources.gatewayFirmwareStatus
            : activeSerial && (gatewayType === "on-prem" ? apiResources.configurationOnPremFirmware : apiResources.configurationFirmware),
        isCloud ? {id} : {id: activeSerial, siteId: id},
        {pollInterval: 30000}
    );
    const applianceFirmwareStatus = getDataContent(applianceFirmwareData);
    const [updateWindowsData] = useCrudSubscription(CrudTypes.GET, apiResources.update, {}, {pollInterval: 30000});
    const updateWindows = getArrayDataContent(updateWindowsData);
    const currentApplianceUpdateWindow = updateWindows.find(
        (update) => update.configurationId === id && update.configurationType === (type === "sites" ? "site" : "gateway")
    ) ?? updateWindows.find((update) => update.allConfigurations === true);
    const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");

    const detailsParams = {
        firmwareStatus: applianceFirmwareStatus,
        firmwareUpdate: currentApplianceUpdateWindow,
    };

    return (
        <BaseCard
            id="FirmwareDetailsCard"
            title="tesseract.appliances.dashboard.firmware.details.tabTitle"
            size={size}
            preview={preview}
        >
            <ListCardContent data={detailsParams}>
                <TextField label="tesseract.appliances.dashboard.firmware.details.version"
                           source="firmwareStatus.firmwareVersion"/>
                {type === "gateways" || !isSc ?
                    <TextField
                        label="tesseract.appliances.dashboard.firmware.details.hotfixes"
                        source="firmwareStatus.hotfixes"
                    /> : null}
                {currentApplianceUpdateWindow ?
                    <CustomField
                        label="tesseract.appliances.dashboard.firmware.details.window"
                        source="firmwareUpdate"
                        render={(value) => translate(
                            "tesseract.appliances.dashboard.firmware.details.nextUpdate",
                            {
                                ...value,
                                from: formatDateTime(value.from),
                                to: formatDateTime(value.to)
                            }
                        )}
                    /> : null
                }
            </ListCardContent>
        </BaseCard>
    );
};