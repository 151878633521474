import React from "react";
import {
    CustomField,
    MultiInput,
    ReadOnlyInput,
    TextArrayInput,
    TextInput,
    validateArray,
    validateDomain,
    validateMaxLengthMemo,
    validatePorts,
    validateRegexMemo
} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    multiInputMainColumnStyle: {
        paddingBottom: 18,
        paddingLeft: 16,
        paddingRight: 16
    },
    topBarColumnStyle: {
        marginTop: 0
    },
    customTextField: {
        marginTop: theme.spacing(1),
        width: 256,
        overflowX: "hidden",
        wordBreak: "break-word"
    }
}));

interface SdwanConnectorServicesStepProps {
    create?: boolean,
}

const SdwanConnectorServicesStep: React.FC<SdwanConnectorServicesStepProps> = ({create}) => {
    const classes = useStyles();
    const inputValidations = useSiteInputValidations();
    const routedMode = useWatch({name: "routedMode"});
    const dnsSuffixValue = "secureedge.barracuda.internal";

    return (
        <React.Fragment>
            <MultiInput
                maxInputs={250}
                source="resources"
                classes={!create ? classes : undefined}
                columnStyle
                columnStyleItemLabelSource="serviceName"
                columnStyleItemLabel="tesseract.network.sdwanConnector.dialog.servers.itemName"
                columnStyleTitleLabel="tesseract.network.sdwanConnector.dialog.servers.serviceName"
            >
                <TextInput
                    source="serviceName"
                    label="tesseract.network.sdwanConnector.dialog.servers.serviceName"
                    description={"tesseract.network.sdwanConnector.dialog.description.servers.serviceName"}
                    isRequired
                    validate={[validateMaxLengthMemo(31), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                />
                <TextInput
                    source="hostname"
                    label="tesseract.network.sdwanConnector.dialog.servers.hostname"
                    description={"tesseract.network.sdwanConnector.dialog.description.servers.hostname"}
                    isRequired
                    validate={[validateDomain]}
                />
                {routedMode && (
                    <ReadOnlyInput
                        source="hostname"
                        label="tesseract.network.sdwanConnector.dialog.servers.dnsSuffix.label"
                        description="tesseract.network.sdwanConnector.dialog.description.servers.dnsSuffix"
                        // @ts-ignore not sure why this is throwing an error
                        field={CustomField}
                        options={{
                            render: (value: string) => `${value}.${dnsSuffixValue}`,
                            classes: {root: classes.customTextField}
                        }}
                    />
                )}
                <TextArrayInput
                    source="ports"
                    label="tesseract.network.sdwanConnector.dialog.servers.ports"
                    description="tesseract.network.sdwanConnector.dialog.description.servers.ports"
                    validate={validateArray([validatePorts])}
                />
                <TextInput
                    source="internalIp"
                    label="tesseract.network.sdwanConnector.dialog.servers.internalIp"
                    description="tesseract.network.sdwanConnector.dialog.description.servers.internalIp"
                    validate={inputValidations.validateIp}
                    isRequired
                />
            </MultiInput>
        </React.Fragment>
    );
};

export default SdwanConnectorServicesStep;