import {categoryIcons} from "../iconMapping";
import {get} from "lodash";
import React from "react";
import {
    BooleanInput,
    FormSection,
    SearchableSelectInput,
    SelectArrayInput,
    SelectInput,
    SelectPairArrayInput,
    TextArrayInput,
    usePreview
} from "@cuda-react/core";
import apiResources from "../../apiResources";
import {CustomAppsIcon} from "@cuda-react/icons";

const hideInput = (key: any, type: any) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

const destinationTypeChoices = [
    {key: "application", name: "tesseract.security.destinationCriteria.application"},
    {key: "category", name: "tesseract.security.destinationCriteria.category"},
    {key: "customCategory", name: "tesseract.security.destinationCriteria.customCategory"},
    {key: "domain", name: "tesseract.security.destinationCriteria.domain"},
    {key: "network", name: "tesseract.security.destinationCriteria.network"},
    {key: "site", name: "tesseract.security.destinationCriteria.site"},
    {key: "onPremGateway", name: "tesseract.security.destinationCriteria.onPremGateway"},
    {key: "ingressSite", name: "tesseract.security.destinationCriteria.ingressSite"},
    {key: "secureEdgeConnector", name: "tesseract.security.destinationCriteria.secureEdgeConnector"},
];

export const getDestinationTypeChoices = (types: any) => destinationTypeChoices.filter(
    (choice) => types.includes(choice.key) || (choice.key === "onPremGateway" && types.includes("onPremGatewayNetworks"))
);

/**
 * Function that returns the components needed to setup +a destination criteria form section.
 *
 * @param destinationTypes - string array of the destination input types to render, e.g. ["network", "site"];
 * @param categoryChoices - optional - the category choices array for when you are rendering a category input
 * @returns {JSX.Element}
 */
// eslint-disable-next-line react/display-name
export default (destinationTypes: any[], categoryChoices: any[] = []) => {
    const destinationSelectChoices = getDestinationTypeChoices(destinationTypes);

    return (
        // @ts-ignore check if 'collapsable' is actually supported...
        <FormSection title="tesseract.security.destinationCriteria.section" collapsable>
            <SelectInput
                source="destination.type"
                label="tesseract.security.destinationCriteria.type"
                description="tesseract.security.destinationCriteria.description"
                choices={destinationSelectChoices}
                isRequired
            />
            {destinationTypes.includes("application") ? (
                <SelectArrayInput
                    source="destination.applications"
                    label="tesseract.security.destinationCriteria.application"
                    description="tesseract.security.destinationCriteria.applicationDescription"
                    resource={apiResources.applications}
                    filterKey="applicationId"
                    iconMap={categoryIcons}
                    optionIcon="categoryId"
                    optionValue="id"
                    hide={hideInput("destination.type", "application")}
                    options={{dropdownAutoWidth: true}}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("secureEdgeConnector") ? (
                <SelectArrayInput
                    source="destination.secureEdgeConnector"
                    label="tesseract.security.destinationCriteria.secureEdgeConnector"
                    description="tesseract.security.destinationCriteria.secureEdgeConnector"
                    resource={apiResources.connectorsList}
                    iconMap={categoryIcons}
                    optionIcon="categoryId"
                    optionValue="uuid"
                    hide={hideInput("destination.type", "secureEdgeConnector")}
                    icon={<CustomAppsIcon/>}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("category") ? (
                <SelectArrayInput
                    source="destination.categories"
                    label="Category"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.categories"
                    choices={categoryChoices}
                    hide={hideInput("destination.type", "category")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("customCategory") ? (
                <SelectArrayInput
                    source="destination.customCategories"
                    label="tesseract.security.urlFiltering.exceptions.customCategories"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.customCategories"
                    resource={apiResources.customCategories}
                    optionValue="id"
                    hide={hideInput("destination.type", "customCategory")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("domain") ? (
                <TextArrayInput
                    source="destination.domains"
                    label="tesseract.security.urlFiltering.exceptions.domain"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.domains"
                    hide={hideInput("destination.type", "domain")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("network") ? (
                <TextArrayInput
                    source="destination.networks"
                    label="tesseract.security.destinationCriteria.network"
                    description="tesseract.security.destinationCriteria.networkDescription"
                    hide={hideInput("destination.type", "network")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("onPremGateway") && (
                <SearchableSelectInput
                    source="destination.applianceId"
                    label="tesseract.security.destinationCriteria.onPremGateway"
                    description="tesseract.security.destinationCriteria.gatewayDescription"
                    resource={apiResources.gateways}
                    options={{params: {filter: {type: "on-prem"}}}}
                    optionValue="id"
                    hide={hideInput("destination.type", "onPremGateway")}
                    clearOnFocus
                    isRequired
                />
            ) || null}
            {destinationTypes.includes("onPremGatewayNetworks") ? (
                <BooleanInput
                    source="destination.allGateways"
                    label="tesseract.security.destinationCriteria.allOnpremGateways"
                    hide={hideInput("destination.type", "onPremGateway")}
                />
            ) : null}
            {destinationTypes.includes("onPremGatewayNetworks") ? (
                <SelectPairArrayInput
                    source="destination.gateways"
                    label="tesseract.security.destinationCriteria.onpremGateway"
                    description="tesseract.security.destinationCriteria.onpremGatewayDescription"
                    primarySource="id"
                    primaryLabel="tesseract.security.destinationCriteria.onpremGateway"
                    primaryOptionValue="uuid"
                    resource={apiResources.gatewaysOnPremNetworks}
                    addTitle="tesseract.security.destinationCriteria.addOnpremGateway"
                    editTitle="tesseract.security.destinationCriteria.editOnpremGateway"
                    secondarySource="networks"
                    secondaryChoices="networks"
                    secondaryOptionValue="id"
                    secondarySelectAll
                    secondarySelectAllText="tesseract.security.destinationCriteria.allNetworks"
                    secondaryLabel="tesseract.security.destinationCriteria.gatewayNetwork"
                    hide={(value, data) => hideInput("destination.type", "onPremGateway")(value, data) || get(data, "destination.allGateways")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("site") ? (
                <BooleanInput
                    source="destination.allSites"
                    label="tesseract.security.destinationCriteria.allSites"
                    hide={hideInput("destination.type", "site")}
                />
            ) : null}
            {destinationTypes.includes("site") ? (
                <SelectPairArrayInput
                    source="destination.sites"
                    label="tesseract.security.destinationCriteria.site"
                    description="tesseract.security.destinationCriteria.siteDescription"
                    primarySource="id"
                    primaryLabel="tesseract.security.destinationCriteria.site"
                    primaryOptionValue="uuid"
                    resource={apiResources.siteNetworks}
                    addTitle="tesseract.security.destinationCriteria.addSite"
                    editTitle="tesseract.security.destinationCriteria.editSite"
                    secondarySource="networks"
                    secondaryChoices="networks"
                    secondaryOptionValue="id"
                    secondarySelectAll
                    secondarySelectAllText="tesseract.security.destinationCriteria.allNetworks"
                    secondaryLabel="tesseract.security.destinationCriteria.siteNetwork"
                    hide={(value, data) => hideInput("destination.type", "site")(value, data) || get(data, "destination.allSites")}
                    isRequired
                />
            ) : null}
            {destinationTypes.includes("ingressSite") ? (
                <SearchableSelectInput
                    source="destination.applianceId"
                    label="tesseract.security.destinationCriteria.site"
                    description="tesseract.security.destinationCriteria.siteDescription"
                    optionValue="id"
                    filterKey="site"
                    resource={apiResources.sites}
                    options={{params: {filter: {modelSeries: "T"}}}}
                    hide={hideInput("destination.type", "ingressSite")}
                    clearOnFocus
                    isRequired
                />
            ) : null}
        </FormSection>
    );
};