import {Grid, TextField} from "@barracuda-internal/bds-core";
import {get} from "lodash";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React from "react";
import {CrudTypes, getDataContent, Select, SelectArray, useCrudSubscription} from "@cuda-react/core";
import {CustomAppsIcon, GatewayIcon, SiteIcon} from "@cuda-react/icons";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import classNames from "classnames";
import {getSourceTypeChoices} from "../../hooks/sourceAndDestinationHooks";
import apiResources from "../../apiResources";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 256
    },
    selectFilter: {
        marginTop: theme.spacing(1),
        width: "100%",
        maxWidth: 256
    },
    textFilter: {
        marginTop: theme.spacing(2),
        width: "100%",
        maxWidth: 256
    },
    typeSelect: {
        width: "100%",
        maxWidth: 256
    },
    disabled: {
        paddingBottom: theme.spacing(2)
    }
}));

interface SourceFilterProps {
    className?: string,
    value?: string | {},
    onChange?: any,
    filterSources: any
}

const SourceFilter: React.FC<SourceFilterProps> = ({className, value, onChange, filterSources}) => {
    const userOrGroupEnabled = useCrudSubscription(CrudTypes.GET, filterSources.includes("userOrGroup") ? apiResources.userOrGroupEnabled : undefined)[0]?.data;
    const userOrGroupDisabled = getDataContent(userOrGroupEnabled) !== true;
    const update = (field: any) => (eventOrValue: any) => onChange({
        ...(value || {}),
        [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
    });
    const classes = useStyles();
    const [translate] = useTranslation();
    const sourceSelectChoices = getSourceTypeChoices(filterSources);
    const filters = [];

    if (get(value, "type") === "application") {
        filters.push(
            <SelectArray
                key="application"
                id="sourceApplications"
                label="tesseract.security.tables.sourceApplication"
                optionValue="applicationId"
                resource={apiResources.customApps}
                options={{params: {filter: {type: "network"}}}}
                icon={<CustomAppsIcon/>}
                value={get(value, "sourceApplication")}
                onChange={update("sourceApplication")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "network") {
        filters.push(
            <TextField
                key="sourceNetwork"
                id="cuda-filter-input-sourceNetwork"
                label={translate("tesseract.security.tables.sourceNetwork")}
                value={get(value, "sourceNetwork", "")}
                onChange={update("sourceNetwork")}
                variant="outlined"
                className={classNames(className, classes.textFilter)}
            />
        );
    }
    if (get(value, "type") === "networks") {
        filters.push(
            <TextField
                key="sourceIngressNatNetwork"
                id="cuda-filter-input-sourceNetwork"
                label={translate("tesseract.security.tables.sourceNetwork")}
                value={get(value, "sourceIngressNatNetwork", "")}
                onChange={update("sourceIngressNatNetwork")}
                variant="outlined"
                className={classNames(className, classes.textFilter)}
            />
        );
    }
    if (get(value, "type") === "onPremGateway") {
        filters.push(
            <SelectArray
                key="sourceOnPremGateway"
                id="sourceOnPremGateway"
                label="tesseract.security.tables.sourceOnpremGateway"
                optionValue="uuid"
                resource={apiResources.gatewaysOnPremNetworks}
                selectAll
                selectAllText="tesseract.security.tables.allOnpremGateways"
                optionIcon="uuid"
                icon={<GatewayIcon/>}
                value={get(value, "sourceOnPremGateway")}
                onChange={update("sourceOnPremGateway")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "site") {
        filters.push(
            <SelectArray
                key="sourceSite"
                id="sourceSite"
                label="tesseract.security.tables.sourceSite"
                optionValue="uuid"
                resource={apiResources.siteNames}
                selectAll
                selectAllText="tesseract.security.tables.allSites"
                iconMap={{"*": <SiteIcon/>}}
                optionIcon="uuid"
                icon={<SingleSiteIcon/>}
                value={get(value, "sourceSite")}
                onChange={update("sourceSite")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "userOrGroup") {
        filters.push(
            <SelectArray
                key="user"
                id="user"
                label="tesseract.security.tables.user"
                optionValue="name"
                optionText="username"
                optionTooltip="name"
                resource={apiResources.users}
                icon={<User/>}
                value={get(value, "user")}
                onChange={update("user")}
                className={classNames(className, classes.selectFilter)}
                disabled={userOrGroupDisabled}
                options={{dropdownAutoWidth: true, optionSubText: "name", filterKey: "name"}}
            />
        );
        filters.push(
            <SelectArray
                key="group"
                id="group"
                label="tesseract.security.tables.group"
                optionValue="name"
                optionTooltip="directoryName"
                resource={apiResources.groups}
                icon={<UserGroup/>}
                value={get(value, "group")}
                onChange={update("group")}
                options={{dropdownAutoWidth: true, optionSubText: "directoryName"}}
                className={classNames(className, classes.selectFilter, userOrGroupDisabled && classes.disabled)}
                disabled={userOrGroupDisabled}
                error={userOrGroupDisabled ? translate("tesseract.security.sourceCriteria.userOrGroupDisabledShort") : undefined}
            />
        );
    }
    if (get(value, "type") === "secureEdgeConnector") {
        filters.push(
            <SelectArray
                id="source.secureEdgeConnector"
                label="tesseract.security.tables.secureEdgeConnector"
                optionValue="uuid"
                resource={apiResources.connectorsList}
                icon={<CustomAppsIcon/>}
                value={get(value, "sourceConnector")}
                onChange={update("sourceConnector")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }

    return (
        <Grid container direction="column" className={classes.container}>
            <Select
                id="cuda-filter-input-type"
                value={get(value, "type", "")}
                onChange={(eventOrValue) => onChange({type: eventOrValue.target ? eventOrValue.target.value : eventOrValue})}
                label={translate("tesseract.security.tables.sourceType")}
                choices={sourceSelectChoices}
                classes={{select: classes.typeSelect, formControl: classes.typeSelect}}
            />
            {filters}
        </Grid>
    );
};

export default SourceFilter;