import {Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {BooleanInput, FormSection, SelectArrayInput, TextInput, validateArray, validateEmail} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, {useMemo} from "react";
import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    inputLabelWidth: {
        width: 150
    },
    inputWidth: {
        width: 316
    },
    message: {
        margin: theme.spacing(1, 2, 2)
    },
    messageError: {
        color: theme.palette.error.main
    }
}));

export type EnrollSelectionStepProps = {
    create?: boolean,
    remainingSeats: number
};
const htmlTags = /<|>/;

export const EnrollSelectionStep = ({remainingSeats, create}: EnrollSelectionStepProps) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const validations = useMemo(() => ({
        validateEmailArray: validateArray(validateEmail),
        validateContent: (value?: string) => value && value.match(htmlTags) ? translate("tesseract.endpoint.enrollEmailDialog.noHtml") : undefined,
        validateNoSeats: () => create && remainingSeats < 1 ? translate("tesseract.endpoint.enrollEmailDialog.noSeats") : undefined
    }), [remainingSeats]);

    return (
        <React.Fragment>
            <Typography className={classes.message}>
                {create && remainingSeats <= 99999 ? (
                    <Trans
                        i18nKey="tesseract.endpoint.enrollEmailDialog.enrollMessage"
                        values={{
                            count: remainingSeats,
                            context: remainingSeats !== 1 ? "plural" : undefined
                        }}
                    >
                        <p className={remainingSeats < 1 ? classes.messageError : undefined}/>
                    </Trans>) : (
                    <Trans
                        i18nKey={`tesseract.endpoint.enrollEmailDialog.${create ? "enrollMessageOld" : "reenrollMessage"}`}/>)
                }
            </Typography>
            <SelectArrayInput
                source="users"
                label="tesseract.endpoint.enrollEmailDialog.users"
                resource={apiResources.users}
                optionValue="name"
                optionText="username"
                options={{
                    label: "tesseract.endpoint.enrollEmailDialog.addUsers",
                    dropdownAutoWidth: true,
                    optionSubText: "name",
                    filterKey: "name"
                }}
                icon={<User/>}
                validate={[validations.validateEmailArray, validations.validateNoSeats]}
                disabled={!create}
                inputLabelProps={{classes: {inputLabel: classes.inputLabelWidth}}}
                inputClasses={{selectArray: classes.inputWidth}}
            />
            {create ?
                <SelectArrayInput
                    source="groups"
                    label="tesseract.endpoint.enrollEmailDialog.groups"
                    resource={apiResources.groups}
                    optionValue="id"
                    options={{
                        label: "tesseract.endpoint.enrollEmailDialog.addGroups",
                        dropdownAutoWidth: true,
                        optionSubText: "directoryName"
                    }}
                    icon={<UserGroup/>}
                    inputLabelProps={{classes: {inputLabel: classes.inputLabelWidth}}}
                    inputClasses={{selectArray: classes.inputWidth}}
                    validate={validations.validateNoSeats}
                /> : null}
            {create ?
                <FormSection title="tesseract.endpoint.enrollEmailDialog.userNotificationSectionTitle">
                    <BooleanInput
                        source="enrollmentEmailEnabled"
                        label="tesseract.endpoint.enrollEmailDialog.enrollmentEmail.label"
                        toggleEnabledText="tesseract.endpoint.enrollEmailDialog.enrollmentEmail.enabled"
                        toggleDisabledText="tesseract.endpoint.enrollEmailDialog.enrollmentEmail.disabled"
                        inputLabelProps={{classes: {inputLabel: classes.inputLabelWidth}}}
                    />
                    <TextInput
                        source="content"
                        label="tesseract.endpoint.enrollEmailDialog.customMessage"
                        validate={validations.validateContent}
                        inputLabelProps={{classes: {inputLabel: classes.inputLabelWidth}}}
                        inputClasses={{textField: classes.inputWidth}}
                        disable={(value, data) => !data?.enrollmentEmailEnabled}
                    />
                </FormSection> :
                <TextInput
                    source="content"
                    label="tesseract.endpoint.enrollEmailDialog.customMessage"
                    validate={validations.validateContent}
                    inputLabelProps={{classes: {inputLabel: classes.inputLabelWidth}}}
                    inputClasses={{textField: classes.inputWidth}}
                    disable={(value, data) => !data?.enrollmentEmailEnabled}
                />
            }
        </React.Fragment>
    );
};

export default EnrollSelectionStep;