import useListenerManager from "./useListenerManager";
import useAppliances, {Appliance} from "./useAppliances";
import useFilterOptions from "./useFilterOptions";
import useReportingApiPolling from "./useReportingApiPolling";
import useFilterState from "./useFilterState";

export default (presetAppliance?: Appliance, hideAppliance?: boolean) => {
    const {listeners, register, unregister} = useListenerManager();
    const disabled = listeners.length === 0;
    const {appliances, appliancesLoading} = useAppliances(presetAppliance);
    const {activeFilters, updateFilter, removeFilter, clearAllFilters} = useFilterState(presetAppliance);
    const {data, loading} = useReportingApiPolling(activeFilters, disabled, appliances, appliancesLoading);
    const {filterButtons, activeFilterInputs} = useFilterOptions(activeFilters, updateFilter, removeFilter, clearAllFilters, appliances, disabled, hideAppliance);

    return {
        register,
        unregister,
        data,
        loading,
        updateFilter,
        filterButtons,
        activeFilters: activeFilterInputs
    };
};