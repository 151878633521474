import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunctionError, numberFormatter} from "../../../utils";
import {CardSizes, DashboardCardProps, UserDataPoint} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {useReportingData} from "../../../contexts";
import {UsersIcon} from "../../icons";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";

export interface BlockedUser {
    value: number,
    name: string,
    total: number
}

export interface TopBlockedUsersCardProps extends DashboardCardProps {
}

/**
 * Shows a list of blocked users and a "progress bar".
 * The progress bar does not actually show the ratio of the values but is dependent on the position of the value.
 */
export const TopBlockedUsersCard = (props: TopBlockedUsersCardProps) => {
    const {preview, size} = props;
    const {data, loading, addFilter} = useReportingData("TopBlockedUsersCard", preview);
    const {blockedUserDataPoints = []} = data || {};
    const [translate] = useTranslation();
    const theme = useTheme();

    const maxValue = Math.max(...blockedUserDataPoints.map((user: UserDataPoint) => user.numberOfSessions));
    const entries: BarGridEntry[] = blockedUserDataPoints.map((user: UserDataPoint) => ({
        title: user.label,
        value: props.size === CardSizes.small ?
            numberFormatter(user.numberOfSessions) :
            translate("stratosReporting.topBlockedUserCard.hits", {
                count: user.numberOfSessions,
                formattedCount: numberFormatter(user.numberOfSessions)
            }),
        barProgress: (100.0 / maxValue) * user.numberOfSessions,
        color: colorFunctionError((100.0 / maxValue) * user.numberOfSessions, theme),
        icon: <UsersIcon size={20}/>,
        //Removing the filter completely until it is fixed on the platform
        // onClick: addFilter ? () => addFilter(user.id, "userDataPoints") : undefined,
        // tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.topBlockedUserCard.title"
            size={size}
            preview={preview}
            loading={loading && !data.blockedUserDataPoints}
            noData={!loading && !data.blockedUserDataPoints}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
            activeFilter
        />
    );
};