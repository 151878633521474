import React from "react";
import {
    getArrayDataContent,
    LinkField,
    ListCardContent,
    SelectedChoiceField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

export interface SiteGatewayCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            gateway?: string | number;
            modelSeries?: "S" | "T";
        };
    };
}

export const SiteGatewayCard: React.FC<SiteGatewayCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const scaleUnitChoices = getArrayDataContent(useCrudProps(apiResources.gatewayScaleUnits)[0]?.data);

    return (
        <BaseCard
            id="SiteGatewayCard"
            title="tesseract.appliances.dashboard.summary.gateway.tabTitle"
            size={size}
            preview={preview}
        >
            <ListCardContent
                resource={details?.gateway ? apiResources.gateways : undefined}
                params={{id: details?.gateway}}
                noDataMessage={!details?.gateway ? "tesseract.appliances.dashboard.summary.gateway.noGateway" : undefined}
            >
                <LinkField
                    source="name"
                    label="tesseract.appliances.dashboard.summary.gateway.name"
                    link={getAzureResourceLink("applicationId", isDemo)}
                    sortable
                />
                <LinkField
                    source="virtualWan.name"
                    label="tesseract.appliances.dashboard.summary.gateway.vwan"
                    link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                    sortable
                />
                <LinkField
                    source="hubName"
                    label="tesseract.appliances.dashboard.summary.gateway.hubName"
                    link={getAzureHubLink("hubId", isDemo)}
                    sortable
                />
                <SelectedChoiceField
                    source="region"
                    label="tesseract.appliances.dashboard.summary.gateway.region"
                    choices={regionChoices}
                    sortable
                />
                <SelectedChoiceField
                    label="tesseract.appliances.dashboard.summary.gateway.size"
                    source="scaleUnit"
                    key="scaleUnit"
                    choices={scaleUnitChoices}
                    skip={(value: any) => value.scaleUnit === 0}
                />
            </ListCardContent>

        </BaseCard>
    );
};