// AzureLogAnalyticsOMS.tsx
import React from "react";
import { BooleanInput, TextInput, PasswordConfirmInput, Validator } from "@cuda-react/core";
import { get } from "lodash";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    azureMonitorAgentForm: {
        marginBottom: theme.spacing(4)
    }
}));

const AzureLogAnalyticsOMS = () => {
    const classes = useStyles();

    return (
        <div className={classes.azureMonitorAgentForm}>
            <BooleanInput
                source="settings.oms_workspace.enabled"
                label="tesseract.settings.tabs.oms.oms"
                additionalInfoLabel="tesseract.settings.tabs.oms.descriptions.oms"
                toggleEnabledText="tesseract.settings.tabs.oms.enabled"
                newStyle
            />
            <TextInput
                source="settings.oms_workspace.id"
                label="tesseract.settings.tabs.oms.id"
                disable={(value: any, allValues: any) => !get(allValues, "settings.oms_workspace.enabled")}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <PasswordConfirmInput
                label="tesseract.settings.tabs.oms.key"
                source="settings.oms_workspace.key"
                disable={(value: any, allValues: any) => !get(allValues, "settings.oms_workspace.enabled")}
                validate={[]}
                noConfirm
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
        </div>
    );
};

export default AzureLogAnalyticsOMS;