import React from "react";
import {DateField, ListCardContent, TextField} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

export interface ConfigurationUpdatesCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            activeSerial?: string;
            gatewayType?: string;
        };
        type: "gateways" | "sites";
    }
}

export const ConfigurationUpdatesCard: React.FC<ConfigurationUpdatesCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const {id, activeSerial} = details || {};
    const unsupported = additionalProps?.type === "gateways" && additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";

    return (
        <BaseCard
            id="ConfigurationUpdatesCard"
            title="tesseract.appliances.dashboard.firmware.updates.tabTitle"
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        >
            <ListCardContent
                getFrom={{
                    resource: activeSerial ? (type !== "sites" ? apiResources.configurationOnPremUpdate : apiResources.configurationUpdate) : undefined,
                    options: {pollInterval: 300000},
                    params: {id: activeSerial, siteId: id}
                }}
            >
                <DateField label="tesseract.appliances.dashboard.firmware.updates.lastUpdate" source="lastUpdate"/>
                <DateField label="tesseract.appliances.dashboard.firmware.updates.lastSeen" source="lastSeen"/>
                <TextField label="tesseract.appliances.dashboard.firmware.updates.remoteIp" source="remoteIp"/>
            </ListCardContent>
        </BaseCard>
    );
};