import React from "react";
import {StatusIconField, TextField} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, StatisticsTable} from "@stratos/reporting";
import {hotfixUpdateStatusIcons} from "../../../../components/iconMapping";

export interface FirmwareHotfixesCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            activeSerial?: string;
            gatewayType?: string;
        };
        type: "gateways" | "sites";
    }
}

export const FirmwareHotfixesCard: React.FC<FirmwareHotfixesCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const {id, activeSerial} = details || {};
    const unsupported = additionalProps?.type === "gateways" && additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";

    return (
        <BaseCard
            id="FirmwareHotfixesCard"
            title="tesseract.appliances.dashboard.firmware.hotfixes.tabTitle"
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        >
            <StatisticsTable
                resource={activeSerial ? (type !== "sites" ? apiResources.configurationOnPremHotfixes : apiResources.configurationHotfixes) : undefined}
                pollInterval={300000}
                params={{id: activeSerial, siteId: id}}
                formatData={(data) => data?.hotfixes || []}
            >
                <StatusIconField
                    label="tesseract.appliances.dashboard.firmware.hotfixes.status"
                    source="state"
                    tooltipSource="state"
                    tooltipPrefix="tesseract.appliances.dashboard.firmware.state."
                    iconMap={hotfixUpdateStatusIcons}
                    width={72}
                />
                <TextField
                    label="tesseract.appliances.dashboard.firmware.hotfixes.name"
                    source="name"
                />
            </StatisticsTable>
        </BaseCard>
    );
};