import React from "react";
import {
    ChipArrayField,
    CrudTypes,
    formatDateTime,
    getDataContent,
    ListCardContent,
    useCrudSubscription
} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";
import {uniq} from "lodash";

export interface DeploymentStatusCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            serials: string[] | [];
        };
    }
}

const combineData = (data1: any = {}, data2: any = {}) => {
    const keys = uniq([...Object.keys(data1), ...Object.keys(data2)]);
    return keys.reduce((combinedData, key) => ({
        ...combinedData,
        [key]: [...(data1[key] ? [data1[key]] : []), ...(data2[key] ? [data2[key]] : [])]
    }), {});
};

export const DeploymentStatusCard = (props: DeploymentStatusCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const {serials} = details || {};
    const dataSerial1 = getDataContent(useCrudSubscription(CrudTypes.GET, serials?.[0] ? apiResources.applianceDeploymentStatus : undefined, {id: serials?.[0]}, {pollInterval: 300000})[0].data, {});
    const dataSerial2 = getDataContent(useCrudSubscription(CrudTypes.GET, serials?.[1] ? apiResources.applianceDeploymentStatus : undefined, {id: serials?.[1]}, {pollInterval: 300000})[0].data, {});
    const combinedData = {
        serial: serials,
        ...combineData(dataSerial1, dataSerial2)
    };

    return (
        <BaseCard
            id="DeploymentStatusCard"
            title="tesseract.appliances.dashboard.deployment.status.title"
            size={size}
            preview={preview}
        >
            <ListCardContent data={combinedData}>
                {serials?.[1] ? (
                    <ChipArrayField
                        label="tesseract.appliances.dashboard.deployment.status.serial"
                        source="serial"
                    />
                ) : null}
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.status"
                    source="statusName"
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.action"
                    source="actionName"
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.message"
                    source="message"
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.registered"
                    source="registered"
                    render={(value: string) => formatDateTime(value)}
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.lastSeen"
                    source="lastSeen"
                    render={(value: string) => formatDateTime(value)}
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.completed"
                    source="completed"
                    render={(value: string) => formatDateTime(value)}
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.localIp"
                    source="localIp"
                />
                <ChipArrayField
                    label="tesseract.appliances.dashboard.deployment.status.remoteIp"
                    source="remoteIp"
                />
            </ListCardContent>
        </BaseCard>
    );
};