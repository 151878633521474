import {get} from "lodash";

const formatIncomingCriteria = (criteria: any) => {
    const type = get(criteria, "type") || null;

    if (type === "site" && criteria.applianceId) {
        return {
            type: "ingressSite",
            wan: criteria.wan,
            port: criteria.port,
            applianceId: criteria.applianceId,
            address: criteria.address
        };
    } else if (type === "category") {
        return {type: criteria.type, categories: criteria.categories};
    } else if (type === "domain") {
        return {type: criteria.type, domains: criteria.domains};
    } else if (type === "application") {
        return {type: criteria.type, applications: criteria.applications, allSites: true};
    } else if (type === "secureEdgeConnector") {
        return {type: criteria.type, secureEdgeConnector: criteria.connectors, allSites: true};
    } else if (type === "network") {
        return {type: criteria.type, networks: criteria.networks, allSites: true};
    } else if (type === "site") {
        return {
            type: criteria.type,
            allSites: criteria.allSites,
            sites: (criteria.sites || []).map((site: any) => ({
                ...site,
                networks: (site.networks && site.networks.length > 0) ? site.networks : ["*"]
            }))
        };
    } else if (type === "onPremGateway" && !criteria.applianceId) {
        return {
            type: criteria.type,
            allGateways: criteria.allGateways,
            gateways: (criteria.gateways || []).map((gateway: any) => ({
                ...gateway,
                networks: (gateway.networks && gateway.networks.length > 0) ? gateway.networks : ["*"]
            }))
        };
    } else if (type === "userOrGroup") {
        return {type: criteria.type, users: criteria.users, groups: criteria.groups, allSites: true};
    } else {
        return criteria;
    }
};

const formatOutgoingCriteria = (criteria: any) => {
    switch (get(criteria, "type")) {
        case "category":
            return {type: criteria.type, categories: criteria.categories};
        case "domain":
            return {type: criteria.type, domains: criteria.domains};
        case "application":
            return {type: criteria.type, applications: criteria.applications};
        case "secureEdgeConnector":
            return {type: criteria.type, connectors: criteria.secureEdgeConnector};
        case "network":
            return {type: criteria.type, networks: criteria.networks};
        case "site":
            return {
                type: criteria.type,
                allSites: criteria.allSites,
                sites: (criteria.sites || []).map((site: any) => ({
                    ...site,
                    networks: site.networks[0] === "*" ? [] : site.networks
                }))
            };
        case "onPremGateway":
            if (!criteria.applianceId) {
                return {
                    type: criteria.type,
                    allGateways: criteria.allGateways,
                    gateways: (criteria.gateways || []).map((gateway: any) => ({
                        ...gateway,
                        networks: gateway.networks[0] === "*" ? [] : gateway.networks
                    }))
                };
            }
            return criteria;
        case "ingressSite":
            return {
                type: "site",
                wan: criteria.wan,
                port: criteria.port,
                address: criteria.address,
                applianceId: criteria.applianceId
            };
        case "userOrGroup":
            return {type: criteria.type, users: criteria.users, groups: criteria.groups};
        default:
            return criteria;
    }
};

/**
 * Formats outgoing form's criteria to the format expected by the service.
 *
 * @param virtualWanId
 * @returns formatted data
 */
export const formatRequestCriteria = (virtualWanId?: any) => ({source, destination, ...data}: any) => ({
    ...data,
    source: formatOutgoingCriteria(source),
    destination: formatOutgoingCriteria(destination),
    icmp: data.icmp ?? data.action,
    virtualWanId: data.virtualWanId || virtualWanId,
});
/**
 * Formats incoming form's criteria from the format expected by the service.
 *
 * @returns formatted data
 */
export const formatResourceCriteria = ({source, destination, ...data}: any) => ({
    ...data,
    source: formatIncomingCriteria(source),
    destination: formatIncomingCriteria(destination)
});