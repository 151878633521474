import React from "react";
import { BooleanInput,
    TextInput,
    PasswordInput,
    validateMaxLength,
    validateRegexMemo,
    validateUrl,
} from "@cuda-react/core";
import { get } from "lodash";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    azureMonitorAgentForm: {
        marginBottom: theme.spacing(4)
    }
}));

const AzureLogAnalyticsDaemon = () => {
    const classes = useStyles();
    const validateUuid = validateRegexMemo(/^([a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12})?$/, "tesseract.validation.uuid");

    return (
        <div className={classes.azureMonitorAgentForm}>
            <BooleanInput
                source="settings.azure_log_analytics.enabled"
                label="tesseract.settings.tabs.oms.azureLogAnalyticsDaemon"
                toggleEnabledText="tesseract.settings.tabs.oms.enabled"
                newStyle
            />
            <TextInput
                source="settings.azure_log_analytics.clientId"
                label="tesseract.settings.tabs.oms.clientId"
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={validateUuid}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <PasswordInput
                source="settings.azure_log_analytics.clientSecret"
                label="tesseract.settings.tabs.oms.clientSecret"
                isRequired
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={validateMaxLength(255)}
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.tenantId"
                label="tesseract.settings.tabs.oms.tenantId"
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={validateUuid}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.immutableId"
                label="tesseract.settings.tabs.oms.immutableId"
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={validateMaxLength(255)}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.stream"
                label="tesseract.settings.tabs.oms.stream"
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={validateMaxLength(255)}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.logsUrl"
                label="tesseract.settings.tabs.oms.logIngestionUrl"
                disable={(value: any, allValues: any) => !get(allValues, "settings.azure_log_analytics.enabled")}
                validate={[validateUrl, validateMaxLength(255)]}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
        </div>
    );
};

export default AzureLogAnalyticsDaemon;