import {Table, TableBody, TableCell, TableRow, Theme, useTheme} from "@mui/material";
import classNames from "classnames";
import {get, merge} from "lodash";
import React from "react";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import NoDataMessage from "../../loading/NoDataMessage/NoDataMessage";
import {getFrom, getFromSupportedProps, useCardGetFrom} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {BaseFieldProps} from "../../fields";
import {ClonableChildren} from "../../../utils/commonTypes";

export const styles = (theme: Theme) => createStyles({
    tableRow: {
        height: 34,
        overflow: "visible"
    },
    tableCells: {
        fontSize: 13,
        border: "none",
        height: 16,
        lineHeight: "15px",
        padding: theme.spacing(0, 1),
        overflow: "visible",
        whiteSpace: "normal"
    },
    tableLabelCell: {
        paddingLeft: theme.spacing(4),
        width: 120
    },
    tableLabelIconCell: {
        paddingLeft: theme.spacing(3),
        width: `calc(120px + ${theme.spacing(3)})`
    },
    table: {
        tableLayout: "auto",
        overflowY: "visible",
        overflowX: "hidden",
        marginTop: 0
    },
    message: {
        display: "flex",
        justifyContent: "center"
    },
    labelIcon: {
        height: "16px !important",
        marginBottom: -3,
        width: 24,
        padding: theme.spacing(0, 1, 0, 0)
    }
});

const useStyles = makeOverrideableStyles("ListCardContent", styles);

export interface ListCardContentProps<Data, Props extends object> extends StyledComponentProps<typeof styles>, getFromSupportedProps<Data, Props> {
    /**
     * provide a classname to be set on each cell.
     *
     * This is now deprecated, please use the 'classes' method of overriding styles.
     * @deprecated
     */
    cellClassName?: string,
    /**
     * a list of components to render using the supplied data. This component relies on props on its children to correctly render the list rows.
     *
     * Each component is rendered with the prop 'data', which has the associated value stored at data.source, or for arraySource it is at the root of data.
     * All cuda-react "*Field" components are made to work seamlessly as children of ListCardContent and [Table](/?path=/docs/core-components-table-connectedtable--connected-table) components.
     *
     * @property {string} source for each child that has a 'source' prop, one row is rendered. The source prop is also used by all "*Field" components to target the section of the row data they should use to render.
     * @property {string[]} arraySource for each child that has an 'arraySource' prop, one row is rendered per entry in the row data located at [arraySource].
     * @property {boolean} noLabel if true the label will not be rendered and the value will occupy the whole width
     * @property {string} label used to generate the row label (except if the prop 'isMap' is set)
     * @property {string} labelIcon used to optionally render an icon. This should provided as an Element (i.e. MyIcon rather than \<MyIcon /\>).
     * @property {boolean} skipMissing this is used to override the global setting for this component.
     * @property {boolean} skip if truthy, the child is always not rendered.
     * @property {boolean} isMap if truthy, then the row data at [source] is expected to be an object, and one row is rendered per entry, using the 'key' as the label.
     * @property {string} rowClassName class to apply to the table row for this child
     * @property {string} cellClassName class to apply to each table cell for this child
     */
    children?: ClonableChildren<BaseFieldProps>,
    /**
     * CRUD configuration, defining the values to use for fetching data via the CRUD framework. You can provide the CRUD parameters either
     * inside a getFrom object, or as individual props themselves.
     *
     * See [cardGetFromPropTypes](/?path=/docs/core-hooks-card-hooks--page#cardgetfromproptypes--codeobjectcode) for full definition.
     */
    getFrom?: getFrom<Data, Props>,
    /** if true, forcibly show the no data message will be rendered */
    noData?: boolean,
    /** message to display when either noData prop is true, or the resolved content is empty. */
    noDataMessage?: string,
    /**
     * if true rows are not rendered if no truthy value is found at 'source' or 'arraySource'.
     */
    skipMissing?: boolean,
    /**
     *  if false, content is not rendered (although CRUD data is still collected).
     *
     *  This prop is passed down by [TabbedCard](/?path=/docs/core-components-cards-tabbedcard--tabbed-card) when tab is not currently selected.
     */
    visible: boolean
}

/**
 * Content for a Card that contains a list table (i.e 2-column headerless table, with label on left and data on right).
 *
 * The data can either be given via the "data" prop, or from a CRUD resource defined by the "getFrom" prop object.
 *
 * This component relies on props on the children to define what is to be rendered. See the "children" prop description for more information.
 */
export const ListCardContent = <Data, Props extends ListCardContentProps<Data, Props>>(props: Props) => {
    const {
        data,
        noData,
        noDataMessage,
        visible,
        getFrom,
        children,
        skipMissing,
        cellClassName
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const theme = useTheme();

    const [getFromData, getFromStatus, error] = useCardGetFrom(getFrom, props);
    const content = getFromData || data || {};
    const isLoading = !!getFromStatus;
    const childrenArray = React.Children.toArray(children);

    const renderRow = (key: string, child: React.ReactElement, data: any, label?: React.ReactNode) => {
        const labelIcon = child.props.labelIcon && React.createElement(
            child.props.labelIcon,
            merge(
                {classes: {icon: classes.labelIcon}, data},
                child.props.iconProps
            )
        );
        const labelText = typeof label === "string" ? translate(label) : label;
        const cellValue = React.cloneElement(child, {data});

        return (
            <TableRow key={key} className={classNames(classes.tableRow, child.props.rowClassName)}>
                {!child.props.noLabel ? (
                    <TableCell
                        className={classNames(classes.tableCells, classes.tableLabelCell, child.props.labelIcon && classes.tableLabelIconCell, cellClassName, child.props.cellClassName)}
                        style={{color: theme.palette.text.secondary}}
                    >
                        {labelIcon || null}
                        {labelText || null}
                    </TableCell>
                ) : null}
                <TableCell
                    className={classNames(classes.tableCells, cellClassName, child.props.cellClassName)}
                    colSpan={child.props.noLabel ? 2 : undefined}
                >
                    {cellValue}
                </TableCell>
            </TableRow>
        );
    };

    const tableContent = childrenArray.filter((child): child is React.ReactElement => !!child).map((child) => {
        const childValue = get(content, child.props.arraySource || child.props.source);
        const skipIfMissing = child.props.skipMissing !== undefined ? child.props.skipMissing : skipMissing;
        const skip = child.props.skip !== undefined ? child.props.skip(content) : false;
        const childHasValue = Array.isArray(childValue) ? childValue.length > 0 : (!!childValue || childValue === false || childValue === 0);

        if (!skip && (!skipIfMissing || childHasValue)) {
            if (child.props.isMap) {
                const dataKeys = Object.keys(childValue);
                return dataKeys.map((item) => {
                    const data = {[child.props.source]: get(content, child.props.source)[item]};
                    return renderRow(
                        child.props.source + "-" + item,
                        child,
                        data,
                        item
                    );
                });
            }
            if (child.props.arraySource) {
                const labelSource = child.props.labelSource || "name";
                return childValue.map((value: any) => value ? renderRow(
                    child.props.source + "-" + get(value, labelSource),
                    child,
                    value,
                    child.props.label || get(value, labelSource)
                ) : null);
            }
            return renderRow(
                child.props.source,
                child,
                content,
                child.props.label
            );
        }
    }).filter((row) => !!row);

    const hasData = !noData && Object.keys(tableContent).length > 0;

    if (!visible) {
        return null;
    }

    return (
        <React.Fragment>
            {!error && hasData && (
                <Table className={classes.table}>
                    <TableBody>
                        {tableContent}
                    </TableBody>
                </Table>
            ) || (
                <div className={classes.message}>
                    {isLoading && !error && (<LoadingMessage/>)}
                    {error && (<NoDataMessage message={error}/>)}
                    {!isLoading && !error && (<NoDataMessage message={noDataMessage}/>)}
                </div>
            )}
        </React.Fragment>
    );
};

ListCardContent.defaultProps = {
    noDataMessage: "cuda.cards.noListData",
    skipMissing: true,
    visible: true
};

export default ListCardContent;