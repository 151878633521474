import React, {useRef} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    PropGateway,
    Select,
    StatusIconField,
    TablePage,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import {SiteIcon} from "@cuda-react/icons";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditApplicationControl from "./CreateEditApplicationControl";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {settingsIcons} from "../../../../../components/iconMapping";
import {get, merge} from "lodash";
import GroupingField from "../GroupingField";
import apiResources from "../../../../../apiResources";


interface ApplicationControlProps {
    type: "site" | "gateway" | string;
    actions?: any
}

const ApplicationControl: React.FC<ApplicationControlProps> = ({type, actions}) => {
    const isGateway = type === "gateway";
    const tableRefreshRef = useRef<any>();
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.applicationControlActions)[0]?.data);
    const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.applicationControlDefault, {filter: {type}});
    const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.applicationControlDefault);
    const defaultAction = getDataContent(defaultActionData);
    const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
    const handleUpdateDefaultAction = (action: string) => {
        if (action && !defaultLoading && action !== defaultAction) {
            registerAction("Default Update", {action});
            return updateDefaultAction({action, filter: {type}}).then(refreshDefaultAction);
        }
    };
    const [, , callApplicationControl] = useCrudFetch(CrudTypes.CREATE, apiResources.applicationControl, {});
    const handleOrderClick = (id: any, direction: string, data: any) => callApplicationControl({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField();
    const renderSiteGroupName = generateChipArrayProps("scope")({data: {scope: {type: isGateway ? "gateway" : "site"}}}).render;
    const registerAction = useMixpanel(isGateway ? "Gateway ACL" : "Site ACL");

    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={[
                "tesseract.securityAndAccess.network",
                isGateway ? "tesseract.securityAndAccess.gateway" : "tesseract.securityAndAccess.site"
            ]}
            titleActions={
                <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditApplicationControl}
                    type={type}
                    onSuccess={tableRefreshRef.current}
                />
            }
            authenticated
            resource={apiResources.applicationControlAll}
            exportResource={{resource: apiResources.applicationControlExport, filename: "applicationControl"}}
            actions={[
                <InputLabel
                    key="defaultAction"
                    label="tesseract.security.applicationControl.settings.defaultAction"
                    minimised
                    // @ts-ignore
                    left
                >
                    <Select
                        key="defaultAction"
                        id="defaultAction"
                        // @ts-ignore
                        source="defaultAction"
                        value={!defaultLoading ? defaultAction : ""}
                        // @ts-ignore
                        onChange={(eventOrValue) => eventOrValue.target ? handleUpdateDefaultAction(eventOrValue.target.value) : handleUpdateDefaultAction(eventOrValue)}
                        onBlur={() => {
                        }}
                        disabled={!!defaultLoading}
                        meta={{}}
                        choices={actionChoices}
                    />
                </InputLabel>
            ]}
            params={(data) => merge({}, formatSourceDestinationFilters(data), {filter: {type}})}
            formatData={formatUserGroupInTableData}
            minCellWidth={128}
            groupBy="scope"
            groupField={
                <GroupingField
                    createEditComponent={CreateEditApplicationControl}
                    // @ts-ignore
                    render={renderSiteGroupName}
                    type={type}
                    onSuccess={tableRefreshRef.current}
                />
            }
            defaultItemsPerPage={5000}
            flat
            refreshRef={tableRefreshRef}
        >
            <ActionButtonsField
                left
                label="tesseract.security.applicationControl.settings.order"
                width={96}
                source="id"
                filter="selectarray"
                filterProps={{
                    label: "tesseract.security.applicationControl.settings.scope",
                    source: "scope",
                    resource: isGateway ? apiResources.gateways : apiResources.siteNetworks,
                    optionValue: "uuid",
                    selectAll: true,
                    selectAllValue: isGateway ? "allGateways" : "allSites",
                    selectAllText: `tesseract.security.applicationControl.settings.${isGateway ? "allGateways" : "allSites"}`,
                    iconMap: {allSites: <SiteIcon/>},
                    icon: <SingleSiteIcon/>
                }}
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "increase", data)}
                    disabled={(data: any) => get(data, "order", 0) < 2}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "decrease", data)}
                    disabled={(data: any) => (get(data, "groupTotal", 0) - get(data, "order", 0)) < 1}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.applicationControl.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.applicationControl.settings.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.applicationControl.settings.action"
                iconMap={settingsIcons}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
                width={140}
            />
            <PropGateway
                source="source"
                label="tesseract.security.applicationControl.settings.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: [isGateway && "application", "onPremGateway", "network", "site", "userOrGroup", "secureEdgeConnector"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.applicationControl.settings.destination"
                editProps={generateChipArrayProps("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["network", "application", "onPremGatewayNetworks", "site", "secureEdgeConnector"]
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                width={96}
                source="id"
            >
                <CreateEditButtonDialog
                    component={CreateEditApplicationControl}
                    type={type}
                    onSuccess={tableRefreshRef.current}
                />
                <DeleteDialog
                    resource={apiResources.applicationControl}
                    title="tesseract.security.applicationControl.settings.delete.title"
                    message="tesseract.security.applicationControl.settings.delete.body"
                    onSuccess={() => {
                        tableRefreshRef.current?.();
                        registerAction("Delete");
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default ApplicationControl;