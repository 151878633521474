import React from "react";
import {BaseBarCard} from "../../baseCards";
import {colorFunction, numberFormatter} from "../../../utils";
import {DashboardCardProps} from "../../../typesAndConstants";
import {BarGridEntry} from "../../statistics/BarGrid";
import {sortBy} from "lodash";
import {NetworkIcon, StratosSupportIcon} from "@cuda-react/icons";
import Flag from "react-world-flags";
import {useCardFetch} from "../../../hooks";
import apiResources from "../../../apiResources";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaUserCountries";
import {usei18nCountries} from "../../../utils/countries";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material";

type CountryTuple = [string, number];

export const ZTNAUserGeoLocationCard = (props: DashboardCardProps) => {
    const {preview, size} = props;
    const {data = {}, loading} = useCardFetch({url: !preview ? apiResources.ZTNAUserCountries : undefined});
    const {countries = {}} = (preview ? generatePreviewData() : data) || {};

    const mappedCountries: CountryTuple[] = Object.entries(countries);
    const sortedMappedCountries = sortBy(mappedCountries, 1).reverse();
    const maxValue = Math.max(...mappedCountries.map(([__, value]) => value));

    const getCountryNameByCode = usei18nCountries();

    const [translate] = useTranslation();
    const theme = useTheme();

    const entries: BarGridEntry[] = sortedMappedCountries.map(([key, value]) => ({
        title: getCountryNameByCode(key),
        value: size === "small" ? numberFormatter(value) : translate("stratosReporting.ZTNAUserGeoLocation.devices", {value: numberFormatter(value), context: value === 1 ? undefined : "plural"}),
        barProgress: (100.0 / maxValue) * value,
        icon: key ? (key === "ZZ" ? <NetworkIcon/> : (
            <Flag
                code={key}
                fallback={<StratosSupportIcon/>}
                style={{
                    width: 40,
                    height: 20,
                    borderRadius: "50%",
                    marginRight: 0,
                    marginLeft: -6,
                    overflow: "hidden",
                    objectFit: "cover",
                    transform: "scaleX(0.50)",
                    flexShrink: 0
                }}
            />
        )) : <StratosSupportIcon/>,
        color: colorFunction((100.0 / maxValue) * value, theme),
    }));

    return (
        <BaseBarCard
            entries={entries}
            title="stratosReporting.ZTNAUserGeoLocation.title"
            size={size}
            preview={preview}
            loading={loading && !countries}
            noData={!loading && !countries}
            unsupported={data?.instanceId === "_UNSUPPORTED_"}
        />
    );
};