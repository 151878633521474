import {CrudTypes, useCrudFetch, useCrudProps, useGlobalParam} from "@cuda-react/core";
import apiResources from "../apiResources";
import {parseJson} from "../utils/jsonUtils";

export default (dashboardName: string) => {
    const [{data: remoteStoredData}, , refreshDashboard] = useCrudProps(apiResources.customDashboard, {filter: {name: dashboardName}});
    const selectedDashboard = remoteStoredData?.content?.[0];
    const remoteStoredCustomDashboards = parseJson(selectedDashboard?.value, []);
    const idDashboard = selectedDashboard?.id;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    const [, , performCreate] = useCrudFetch(CrudTypes.CREATE, apiResources.customDashboard);
    const [, , performUpdate] = useCrudFetch(CrudTypes.UPDATE, apiResources.customDashboard, {id: idDashboard});
    const handleDashboardFetch = (dashboards: any) => (idDashboard ? performUpdate({
        data: {
            id: idDashboard,
            name: dashboardName,
            value: JSON.stringify(dashboards),
            virtualWanId
        }
    }) : performCreate({
        data: {
            name: dashboardName,
            value: JSON.stringify(dashboards),
            virtualWanId
        }
    })).then(() => refreshDashboard());

    return {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards: !remoteStoredData
    };
};